
import 'reflect-metadata';
import { Vue, Prop, Component } from 'nuxt-property-decorator';
import { themeMap } from '~/helpers/colors';
import { validateTheme } from '~/helpers/validators';

@Component
export default class DrawersMixin extends Vue {
  @Prop({ default: 'white', validator: (value) => validateTheme(value) }) readonly theme!: string;

  get classes() {
    return {
      [`u-background-color-${themeMap[this.theme].background}`]: this.theme && themeMap[this.theme],
      [`u-color-${themeMap[this.theme].foreground}`]: this.theme && themeMap[this.theme],
    };
  }
}
