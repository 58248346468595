
























/**
 * Base Layout - A grid-like layout system.
 *
 * A BaseLayout must only ever have BaseLayoutItem components as immediate children.
 *
 * The layout object provides us with a column-style layout system. This file
 * contains the basic structural elements, but classes should be complemented
 * with width utilities, for example:
 *
 *   <BaseLayout>
 *     <BaseLayoutItem r="1/2">
 *     </BaseLayoutItem>
 *     <BaseLayoutItem r="1/2">
 *     </BaseLayoutItem>
 *   </BaseLayout>
 *
 * The above will create a two-column structure in which each column will
 * fluidly fill half of the width of the parent. We can have more complex
 * systems:
 *
 *   <BaseLayout>
 *     <BaseLayoutItem desktopSmall="1/3">
 *     </BaseLayoutItem>
 *     <BaseLayoutItem r="1/2" desktopSmall="1/3">
 *     </BaseLayoutItem>
 *     <BaseLayoutItem r="1/2" desktopSmall="1/3">
 *     </BaseLayoutItem>
 *   </BaseLayout>
 *
 * The above will create a system in which the first item will be 100% width
 * until we enter our desktopSmall breakpoint, when it will become 33.333% width. The
 * second and third items will be 50% of their parent, until they also become
 * 33.333% width at the desktopSmall breakpoint.
 *
 * We can also manipulate entire layout systems by adding a series of modifiers
 * to the BaseLayout block. For example:
 *
 *   <BaseLayout reverse>
 *
 * This will reverse the displayed order of the system so that it runs in the
 * opposite order to our source, effectively flipping the system over.
 *
 *   <BaseLayout fill-direction="around">
 *
 * This will cause the system to fill up from the center, putting space around the layout items.
 * Default behaviour is to fill up the layout system from the left, similar to flex-start.
 *
 *   <BaseLayout gutter="medium">
 *
 * This will cause each layout item to have a "medium" gutter size, inline with the spacing units.
 *
 *   <BaseLayout align="stretch">
 *
 * This will cause all the layout items to be of the same height.
 */

import { mixins, Prop, Component } from 'nuxt-property-decorator';
import layoutMixin from '../layoutMixin.vue';

const directions = ['row', 'column'];

@Component
export default class BaseLayout extends mixins(layoutMixin) {
  @Prop({ default: false }) readonly autoWidth!: boolean;
  @Prop({ default: false }) readonly fullWidth!: boolean;
  @Prop({ default: false }) readonly reverseFromSmallDesk!: boolean;
  @Prop({ default: false }) readonly spaceBetweenFromSmallDesk!: boolean;
  @Prop({ default: 'row', type: String, validator: (value) => directions.includes(value) })
  readonly direction!: string;
}
