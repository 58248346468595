var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    _vm.props.tag,
    _vm._b(
      {
        tag: "component",
        staticClass: "v-section",
        class: [_vm.data.class, _vm.data.staticClass],
        attrs: { "data-size": _vm.props.size, "data-theme": _vm.props.theme }
      },
      "component",
      _vm.data.attrs,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }