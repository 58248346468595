export const mapObject = <Object>(object: Object, mapFunction: Function): Object =>
  Object.fromEntries(
    Object.entries(object).map(([key, value], index) => [key, mapFunction(value, key, index)])
  );

export const filterObject = function<Object extends Record<string | number, any>>(
  object: Object,
  filterFunction: (value: any, key: string | number) => boolean
): Partial<Object> {
  const result: any = {};
  let key: string | number;

  for (key in object) {
    if ({}.hasOwnProperty.call(object, key) && !!filterFunction(object[key], key)) {
      result[key] = object[key];
    }
  }

  return result;
};
