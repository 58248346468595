var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    _vm._g(
      _vm._b(
        {
          tag: "component",
          class: [_vm.buttonClasses, _vm.additionalButtonClasses],
          attrs: { type: _vm.nativeType }
        },
        "component",
        _vm.$attrs,
        false
      ),
      _vm.buttonListeners
    ),
    [
      _vm.active && _vm.type === "outline"
        ? _c(
            "span",
            { staticClass: "v-btn__left-content" },
            [
              _c("BaseIcon", {
                attrs: {
                  icon: "Tick",
                  theme: _vm.themeMap[_vm.theme].foreground,
                  size: "base"
                }
              })
            ],
            1
          )
        : _vm.$slots["left-content"]
        ? _c(
            "span",
            { staticClass: "v-btn__left-content" },
            [_vm._t("left-content")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c("BaseIcon", {
            attrs: {
              icon: "LoadingSpinner",
              theme: _vm.loadingSpinnerColor,
              size: _vm.loadingSpinnerSize
            }
          })
        : _c(
            "span",
            { staticClass: "v-btn__content", class: _vm.contentClasses },
            [_vm._t("default")],
            2
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }