import { AxiosError } from 'axios';
import * as SentryTypes from '@sentry/minimal';
import { Data } from '../interfaces';
import { Severity } from '~/node_modules/@sentry/types';

export class SentryLogger {
  protected sentry: typeof SentryTypes;

  constructor(sentry: typeof SentryTypes) {
    this.sentry = sentry;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public breadcrumb(category: string, message: string, level: Severity): void {
    // if (this.sentry) {
    //   this.sentry.addBreadcrumb({
    //     category,
    //     message,
    //     level,
    //   });
    // }
  }

  public captureException(error: Error): void {
    this.sentry.captureException(error);
  }

  public captureAxiosException(error: AxiosError, payload: Partial<Data> = {}): string | undefined {
    const { response } = error;
    const { code, httpCode, exception, method, message } = payload;
    const extra = this.extra(payload);

    // if there is no response it means there was a request error
    if (!response) {
      return this.sentry.captureException(
        new Error(`Request error: ${method} ${message} ${code || ''}`),
        extra
      );
    }
    const { status } = response;

    if (status >= 500) {
      return this.sentry.captureException(error, extra);
    }
    if (status === 401 && process.env.captureUnauthorizedExceptions === 'true') {
      return this.sentry.captureMessage(`Unauthorized exception: ${method} ${httpCode} ${exception}`, extra);
    }
    if (status === 403 && process.env.captureForbiddenExceptions === 'true') {
      return this.sentry.captureMessage(`Forbidden exception: ${method} ${httpCode} ${exception}`, extra);
    }
    if (status === 404 && process.env.captureNotFoundExceptions === 'true') {
      return this.sentry.captureMessage(`Not found exception: ${method} ${httpCode} ${exception}`, extra);
    }
    if (status >= 400 && process.env.captureBusinessExceptions === 'true') {
      return this.sentry.captureMessage(`Business exception: ${method} ${httpCode} ${exception}`, extra);
    }
    if (status >= 300 && process.env.captureRedirectExceptions === 'true') {
      return this.sentry.captureMessage(`Redirect exception: ${method} ${httpCode} ${exception}`, extra);
    }
    if (process.env.debugLevel === 'debug') {
      return this.sentry.captureMessage(`Debug exception: ${method} ${httpCode} ${exception}`, extra);
    }
  }

  protected extra(data: Partial<Data>): Record<string, any> {
    return {
      extra: {
        [`Axios`]: data.axios,
        [`Axios error`]: data.isAxiosError,
        [`Axios time start`]: data.start,
        [`Axios time finish`]: data.end,
        [`Axios time total (ms)`]: data.timing,
        [`Exception`]: data.exception,
        [`Exception code`]: data.code,
        [`Exception message`]: data.message,
        [`Exception name`]: data.name,
        [`Google Optimise experiments`]: {
          [`Registration`]: process.env.registrationExperimentId,
          [`Registration (simplified)`]: process.env.simplifiedRegistrationExperimentId,
        },
        [`Google Tag Manager ID`]: process.env.gtmId,
        [`Process`]: data.process,
        [`Request ID`]: data.id,
        [`Request method`]: data.method,
        [`Request URL`]: data.url,
        [`Request params`]: data.params,
        [`Request headers`]: data.headers,
        // [`Request body`]: data.body,
        [`Response data HTTP Code`]: data.httpCode,
        [`Response data success`]: data.success,
        [`Response data errors`]: data.errors,
        [`Response data messages`]: data.messages,
        [`Response data meta`]: data.meta,
        // [`Response data body`]: data.data,
        [`Response HTTP headers`]: data.resHeaders,
        [`Response HTTP status`]: data.status,
        [`Response HTTP status text`]: data.statusText,
      },
    };
  }
}
