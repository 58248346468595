// import Vue from 'vue';
import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '~/store/index';

type ScrollTypes = 'THROTTLED' | 'LAZY_THROTTLED' | 'DEBOUNCED' | 'LAZY_DEBOUNCED';

const THROTTLED: string = 'THROTTLED';
const LAZY_THROTTLED: string = 'LAZY_THROTTLED';
const DEBOUNCED: string = 'DEBOUNCED';
const LAZY_DEBOUNCED: string = 'LAZY_DEBOUNCED';

const types = {
  SCROLL_UPDATE_THROTTLED: 'SCROLL_UPDATE_THROTTLED',
  SCROLL_UPDATE_LAZY_THROTTLED: 'SCROLL_UPDATE_LAZY_THROTTLED',
  SCROLL_UPDATE_DEBOUNCED: 'SCROLL_UPDATE_DEBOUNCED',
  SCROLL_UPDATE_LAZY_DEBOUNCED: 'SCROLL_UPDATE_LAZY_DEBOUNCED',
  SCROLL_UPDATE_DISABLED_STATUS: 'SCROLL_UPDATE_DISABLED_STATUS',
};

export interface ScrollPosition {
  position: number;
}

interface ScrollState extends ScrollPosition {
  type: ScrollTypes;
}

export interface ModuleState {
  disabled: boolean;
  throttled: ScrollPosition;
  lazyThrottled: ScrollPosition;
  debounced: ScrollPosition;
  lazyDebounced: ScrollPosition;
}

/**
 * The scroll module provides throttled access to the window's scroll event.
 * It provides two entries, one more lazily throttled than the other. The lazy
 * one is ideal for more heavier callback scripts.
 */

export const state = (): ModuleState => ({
  disabled: false,
  throttled: {
    position: 0,
  },
  lazyThrottled: {
    position: 0,
  },
  debounced: {
    position: 0,
  },
  lazyDebounced: {
    position: 0,
  },
});

export const actions: ActionTree<ModuleState, RootState> = {
  update({ commit }, newState: ScrollState) {
    // this.$logger.store(`action`, `[scroll/update]`, { newState }); disabled for performance reasons, enable to debug scrolling

    switch (newState.type) {
      case THROTTLED:
        commit(types.SCROLL_UPDATE_THROTTLED, newState.position);
        break;
      case LAZY_THROTTLED:
        commit(types.SCROLL_UPDATE_LAZY_THROTTLED, newState.position);
        break;
      case DEBOUNCED:
        commit(types.SCROLL_UPDATE_DEBOUNCED, newState.position);
        break;
      case LAZY_DEBOUNCED:
        commit(types.SCROLL_UPDATE_LAZY_DEBOUNCED, newState.position);
        break;
      default:
    }
  },
  setScrollDisabled({ commit }, isDisabled: boolean) {
    // this.$logger.store(`action`, `[scroll/setScrollDisabled]`, isDisabled); disabled for performance reasons, enable to debug scrolling

    commit(types.SCROLL_UPDATE_DISABLED_STATUS, isDisabled);
  },
};

export const mutations: MutationTree<ModuleState> = {
  [types.SCROLL_UPDATE_THROTTLED](state, position: number) {
    state.throttled.position = position;
  },
  [types.SCROLL_UPDATE_LAZY_THROTTLED](state, position: number) {
    state.lazyThrottled.position = position;
  },
  [types.SCROLL_UPDATE_DEBOUNCED](state, position: number) {
    state.debounced.position = position;
  },
  [types.SCROLL_UPDATE_LAZY_DEBOUNCED](state, position: number) {
    state.lazyDebounced.position = position;
  },
  [types.SCROLL_UPDATE_DISABLED_STATUS](state, isDisabled: boolean) {
    state.disabled = isDisabled;
  },
};
