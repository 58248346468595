/**
 * Closes overlays with the use of browser back button or when the route changes.
 *
 * Popstate event fires when browser back/forward button is clicked.
 * The event is fired before router beforeEach, so by using a flag and route path params
 * we can identify when browser back button is clicked.
 */
import zendesk from '~/helpers/zendesk';

export default function({ app, store }) {
  let externalNavigation;
  let prevPath;

  window.onpopstate = () => (externalNavigation = true);

  app.router.beforeEach((to, from, next) => {
    const browserBackButtonClicked = externalNavigation && to.fullPath === prevPath;

    if (browserBackButtonClicked && isOverlayOpen(store.state)) {
      // Whilst Vue Router is blocking the page routing, the browsers path history is still updating.
      // This keeps it in sync with the vue router when navigating with the browser back/forward buttons.
      window.history.go(1);
      closeOverlays(store);
      if (zendesk.isHidden()) zendesk.toggleLauncher(true);
    } else {
      const isFromToysToToysAllPage = to.name === 'toys-all' && from.name === 'toys';
      if (isOverlayOpen(store.state) && isPageChange(to, from)) closeOverlays(store, isFromToysToToysAllPage);
      next();
      prevPath = from.fullPath;
    }
    externalNavigation = false;
  });
}

function closeOverlays(store, isToysAllPage) {
  // don't close filters drawer when navigate from toys to toys/all page
  if (store.state.drawers.isSideDrawerOpen && !isToysAllPage) {
    store.dispatch('drawers/toggleSideDrawer', { isOpen: false });
  }
  if (store.state.navigation.isHamburgerMenuOpen) store.dispatch('navigation/toggleHamburgerMenu', false);
  if (store.state.navigation.isDropdownMenuOpen) store.dispatch('navigation/toggleDropdownMenu', false);
  if (store.state.modal.isModalOpen) store.dispatch('modal/toggleModal', false);
  if (store.state.scroll.disabled) store.dispatch('scroll/setScrollDisabled', false);
  if (zendesk.isOpen()) zendesk.close();
}

function isOverlayOpen(state) {
  return (
    state.drawers.isSideDrawerOpen ||
    state.navigation.isHamburgerMenuOpen ||
    state.navigation.isDropdownMenuOpen ||
    state.modal.isModalOpen ||
    state.scroll.disabled ||
    zendesk.isOpen()
  );
}

function isPageChange(to, from) {
  return to.path !== from.path;
}
