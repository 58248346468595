import Vue from 'vue';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { SentryLogger } from './loggers/sentry';
import { Data, Requests, ResponseStatus } from './interfaces';
import { Report } from './data/data';
import { Severity } from '~/node_modules/@sentry/types';

export default class AppLogger {
  debugging: boolean;
  report: Report;
  sentryLogger: SentryLogger | null;
  requests: Requests = {};
  status: ResponseStatus = {
    status: 0,
    statusText: `ERROR`,
  };

  constructor(debugging: boolean, sentryLogger: SentryLogger | null = null) {
    this.debugging = debugging;
    this.sentryLogger = sentryLogger;

    this.report = new Report();
  }

  /**
   * @param {String} message
   * @param {Object} data
   */
  public debug(message: string, data: object = {}): void {
    if (this.debugging) {
      Vue.$log.debug(message, process.server ? {} : data);
    }
    if (this.sentryLogger) {
      this.sentryLogger.breadcrumb('debug', message, Severity.Debug);
    }
  }

  /**
   * @param {String} message
   * @param {Object} data
   */
  public warn(message: string, data: object = {}): void {
    if (this.debugging) {
      Vue.$log.debug(message, process.server ? {} : data);
    }
    if (this.sentryLogger) {
      this.sentryLogger.breadcrumb('warn', message, Severity.Warning);
    }
  }

  /**
   * @param {String} type
   * @param {String} path
   * @param {Object} data
   */
  public store(type: string, path: string, data: object = {}): void {
    if (this.debugging) {
      Vue.$log.debug(type, path, process.server ? {} : data);
    }
    if (this.sentryLogger) {
      this.sentryLogger.breadcrumb('store', `${type} ${path}`, Severity.Info);
    }
  }

  /**
   * USED IN THE AXIOS INTERCEPTOR FOR REQUESTS
   *
   * @param {AxiosRequestConfig} config
   */
  public onRequest(config: AxiosRequestConfig): void {
    if (this.debugging || this.sentryLogger) {
      const payload: Partial<Data> = this.report.base();

      Object.assign(payload, {
        ...this.report.request(config),
      });
      const { method, url } = payload;

      if (this.debugging) {
        Vue.$log.debug(method, url, process.server ? {} : payload);

        this.requests[payload.id as string] = payload;

        (config as any).__requestId = payload.id;
      }
      if (this.sentryLogger) {
        this.sentryLogger.breadcrumb('request', `${method} ${url}`, Severity.Info);
      }
    }
  }

  /**
   * USED IN THE AXIOS INTERCEPTOR FOR SUCCESS RESPONSES
   *
   * @param {AxiosResponse} response
   */
  public onResponse(response: AxiosResponse): void {
    if (this.debugging || this.sentryLogger) {
      const requestId: string = ((response.config as any) || {}).__requestId || 'id';

      const payload: Partial<Data> = this.requests[requestId] || {};

      Object.assign(payload, {
        ...this.report.performance(Number(payload.start)),
        ...this.report.response(response),
      });
      const { method, timing, status, statusText, url } = payload;

      if (this.debugging) {
        Vue.$log.debug(timing, String(status), statusText, method, url, process.server ? {} : payload);
      }
      if (this.sentryLogger) {
        this.sentryLogger.breadcrumb(
          'response',
          `${String(status)} ${statusText} ${method} ${url}`,
          Severity.Info
        );
      }
      delete this.requests[requestId];
    }
  }

  /**
   * USED TO CAPTURE GENERIC EXCEPTIONS
   *
   * @param {Error} error
   */
  public onError(error: Error): void {
    if (this.debugging) {
      Vue.$log.error(error);
    }
    if (this.sentryLogger) {
      this.sentryLogger.captureException(error);
    }
  }

  /**
   * USED TO CAPTURE AUTH EXCEPTIONS
   *
   * @param {Error} error
   */
  public onAuthError(error: Error): void {
    if (this.debugging) {
      Vue.$log.error(error);
    }
    if (this.sentryLogger) {
      this.sentryLogger.captureException(error);
    }
  }

  /**
   * USED IN THE MAIN VUE COMPONENT TO INTERCEPT RENDERING ERRORS
   *
   * @param {Error} error
   */
  public onRenderError(error: Error): void {
    if (this.debugging) {
      Vue.$log.error(error);
    }
    if (this.sentryLogger) {
      this.sentryLogger.captureException(error);
    }
  }

  /**
   * USED IN THE AXIOS INTERCEPTOR FOR ERROR RESPONSES
   *
   * @param {AxiosError} error
   */
  public onResponseError(error: AxiosError): void {
    if (this.debugging || this.sentryLogger) {
      const requestId: string = ((error.config as any) || {}).__requestId || 'id';

      const payload: Partial<Data> = this.requests[requestId] || {};

      const { response } = error;

      Object.assign(payload, {
        ...this.report.performance(Number(payload.start)),
        ...this.report.exception(error),
        ...(response && response.data ? this.report.response(response) : this.status),
      });
      const { method, timing, status, statusText, url } = payload;

      if (this.debugging) {
        Vue.$log.error(
          timing,
          String(status),
          statusText,
          error.message,
          method,
          url,
          process.server ? {} : payload
        );
      }
      if (this.sentryLogger) {
        this.sentryLogger.captureAxiosException(error, payload as Data);
      }
      delete this.requests[requestId];
    }
  }
}
