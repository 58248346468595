import * as CHECKOUT_STEP from '~/constants/gifting/checkoutSteps';

export interface StepMeta {
  title: string;
  url: string;
  step: string;
}

export interface StepsMeta {
  [key: number]: StepMeta;
}

export const stepsMeta: StepsMeta = {
  [CHECKOUT_STEP.GUEST_OR_USER]: {
    title: 'Gift Checkout',
    url: '/gift/checkout/account',
    step: CHECKOUT_STEP.GUEST_OR_USER.toString(),
  },
  [CHECKOUT_STEP.ABOUT_YOU]: {
    title: 'About you',
    url: '/gift/checkout/about-you',
    step: CHECKOUT_STEP.ABOUT_YOU.toString(),
  },
  [CHECKOUT_STEP.DELIVERY_DETAILS]: {
    title: 'Delivery details',
    url: '/gift/checkout/delivery',
    step: CHECKOUT_STEP.DELIVERY_DETAILS.toString(),
  },
  [CHECKOUT_STEP.BILLING]: {
    title: 'Billing',
    url: '/gift/checkout/billing',
    step: CHECKOUT_STEP.BILLING.toString(),
  },
};

export const successMeta: StepMeta = {
  title: 'Hooray!',
  url: '/gift/checkout/success',
  step: CHECKOUT_STEP.SUCCESS.toString(),
};
