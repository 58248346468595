import { TYPE_INFO, TYPE_ERROR, TYPE_SUCCESS } from '~/constants/notifications';

export interface NotificationConfig {
  icon: string;
  color: string;
  theme: string;
  close: string;
}

export interface NotificationMeta {
  [key: string]: NotificationConfig;
}

export const notificationMeta: NotificationMeta = {
  [TYPE_ERROR]: {
    icon: 'Error',
    color: 'light-grey-one',
    theme: 'white',
    close: 'white',
  },
  [TYPE_SUCCESS]: {
    icon: 'Tick',
    color: 'secondary',
    theme: 'secondary',
    close: 'secondary',
  },
  [TYPE_INFO]: {
    icon: 'Info',
    color: 'light-grey-one',
    theme: 'white',
    close: 'white',
  },
};
