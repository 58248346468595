import { SortOption } from '~/types/products/sort';
import { SelectedAssociations } from '~/store/products/associations';
import { Range } from '~/types/products/range';

/**
 * The query param keys that the API accepts for filtering products
 * at `GET /guest/products`
 */
export interface QueryParams {
  match?: string;
  orderBy?: string;
  sortedBy?: string;
  from?: string;
  to?: string;
  hidePrevious?: boolean;
  hideNotInStock?: boolean;
  perPage?: number;
  page?: number;
  search?: string;
  with?: string;
  associations?: number;
}

export interface Filters {
  associations: SelectedAssociations;
  range: Range;
  sort: SortOption;
  search: string;
  perPage: number;
  page: number;
  hidePrevious: boolean;
  hideNotInStock: boolean;
}

export const availableAssociationClasses = ['brand', 'age', 'category', 'collection', 'skill'];

export type AssociationClasses = typeof availableAssociationClasses[number];

export const availableParamKeys = [
  'brand',
  'age',
  'category',
  'skill',
  'from',
  'to',
  'orderBy',
  'sortedBy',
  'page',
  'search',
  'hidePrevious',
  'hideNotInStock',
];

export interface BrowserQueryParams {
  brand?: string;
  age?: string;
  category?: string;
  skill?: string;
  from?: string;
  to?: string;
  orderBy?: string;
  sortedBy?: string;
  page?: string;
  search?: string;
  hidePrevious?: string;
  hideNotInStock?: string;
}
