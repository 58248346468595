import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '~/store/index';
import { SpellingSuggestion } from '~/types/products/search';

const types = {
  SET_TERM: 'SET_TERM',
  SET_SUGGESTIONS: 'SET_SUGGESTIONS',
  SET_SPELLING_SUGGESTIONS: 'SET_SPELLING_SUGGESTIONS',
};

// @todo Suggestions needs typing instead of Array<any>
export interface ModuleState {
  suggestions: Array<any>;
  spellingSuggestions: Array<SpellingSuggestion>;
  term: string;
}

const defaults: ModuleState = {
  suggestions: [],
  spellingSuggestions: [],
  term: '',
};

export const state = (): ModuleState => ({
  suggestions: defaults.suggestions,
  spellingSuggestions: defaults.spellingSuggestions,
  term: defaults.term,
});

export const getters: GetterTree<ModuleState, RootState> = {
  suggestion: (state): any | null => {
    if (!state.suggestions || !state.suggestions.length) return null;

    return Object.values(state.suggestions)[0];
  },
  hasSearchTerms: (state): boolean => {
    return !!state.term.length;
  },
  getClosestSpellingSuggestion: (state): string => {
    if (state.spellingSuggestions.length === 0) return '';
    const maxScore = state.spellingSuggestions.reduce(
      (max, character) => (character.score > max ? character.score : max),
      state.spellingSuggestions[0].score
    );
    const mainSuggestion = state.spellingSuggestions.find((suggestion) => {
      return suggestion.score === maxScore;
    });

    return mainSuggestion ? mainSuggestion.text : '';
  },
};

export const actions: ActionTree<ModuleState, RootState> = {
  setTerm({ commit }, { term }: { term: string }) {
    this.$logger.store(`action`, `[products/search/setTerm]`, { term });

    commit(types.SET_TERM, term);
  },

  setSuggestions({ commit }, { suggestions }: { suggestions: Array<any> }) {
    this.$logger.store(`action`, `[products/search/setSuggestions]`, { suggestions });

    commit(types.SET_SUGGESTIONS, suggestions);
  },

  setSpellingSuggestions({ commit }, suggestions: Array<SpellingSuggestion>) {
    this.$logger.store(`action`, `[products/search/setSpellingSuggestions]`, { suggestions });

    commit(types.SET_SPELLING_SUGGESTIONS, suggestions);
  },

  async reset({ dispatch }) {
    this.$logger.store(`action`, `[products/search/reset]`);

    await Promise.all([
      dispatch('setTerm', { term: defaults.term }),
      dispatch('setSuggestions', { term: defaults.suggestions }),
    ]);
  },
};

export const mutations: MutationTree<ModuleState> = {
  SET_TERM(state, term: string) {
    state.term = term;
  },

  SET_SUGGESTIONS(state, suggestions: Array<any>) {
    state.suggestions = suggestions;
  },

  SET_SPELLING_SUGGESTIONS(state, suggestions: Array<SpellingSuggestion>) {
    state.spellingSuggestions = suggestions;
  },
};
