






import 'reflect-metadata';
import { Vue, Component } from 'nuxt-property-decorator';
import TheToyBasket from '../TheToyBasket/TheToyBasket.vue';

// @TODO add async rendering of TheToyBasket as optimisation.
@Component({
  components: {
    TheToyBasket,
  },
})
export default class TheToyBasketContainer extends Vue {}
