var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "v-carousel", class: { "v-carousel--loaded": _vm.loaded } },
    [
      _vm.navigationTop ? _vm._t("navigation") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "swiper",
              rawName: "v-swiper",
              value: _vm.customOptions,
              expression: "customOptions"
            }
          ]
        },
        [_c("div", { staticClass: "swiper-wrapper" }, [_vm._t("slides")], 2)]
      ),
      _vm._v(" "),
      !_vm.navigationTop ? _vm._t("navigation") : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }