var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BlockAccordion",
    {
      directives: [
        {
          name: "intersect",
          rawName: "v-intersect.once",
          value: { handler: _vm.onIntersect, options: _vm.intersectOptions },
          expression: "{ handler: onIntersect, options: intersectOptions }",
          modifiers: { once: true }
        }
      ],
      staticClass: "v-filters-accordion",
      attrs: {
        theme: "light-grey-two",
        name: _vm.association.name,
        "is-open": _vm.isOpen
      },
      on: {
        "toggle-accordion": function($event) {
          return _vm.$emit("toggle-accordion")
        }
      }
    },
    [
      _c(
        "BaseText",
        {
          staticClass: "v-filters-accordion__header",
          attrs: { slot: "header", size: "button", color: "secondary" },
          slot: "header"
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.association.name) +
              " (" +
              _vm._s(_vm.association.bucketsCount) +
              ")\n  "
          )
        ]
      ),
      _vm._v(" "),
      !_vm.delayed || _vm.isVisible
        ? _c(
            "div",
            {
              class: {
                "v-filters-accordion__body": true,
                "u-scroll-bar--secondary": true,
                "v-filters-accordion__body--busy": _vm.busy
              },
              attrs: { slot: "body" },
              slot: "body"
            },
            [
              _vm.association.name === "Age"
                ? [
                    _c(
                      "BaseText",
                      {
                        staticClass: "u-margin-bottom-tiny",
                        attrs: { color: "secondary", bold: true }
                      },
                      [_vm._v("\n        Baby\n      ")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.ageBabyBuckets, function(bucket) {
                      return _c("BucketCheckbox", {
                        key: bucket.id,
                        staticClass: "v-filters-accordion__checkbox",
                        attrs: { bucket: bucket },
                        on: {
                          "bucket:toggle": function($event) {
                            return _vm.toggleBucket(bucket)
                          }
                        }
                      })
                    }),
                    _vm._v(" "),
                    _c(
                      "BaseText",
                      {
                        staticClass: "u-margin-bottom-tiny",
                        attrs: { color: "secondary", bold: true }
                      },
                      [_vm._v("\n        Toddler\n      ")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.ageToddlerBuckets, function(bucket) {
                      return _c("BucketCheckbox", {
                        key: bucket.id,
                        staticClass: "v-filters-accordion__checkbox",
                        attrs: { bucket: bucket },
                        on: {
                          "bucket:toggle": function($event) {
                            return _vm.toggleBucket(bucket)
                          }
                        }
                      })
                    }),
                    _vm._v(" "),
                    _c(
                      "BaseText",
                      {
                        staticClass: "u-margin-bottom-tiny",
                        attrs: { color: "secondary", bold: true }
                      },
                      [_vm._v("\n        Child\n      ")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.ageChildBuckets, function(bucket) {
                      return _c("BucketCheckbox", {
                        key: bucket.id,
                        staticClass: "v-filters-accordion__checkbox",
                        attrs: { bucket: bucket },
                        on: {
                          "bucket:toggle": function($event) {
                            return _vm.toggleBucket(bucket)
                          }
                        }
                      })
                    })
                  ]
                : _vm.association.name === "Skills"
                ? [
                    _c(
                      "BaseText",
                      {
                        staticClass: "u-margin-bottom-tiny",
                        attrs: { color: "secondary", bold: true }
                      },
                      [_vm._v("\n        Understanding the world\n      ")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.skillUnderstandingTheWorldBuckets, function(
                      bucket
                    ) {
                      return _c("BucketCheckbox", {
                        key: bucket.id,
                        staticClass: "v-filters-accordion__checkbox",
                        attrs: { bucket: bucket },
                        on: {
                          "bucket:toggle": function($event) {
                            return _vm.toggleBucket(bucket)
                          }
                        }
                      })
                    }),
                    _vm._v(" "),
                    _c(
                      "BaseText",
                      {
                        staticClass: "u-margin-bottom-tiny",
                        attrs: { color: "secondary", bold: true }
                      },
                      [_vm._v("\n        Connecting with Others\n      ")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.skillConnectingWithOthersBuckets, function(
                      bucket
                    ) {
                      return _c("BucketCheckbox", {
                        key: bucket.id,
                        staticClass: "v-filters-accordion__checkbox",
                        attrs: { bucket: bucket },
                        on: {
                          "bucket:toggle": function($event) {
                            return _vm.toggleBucket(bucket)
                          }
                        }
                      })
                    }),
                    _vm._v(" "),
                    _c(
                      "BaseText",
                      {
                        staticClass: "u-margin-bottom-tiny",
                        attrs: { color: "secondary", bold: true }
                      },
                      [_vm._v("\n        Active play\n      ")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.skillActivePlayBuckets, function(bucket) {
                      return _c("BucketCheckbox", {
                        key: bucket.id,
                        staticClass: "v-filters-accordion__checkbox",
                        attrs: { bucket: bucket },
                        on: {
                          "bucket:toggle": function($event) {
                            return _vm.toggleBucket(bucket)
                          }
                        }
                      })
                    }),
                    _vm._v(" "),
                    _c(
                      "BaseText",
                      {
                        staticClass: "u-margin-bottom-tiny",
                        attrs: { color: "secondary", bold: true }
                      },
                      [_vm._v("\n        Mindfulness\n      ")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.skillMindfulnessBuckets, function(bucket) {
                      return _c("BucketCheckbox", {
                        key: bucket.id,
                        staticClass: "v-filters-accordion__checkbox",
                        attrs: { bucket: bucket },
                        on: {
                          "bucket:toggle": function($event) {
                            return _vm.toggleBucket(bucket)
                          }
                        }
                      })
                    })
                  ]
                : _vm._l(_vm.association.buckets, function(bucket) {
                    return _c("BucketCheckbox", {
                      key: bucket.id,
                      staticClass: "v-filters-accordion__checkbox",
                      attrs: { bucket: bucket },
                      on: {
                        "bucket:toggle": function($event) {
                          return _vm.toggleBucket(bucket)
                        }
                      }
                    })
                  })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }