var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VueSlider", {
    staticClass: "v-range-slider",
    attrs: {
      value: _vm.value,
      min: _vm.min,
      max: _vm.max,
      "dot-options": _vm.dotOptions,
      "dot-size": 16,
      duration: 0.2,
      "enable-cross": false,
      "min-range": 10,
      height: 2,
      lazy: true,
      "process-style": _vm.processStyle,
      "rail-style": _vm.railStyle,
      tooltip: "always",
      "tooltip-style": _vm.tooltipStyle,
      formatter: _vm.formatter
    },
    on: { change: _vm.handleChange }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }