var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "v-selected-filters-tags" },
    _vm._l(_vm.selectedFilters, function(filter) {
      return _c("FiltersTag", {
        key: filter.name || filter.type,
        staticClass: "v-selected-filters-tags__tag",
        attrs: { "selected-filter": filter },
        on: {
          "filter:remove": function($event) {
            return _vm.$emit("filter:remove", filter)
          }
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }