import {
  QueryParams,
  Filters,
  BrowserQueryParams,
  availableParamKeys,
  AssociationClasses,
} from '~/types/products/query';
import { SelectedAssociations, SelectedBucket } from '~/store/products/associations';
import { SortParams } from '~/types/products/sort';
import { filterObject } from '~/utilities/iteration';
import { Range, RangeBrowserQueryParams, RangeQueryParams } from '~/types/products/range';
import { defaults as defaultRange } from '~/store/products/range';

const defaultWiths = ['productVariants', 'route', 'assets.transforms'];

/**
 * # ProductQuery
 *
 * Multiple processes happen when selecting a filter/sort/page/search etc.
 * 1. We save those filters to the store
 * 2. Then we have to turn those filters into the correct query params that the API takes.
 *    See the API front end guide here https://whirli.atlassian.net/browse/WAPI-316.
 * 3. We also have to turn those filters to user facing query params and update the browser URL.
 *
 * In addition, when a user visits a browse toys page with query params we also have to handle these params (i.e. the opposite of above).
 * We have to parse the user facing query params into the correct store modules structures.
 *
 * This involves parsing the filters into two different object structures, for API and browser params.
 *
 * This ProductQuery class aims to split the API and browser objects into two separate, more readable function calls.
 *
 * It also aims to encapsulate logic that the user (dev) doesn't need to see,
 * for example specific association object transformations (e.g. transformSelectedAssociationsToParams, transformToSelectedBucket, transformFromSelectedBucket methods)
 *
 * ---
 * **Example of user selecting the filter brand: "Marvel" via the filters panel:**
 * ```
 * const pq: ProductQuery = new ProductQuery();
 *
 * // Transforms the filters object (from Filters module) to **user facing** query params.
 * pq.transformFiltersToParams(...);
 *
 * // Transforms the filters object (from Filters module) to **API** query params.
 * pq.api.makeQueryParams(...);
 * ```
 *
 * ---
 * **Example of user visiting "/toys/all?brand=foo;baz=car;age=2-3-months":**
 * ```
 * const pq: ProductQuery = new ProductQuery();
 *
 * // Removes unavailable filters like baz=car.
 * pq.removeUnavailable($route.query);
 *
 * // Transforms the query params to the structure that the association store module accepts.
 * pq.transformToSelectedAssociations(...);
 * ```
 */
export default class ProductQuery {
  public api: ApiProductQuery;

  constructor(queryProductsWith?: Array<string>) {
    this.api = new ApiProductQuery(queryProductsWith);
  }

  public removeUnavailable(params: { [key: string]: string }) {
    return filterObject(params, (_value, key: string | number) => availableParamKeys.includes(key as string));
  }

  /**
   * **Pass in:**
   * { from: '0-tokens', to: '50-tokens' }
   * **Returns:**
   * { from: 0, to: 50, type: 'tokens' }
   */
  public transformToRange(rangeParams: RangeBrowserQueryParams): Range {
    const rangeType: string | null = rangeParams.from.split('-')[1] || null;
    if (!rangeType) return defaultRange;

    const from: number = parseInt(rangeParams.from.split('-')[0]);
    const to: number | null = rangeParams.to ? parseInt(rangeParams.to.split('-')[0]) : null;

    return { from, to, type: rangeType };
  }

  /**
   * Transform **from** URL query params, **to** SelectedAssociations.
   */
  public transformToSelectedAssociations(params: BrowserQueryParams): SelectedAssociations {
    const associations: SelectedAssociations = {};

    if (params.brand) associations.brand = this.transformToSelectedBucket(params.brand, 'brand');
    if (params.age) associations.age = this.transformToSelectedBucket(params.age, 'age');
    if (params.category) {
      associations.category = this.transformToSelectedBucket(params.category, 'category');
    }
    if (params.skill) associations.skill = this.transformToSelectedBucket(params.skill, 'skill');

    return associations;
  }

  /**
   * Transform **from** Filters, **to** user facing URL query params.
   */
  public transformFiltersToParams(filters: Filters): BrowserQueryParams {
    const associations: BrowserQueryParams = this.transformSelectedAssociationsToParams(filters.associations);
    const sort: SortParams = filters.sort?.sort;
    const params = filters;

    let range = {};
    if (filters.range && filters.range.type !== null) {
      range = this.transformRangeToParams(filters.range);
      delete params.range;
    }

    delete params.associations;
    delete params.sort;
    delete params.perPage;
    if (!filters.search) delete params.search;
    if (!filters.hidePrevious) delete params.hidePrevious;
    if (!filters.hideNotInStock) delete params.hideNotInStock;

    return Object.assign(params, associations, sort, range);
  }

  /**
   * Turns SelectedBuckets into a user facing query params type, for example:
   *
   * **Pass in:**
   * ```
   * {
   *   brand: [
   *     { name: 'foo', slug: 'foo-slug', associationClass: 'brand' },
   *     { name: 'bar', slug: 'bar-slug', associationClass: 'brand' }
   *   ],
   *   age: [
   *     { name: 'baz', slug: 'baz-slug', associationClass: 'age' }
   *   ]
   * }
   * ```
   * **Returns:**
   * ```
   * { brand: 'foo-slug;bar-slug', age: 'baz-slug'}
   * ```
   * Used when the user selects a filter with the filtering panel.
   * This turns those filter params to a user facing URL params.
   */
  protected transformSelectedAssociationsToParams(params: SelectedAssociations): BrowserQueryParams {
    const URLParams: BrowserQueryParams = {};

    if (params.brand) URLParams.brand = this.transformFromSelectedBucket(params.brand);
    if (params.age) URLParams.age = this.transformFromSelectedBucket(params.age);
    if (params.category) URLParams.category = this.transformFromSelectedBucket(params.category);
    if (params.skill) URLParams.skill = this.transformFromSelectedBucket(params.skill);

    return URLParams;
  }

  /**
   * Turns Range into a user facing query params type, for example:
   *
   * **Pass in:**
   * ```
   * { from: 0, to: 50, type: 'tokens' }
   * ```
   * **Returns:**
   * ```
   * { from: '0-tokens', to: '50-tokens' }
   * ```
   * Used when the user selects a filter with the filtering panel.
   * This turns the range into user facing URL params.
   */
  protected transformRangeToParams(range: Range): RangeBrowserQueryParams | {} {
    if (!range.type || range.from === null) return {};

    const rangeString: (number: number) => string = (number) => `${number}-${range.type}`;
    const params: RangeBrowserQueryParams = { from: rangeString(range.from) };
    if (range.to) Object.assign(params, { to: rangeString(range.to) });

    return params;
  }

  /**
   * **Pass in:**
   * ```
   * transformToSelectedBucket('i-am-a-slug', 'brand')
   * ```
   * **Returns:**
   * ```
   * { name: 'i-am-a-slug', slug: 'i-am-a-slug', associationClass: 'brand' },
   * ```
   */
  protected transformToSelectedBucket(params: string, associationClass: AssociationClasses) {
    const slugs: Array<string> = params.split(';');
    return slugs.map((slug: string) => ({ name: slug, slug, associationClass }));
  }

  /**
   * **Pass in:**
   * ```
   *   [{ name: 'foo', slug: 'foo-slug', associationClass: 'brand' }, { name: 'bar', slug: 'bar-slug', associationClass: 'brand' }]
   * ```
   * **Returns:**
   * ```
   * 'foo-slug;bar-slug'
   * ```
   */
  protected transformFromSelectedBucket(selectedBuckets: Array<SelectedBucket>): string {
    return selectedBuckets.map((selectedBucket: SelectedBucket) => selectedBucket.slug).join(';');
  }
}

/**
 * API related query params transformations.
 */
class ApiProductQuery {
  protected queryProductsWith: { with: string };

  constructor(queryProductsWith?: Array<string>) {
    this.queryProductsWith = this.parseWithIntoParams(queryProductsWith || defaultWiths);
  }

  /**
   * https://whirli.atlassian.net/browse/WAPI-316
   *
   * ### Products API query examples:
   * - `?perPage=20` - Shows a maximum of 20 toys per page.
   * - `?page=2` - Shows page 2 of paginated toys.
   * - `?from=tokens:0&to=tokens:50` - Shows a range of 0 - 50 tokens
   * - `?match=productAssociations.slug:slug` - Used by: associations, example `?match=productAssociations.slug:hasbro`
   * - `?orderBy=popularity&sortedBy=desc` - Used by: sort.
   * - `?search=brown` - Used by: search.
   * - `?hidePrevious=true` - Used by: hidePrevious. `hidePrevious=true` will hide toys previously ordered by the authenticated user.
   * - `?with=productVariants` - Returns data with productVariants
   * - `?with=productAssociations` - Returns product Associations with the product which contains a property called association_class
   *     which tells you whether its a brand, manufacturer, age range etc.
   * - `?with=route` - Returns data with route object
   * - `?with=assets.transforms` - Returns assets objects
   * - `?with=assets.transforms;productVariants;route` - Multiple "withs"
   *
   * ### Pass in:
   * ```
   * {
   *   filters: {
   *     associations: { brand: ['hasbro'], category: ['baby'] },
   *     sort: { sort: { orderBy: 'popularity', sortedBy: 'desc' } },
   *     range: { from: 0, to: 50, type: 'tokens' }
   *     search: 'brown',
   *     hidePrevious: false,
   *   },
   *   with: ['productVariants', 'route', 'assets.transforms'],
   * }
   * ```
   *
   * ### Returns:
   * ```
   * {
   *   match: 'productAssociations.slug:hasbro;productAssociations.slug:baby',
   *   page: 2,
   *   perPage: 24,
   *   orderBy: 'popularity',
   *   sortedBy: 'desc',
   *   search: 'brown',
   *   from: 'tokens:0',
   *   to: 'tokens:50',
   *   hidePrevious: false,
   *   with: 'productVariants;route;assets.transforms'
   * }
   * ```
   * We return an object and Axios turns it into a query string.
   */
  public makeQueryParams(filters: Filters): QueryParams {
    const { associations, sort, hidePrevious, hideNotInStock, perPage, page, search, range } = filters;
    const queryParams: QueryParams = {};

    if (Object.keys(associations).length) Object.assign(queryParams, this.transformToParams(associations));
    if (range) Object.assign(queryParams, this.transformRangeToParams(range));
    if (sort) Object.assign(queryParams, sort.sort);
    if (hidePrevious) queryParams.hidePrevious = hidePrevious;
    if (hideNotInStock) queryParams.hideNotInStock = hideNotInStock;
    if (perPage) queryParams.perPage = perPage;
    if (page) queryParams.page = page;
    if (search) queryParams.search = search;
    Object.assign(queryParams, this.queryProductsWith);

    return queryParams;
  }

  /**
   * Turns the Associations object into a string with the queryParam key that the API needs: `productAssociations.slug:...`.
   *
   * Separates each association with a `;`.
   *
   * Puts it into an object with the key `match` as the API is looking for `?match=productAssociations.slug:hasbro;productAssociations.slug:baby`.
   *
   * Pass in:
   * ```
   * {
   *   brand: [
   *     { name: 'foo', slug: 'foo', associationClass: 'brand' },
   *     { name: 'bar', slug: 'bar', associationClass: 'brand' }
   *   ],
   *   age: [
   *     { name: 'baz', slug: 'baz', associationClass: 'age' }
   *   ]
   * }
   * ```
   * Returns:
   * ```
   * { match: "productAssociations.slug:foo;productAssociations.slug:bar;productAssociations.slug:baz" }
   * ```
   */
  protected transformToParams(associations: SelectedAssociations): { match: string } {
    const selectedBuckets: Array<SelectedBucket> = Object.values(associations).flat();
    const queryString: string = selectedBuckets
      .map((bucket: SelectedBucket) => `productAssociations.slug:${bucket.slug}`)
      .join(';');

    return { match: queryString };
  }

  /**
   * **Pass in:**
   * ```
   * { from: 0, to: 30, type: 'tokens' }
   * ```
   * **Returns:**
   * ```
   * { from: 'tokens:0', to: 'tokens:30' }
   * ```
   * **Or for unlimited max, pass in:**
   * ```
   * { from: 50, to: null, type: 'tokens' }
   * ```
   * **Returns:**
   * ```
   * { from: 'tokens:0' }
   * ```
   */
  protected transformRangeToParams(range: Range): RangeQueryParams | {} {
    const rangeString = (number: number): string => `${range.type}:${number}`;

    const rangeParams: {} = {};

    if (range.from !== null) Object.assign(rangeParams, { from: rangeString(range.from) });
    if (range.to !== null) Object.assign(rangeParams, { to: rangeString(range.to) });

    return rangeParams;
  }

  protected parseWithIntoParams(withRelationships: Array<string>): { with: string } {
    const queryString: string = withRelationships.join(';');

    return { with: queryString };
  }
}
