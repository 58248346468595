var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "v-tab",
      class:
        ((_obj = {
          "v-tab--active": _vm.isActive,
          "v-tab--inactive": !_vm.isActive
        }),
        (_obj["v-tab--" + _vm.theme] = _vm.theme),
        _obj),
      attrs: {
        id: _vm.id,
        "aria-controls": _vm.ariaControls,
        "aria-selected": _vm.isActive,
        role: "tab"
      },
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _c(
        "span",
        { staticClass: "v-tab__content u-text--button" },
        [_vm._t("default")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }