const ORDERED_NOT_PICKED: number = 9;
const PICKED_FOR_ORDER: number = 10;
const PACKED: number = 11;
const DISPATCHED: number = 12;
const WITH_CUSTOMER: number = 2;
const DESIGNATED_FOR_RETURN: number = 6;
const RETURN_PLACED: number = 7;
const RETURNED_IN_TRANSIT: number = 13;
const RETURNED_IN_INBOUND: number = 14;
const RETURNED_BEING_CLEANED: number = 15;
const IN_WORKSHOP_DAMAGED: number = 18;
const IN_WORKSHOP_MISSING_PARTS: number = 16;
const IN_WORKSHOP_DAMAGED_AND_MISSING_PARTS: number = 17;
const IN_WAREHOUSE: number = 1;
const SOLD: number = 8;
const WRITE_OFF: number = 5;

const stockStatus = {
  9: ORDERED_NOT_PICKED,
  10: PICKED_FOR_ORDER,
  11: PACKED,
  12: DISPATCHED,
  2: WITH_CUSTOMER,
  6: DESIGNATED_FOR_RETURN,
  7: RETURN_PLACED,
  13: RETURNED_IN_TRANSIT,
  14: RETURNED_IN_INBOUND,
  15: RETURNED_BEING_CLEANED,
  18: IN_WORKSHOP_DAMAGED,
  16: IN_WORKSHOP_MISSING_PARTS,
  17: IN_WORKSHOP_DAMAGED_AND_MISSING_PARTS,
  1: IN_WAREHOUSE,
  8: SOLD,
  5: WRITE_OFF,
};

export {
  ORDERED_NOT_PICKED,
  PICKED_FOR_ORDER,
  PACKED,
  DISPATCHED,
  WITH_CUSTOMER,
  DESIGNATED_FOR_RETURN,
  RETURN_PLACED,
  RETURNED_IN_TRANSIT,
  RETURNED_IN_INBOUND,
  RETURNED_BEING_CLEANED,
  IN_WORKSHOP_DAMAGED,
  IN_WORKSHOP_MISSING_PARTS,
  IN_WORKSHOP_DAMAGED_AND_MISSING_PARTS,
  IN_WAREHOUSE,
  SOLD,
  WRITE_OFF,
  stockStatus,
};
