var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 32 32" } },
    [
      _c("path", {
        attrs: {
          d:
            "M16.9 18.5l-2.7-2.9-5.4 2.9 5.9-6.3 2.8 2.9 5.3-2.9-5.9 6.3zM15.8 5C9.8 5 5 9.5 5 15c0 3.1 1.6 6 4 7.8v3.8l3.7-2c1 .3 2 .4 3.1.4 6 0 10.8-4.5 10.8-10S21.7 5 15.8 5z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }