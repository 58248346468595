import BaseExperiment, { ExperimentVariant } from '../BaseExperiment';

export default class FirstRegistrationStepExperiment extends BaseExperiment {
  protected experimentId: string = process.env.firstRegistrationStepExperimentId
    ? process.env.firstRegistrationStepExperimentId
    : '';

  protected variants: ExperimentVariant[] = [
    {
      id: 0,
      percentage: 50,
    },
    {
      id: 1,
      percentage: 50,
    },
  ];

  public setup(): void {}

  public beforeRouteEnter(): void {
    if (this.isRegPage()) {
      this.generateAndPushExperiment();
      super.saveAndShip();
    }
  }

  protected isRegPage(): string | boolean | null | undefined {
    return this.route.name && this.route.name.startsWith('register');
  }
}
