var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "v-disc-icon", class: _vm.discClasses },
    [
      _vm.icon === "Bag"
        ? _c("TheBagIcon", {
            staticClass: "v-disc-icon__graphic",
            attrs: {
              number: _vm.number,
              size: _vm.iconSize,
              theme: _vm.iconColor
            }
          })
        : _c("BaseIcon", {
            staticClass: "v-disc-icon__graphic",
            attrs: { icon: _vm.icon, size: _vm.iconSize, theme: _vm.iconColor }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }