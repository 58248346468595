




















































import { Vue, Prop, Component } from 'nuxt-property-decorator';
import BaseCarousel from '../BaseCarousel/BaseCarousel.vue';
import { SlidesPerView } from '~/types/carousels';
import ButtonIcon from '~/components/partials/icons/ButtonIcon/ButtonIcon.vue';
import mq from '~/helpers/screen';
import { BreakpointName, SMALL_DESK } from '~/constants/screen-breakpoints';
/**
 * Paginated Carousel
 *
 * The Paginated Carousel essentially has two modes:
 *
 * The default mode simply has pagination dots which are positioned top-right of the carousel until smallDesk at which point they are positioned bottom center
 *
 * When passed the elaborate="true" prop. The carousel is rendered with navigation buttons and pagination fixed at bottom center
 */

@Component({
  components: {
    BaseCarousel,
    ButtonIcon,
  },
})
export default class PaginatedCarousel extends Vue {
  @Prop({ default: true }) readonly elaborate!: boolean;
  @Prop({ default: true }) readonly loop!: boolean;
  @Prop({ required: true }) readonly name!: string;
  @Prop({ default: null }) readonly options?: any;
  @Prop({ default: true }) readonly showNavigation!: boolean;
  @Prop({ default: () => ({ mobile: 2, tablet: 4, desk: 4 }) }) readonly slidesPerView!: SlidesPerView;
  pagination = {
    bulletClass: 'v-paginated-carousel__pagination-dot',
    bulletActiveClass: 'v-paginated-carousel__pagination-dot--active',
    el: this.showNavigation ? `.v-paginated-carousel__pagination--${this.name}` : null,
    clickable: true,
    type: 'bullets',
  };

  sizeMap = {
    mobile: 'tiny',
    tablet: 'base',
    smallDesk: 'big',
    largeDesk: 'big',
    hugeDesk: 'big',
  };

  get currentScreenSize(): BreakpointName {
    return this.$store.state.resize.debounced.breakpoint;
  }

  get isDesk(): boolean {
    return mq({ from: SMALL_DESK }, this.currentScreenSize);
  }

  get navBtnSettings(): object {
    return {
      disc: this.isDesk,
      size: this.sizeMap[this.currentScreenSize] || 'tiny',
      theme: this.isDesk ? 'secondary' : 'charcoal',
    };
  }
}
