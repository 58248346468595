import { AxiosRequestConfig } from 'axios';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '~/store';
import {
  types,
  state as BState,
  getters as BGetters,
  actions as BActions,
  mutations as BMutations,
  ModuleState as BModuleState,
} from '~/helpers/store/BasketStore';
import { Basket } from '~/types/basket';
import { itemCount, totalTokens } from '~/helpers/models/basket';
import { STORAGE_GIFT_BASKET_ID, STORAGE_GIFT_BASKET_COUNT } from '~/plugins/services/Gift/gifts';

interface LocalState extends Partial<BModuleState> {}

export interface ModuleState extends LocalState {}

const giftState: LocalState = {
  logName: 'store/gift/basket',
};

export const giftGetters: GetterTree<ModuleState, RootState> = {
  lazyLoading: (state) => !state.isFetched && state.isBusy,
  value(state) {
    if (!state.data) return 0;
    const discountedTotalTokens: number = totalTokens(state.data);

    return discountedTotalTokens > 0
      ? discountedTotalTokens * 100
      : state.data.totalTokens
      ? state.data.totalTokens * 100
      : 0;
  },
};

const giftActions: ActionTree<ModuleState, RootState> = {
  async fetch({ dispatch }) {
    this.$logger.store(`action`, `[gift/basket/makeRequest]`);

    await dispatch('makeRequest', {
      requestName: 'fetchGift',
      request: async () => await dispatch(this.$auth.loggedIn ? 'getUserGiftData' : 'getGuestGiftData'),
    });
  },

  getUserBasketData(): Promise<any> {
    this.$logger.store(`action`, `[gift/basket/getUserBasketData]`);

    return this.$whirli.users.baskets.getActive({ params: { isGift: true } });
  },

  getGuestBasketData(): Promise<any> | null {
    this.$logger.store(`action`, `[gift/basket/getGuestBasketData]`);

    const cachedGift = this.$gifts.guest.getCachedGift();
    if (!cachedGift) return null;

    const config = { params: { isGift: true, giftToken: cachedGift.accessToken } };

    return this.$whirli.guests.baskets.get(cachedGift.id, config);
  },

  extraRequestConfig(): AxiosRequestConfig {
    this.$logger.store(`action`, `[gift/basket/extraRequestConfig]`);

    const params: AxiosRequestConfig = { params: { isGift: true } };

    if (!this.$auth.loggedIn) {
      const cachedGift = this.$gifts.guest.getCachedGift();

      if (cachedGift) params.params.giftToken = cachedGift.accessToken;
    }

    return params;
  },

  getBasketId({ state }): string | null {
    this.$logger.store(`action`, `[gift/basket/getBasketId]`);

    return state.id || this.$storage.getUniversal(STORAGE_GIFT_BASKET_ID) || null;
  },

  saveBasket({ commit, dispatch }, basket) {
    this.$logger.store(`action`, `[gift/basket/saveBasket]`, { basket });

    commit(types.PUT_BASKET_AND_ID, { id: basket ? basket.id : null, basket });
    commit(types.SET_BASKET_FETCHED, true);
    dispatch('updateBasketCount', { basket });
    try {
      if (basket.giftBasket && basket.giftBasket.gift) {
        dispatch('gift/saveGift', basket.giftBasket.gift, { root: true });
      } else if (basket.checkout) {
        // If it exists, giftBasket.gift will have the checkout message, so no need to save from basket.
        dispatch('gift/updateCheckoutHint', basket.checkout, { root: true });
      }
    } catch (error) {}
    this.$storage.setUniversal(STORAGE_GIFT_BASKET_ID, basket ? basket.id : null);
  },

  updateBasketCount({ commit }, { basket, count = 0 }: { basket?: Basket; count?: number }) {
    this.$logger.store(`action`, `[gift/basket/updateBasketCount]`, { basket, count });

    if (!count && basket) count = itemCount(basket);

    this.$storage.setUniversal(STORAGE_GIFT_BASKET_COUNT, count);
    commit(types.PUT_BASKET_COUNT, count);
  },

  async clearCachedBasket({ dispatch }): Promise<void> {
    this.$logger.store(`action`, `[gift/basket/clearCachedBasket]`);

    await dispatch('saveBasket', { id: null, basket: null });
    this.$storage.removeUniversal(STORAGE_GIFT_BASKET_COUNT);
    this.$storage.removeUniversal(STORAGE_GIFT_BASKET_ID);
  },
};

const giftMutations: MutationTree<ModuleState> = {};

/**
 * The below merges the additions and overrides from above with the reusable BasketStore. This allows us to
 * reuse all the common basket functionality between baskets, such as the Toy Basket and Gift Basket.
 */
export const state = (): ModuleState => Object.assign({}, BState(), giftState);

export const getters: GetterTree<ModuleState, RootState> = Object.assign({}, BGetters, giftGetters);

export const actions: ActionTree<ModuleState, RootState> = Object.assign({}, BActions, giftActions);

export const mutations: MutationTree<ModuleState> = Object.assign({}, BMutations, giftMutations);
