

















import { Vue, Component, namespace } from 'nuxt-property-decorator';
import { ZenDeskTriggerDisplayOption, ZenDeskTriggerDisplayOptions } from './interfaces';
import zendesk from '~/helpers/zendesk';

const ZenDeskStore = namespace('zendesk');

const zenDeskDisplayOptions: ZenDeskTriggerDisplayOptions = {
  chat: {
    text: 'Chat',
    icon: 'ChatIcon',
    class: 'zendesk-trigger--chat',
    label: 'Open customer support live chat',
  },
  help: {
    text: 'Help',
    icon: 'HelpIcon',
    class: 'zendesk-trigger--help',
    label: 'Open customer support help form',
  },
};

@Component({
  components: {
    ZenDesk: () => import('./ZenDesk.vue' /* webpackPrefetch: true */ /* webpackChunkName: "zenDesk" */),
    ChatIcon: () =>
      import(
        './ZenDeskIcons/ChatIcon/ChatIcon.vue' /* webpackPrefetch: true */ /* webpackChunkName: "zenDeskChatIcon" */
      ),
    HelpIcon: () =>
      import(
        './ZenDeskIcons/HelpIcon/HelpIcon.vue' /* webpackPrefetch: true */ /* webpackChunkName: "zenDeskHelpIcon" */
      ),
  },
})
export default class ZenDeskWrapper extends Vue {
  @ZenDeskStore.State isZenDeskActive!: boolean;
  @ZenDeskStore.State isZenDeskLoaded!: boolean;
  @ZenDeskStore.Action updateZenDeskActiveState!: Function;
  @ZenDeskStore.Mutation UPDATE_ZENDESK_LOADED_STATE!: Function;
  time: string = '';

  openZenDesk(): void {
    this.updateZenDeskActiveState(true);
  }

  updateZenDeskLoadingState(state: boolean): void {
    this.UPDATE_ZENDESK_LOADED_STATE(state);
  }

  pollZenDeskWidget(): void {
    setTimeout(() => {
      const display = this.$zendesk.zE('webWidget:get', 'display');
      if (!display || display === 'hidden') {
        this.pollZenDeskWidget();
        return;
      }

      this.updateZenDeskLoadingState(true);
    }, 100);
  }

  setTime(): void {
    const date = new Date();
    this.time = date.toLocaleString('en-GB', {
      timeZone: 'Europe/London',
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  get zenDeskTriggerDisplay(): ZenDeskTriggerDisplayOption | null {
    if (this.time === '') return null; // Stop flash
    const display: keyof typeof zenDeskDisplayOptions =
      this.time < '10:00' || this.time > '16:00' ? 'help' : 'chat';

    return zenDeskDisplayOptions[display];
  }

  mounted(): void {
    this.setTime();

    this.$zendesk.$on('loaded', () => {
      if (this.$auth.loggedIn && this.$auth.user) {
        zendesk.authUser(`${this.$auth.user.firstname} ${this.$auth.user.lastname}`, this.$auth.user.email);
      }

      zendesk.open();
      // (this.$refs.zenDeskTriggerText as HTMLElement).textContent = 'Loading';
      this.pollZenDeskWidget();
    });
  }
}
