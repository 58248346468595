import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '~/store';

const types = {
  PUT_USER_DETAILS: 'PUT_USER_DETAILS',
  RESET_USER_DETAILS: 'RESET_USER_DETAILS',
};

export interface ModuleState {
  firstname: string;
  email: string;
  allowEmailTransactional: boolean;
  allowEmailDiscountsOffers: boolean;
}

export const state = (): ModuleState => ({
  firstname: '',
  email: '',
  allowEmailTransactional: false,
  allowEmailDiscountsOffers: false,
});

export const actions: ActionTree<ModuleState, RootState> = {
  putUserDetails({ commit }, newState: ModuleState) {
    this.$logger.store(`action`, `[registration/userDetails/putUserDetails]`, { newState });

    commit(types.PUT_USER_DETAILS, { newState: { ...newState } });
  },
  reset({ commit }) {
    commit(types.RESET_USER_DETAILS);
  },
};

export const mutations: MutationTree<ModuleState> = {
  [types.PUT_USER_DETAILS](state, { newState }: { newState: ModuleState }) {
    state.firstname = newState.firstname;
    state.email = newState.email;
    state.allowEmailTransactional = newState.allowEmailTransactional;
    state.allowEmailDiscountsOffers = newState.allowEmailDiscountsOffers;
  },
  [types.RESET_USER_DETAILS](state) {
    state.firstname = '';
    state.email = '';
    state.allowEmailTransactional = false;
    state.allowEmailDiscountsOffers = false;
  },
};
