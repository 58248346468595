

















import { Prop, Component, mixins } from 'nuxt-property-decorator';
import accordionsMixin from '../accordionsMixin.vue';

const availableBorderThemes: string[] = [
  'secondary-light',
  'quaternary-dark',
  'light-grey-one',
  'light-grey-two',
  'grey-two',
  'none',
];
const availableArrowThemes: string[] = ['primary', 'secondary', 'white', 'quaternary-dark'];
const availableSizes: string[] = ['none', 'small', 'base', 'medium'];

/**
 * ### **Features:**
 * - Border themes - secondary-light, quaternary-dark, light-grey-one, light-grey-two, grey-two.
 * - Arrow themes - secondary, white.
 * - Sizes - Defines the vertical padding, options: base, medium.
 * - Name - Must be unique!!
 *
 * ### **Other notes:**
 * The `name` prop must be unique! This is for accessibility!
 */
@Component
export default class FlatAccordion extends mixins(accordionsMixin) {
  @Prop({ default: 'base', validator: (value) => availableSizes.includes(value) }) readonly size!: string;
  @Prop({ default: 'light-grey-one', validator: (value) => availableBorderThemes.includes(value) })
  readonly borderTheme!: string;

  @Prop({ default: false }) readonly borderTop!: boolean;

  @Prop({ default: 'secondary', validator: (value) => availableArrowThemes.includes(value) })
  readonly arrowTheme!: string;

  get classes() {
    return [
      { [`v-flat-accordion--border-${this.borderTheme}`]: this.borderTheme },
      { 'v-flat-accordion--border-top': this.borderTop },
      { [`v-flat-accordion--size-${this.size}`]: this.size },
    ];
  }
}
