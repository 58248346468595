import { themeMap } from './colors';

export function validateTheme(theme: string, extraColors: string[] = []): boolean {
  const baseThemes: string[] = Object.keys(themeMap);
  const availableThemes: string[] = baseThemes.concat(extraColors);

  return availableThemes.includes(theme);
}

export function isset(param: any): boolean {
  return param && typeof param !== 'undefined' && param !== 'undefined';
}
