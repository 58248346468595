var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 145 145" } },
    [
      _c("path", {
        attrs: {
          d:
            "M72.5 0c40 0 72.5 32.5 72.5 72.5S112.5 145 72.5 145 0 112.5 0 72.5 32.5 0 72.5 0zM60 48v49l34-24.5L60 48z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }