import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '~/store/index';
import { DiscountCode } from '~/types/discount-code';

export const types = {
  PUT_DISCOUNT_CODE: 'PUT_DISCOUNT_CODE',
  RESET_CODE: 'RESET_CODE',
  SET_ERROR: 'SET_ERROR',
  SET_DEFAULT_ERROR: 'SET_DEFAULT_ERROR',
  CLEAR_ERROR: 'CLEAR_ERROR',
  SET_LOADING: 'SET_LOADING',
};

export interface ModuleState {
  code: DiscountCode;
  errors: Array<string>;
  loading: boolean;
}

export const state = (): ModuleState => ({
  code: {
    type: null,
    name: null,
    value: null,
    valueTypeId: null,
  },
  errors: [],
  loading: false,
});

export const getters: GetterTree<ModuleState, RootState> = {
  getValue: (state): number | null => {
    return state.code.value;
  },
  getCode: (state): string | null => {
    return state.code.name;
  },
  getValueTypeId: (state): number | null => {
    return state.code.valueTypeId;
  },
  getType: (state): string | null => {
    return state.code.type;
  },
  getDiscountCode(state): DiscountCode {
    return state.code;
  },
  getErrors(state): Array<string> {
    return state.errors;
  },
  getLoading(state): boolean {
    return state.loading;
  },
};

export const actions: ActionTree<ModuleState, RootState> = {
  reset({ commit }) {
    commit(types.RESET_CODE);
    commit(types.CLEAR_ERROR);
  },
  clearError({ commit }) {
    commit(types.CLEAR_ERROR);
  },
};

export const mutations: MutationTree<ModuleState> = {
  [types.PUT_DISCOUNT_CODE](state, discountCode: DiscountCode | null) {
    if (discountCode) {
      state.code.type = discountCode.type;
      state.code.name = discountCode.name;
      state.code.value = discountCode.value;
      state.code.valueTypeId = discountCode.valueTypeId;
    } else {
      state.code.type = null;
      state.code.name = null;
      state.code.value = null;
      state.code.valueTypeId = null;
    }
  },
  [types.RESET_CODE](state) {
    state.code.type = null;
    state.code.name = null;
    state.code.value = null;
    state.code.valueTypeId = null;
  },
  [types.SET_ERROR](state, error) {
    state.errors = error.response.data.errors.message;
  },
  [types.CLEAR_ERROR](state) {
    state.errors = [];
  },
  [types.SET_LOADING](state) {
    state.loading = !state.loading;
  },
};
