import Vue from 'vue'

export default (ctx, inject) => {
  const { toggles, queryString } = JSON.parse(`{"toggles":{"electronicDeliveryDate":true,"popularScore":true,"waitSpot":true,"review":true,"reviewToysPage":true,"myPlaytime":true,"greenFriday":true,"childProfiles":true},"queryString":true}`)

  Vue.component('feature-toggle', () => import('./feature-toggle.vue'))

  const featureToggle = {
    toggles,
    queryString,
    isQueryStringAllowed: (fn) => {
      featureToggle.isQueryStringAllowedFn = fn
    }
  }

  ctx.$featureToggle = featureToggle
  inject('featureToggle', featureToggle)
}
