var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SideDrawer",
    {
      staticClass: "v-filters-side-drawer",
      attrs: { name: "filters", theme: "white" }
    },
    [
      _c(
        "div",
        { staticClass: "v-filters-side-drawer__header" },
        [
          _c("ButtonIcon", {
            staticClass: "v-filters-side-drawer__close-btn",
            attrs: {
              direction: "left",
              icon: "ArrowCenter",
              theme: "white",
              size: "base",
              "native-type": "button"
            },
            nativeOn: {
              click: function($event) {
                return _vm.closeFiltersSideDrawer($event)
              }
            }
          }),
          _vm._v(" "),
          _c(
            "BaseText",
            {
              staticClass: "v-filters-side-drawer__header-text",
              attrs: { color: "white", size: "button", tag: "span" }
            },
            [
              _vm._v(
                "\n      Filters" +
                  _vm._s(
                    !!_vm.numberOfFiltersApplied
                      ? " (" + _vm.numberOfFiltersApplied + ")"
                      : ""
                  ) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          !!_vm.numberOfFiltersApplied
            ? _c(
                "button",
                {
                  staticClass: "v-filters-side-drawer__clear-btn",
                  on: { click: _vm.clearFilters }
                },
                [
                  _c(
                    "BaseText",
                    {
                      staticClass: "v-filters-side-drawer__clear-btn-text",
                      attrs: { color: "white", tag: "span" }
                    },
                    [_vm._v("\n        Clear all\n      ")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: {
            "v-filters-side-drawer__body": true,
            "v-filters-side-drawer__body--busy": _vm.busy
          }
        },
        [_c("FiltersPanel")],
        1
      ),
      _vm._v(" "),
      _c(
        "BaseButton",
        {
          staticClass: "v-filters-side-drawer__apply-btn",
          attrs: {
            type: "block",
            theme: "primary",
            block: true,
            wide: true,
            "no-hover": true,
            loading: _vm.busy
          },
          nativeOn: {
            click: function($event) {
              return _vm.closeFiltersSideDrawer($event)
            }
          }
        },
        [_vm._v("\n    Close\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }