


























import 'reflect-metadata';
import { Vue, Component } from 'nuxt-property-decorator';

@Component
export default class PageNotFoundLayout extends Vue {}
