






























































import { Vue, Component, Prop, namespace } from 'nuxt-property-decorator';
import { GROUP_SYSTEM } from '~/constants/notifications';
import { notificationMeta } from '~/config/notifications/notifications';
import ButtonIcon from '~/components/partials/icons/ButtonIcon/ButtonIcon.vue';
import { ToggleDrawerPayload } from '~/store/drawers';

const DrawersStore = namespace('drawers');
const ProductListsStore = namespace('productLists');

@Component({
  components: { ButtonIcon },
})
export default class TheNotifications extends Vue {
  @Prop({ default: GROUP_SYSTEM }) readonly group!: string;
  @Prop({ default: null }) readonly animationName!: string;
  @Prop({ default: false }) readonly reverseOrder!: boolean;

  @DrawersStore.Action toggleSideDrawer!: (payload: ToggleDrawerPayload) => void;
  @ProductListsStore.Action addToFavourites!: (payload: string) => Promise<void>;
  @ProductListsStore.Action addToGiftList!: (payload: string) => Promise<void>;

  notificationMeta = notificationMeta;

  icon(type: string): string {
    return this.notificationMeta[type].icon;
  }

  color(type: string): string {
    return this.notificationMeta[type].color;
  }

  theme(type: string): string {
    return this.notificationMeta[type].theme;
  }

  close(type: string): string {
    return this.notificationMeta[type].close;
  }

  onNotificationClicked(functionKey: string, parameters: any) {
    switch (functionKey) {
      case 'openBasket':
        return this.toggleSideDrawer({ name: 'basket', isOpen: true });
      case 'addToFavourites':
        return this.addToFavourites(parameters.id);
      case 'toggleGiftMode':
        return this.$gifts.giftMode.toggle();
      case 'addToGiftList':
        return this.addToGiftList(parameters.id);
      default:
        return null;
    }
  }
}
