var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
    [_c("path", { attrs: { d: "M11 8.7v-2h2v1.9h-2zm0 8.6v-6.7h2v6.7h-2z" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }