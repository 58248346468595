var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.iconComponent,
    _vm._b(
      { tag: "component", staticClass: "v-icon", class: _vm.iconClasses },
      "component",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }