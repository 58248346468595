// there is a bug in chrome where 302 redirects are being cached and causing unwanted
// redirects - https://whirli.atlassian.net/browse/UWA-824

export function redirectWithoutCaching(context: any, path: string) {
  const { redirect, query } = context;

  if (process.server) {
    const { res } = context;

    res.setHeader('Cache-Control', 'private, no-cache, no-store, must-revalidate');
    res.setHeader('Expires', '-1');
    res.setHeader('Pragma', 'no-cache');
  }
  return redirect({ path, query });
}
