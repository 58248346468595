import { filterObject } from '~/utilities/iteration';

export function removeValueFromArray<T>(array: Array<T>, value: T): Array<T> {
  const index: number = array.indexOf(value);
  if (index > -1) {
    array.splice(index, 1);
  }

  return array;
}

export function removeValueFromArrayAll<T>(array: Array<T>, value: T): Array<T> {
  let i: number = 0;

  while (i < array.length) {
    if (array[i] === value) {
      array.splice(i, 1);
    } else {
      i = i + 1;
    }
  }

  return array;
}

export function hasOwnProp(object: object, property: any): boolean {
  return {}.hasOwnProperty.call(object, property);
}

export function deleteKeysWithEmptyStrings<T extends Record<string | number, any>>(object: T): Partial<T> {
  return filterObject(object, (value: any) => {
    return value !== '';
  });
}
