








import 'reflect-metadata';
import { Vue, Prop, Component } from 'nuxt-property-decorator';

@Component
export default class BaseTag extends Vue {
  @Prop({ default: () => ['micro', 'mini', 'none', 'mini'] })
  readonly padding!: string[];

  @Prop({ default: 'div', validator: (value) => ['div', 'span', 'button'].includes(value) })
  readonly tag!: string;

  @Prop({ default: 'none', validator: (value) => ['none', 'left', 'right'].includes(value) })
  readonly tilt!: string;

  @Prop({ default: 'secondary', validator: (value) => ['secondary', 'tertiary'].includes(value) })
  readonly theme!: string;

  get classes() {
    return {
      [`v-tag--${this.theme}`]: this.theme,
      [`v-tag--tilt-${this.tilt}`]: this.tilt !== 'none',
    };
  }
}
