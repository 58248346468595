
import 'reflect-metadata';
import { Vue, Prop, Component } from 'nuxt-property-decorator';

type LoadingStatus = 'loading' | 'loaded';

@Component
export default class ImageMixin extends Vue {
  @Prop({ default: null }) readonly imageClasses!: string;
  @Prop({ required: true }) readonly image!: string;
  @Prop({ required: true }) readonly alt!: string;

  intersectOptions: IntersectionObserverInit = {};
  loadingStatus: LoadingStatus = 'loading';

  imageElement: HTMLImageElement = {} as HTMLImageElement;

  mounted(): void {
    this.imageElement = {} as HTMLImageElement;
  }

  loadImage(): void {
    this.imageElement.src = this.image;
    this.imageElement.addEventListener('load', () => {
      this.loadingStatus = 'loaded';
    });
  }

  onIntersect(isIntersecting: boolean): void {
    if (isIntersecting) {
      this.loadImage();
    }
  }

  beforeDestroy() {
    this.imageElement.removeEventListener('load', this.loadImage);
  }
}
