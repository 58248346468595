var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 32 32" } },
    [
      _c("path", {
        attrs: {
          d:
            "M16 5C9.9 5 5 9.9 5 16s4.9 11 11 11 11-4.9 11-11S22.1 5 16 5zM6.9 16.9H10c.1 1.2.2 2.3.4 3.3-.8.2-1.5.5-2.1.8-.7-1.1-1.3-2.5-1.4-4.1zm8.2-6.3c-.8 0-1.6-.1-2.3-.3.6-1.6 1.4-2.8 2.3-3.3v3.6zm0 1.9v2.6h-3.2c0-1 .2-2 .4-2.9.9.1 1.8.2 2.8.3zm0 4.4v2.6c-1 0-1.9.2-2.8.3-.2-.9-.3-1.9-.4-2.9h3.2zm0 4.5v3.5c-.9-.5-1.7-1.6-2.3-3.3.7-.1 1.5-.2 2.3-.2zm1.8 0c.8 0 1.6.1 2.3.3-.6 1.6-1.4 2.8-2.3 3.3v-3.6zm0-1.9v-2.6h3.2c0 1-.2 2-.4 2.9-.9-.1-1.8-.2-2.8-.3zm0-4.4v-2.6c1 0 1.9-.1 2.8-.3.2.9.3 1.9.4 2.9h-3.2zm0-4.5V7.1c.9.5 1.7 1.6 2.3 3.3-.7.1-1.5.2-2.3.2zM20 7.8c.9.4 1.7 1 2.4 1.7-.4.1-.9.3-1.4.5-.3-.9-.6-1.6-1-2.2zm-9 2.1c-.5-.1-1-.3-1.4-.5.7-.7 1.5-1.2 2.4-1.7-.4.7-.7 1.4-1 2.2zm-.5 1.8c-.2 1-.4 2.2-.4 3.3H6.9c.2-1.5.7-3 1.5-4.2.6.4 1.3.7 2.1.9zm.5 10.4c.3.8.6 1.6 1 2.2-.9-.4-1.7-1-2.4-1.7.4-.2.9-.4 1.4-.5zm10 0c.5.2.9.3 1.4.5-.7.7-1.5 1.2-2.4 1.7.4-.7.7-1.4 1-2.2zm.5-1.8c.2-1 .4-2.2.4-3.3h3.2c-.2 1.5-.7 3-1.5 4.2-.6-.4-1.3-.7-2.1-.9zm.5-5.2c-.1-1.2-.2-2.3-.4-3.3.8-.2 1.5-.5 2.1-.8.8 1.2 1.4 2.6 1.5 4.2H22z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }