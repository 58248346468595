




import 'reflect-metadata';
import { Vue, Prop, Component } from 'nuxt-property-decorator';

@Component
export default class SelectedFiltersCount extends Vue {
  @Prop({ required: true }) readonly count!: number;
}
