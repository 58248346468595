import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '~/store/index';

const types = {
  PUT_HAMBURGER_MENU_STATE: 'PUT_HAMBURGER_MENU_STATE',
  PUT_DROPDOWN_MENU_STATE: 'PUT_DROPDOWN_MENU_STATE',
};

export interface ModuleState {
  isHamburgerMenuOpen: boolean;
  isDropdownMenuOpen: boolean;
}

export const state = (): ModuleState => ({
  isHamburgerMenuOpen: false,
  isDropdownMenuOpen: false,
});

export const actions: ActionTree<ModuleState, RootState> = {
  toggleHamburgerMenu({ commit }, isOpen: boolean) {
    this.$logger.store(`action`, `[navigation/toggleHamburgerMenu]`, { isOpen });

    if (isOpen) commit(types.PUT_DROPDOWN_MENU_STATE, { isDropdownMenuOpen: false });
    commit(types.PUT_HAMBURGER_MENU_STATE, { isHamburgerMenuOpen: isOpen });
  },
  toggleDropdownMenu({ commit }, isOpen: boolean) {
    this.$logger.store(`action`, `[navigation/toggleDropdownMenu]`, { isOpen });

    if (isOpen) commit(types.PUT_HAMBURGER_MENU_STATE, { isHamburgerMenuOpen: false });
    commit(types.PUT_DROPDOWN_MENU_STATE, { isDropdownMenuOpen: isOpen });
  },
};

export const mutations: MutationTree<ModuleState> = {
  [types.PUT_HAMBURGER_MENU_STATE](state, { isHamburgerMenuOpen }: Pick<ModuleState, 'isHamburgerMenuOpen'>) {
    state.isHamburgerMenuOpen = isHamburgerMenuOpen;
  },
  [types.PUT_DROPDOWN_MENU_STATE](state, { isDropdownMenuOpen }: Pick<ModuleState, 'isDropdownMenuOpen'>) {
    state.isDropdownMenuOpen = isDropdownMenuOpen;
  },
};
