






import 'reflect-metadata';
import { Vue, Component, Prop } from 'nuxt-property-decorator';

@Component
export default class BaseOverlay extends Vue {
  @Prop({ default: false }) readonly visible!: boolean;
  @Prop({ default: false }) readonly coverNav!: boolean;
}
