




















import axios from 'axios';
import { Vue, Component } from 'nuxt-property-decorator';
import NavMobileBar from '../NavMobileBar/NavMobileBar.vue';
import NavDesktopBar from '../NavDesktopBar/NavDesktopBar.vue';

@Component({
  components: {
    NavMobileBar,
    NavDesktopBar,
  },
})
export default class TheNavBar extends Vue {
  products: Array<any> = [];
  filteredSuggestions: Array<any> = [];
  searchInitiated: Boolean = false;
  nextPage: number = 1;
  searchQuery: string = '';
  hasMoreResults: boolean = true;
  requestedPages: Set<number> = new Set();

  get isRegistrationFlow(): boolean {
    return this.$route.name === 'register';
  }

  async handleSearchInput(searchQuery: string) {
    this.searchQuery = searchQuery;
    this.nextPage = 1;
    this.requestedPages.clear();
    await this.fetchSuggestions(searchQuery);
  }

  async loadMoreResults() {
    if (this.hasMoreResults) {
      await this.fetchSuggestions(this.searchQuery);
    }
  }

  async fetchSuggestions(searchQuery: string) {
    try {
      const currentPage = this.nextPage;

      // Check if the current page has already been requested
      if (this.requestedPages.has(currentPage)) {
        return;
      }

      const query = searchQuery.toLowerCase();
      const host = process.env.baseHost;
      const port = process.env.basePort;
      const apiUseHttps = process.env.protocol?.toLocaleLowerCase() === 'true';
      const protocol = apiUseHttps ? 'https' : 'http';
      const url = `${protocol}://${host}:${port}/api/guest/products/search?search=${query}&page=${currentPage}&per_page=10`;

      if (query.length >= 3) {
        const response = await axios.get(url);
        if (response.data && response.data.data && Array.isArray(response.data.data.products)) {
          const filteredSuggestions = response.data.data.products.map((product: any) => ({
            name: product.name,
            tokens: product.tokens,
            discountedValue: product.discounted_value,
            url: product.assets[0]?.url || '',
            slug: product.slug,
          }));
          this.searchInitiated = true;

          if (currentPage === 1) {
            this.filteredSuggestions = filteredSuggestions;
          } else {
            this.filteredSuggestions = [...this.filteredSuggestions, ...filteredSuggestions];
          }

          // Add the current page to the set of requested pages
          this.requestedPages.add(currentPage);

          this.nextPage++;
          this.hasMoreResults = response.data.data.meta.last_page > response.data.data.meta.current_page;
        } else {
          console.error('Unexpected response structure:', response.data);
          this.hasMoreResults = false;
        }
      } else {
        this.hasMoreResults = false;
      }
    } catch (error) {
      this.hasMoreResults = false;
    }
  }
}
