var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 12 12" } },
    [
      _c("path", {
        attrs: {
          d:
            "M9.4 3h-1c-.1 0-.2.1-.2.2l-.7 3.1-.8-3.1c0-.1-.1-.2-.2-.2H5.4c-.1 0-.1.1-.2.2l-.7 3.2-.8-3.2c0-.1-.1-.2-.2-.2h-1s-.1 0-.1.1-.1.1 0 .2l1.4 5.6s.1.1.2.1h1c.1 0 .2-.1.2-.2L6 5.6l.8 3.2c0 .1.1.2.2.2h1c.1 0 .2-.1.2-.2l1.4-5.6V3h-.2",
          "fill-rule": "evenodd",
          "clip-rule": "evenodd"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }