






























import 'reflect-metadata';
import { Vue, Prop, Component } from 'nuxt-property-decorator';
import TheHamburger from '../TheHamburger/TheHamburger.vue';
import TheBagIcon from '~/components/partials/icons/TheBagIcon/TheBagIcon.vue';
import mq from '~/helpers/screen';
import { TABLET } from '~/constants/screen-breakpoints';

const availableThemes: string[] = ['secondary', 'tertiary', 'white'];

@Component({
  components: {
    TheBagIcon,
    TheHamburger,
  },
})
export default class NavIconLink extends Vue {
  @Prop({
    required: true,
    validator: (value) => ['Hamburger', 'Toys', 'Profile', 'Bag', 'Cross'].includes(value),
  })
  readonly icon!: string;

  @Prop({ default: 'secondary', validator: (value) => availableThemes.includes(value) })
  readonly theme!: string;

  @Prop({ default: 'nuxt-link', validator: (value) => ['a', 'nuxt-link', 'button'].includes(value) })
  readonly tag!: string;

  @Prop({ default: 0 }) readonly number!: number;

  @Prop({ default: false }) readonly active!: boolean;

  @Prop({ default: false }) readonly showWarningBadge!: boolean;

  get iconSize() {
    const currentScreenSize: string = this.$store.state.resize.debounced.breakpoint;
    return mq({ from: TABLET }, currentScreenSize) ? 'medium' : 'base';
  }
}
