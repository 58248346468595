var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 397.6 338.6"
      }
    },
    [
      _c("path", {
        attrs: { d: "M397.6 51.5H0v68.9h18.4v218.2h360.8V120.4h18.4z" }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M18.4 120.4h360.8v14.2H18.4z",
          fill: "#001",
          "fill-opacity": ".25"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M232.2 0c-19 0-33.4 16.5-33.4 16.5S184.4 0 165.4 0s-48.7 17.8-48.7 37.2 22 24.1 41.1 24.1h17v277.2h48V61.4h17c19 0 41.1-4.7 41.1-24.1S251.3 0 232.2 0z",
          fill: "#63d6c5"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }