var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._b({ staticClass: "v-filters-panel" }, "div", _vm.$attrs, false),
    [
      _c(
        "div",
        {
          staticClass: "v-filters-panel__toggle-container",
          class: { "v-filters-panel__toggle-container--busy": _vm.busy }
        },
        [
          _vm.$auth.loggedIn && !_vm.$auth.user.onFirstOrder
            ? _c(
                "BaseToggleButton",
                {
                  staticClass:
                    "v-filters-panel__toggle-btn u-margin-bottom-mini",
                  attrs: { id: "ordered-previously", on: _vm.hidePrevious },
                  on: { toggled: _vm.toggleProductsNotPreviouslyOrdered }
                },
                [
                  _c(
                    "BaseText",
                    { attrs: { color: "charcoal", tag: "span" } },
                    [
                      _vm._v(
                        "\n        Only show toys I've not ordered before\n      "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "BaseToggleButton",
            {
              staticClass: "v-filters-panel__toggle-btn",
              attrs: { id: "not-in-stock", on: _vm.hideNotInStock },
              on: { toggled: _vm.toggleProductsNotInStock }
            },
            [
              _c("BaseText", { attrs: { color: "charcoal", tag: "span" } }, [
                _vm._v("\n        Only show toys that are in stock\n      ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("SelectedFilters"),
      _vm._v(" "),
      _vm.associations && _vm.associations.length
        ? _c("BaseAccordionGroup", {
            staticClass: "v-filters-panel__accordions",
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var group = ref.group
                    return [
                      _c("TokenSliderAccordion", {
                        attrs: { "is-open": group.list.includes("tokens") },
                        on: {
                          "toggle-accordion": function($event) {
                            return group.update("tokens")
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.associations, function(association, index) {
                        return _c("FiltersAccordion", {
                          key: index,
                          attrs: {
                            delayed: true,
                            association: association,
                            "is-open":
                              group.list.includes(association.name) ||
                              _vm.isOpen(association)
                          },
                          on: {
                            "toggle-accordion": function($event) {
                              return group.update(association.name)
                            }
                          }
                        })
                      })
                    ]
                  }
                }
              ],
              null,
              false,
              1224493108
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }