import {
  TypeSubscriptionIntervalFormat,
  TypeSubscriptionIntervalLength,
  TypeSubscriptionIntervalLengthDescriptive,
  TypeSubscriptionIntervalLengthShorthand,
} from '~/types/subscription';

// Longhand values
export const MONTHLY_LONG: TypeSubscriptionIntervalLengthDescriptive = 'monthly';
export const THREE_MONTHS_LONG: TypeSubscriptionIntervalLengthDescriptive = 'every 3 months';
export const HALF_YEARLY_LONG: TypeSubscriptionIntervalLengthDescriptive = 'half-yearly';
export const YEARLY_LONG: TypeSubscriptionIntervalLengthDescriptive = 'yearly';

// Shorthand values
export const MONTHLY: TypeSubscriptionIntervalLengthShorthand = 'mo';
export const THREE_MONTHS: TypeSubscriptionIntervalLengthShorthand = '3mo';
export const HALF_YEARLY: TypeSubscriptionIntervalLengthShorthand = '6mo';
export const YEARLY: TypeSubscriptionIntervalLengthShorthand = 'yr';

// Numerical values
export const MONTHLY_NUMBER: TypeSubscriptionIntervalLength = 1;
export const THREE_MONTHS_NUMBER: TypeSubscriptionIntervalLength = 3;
export const HALF_YEARLY_NUMBER: TypeSubscriptionIntervalLength = 6;
export const YEARLY_NUMBER: TypeSubscriptionIntervalLength = 12;

// Formats
export const FORMAT_LONG: TypeSubscriptionIntervalFormat = 'long';
export const FORMAT_SHORT: TypeSubscriptionIntervalFormat = 'short';

export const FREQUENCY_BY_INTERVAL: Record<number, TypeSubscriptionIntervalLengthDescriptive> = {
  1: 'monthly',
  3: 'every 3 months',
  6: 'half-yearly',
  12: 'yearly',
};
