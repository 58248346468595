import { hasActiveSubscription } from '~/helpers/models/user';
import { redirectWithoutCaching } from '~/helpers/redirect';

export default function(context) {
  const { app, query } = context;

  if (!query.fromRegistration && app.$auth.user && !hasActiveSubscription(app.$auth.user)) {
    // Prevent redirect to continue registration from homepage if
    // - user is logged in
    // - does not have active subscription
    // - has active Refer registration flow
    if (query.code && query.by) return;
    return redirectWithoutCaching(context, '/register');
  }
}
