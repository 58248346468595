




import { Vue, Prop, Component } from 'nuxt-property-decorator';
import { colorFromTheme } from '~/helpers/colors';

export const availableColors: string[] = [
  'charcoal',
  'light-grey-one',
  'grey-one',
  'grey-two',
  'secondary',
  'primary-dark',
];

@Component
export default class BaseDivider extends Vue {
  @Prop({ default: '1px' }) readonly height!: string;
  @Prop({ default: 'light-grey-one', validator: (value) => availableColors.includes(value) })
  readonly color!: string;

  get styles(): object {
    return {
      height: this.height,
      backgroundColor: colorFromTheme(this.color),
    };
  }
}
