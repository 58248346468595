var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "v-fetch-retry",
      attrs: { "aria-live": "polite", role: "region" }
    },
    [
      _c(
        "BaseText",
        { attrs: { center: true, color: _vm.color } },
        [
          _vm._t("default", [_vm._v("We were unable to display this data.")]),
          _vm._v(" "),
          !_vm.hideRetry
            ? _c(
                "button",
                {
                  staticClass: "v-fetch-retry__btn",
                  style: _vm.textColor,
                  on: {
                    click: function($event) {
                      return _vm.$emit("click")
                    }
                  }
                },
                [_vm._v("\n      Click here to retry\n    ")]
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }