import { ADDITIONAL_TOKEN_BUNDLE_SIZE } from '~/constants/subscription-addons';
import { MergedAddonAndPricingPlan, SubscriptionAddonPricingPlan } from '~/types/subscription';
import { UserSubscription } from '~/types/user';

export function tokensFromBundles(bundles: number): number {
  return bundles * ADDITIONAL_TOKEN_BUNDLE_SIZE;
}

export function bundlesFromTokens(tokens: number): number {
  return tokens / ADDITIONAL_TOKEN_BUNDLE_SIZE;
}

export function getAddonByName(
  name: string,
  addons: MergedAddonAndPricingPlan[]
): MergedAddonAndPricingPlan | undefined {
  return addons.find((addon: MergedAddonAndPricingPlan) =>
    addon.subscriptionAddonPricingPlan?.name.includes(name)
  );
}

export function getAddonByParentName(
  name: string,
  addons: MergedAddonAndPricingPlan[]
): MergedAddonAndPricingPlan | undefined {
  return addons.find((addon: MergedAddonAndPricingPlan) => addon?.name?.includes(name));
}

export function getAddonsByName(
  name: string,
  addons: MergedAddonAndPricingPlan[]
): MergedAddonAndPricingPlan[] | undefined {
  return addons.filter((addon: MergedAddonAndPricingPlan) => addon?.name?.includes(name));
}

export function getAddonsCost(addons: MergedAddonAndPricingPlan[]): number {
  return addons.reduce((total: number, addon: MergedAddonAndPricingPlan) => {
    return total + parseFloat(addon.subscriptionAddonPricingPlan.cost) * addon.quantity;
  }, 0);
}

export function getAddonByParentNameAndFrequency(
  subscriptionAddonPricingPlans: Array<SubscriptionAddonPricingPlan>,
  name: string,
  frequency: number
): SubscriptionAddonPricingPlan | undefined {
  return subscriptionAddonPricingPlans.find(
    (addon: SubscriptionAddonPricingPlan) => addon?.name?.includes(name) && addon.intervalLength === frequency
  );
}

export function getActiveSubscriptionAddonPricingPlans(
  activeUserSubscription: UserSubscription | null
): Array<MergedAddonAndPricingPlan> {
  if (!activeUserSubscription || !activeUserSubscription.activeAddons) return [];
  return activeUserSubscription.activeAddons.map((addonPricingPlan: MergedAddonAndPricingPlan) => {
    return addonPricingPlan;
  });
}
