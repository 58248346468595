






















import { Vue, Component, Prop } from 'nuxt-property-decorator';
// @ts-ignore
import VueSlider from 'vue-slider-component/dist-css/vue-slider-component.umd.min.js';
import { colorFromTheme } from '~/helpers/colors';

@Component({
  components: { VueSlider },
})
export default class BaseRangeSlider extends Vue {
  @Prop({ default: false }) readonly disabled!: boolean;
  @Prop() readonly value!: [number, number];
  @Prop() readonly min!: number;
  @Prop() readonly max!: number;

  dotOptions = [
    { style: { border: `1px solid ${colorFromTheme('secondary')}` } },
    { style: { border: `5px solid ${colorFromTheme('secondary')}` } },
  ];

  processStyle = { backgroundColor: colorFromTheme('secondary') };
  railStyle = { backgroundColor: colorFromTheme('light-grey-one') };
  tooltipStyle = {
    color: colorFromTheme('secondary'),
    backgroundColor: 'transparent',
    fontFamily: 'MaaxMono, monospace',
  };

  formatter(value: number) {
    return `${value}`;
  }

  handleChange(newValue: [number, number]) {
    this.$emit('range-slider:change', newValue);
  }
}
