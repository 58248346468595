var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 32 32" } },
    [
      _c("path", {
        attrs: {
          d:
            "M28 9.2c-.9.4-1.8.6-2.8.8 1-.6 1.8-1.5 2.2-2.7-1 .5-2 .9-3.1 1.2-1-.9-2.3-1.5-3.7-1.5-2.7 0-4.9 2.1-4.9 4.8 0 .4 0 .7.1 1.1-4.1-.2-7.7-2.1-10.1-5-.5.7-.7 1.5-.7 2.4 0 1.7.9 3.1 2.2 4-.8 0-1.6-.2-2.2-.6v.1c0 2.3 1.7 4.3 3.9 4.7-.4.1-.8.2-1.3.2-.3 0-.6 0-.9-.1.6 1.9 2.4 3.3 4.6 3.3C9.6 23.2 7.5 24 5.2 24c-.4 0-.8 0-1.2-.1 2.2 1.4 4.8 2.2 7.5 2.2 9.1 0 14-7.3 14-13.7v-.6c1-.7 1.8-1.6 2.5-2.6"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }