import Events from './Events';

export default class FacebookEvents extends Events {
  /**
   * Tracks a registration for Facebook.
   *
   * @param {String} contentName
   * @param {String} status
   * @param {Number} value
   * @param {String} currency
   */
  completeRegistration(contentName: string, status = true, value = 0, currency = 'GBP') {
    this.data.pushEvent('FacebookCompleteRegistration', {
      fb_content_name: contentName,
      fb_status: status,
      fb_value: value,
      fb_currency: currency,
    });
  }

  /**
   * Tracks an initiate checkout for Facebook.
   *
   * @param {String} contentCategory
   */
  initiateCheckout(contentCategory: string) {
    this.data.pushEvent('FacebookInitiateCheckout', {
      fb_content_category: contentCategory,
    });
  }

  /**
   * Tracks a lead for Facebook.
   *
   * @param {String} contentName
   * @param {String} contentCategory
   * @param {Number} value
   * @param {String} currency
   */
  lead(contentName: string, contentCategory: string, value = 1, currency = 'GBP') {
    this.data.pushEvent('FacebookLead', {
      fb_content_name: contentName,
      fb_content_category: contentCategory,
      fb_value: value,
      fb_currency: currency,
    });
  }

  /**
   * Tracks a purchase for Facebook.
   *
   * @param {String} contentName
   * @param {Number|String} value
   * @param {String} currency
   */
  purchase(contentName: string, value: string | number = 0, currency = 'GBP') {
    this.data.pushEvent('FacebookPurchase', {
      fb_content_name: contentName,
      fb_value: value,
      fb_currency: currency,
    });
  }

  /**
   * Tracks a subscription for Facebook.
   *
   * @param {String} contentName
   * @param {String} value
   * @param {String} predictedLtv
   * @param {String} currency
   */
  subscription(contentName: string, value: string, predictedLtv: string, currency: string = 'GBP') {
    this.data.pushEvent('FacebookSubscribe', {
      fb_content_name: contentName,
      fb_value: value,
      fb_predicted_ltv: predictedLtv || value,
      fb_currency: currency,
    });
  }

  /**
   * Tracks a page view for Facebook.
   *
   * @param {String} contentName
   * @param {String|Number} contentId
   * @param {String} contentCategory
   * @param {String} value
   */
  viewContent(contentName: string, contentId: string, contentCategory: string, value: string) {
    this.data.pushEvent('FacebookViewContent', {
      fb_content_name: contentName,
      fb_content_id: contentId,
      fb_content_category: contentCategory,
      fb_value: value,
      fb_currency: 'GBP',
    });
  }

  /**
   * View content IDs for dynamic ads tracking
   *
   * @param {Array} contentIds
   * @param {String} value
   */
  viewContentIds(contentIds: Array<string>, value: string) {
    this.data.pushEvent('FacebookViewContentIds', {
      fb_content_ids: contentIds,
      fb_value: value,
      fb_currency: 'GBP',
    });
  }

  /**
   * Tracks an add to cart for Facebook.
   *
   * @param {String} contentName
   * @param {String|Number} contentId
   * @param {String} contentCategory
   * @param {String} value
   */
  addToCart(contentName: string, contentId: string, contentCategory: string, value: string) {
    this.data.pushEvent('FacebookAddToCart', {
      fb_content_name: contentName,
      fb_content_id: contentId,
      fb_content_category: contentCategory,
      fb_value: value,
      fb_currency: 'GBP',
    });
  }
}
