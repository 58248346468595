import { GlobalInjects } from '~/index.d';

export default class DataLayer {
  app: GlobalInjects;

  constructor(app: GlobalInjects) {
    this.app = app;

    if (process.browser) {
      window.dataLayer = window.dataLayer || [];
      this.push({
        page_location:
          document.location.protocol +
          '//' +
          document.location.hostname +
          document.location.pathname +
          document.location.search,
      });
      this.pushEvent('BootDataLayer');
    }
  }

  /**
   * Pushes a data object into the Google Tag Manager dataLayer.
   *
   * @param {Object} object
   */
  push(object: object) {
    this.app.$logger.debug(`gtm`, object);

    if (process.browser) {
      window.dataLayer.push(object);
    }
  }

  /**
   * Fires a Google Tag Manager dataLayer event to hook into.
   *
   * @param {String} event
   * @param {Object} data
   */
  pushEvent(event: string, data = {}) {
    this.push({
      event,
      ...data,
    });
  }
}
