








import { Vue, Component, Prop, Watch } from 'nuxt-property-decorator';
import { addKeyupEventListener, removeKeyupEventListener } from '~/helpers/keyboard';
import BaseModal from '~/components/partials/BaseModal/BaseModal.vue';

@Component({
  components: {
    BaseModal,
  },
})
export default class TheModal extends Vue {
  @Prop({ default: false }) readonly isOpen!: boolean;
  @Prop({ default: true }) readonly overlayCoverNav!: boolean;

  @Watch('isOpen')
  setBackgroundAriaHidden() {
    this.$store.dispatch('scroll/setScrollDisabled', this.isOpen);
    this.$store.dispatch('overlay/toggleOverlay', { usedBy: 'TheModal', coverNav: this.overlayCoverNav });

    if (!document) return;
    const main = document.querySelector('main');
    if (!main) return;
    main.setAttribute('aria-hidden', String(this.isOpen));
  }

  mounted() {
    addKeyupEventListener(27, this.toggleModal);
  }

  destroyed() {
    removeKeyupEventListener(27, this.toggleModal);
  }

  toggleModal() {
    this.$emit('closeModal');
  }
}
