import { Campaign } from '~/types/campaigns';
import { RefereeTypeId } from '~/constants/referral';

interface CampaignsState {
  doubleUpReferral: Campaign;
}

export const state = (): CampaignsState => ({
  doubleUpReferral: {
    key: 'doubleUpReferral',
    start: '2022-12-01T00:00:00Z',
    end: '2023-12-15T23:59:59Z',
    userType: RefereeTypeId.NORMAL,
  },
});
