import DataLayer from '../data/DataLayer';

export default class Events {
  data: DataLayer;

  /**
   * @param data
   */
  constructor(data: DataLayer) {
    this.data = data;
  }

  /**
   * Pushes a data object into the Google Tag Manager dataLayer.
   *
   * @param {Object} object
   */
  push(object: object) {
    this.data.push(object);
  }

  /**
   * Fires a Google Tag Manager dataLayer event to hook into.
   *
   * @param {String} event
   * @param {Object} data
   */
  pushEvent(event: string, data = {}) {
    this.data.push({
      event,
      ...data,
    });
  }
}
