var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "u-fade-in" } }, [
    _vm.visible
      ? _c("div", {
          staticClass: "v-overlay",
          class: { "v-overlay--cover-nav": _vm.coverNav }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }