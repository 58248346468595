



















import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { bannerThemes } from '~/helpers/bannerThemes';

const availableTypes = ['warning', 'info', 'success', 'error', 'referral', 'offer'];

@Component
export default class BaseBanner extends Vue {
  @Prop({ default: true }) readonly canClose!: boolean;
  @Prop({ default: 'left' }) readonly justify!: string;
  @Prop({ default: 'tiny' }) readonly spacing!: string;
  @Prop({ default: 'top' }) readonly align!: string;
  @Prop({ default: 'body' }) readonly size!: string;
  @Prop({ default: false }) readonly uppercase!: boolean;
  @Prop({ default: 'warning', validator: (value) => availableTypes.includes(value) }) readonly type!: string;
  @Prop({ default: true }) readonly hasIcon!: boolean;
  isShown: boolean = true;

  closeBanner() {
    this.hasCloseListener ? this.$emit('close') : (this.isShown = false);
  }

  get hasCloseListener(): boolean {
    if (this.$listeners && this.$listeners.close) {
      return true;
    }
    return false;
  }

  get backgroundColor(): string {
    return bannerThemes[this.type].backgroundColor;
  }

  get textColor(): string {
    return bannerThemes[this.type].textColor;
  }

  get icon(): string | null {
    return this.hasIcon ? bannerThemes[this.type].icon : null;
  }

  get iconTheme(): string {
    return bannerThemes[this.type].iconTheme;
  }
}
