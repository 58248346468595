var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "40",
        height: "40",
        viewBox: "0 0 40 40",
        fill: "none",
        "xmlns:v": "https://vecta.io/nano"
      }
    },
    [
      _c("circle", {
        attrs: {
          cx: "20",
          cy: "20",
          r: "19.5",
          fill: "#70e2d2",
          stroke: "#fff"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M7.029 14.634c.124-.345.282-.676.471-.99a5.39 5.39 0 0 1 1.309-1.47c-.035-.537.092-1.072.364-1.536s.679-.835 1.165-1.065 1.031-.309 1.562-.226 1.026.324 1.419.692a2.82 2.82 0 0 1 .179-.44A5.48 5.48 0 0 0 9.347 8.49a5.48 5.48 0 0 0-3.675 2.229 5.48 5.48 0 0 0-.935 4.195 5.48 5.48 0 0 0 .824 2.033c.409.615.938 1.141 1.556 1.546a5.42 5.42 0 0 1-.088-3.858z",
          fill: "#51247a"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M13.7 25.015l-.28-.375c-.176.179-.304.398-.374.639l-.691 2.406c-.055.192-.072.392-.05.59s.083.39.18.564.226.328.383.452.335.216.527.271l2.278.659-.109-.869a10.4 10.4 0 0 0-1.851-4.343l-.012.005z",
          fill: "#003f23"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          d:
            "M24.395 39.544l.053-.094c2.478-4.453 2.258-9.012 1.778-11.883a20.44 20.44 0 0 0-.288-1.405l.003.001a20.68 20.68 0 0 0-1.482-4.156c-.487-1.013-1.052-1.986-1.688-2.913l1.575-.422c1.063-.286 1.988-.944 2.607-1.855s.89-2.013.765-3.106-.638-2.106-1.447-2.854-1.858-1.18-2.958-1.219a2.01 2.01 0 0 0-1.352-.987 2 2 0 0 0-1.6.355 2 2 0 0 0-.799 1.431l-.99.26c-.027-.59-.249-1.155-.63-1.606s-.901-.764-1.478-.89-1.18-.058-1.715.194-.971.673-1.242 1.198a2.82 2.82 0 0 0-.179.44c-.393-.368-.887-.609-1.419-.692s-1.076-.004-1.562.226-.892.601-1.165 1.065-.4.999-.364 1.536a5.39 5.39 0 0 0-1.309 1.47c-.44.721-.704 1.536-.772 2.378s.063 1.688.382 2.47a5.39 5.39 0 0 0 2.208 2.587 16.9 16.9 0 0 1 1.49 1.013 14.04 14.04 0 0 1 2.597 2.547l.117.156.163.219a10.4 10.4 0 0 1 1.851 4.343c.048.288.084.579.109.869.166 1.977-.239 3.964-1.428 5.584-.173.236-.354.459-.543.67-.603.676-1.282 1.234-1.992 1.664C14.255 39.333 17.115 40 20.13 40a20.08 20.08 0 0 0 4.265-.456z",
          fill: "#21a247"
        }
      }),
      _vm._v(" "),
      _c("g", { attrs: { fill: "#fff" } }, [
        _c("path", {
          attrs: {
            d:
              "M27.723 13.52l-2.913.779c.049.193.137.375.257.534s.27.293.442.394.362.166.56.193a1.51 1.51 0 0 0 .591-.038c.193-.051.373-.141.531-.262a1.51 1.51 0 0 0 .576-1.009 1.51 1.51 0 0 0-.044-.591z"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M23.744 16.146a1.51 1.51 0 0 0 .916-.703c.099-.171.163-.361.189-.557s.013-.396-.038-.587l-2.913.779c.051.191.139.371.26.528s.271.289.442.388.361.163.557.189a1.51 1.51 0 0 0 .587-.039v.001z"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M20.831 16.927c.191-.051.37-.14.528-.26s.289-.271.388-.442.163-.361.189-.557.013-.396-.038-.587l-2.914.779a1.51 1.51 0 0 0 .703.916 1.51 1.51 0 0 0 1.145.15v.001z"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M17.916 17.708c.384-.104.711-.357.91-.701s.253-.754.151-1.139l-2.913.779c.051.191.14.371.26.528s.271.289.442.388.361.163.557.189.396.013.587-.039l.005-.005z"
          }
        })
      ]),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M14.518 17.219c-.98 0-1.919-.387-2.614-1.078a.54.54 0 0 1-.17-.386.54.54 0 0 1 .158-.391c.051-.051.112-.092.179-.119s.139-.041.211-.04.144.016.21.045.126.071.176.124c.324.323.727.555 1.169.673s.907.119 1.349.001l12.329-3.303c.071-.028.147-.041.223-.038a.54.54 0 0 1 .396.198.54.54 0 0 1 .115.427.54.54 0 0 1-.242.371.54.54 0 0 1-.212.08l-12.325 3.309c-.309.085-.628.128-.948.13l-.005-.005z",
          fill: "#148c3e"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          d:
            "M19.525 39.995a15.43 15.43 0 0 0 1.558-4.163c.684-3.214.268-6.606-1.208-9.809-.154-.319-.243-.665-.262-1.018s.033-.707.152-1.041a2.66 2.66 0 0 1 .543-.901c.239-.261.528-.472.85-.62s.669-.231 1.023-.243.707.046 1.038.172.633.316.89.561.462.537.604.861c.841 1.813 1.431 3.733 1.755 5.705.221 2.778-.039 6.397-2.017 9.952l-.033.059A20.06 20.06 0 0 1 20 40c-.159 0-.317-.002-.475-.005z",
          fill: "#a7d1a0"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M9.757 12.466a1.79 1.79 0 0 0 1.497 1.313 1.79 1.79 0 0 0 1.786-.88 1.79 1.79 0 0 0-.129-1.987 1.79 1.79 0 0 0-1.885-.641c-.459.123-.85.424-1.088.835s-.303.9-.181 1.36z",
          fill: "#fff"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M12.151 11.826c-.022-.079-.058-.153-.106-.22-.013.041-.035.079-.064.11s-.066.056-.106.072-.083.022-.126.019-.085-.016-.122-.038-.069-.051-.093-.087-.04-.076-.046-.119-.003-.086.01-.127.035-.079.064-.111a.68.68 0 0 0-.693.412.68.68 0 0 0 .187.784.68.68 0 0 0 .804.055.68.68 0 0 0 .292-.752z",
          fill: "#1d1d1b"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M20.24 11.156a1.16 1.16 0 0 1 .818-1.418 1.16 1.16 0 0 1 1.418.818l-2.236.6z",
          fill: "#003f23"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M14.402 11.822l3.275-.877c.013-.197-.006-.394-.056-.584-.056-.232-.159-.451-.301-.644s-.321-.354-.527-.476-.434-.202-.671-.234-.478-.018-.709.044-.447.169-.636.316-.346.33-.463.539-.191.439-.218.676a1.79 1.79 0 0 0 .062.708c.051.19.133.37.244.532z",
          fill: "#fff"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M15.696 11.476l.688-.184a.67.67 0 0 0 .057-.864c-.018.059-.056.111-.106.147s-.111.055-.173.054-.122-.023-.171-.062-.083-.092-.099-.152c-.011-.045-.011-.093 0-.138a.29.29 0 0 1 .064-.122.65.65 0 0 0-.244.017.68.68 0 0 0-.505.649.68.68 0 0 0 .488.662v-.007z",
          fill: "#1d1d1b"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M23.312 9.641a4.58 4.58 0 0 1 1.473.297 2.01 2.01 0 0 0-2.46-1.421 2.08 2.08 0 0 0-.365.138 2.01 2.01 0 0 1 1.352.987v-.001z",
          fill: "#148c3e"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M10.819 22.093l.103 1.583c.013.199.065.393.152.572s.21.338.36.47.324.232.512.296.388.09.586.077l1.169-.075c-.809-1.112-1.78-2.097-2.881-2.922h-.001z",
          fill: "#003f23"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M33.913 18.565l-.104-.119c.072-.686-.026-1.714-.356-2.873-.029.649-.52 1.478-1.074 2.208h-.172a9.61 9.61 0 0 0-.583-1.891c.055.735-.46 1.758-.996 2.608a2.19 2.19 0 0 0-.16.199l-.008.012c-.697-.458-1.362-1.012-1.59-1.558.078 1.129.32 2.066.601 2.645a9.21 9.21 0 0 1-1.447 1.053c-1.065.626-2.208 1.021-3.219 1.11-.119.012-.236.033-.352.064a20.68 20.68 0 0 1 1.482 4.156c3.082-.519 6.309-2.492 8.074-4.981.459-.649 1.169-2.235 1.257-4.173-.203.53-.734 1.069-1.355 1.558v-.018z",
          fill: "#00994c"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M8.813 12.175c-.035-.537.092-1.072.364-1.536s.679-.835 1.165-1.065 1.031-.309 1.562-.226 1.026.324 1.419.692a2.68 2.68 0 0 1 1.613-1.727 2.68 2.68 0 0 1 2.353.222l.09-.229a3.7 3.7 0 0 0 .033-2.899 3.7 3.7 0 0 0-2.066-2.033 3.7 3.7 0 0 0-2.898.079 3.7 3.7 0 0 0-1.194.873c-.335.368-.593.801-.757 1.271l-3.247 8.252c-.099.251-.17.513-.213.779.124-.345.282-.676.471-.99a5.39 5.39 0 0 1 1.309-1.47l-.004.006z",
          fill: "#6d3e91"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M15.679 32.054a9.5 9.5 0 0 1 .275-1.025c.39-1.169.992-2.221 1.696-2.953.197-.205.351-.447.455-.712s.154-.548.148-.832-.068-.565-.182-.825-.279-.496-.484-.693-.447-.351-.712-.455-.547-.154-.832-.148-.565.068-.825.182-.496.279-.693.484c-2.291 2.386-3.657 6.344-3.309 9.625.09.849.587 2.46 1.795 3.971-.196-.532-.149-1.288 0-2.062a2.11 2.11 0 0 0 .158.022c.39.564 1.146 1.275 2.154 1.93-.408-.512-.578-1.456-.639-2.367.045-.035.088-.071.13-.109.523.403 1.086.751 1.681 1.039-.519-.52-.809-1.627-.964-2.619.002-.087-.002-.174-.01-.26a5.4 5.4 0 0 1-.025-.512c.864.182 1.761.474 2.192.939-.661-1.452-1.496-2.435-2.012-2.626l.001.007z",
          fill: "#00994c"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }