import Vue from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import BaseIcon from '~/components/partials/icons/BaseIcon/BaseIcon';
import BaseButton from '~/components/partials/buttons/BaseButton/BaseButton';
import BaseImage from '~/components/partials/images/BaseImage/BaseImage';
import BaseAccordion from '~/components/partials/accordions/BaseAccordion/BaseAccordion';
import BaseAccordionGroup from '~/components/partials/accordions/BaseAccordionGroup/BaseAccordionGroup';
import BaseBox from '~/components/primitives/BaseBox/BaseBox';
import BaseStack from '~/components/primitives/BaseStack/BaseStack';
import BaseSection from '~/components/primitives/BaseSection/BaseSection';
import BaseWrapper from '~/components/primitives/BaseWrapper/BaseWrapper';
import BaseLayout from '~/components/primitives/layout/BaseLayout/BaseLayout';
import BaseLayoutItem from '~/components/primitives/layout/BaseLayoutItem/BaseLayoutItem';
import BaseLink from '~/components/partials/links/BaseLink/BaseLink';
import BaseDropdown from '~/components/partials/BaseDropdown/BaseDropdown';
import BaseList from '~/components/partials/lists/BaseList/BaseList';
import BaseListItem from '~/components/partials/lists/BaseListItem/BaseListItem';
import TheNavBar from '~/components/prefabs/navigation/TheNavBar/TheNavBar';
import TheFooter from '~/components/prefabs/TheFooter/TheFooter';
import BaseTextInput from '~/components/partials/inputs/BaseTextInput/BaseTextInput';
import BaseMediaItem from '~/components/partials/media-items/BaseMediaItem/BaseMediaItem';
import TheOverlay from '~/components/containers/TheOverlay/TheOverlay';
import DiscIcon from '~/components/partials/icons/DiscIcon/DiscIcon';
import BaseVerticalSpacer from '~/components/primitives/BaseVerticalSpacer/BaseVerticalSpacer';
import BaseCluster from '~/components/primitives/BaseCluster/BaseCluster';
import BaseTag from '~/components/partials/BaseTag/BaseTag';
import BaseCard from '~/components/partials/BaseCard/BaseCard';
import BaseDivider from '~/components/partials/BaseDivider/BaseDivider';
import BaseText from '~/components/partials/BaseText/BaseText';
import BaseAnimation from '~/components/partials/animations/BaseAnimation/BaseAnimation';
import BaseCheckbox from '~/components/partials/inputs/BaseCheckbox/BaseCheckbox';
import BaseRadioButton from '~/components/partials/inputs/BaseRadioButton/BaseRadioButton';
import BaseProductImage from '~/components/partials/BaseProductImage/BaseProductImage';
import ThePageHeader from '~/components/prefabs/page-headers/ThePageHeader.vue';
import FetchRetry from '~/components/containers/FetchRetry/FetchRetry.vue';
import LoadingContentSwapper from '@/components/partials/LoadingContentSwapper/LoadingContentSwapper';

Vue.component('Validate', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.component('BaseIcon', BaseIcon);
Vue.component('BaseButton', BaseButton);
Vue.component('BaseImage', BaseImage);
Vue.component('BaseAccordion', BaseAccordion);
Vue.component('BaseAccordionGroup', BaseAccordionGroup);
Vue.component('BaseBox', BaseBox);
Vue.component('BaseStack', BaseStack);
Vue.component('BaseSection', BaseSection);
Vue.component('BaseWrapper', BaseWrapper);
Vue.component('BaseLayout', BaseLayout);
Vue.component('BaseLayoutItem', BaseLayoutItem);
Vue.component('BaseLink', BaseLink);
Vue.component('BaseDropdown', BaseDropdown);
Vue.component('BaseList', BaseList);
Vue.component('BaseListItem', BaseListItem);
Vue.component('TheNavBar', TheNavBar);
Vue.component('TheFooter', TheFooter);
Vue.component('BaseTextInput', BaseTextInput);
Vue.component('BaseMediaItem', BaseMediaItem);
Vue.component('TheOverlay', TheOverlay);
Vue.component('DiscIcon', DiscIcon);
Vue.component('BaseVerticalSpacer', BaseVerticalSpacer);
Vue.component('BaseCluster', BaseCluster);
Vue.component('BaseTag', BaseTag);
Vue.component('BaseCard', BaseCard);
Vue.component('BaseDivider', BaseDivider);
Vue.component('BaseText', BaseText);
Vue.component('BaseAnimation', BaseAnimation);
Vue.component('BaseCheckbox', BaseCheckbox);
Vue.component('BaseRadioButton', BaseRadioButton);
Vue.component('BaseProductImage', BaseProductImage);
Vue.component('ThePageHeader', ThePageHeader);
Vue.component('FetchRetry', FetchRetry);
Vue.component('LoadingContentSwapper', LoadingContentSwapper);
