




































import { Prop, Component, mixins } from 'nuxt-property-decorator';
import InputMixin from '../inputMixin.vue';
import MixinRadioAndChecks from '../MixinRadioAndChecks.vue';
import BaseInput from '../BaseInput/BaseInput.vue';

const availableShapes: string[] = ['square', 'circle'];
const availableThemes: string[] = ['secondary', 'charcoal'];

@Component({
  inheritAttrs: false,
  components: {
    BaseInput,
  },
})
export default class BaseCheckbox extends mixins(InputMixin, MixinRadioAndChecks) {
  focus!: boolean;

  @Prop({ default: false }) readonly circle!: boolean;
  @Prop({ default: false }) readonly reverse!: boolean;
  @Prop({ default: 'square', validator: (value) => availableShapes.includes(value) })
  readonly shape!: string;

  @Prop({ default: 'secondary', validator: (value) => availableThemes.includes(value) })
  readonly theme!: string;

  get loadAnimationData(): Function {
    return (): Promise<any> => {
      return import('~/assets/animations/checkbox-' + this.shape + '-' + this.theme + '.json');
    };
  }

  get listeners() {
    const vm = this;
    return {
      change(event: { target: { checked: boolean } }) {
        vm.$emit('change', event.target.checked);
      },
    };
  }

  get checkboxClasses() {
    return {
      [`v-checkbox--${this.theme}`]: this.theme,
      'v-checkbox--circle': this.shape === 'circle',
      'v-checkbox--focus': this.focus,
      'v-checkbox--reverse': this.reverse,
    };
  }
}
