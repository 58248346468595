





































import 'reflect-metadata';
import { Vue, Component, State } from 'nuxt-property-decorator';
import { addKeyupEventListener, removeKeyupEventListener } from '~/helpers/keyboard';
import { hasActiveSubscription } from '~/helpers/models/user';
import zendesk from '~/helpers/zendesk';
import { featureFlagParam } from '~/helpers/featureFlag';

@Component
export default class NavDropdownMenu extends Vue {
  @State((store) => store.storage.settingsGiftMode) giftMode!: boolean;
  zendesk = zendesk;
  featureFlagParam = featureFlagParam;

  get links() {
    const { user } = this.$auth;

    switch (true) {
      case user && hasActiveSubscription(user):
        return [
          { to: '/', text: 'My toybox', subText: 'The toys you’re playing with now' },
          { to: '/lists', text: 'My lists', subText: 'Waitlist / favourites and gift list' },
          { to: '/account', text: 'My account', subText: 'Manage your account and subscription' },
          {
            to: '/my-playtime',
            text: 'My playtime',
            subText: 'See your Whirli usage so far',
          },
          {
            to: '/refer-a-friend',
            text: 'Refer friends',
            subText: 'Get free credits when you refer friends',
          },
        ];
      case user && !!user.userSubscriptions.length:
        return [
          { to: '/register', text: 'Continue registration', subText: 'Start to play the Whirli way!' },
          { to: '/lists', text: 'My lists', subText: 'Favourites and gift list' },
        ];
      default:
        return [
          { to: '/register', text: 'Choose a Whirli plan', subText: 'Start to play the Whirli way!' },
          { to: '/lists', text: 'My lists', subText: 'Favourites and gift list' },
        ];
    }
  }

  mounted(): void {
    addKeyupEventListener(27, this.toggleMenu);
  }

  destroyed(): void {
    removeKeyupEventListener(27, this.toggleMenu);
  }

  toggleMenu(): void {
    this.$emit('toggleDropdownMenu');
  }

  async logout(): Promise<void> {
    try {
      await this.$store.dispatch('user/logout');
      localStorage.clear();
      if (this.giftMode) await this.$gifts.giftMode.toggle(false);
      zendesk.logout();
    } catch (error) {
      throw error;
    }
  }
}
