


























































import 'reflect-metadata';
import { Vue, Component, Prop, State, namespace } from 'nuxt-property-decorator';
import { hasActiveSubscription } from '~/helpers/models/user';
import zendesk from '~/helpers/zendesk';
import { featureFlagParam } from '~/helpers/featureFlag';
import { ReferState } from '~/store/refer';

const ReferStore = namespace('refer');

@Component
export default class NavAccountDropdown extends Vue {
  @Prop({ default: false }) readonly visible!: boolean;
  @Prop({ default: 'secondary' }) readonly theme!: string;

  @State((store) => store.storage.settingsGiftMode) giftMode!: boolean;
  @ReferStore.Action('initialiseStore') initialiseReferStore!: (referralData: ReferState) => void;
  @ReferStore.State referralCode!: string | null;

  zendesk = zendesk;
  featureFlagParam: Function = featureFlagParam;

  get textColor() {
    return this.theme === 'tertiary' || this.theme === 'white' ? 'secondary' : 'white';
  }

  get discountCode(): string {
    return !this.giftMode && this.referralCode ? `?discountCode=${this.referralCode}` : '';
  }

  get links() {
    const { user } = this.$auth;

    switch (true) {
      case user && hasActiveSubscription(user):
        return [
          [{ to: '/', text: 'My toybox' }],
          [{ to: '/lists', text: 'My lists' }],
          [{ to: '/account', text: 'Account Info' }],
          [
            {
              to: '/my-playtime',
              text: 'My playtime',
            },
          ],
          [{ to: '/refer-a-friend', text: 'Refer friends' }],
          [{ text: 'Log out', onClick: this.logout }],
        ];
      case user && user.userSubscriptions && !!user.userSubscriptions.length:
        return [
          [{ to: `/register${this.discountCode}`, text: 'Continue registration' }],
          [{ to: '/lists', text: 'My lists' }],
          [{ text: 'Log out', onClick: this.logout }],
        ];
      default:
        return [
          [{ to: '/register', text: 'Choose a Whirli plan' }],
          [{ to: '/lists', text: 'My lists' }],
          [{ text: 'Log out', onClick: this.logout }],
        ];
    }
  }

  get linkClasses() {
    return {
      'u-text--button': true,
      [`u-color-${this.textColor}`]: this.textColor,
    };
  }

  async logout() {
    try {
      this.$emit('close-account-dropdown');
      await this.$store.dispatch('user/logout');
      if (this.giftMode) await this.$gifts.giftMode.toggle(false);
      zendesk.logout();
      localStorage.clear();
    } catch (error) {
      throw error;
    }
  }
}
