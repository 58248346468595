import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '~/store/index';
import { Card } from '~/types/card';

const types = {
  PUT_CARD: 'PUT_CARD',
};

interface ModuleState {
  card: Card;
}

export const state = (): ModuleState => ({
  card: {
    success: null,
    last4: null,
    brand: null,
    expMonth: null,
    expYear: null,
  },
});

export const actions: ActionTree<ModuleState, RootState> = {
  async loadUserCard({ commit }): Promise<any> {
    this.$logger.store(`action`, `[user/card/loadUserCard]`);
    const response = await this.$whirli.users.card.get();
    commit(types.PUT_CARD, response.data);
  },
};

export const mutations: MutationTree<ModuleState> = {
  [types.PUT_CARD](state, card: Card) {
    state.card = card;
  },
};
