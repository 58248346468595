var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "div",
    _vm._b(
      {
        staticClass: "v-base-spacer",
        class: [_vm.data.class, _vm.data.staticClass],
        attrs: {
          "data-default": _vm.props.default,
          "data-tablet": _vm.props.tablet,
          "data-small-desk": _vm.props.smallDesk
        }
      },
      "div",
      _vm.data.attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }