var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    _vm.props.tag,
    _vm._g(
      _vm._b(
        {
          tag: "component",
          staticClass: "v-text",
          class: [
            _vm.data.class,
            _vm.data.staticClass,
            {
              "u-text--responsive": _vm.props.responsive,
              "u-text--center": _vm.props.center,
              "u-text--center-until-desk": _vm.props.centerUntilSmallDesk,
              "u-bold": _vm.props.bold,
              "u-uppercase": _vm.props.uppercase,
              "u-capitalize": _vm.props.capitalize,
              "u-underline": _vm.props.underline,
              "u-line-through": _vm.props.lineThrough
            }
          ],
          attrs: {
            "data-color": _vm.props.color,
            "data-size": _vm.props.size,
            itemprop: _vm.props.tag == "h1" ? _vm.name : false
          }
        },
        "component",
        _vm.data.attrs,
        false
      ),
      _vm.listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }