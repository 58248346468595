var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20" } },
    [
      _c("path", {
        attrs: { d: "M13.3 6.2l1.4 1.4-6.2 6.1L5 10.3l1.4-1.4 2.1 2z" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }