
















import { Vue, Prop, Component } from 'nuxt-property-decorator';

/**
 * Base Layout Item
 *
 * The Base Layout Item must only ever be used as a direct descendent of a BaseLayout.
 *
 * By default, it spans 100% width of the layout it's placed in.
 * You can alter this default behaviour by passing a string to the r prop, stating a ratio of column / max columns.
 *
 * <BaseLayout>
 *   <BaseLayoutItem r="1/2">   <!-- This will span one half of the width of the layout -->
 *     Content
 *   </BaseLayoutItem>
 * </BaseLayout>
 *
 * You can also change this on a per breakpoint basis, by using the breakpoint name as a prop:
 *
 * <BaseLayout>
 *   <!-- This will span one half by default, one third on tablet up, and one quarter on smallDesk and above -->
 *   <BaseLayoutItem r="1/2" tablet="1/3" small-desk="1/4">
 *     Content
 *   </BaseLayoutItem>
 * </BaseLayout>
 */

@Component
export default class BaseLayoutItem extends Vue {
  @Prop({ default: '1/1', type: String }) readonly r!: string;
  @Prop({ default: false, type: [String, Boolean] }) readonly mobile!: string | boolean;
  @Prop({ default: false, type: [String, Boolean] }) readonly tablet!: string | boolean;
  @Prop({ default: false, type: [String, Boolean] }) readonly smallDesk!: string | boolean;
  @Prop({ default: false, type: [String, Boolean] }) readonly largeDesk!: string | boolean;
  @Prop({ default: false, type: [String, Boolean] }) readonly hugeDesk!: string | boolean;
}
