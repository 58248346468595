
import { Vue, Prop, Component } from 'nuxt-property-decorator';

@Component
export default class AccordionsMixin extends Vue {
  @Prop({ required: true }) readonly name!: string;
  @Prop({ default: false }) readonly isDisabled!: boolean;
  @Prop({ default: false }) readonly isOpen!: boolean;
  @Prop({ default: false }) readonly isFixedOpen!: boolean;

  toggleAccordion() {
    this.$emit('toggle-accordion', this.name);
  }
}
