import { extend, setInteractionMode } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

/**
 * Similar to the built-in `eager` mode - will first check if input is dirty before flagging it as invalid on initial touch
 */
const eagerDirtyValidation = (args) => {
  const { errors, flags } = args;
  if (errors.length) return { on: ['input', 'change'] };

  if (flags.dirty) return { on: ['change', 'blur'] };

  return { on: [] };
};

setInteractionMode('eagerDirty', eagerDirtyValidation);

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('email', {
  ...email,
  message: 'Please enter a valid email address.',
});

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password confirmation does not match.',
});

extend('wholeNumber', {
  validate(value) {
    return value % 1 === 0;
  },
  message: 'The amount must be a whole number',
});

extend('minAmount', {
  params: ['minAmount'],
  validate(value, args) {
    return value >= parseInt(args.minAmount);
  },
  message: `The amount must be at least £{minAmount}`,
});

extend('maxChars', {
  params: ['maxChars'],
  validate(value, args) {
    return value.length <= parseInt(args.maxChars);
  },
  message: `You cannot exceed {maxChars} characters.`,
});
