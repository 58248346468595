












































































































import 'reflect-metadata';
import { Component, Prop, mixins } from 'nuxt-property-decorator';
import BucketCheckbox from '../BucketCheckbox/BucketCheckbox.vue';
import BlockAccordion from '~/components/partials/accordions/BlockAccordion/BlockAccordion.vue';
import FiltersMixin from '~/components/features/browse-toys/filters/FiltersMixin/FiltersMixin.vue';
import { Bucket, ProductAssociationMeta } from '~/types/products/associations';

@Component({
  components: {
    BlockAccordion,
    BucketCheckbox,
  },
})
export default class FiltersAccordion extends mixins(FiltersMixin) {
  @Prop({ default: false }) readonly delayed!: boolean;
  @Prop({ default: false }) readonly isOpen!: boolean;
  @Prop({ required: true }) readonly association!: ProductAssociationMeta;

  public isVisible: boolean = false;
  public intersectOptions: IntersectionObserverInit = {};

  async toggleBucket(bucket: Bucket) {
    this.$gtm.analytics.event('Browse Toys', 'Filter', bucket.name);
    try {
      const slugToSend = bucket.slug ? bucket.slug : bucket.name;
      await this.toggleBucketAssociation({
        name: bucket.name,
        slug: slugToSend,
        id: bucket.id,
        associationClass: bucket.associationClass,
      });
    } catch (error) {
      throw error;
    }
  }

  get ageBabyBuckets(): Array<Bucket> {
    return this.association.buckets.filter((b) => b.association.ageGroup === 1);
  }

  get ageToddlerBuckets(): Array<Bucket> {
    return this.association.buckets.filter((b) => b.association.ageGroup === 2);
  }

  get ageChildBuckets(): Array<Bucket> {
    return this.association.buckets.filter((b) => b.association.ageGroup === 3);
  }

  get skillUnderstandingTheWorldBuckets(): Array<Bucket> {
    return this.association.buckets.filter((b) => b.association.skillGroup === 1);
  }

  get skillConnectingWithOthersBuckets(): Array<Bucket> {
    return this.association.buckets.filter((b) => b.association.skillGroup === 2);
  }

  get skillActivePlayBuckets(): Array<Bucket> {
    return this.association.buckets.filter((b) => b.association.skillGroup === 3);
  }

  get skillMindfulnessBuckets(): Array<Bucket> {
    return this.association.buckets.filter((b) => b.association.skillGroup === 4);
  }

  onIntersect(isIntersecting: boolean): void {
    if (isIntersecting) {
      setTimeout(
        () => {
          this.isVisible = true;
        },
        this.delayed ? 300 : 0
      );
    }
  }
}
