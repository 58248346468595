var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    _vm._b(
      {
        tag: "component",
        class: { "u-underline": _vm.underline, "u-bold": _vm.bold }
      },
      "component",
      [_vm.$attrs, _vm.attributes],
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }