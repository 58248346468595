




import 'reflect-metadata';
import { Vue, Component, namespace } from 'nuxt-property-decorator';
import BaseOverlay from '~/components/partials/BaseOverlay/BaseOverlay.vue';

const overlayStore = namespace('overlay');

@Component({
  components: { BaseOverlay },
})
export default class TheOverlay extends Vue {
  @overlayStore.State usedBy!: Array<string>;
  @overlayStore.State coverNav!: boolean;
}
