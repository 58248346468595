var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TheModal",
    {
      attrs: { "is-open": _vm.isModalOpen },
      on: {
        closeModal: function($event) {
          return _vm.toggleModal(false)
        }
      }
    },
    [
      _c(
        "BaseBox",
        { attrs: { padding: "medium" } },
        [
          _c(
            "BaseLayout",
            { attrs: { align: "middle", direction: "column" } },
            [
              _c(
                "BaseLayoutItem",
                { attrs: { r: "1/1", "small-desk": "2/3" } },
                [
                  _c("BaseVerticalSpacer", {
                    attrs: {
                      default: "small",
                      tablet: "medium",
                      "small-desk": "large"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "BaseText",
                    {
                      attrs: {
                        tag: "h2",
                        size: "sub-header",
                        center: true,
                        uppercase: ""
                      }
                    },
                    [_vm._v("Ready to get started?")]
                  ),
                  _vm._v(" "),
                  _c("BaseVerticalSpacer", { attrs: { default: "small" } }),
                  _vm._v(" "),
                  _c("BaseText", { attrs: { center: true } }, [
                    _vm._v(
                      "\n          Sign up on one of our flexible, affordable plans and start building your first Toybox.\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("BaseVerticalSpacer", {
                    attrs: { default: "small", tablet: "medium" }
                  }),
                  _vm._v(" "),
                  _c(
                    "BaseLayout",
                    { attrs: { align: "middle", direction: "column" } },
                    [
                      _c(
                        "BaseLayoutItem",
                        { attrs: { r: "1/1" } },
                        [
                          _c(
                            "BaseList",
                            { staticClass: "signup-modal__list" },
                            _vm._l(_vm.signUpModalListItems, function(
                              item,
                              index
                            ) {
                              return _c(
                                "BaseListItem",
                                {
                                  key: index,
                                  staticClass:
                                    "u-flex u-flex--row u-margin-bottom-mini"
                                },
                                [
                                  _c("BaseIcon", {
                                    staticClass: "u-flex--shrink-none",
                                    attrs: {
                                      icon: "ArrowSide",
                                      theme: "secondary",
                                      size: "base"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "BaseText",
                                    { staticClass: "u-margin-left-tiny" },
                                    [_vm._v(_vm._s(item))]
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("BaseVerticalSpacer", {
                    attrs: { default: "small", tablet: "large" }
                  }),
                  _vm._v(" "),
                  _c(
                    "BaseLayout",
                    {
                      attrs: {
                        direction: "column",
                        "fill-direction": "center",
                        justify: "center",
                        align: "middle"
                      }
                    },
                    [
                      _c(
                        "BaseLayoutItem",
                        { attrs: { r: "1/1", tablet: "1/2" } },
                        [
                          _c(
                            "BaseButton",
                            {
                              attrs: { role: "button", tag: "a", block: true },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.SignupModalABTestGAPushLink(
                                    "click",
                                    "SignUpButton",
                                    "/register"
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              Sign up now\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("BaseVerticalSpacer", {
                            attrs: { default: "small" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "BaseLayoutItem",
                        {
                          staticClass: "signup-modal__secondary-action-wrapper",
                          attrs: { r: "1/1", tablet: "1/2" }
                        },
                        [
                          _c(
                            "BaseButton",
                            {
                              attrs: { role: "button", tag: "a", type: "line" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.SignupModalABTestGAPushLink(
                                    "click",
                                    "HowItWorksButton",
                                    "/how-it-works"
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              See how it works\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }