var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 40 40" } },
    [
      _c("path", {
        attrs: {
          d:
            "M27.5 5c-2.9 0-5.7 1.3-7.5 3.5C18.2 6.3 15.4 5 12.5 5c-5.1 0-9.2 4-9.2 9.2 0 6.3 5.7 11.4 14.2 19.2l2.4 2.2 2.4-2.2c8.6-7.8 14.2-12.9 14.2-19.2.2-5.2-3.9-9.2-9-9.2z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }