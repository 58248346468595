import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { ModuleState as CheckoutModuleState } from './checkout';
import { ModuleState as DiscountCodeModuleState } from './discountCode';
import { RootState } from '~/store/index';
import { Gift, GiftOrder } from '~/types/whirli-models/gift';
import { getUserResourceType, GUESTS, UserType } from '~/helpers/api';
import { setupLazy, LazyLoadableState } from '~/helpers/store/lazyLoad';
import { STORAGE_CACHED_GIFT } from '~/plugins/services/Gift/gifts';
import { Checkout } from '~/types/basket';
import { CostItem } from '~/types/cost-breakdown';
import { costBreakdown } from '~/plugins/filters';
import { PERCENTAGE_VALUE_TYPE } from '~/constants/discount-types';

const cost = (cost: number | string, zeroText: string = '-') => costBreakdown(cost, zeroText);

const {
  lazyLoadableTypes,
  lazyLoadableState,
  lazyLoadableGetters,
  lazyLoadableActions,
  lazyLoadableMutations,
} = setupLazy<ModuleState, RootState>();

export const types = {
  ...lazyLoadableTypes,
  SET_GIFT: 'SET_GIFT',
  SET_GIFT_ORDER: 'SET_GIFT_ORDER',
  SET_CHECKOUT_HINT: 'SET_CHECKOUT_HINT',
};

export interface CreateGiftPayload {
  value?: number;
  withBasket?: boolean;
  productListId?: string;
}

export interface GiftOrderPayload {
  code: string;
}

interface LocalModuleState extends LazyLoadableState {
  gift: Gift | null;
  guestToken: string | null;
  giftOrder: GiftOrder | null;
}

export interface ModuleState extends LocalModuleState {
  basket: any;
  checkout: CheckoutModuleState;
  discountCode: DiscountCodeModuleState;
}

export const state = (): LocalModuleState => ({
  ...lazyLoadableState,
  gift: null,
  guestToken: null,
  giftOrder: null,
});

export const getters: GetterTree<ModuleState, RootState> = {
  ...lazyLoadableGetters,
  giftToysOnly(state): boolean {
    if (!state.gift) return false;

    return state.gift.value === 0;
  },
  valueOnly(state): boolean {
    if (!state.gift) return false;

    return state.gift.value !== 0 && !state.gift.withBasket;
  },
  valueWithToys(state): boolean {
    if (!state.gift) return false;

    return state.gift.value !== 0 && !!state.gift.withBasket;
  },
  totalCost(state, getters, rootState): string | number {
    if (!state.gift) return cost(0);

    const value = getters.giftToysOnly ? getters['basket/value'] : state.gift.value;

    const addonCost = state.checkout.deliveryDetails.deliveryOption?.originalCost || 0;

    let total = value + addonCost + state.gift.shippingFee;

    if (rootState.gift.discountCode.code.value) {
      if (rootState.gift.discountCode.code.valueTypeId === PERCENTAGE_VALUE_TYPE) {
        // Percentage is returned as an integer, so e.g. 15% is 1500.
        total = total * (1 - rootState.gift.discountCode.code.value / 10000);
      } else {
        total = total - rootState.gift.discountCode.code.value;
      }
    }

    return total < 0 ? 0 : total;
  },

  costBreakdown(state, getters): Array<CostItem> {
    if (!state.gift) return [];

    const costItems: Array<CostItem> = [];

    if (getters.giftToysOnly) {
      costItems.push({ name: 'Toy value', cost: cost(getters['basket/value']) });
    } else if (state.gift.value) {
      costItems.push({ name: 'Gift value', cost: cost(state.gift.value) });
    }

    if (state.gift.shippingFee) {
      costItems.push({ name: 'Shipping fee', cost: cost(state.gift.shippingFee, '-') });
    }

    const addonDelivery = state.checkout.deliveryDetails.deliveryOption;

    if (addonDelivery) costItems.push({ name: addonDelivery.name, cost: cost(addonDelivery.originalCost) });

    return costItems;
  },
  toyAllowanceBreakdown(state, getters): CostItem | null {
    if (!state.gift || !state.gift.value) return null;

    if (getters.giftToysOnly || getters['basket/value'] === 0) return null;

    return {
      name: 'Toy allowance',
      cost: `${cost(getters['basket/value'])} / ${cost(state.gift.value)}`,
    };
  },
};

export const actions: ActionTree<ModuleState, RootState> = {
  ...lazyLoadableActions,
  async initialiseModule({ dispatch }): Promise<void> {
    this.$logger.store(`action`, `[gift/initialiseModule]`);

    await dispatch('initLazyLoadModule', async () => await dispatch('getCurrent'));
  },
  async getCurrent({ dispatch }): Promise<void> {
    this.$logger.store(`action`, `[gift/getCurrent]`);

    const config: Record<string, any> = { params: { with: 'giftBasket' } };

    if (!this.$auth.loggedIn) {
      const cachedGift = this.$gifts.guest.getCachedGift();
      if (!cachedGift) return;

      config.params.giftToken = cachedGift.accessToken;
    }
    const userType: UserType = getUserResourceType(this.$auth);

    try {
      const response = await this.$whirli[userType].gifts.getActive(config);
      const gift = response.data;

      if (gift) {
        await dispatch('saveGift', gift);
      }
    } catch (error) {
      if (error.response) {
        this.$logger.store(`action`, `[gift/getCurrent] No active gift found`);

        this.$gifts.giftMode.toggle(false);
        await Promise.all([dispatch('clearCachedGift'), dispatch('basket/clearCachedBasket')]);

        return;
      }
      throw error;
    }
  },
  async create({ dispatch }, payload: CreateGiftPayload) {
    this.$logger.store(`action`, `[gift/create]`, { payload });

    try {
      const userType = getUserResourceType(this.$auth);
      const gift = (await this.$whirli[userType].gifts.create(payload)).data;

      if (gift) {
        await dispatch('clearCachedGift');
        await dispatch('basket/clearCachedBasket');
        await dispatch('saveGift', gift);
      }
    } catch (error) {
      throw error;
    }
  },
  async saveGift({ commit, dispatch }, gift: Gift) {
    this.$logger.store(`action`, `[gift/saveGift]`, { gift });

    const userType: UserType = getUserResourceType(this.$auth);

    if (userType === GUESTS) {
      if (!gift.id) throw new Error('No id received when creating gift as a guest');
      if (!gift.guestToken) throw new Error('No guest token received when creating gift as a guest');

      this.$gifts.guest.cacheGift(gift.id, gift.guestToken);
    }

    commit(types.SET_GIFT, gift);
    commit(types.SET_FETCHED, true);

    if (gift.giftBasket && gift.giftBasket.basket) {
      await dispatch('gift/basket/saveBasket', gift.giftBasket.basket, { root: true });
    }
  },
  updateCheckoutHint({ commit }, checkoutHint) {
    this.$logger.store(`action`, `[gift/updateCheckoutHint]`, { checkoutHint });

    commit(types.SET_CHECKOUT_HINT, checkoutHint);
  },
  clearCachedGift({ commit }) {
    this.$logger.store(`action`, `[gift/clearCachedGift]`);

    commit(types.SET_GIFT, null);
    this.$storage.removeUniversal(STORAGE_CACHED_GIFT);
  },

  async getGift({ commit }, payload: GiftOrderPayload) {
    this.$logger.store(`action`, `[gift/getGift]`, { payload });

    const { data } = await this.$whirli.codes.get(payload);
    commit(types.SET_GIFT_ORDER, data);
  },
  resetGiftOrder({ commit }) {
    commit(types.SET_GIFT_ORDER, null);
  },
};

export const mutations: MutationTree<ModuleState> = {
  ...lazyLoadableMutations,
  [types.SET_GIFT](state, gift: Gift | null) {
    state.gift = gift;
  },
  [types.SET_GIFT_ORDER](state, giftOrder) {
    state.giftOrder = giftOrder;
  },
  [types.SET_CHECKOUT_HINT](state, checkoutHint: Checkout) {
    if (!state.gift) throw new Error('No gift to set checkout hint on');
    state.gift.checkout = checkoutHint;
  },
};

// allowEmailDiscountsOffers: false
// billingAddress: null
// billingAddressTwo: null
// billingCity: null
// billingCountry: null
// billingEmail: null
// billingFirstname: null
// billingLastname: null
// billingZip: null
// code: null
// completedAt: null
// createdAt: "2020-05-26T14:12:29.000000Z"
// deletedAt: null
// deliveryMethod: null
// dispatchedAt: null
// expectedDeliveryDate: null
// giftStatusId: 1
// guest: false
// guestToken: null
// hasReusablePackagingOptIn: null
// hasToys: false
// highlights: []
// id: "zrBWVDdV8G"
// message: null
// packedAt: null
// placedAt: null
// redeemedAt: null
// resourceType: "gifts"
// shippingAddress: null
// shippingAddressTwo: null
// shippingCity: null
// shippingCountry: null
// shippingEmail: null
// shippingFirstname: null
// shippingLastname: null
// shippingZip: null
// statusId: 1
// stripeCustomerId: null
// toGiftRecipient: null
// updatedAt: "2020-05-26T14:12:29.000000Z"
// value: 0
// withBasket: false
