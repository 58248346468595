var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "40",
        height: "40",
        viewBox: "0 0 40 40",
        fill: "none",
        "xmlns:v": "https://vecta.io/nano"
      }
    },
    [
      _c("circle", {
        attrs: {
          cx: "20.108",
          cy: "20.215",
          r: "19.5",
          fill: "#5d3e99",
          stroke: "#fff"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          d:
            "M21.471 40.17l.026-.515c.228-3.898 1.165-7.726 2.768-11.296a8.52 8.52 0 0 1-9.171-6.873 8.52 8.52 0 0 1 5.971-9.783c-.4-.48-.664-1.058-.764-1.675s-.034-1.249.193-1.831a1.02 1.02 0 0 1 1.314-.561 1.02 1.02 0 0 1 .59 1.302c-.102.257-.127.537-.074.808a1.44 1.44 0 0 0 .375.719 4.78 4.78 0 0 1 .322-1.893c.157-.341.438-.61.787-.751s.737-.143 1.087-.007.635.401.796.74.187.727.073 1.085c-.177.456-.166.964.032 1.412s.564.799 1.02.977a1.37 1.37 0 0 1 .27.137 8.52 8.52 0 0 1 4.32 10.793c-.082.21-.174.416-.272.617a13.64 13.64 0 0 1-.887 1.557 28.43 28.43 0 0 0-4.674 10.918c-.205 1.043-.351 2.094-.438 3.148l-.029.388a19.96 19.96 0 0 1-3.635.584z",
          fill: "#e56ca6"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M34.083 16.936L19.676 9.621l-1.695 3.338 14.407 7.315 1.695-3.338z",
          fill: "#f39100"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M33.352 18.374l-14.407-7.315-.233.458 14.407 7.315.233-.459z",
          fill: "#181716"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M26.521 19.498a3.2 3.2 0 0 0-1.232-3.829 3.2 3.2 0 0 0-4.002.4 3.2 3.2 0 0 0-1.121-2.297 3.2 3.2 0 0 0-2.445-.744 3.2 3.2 0 0 0-2.21 1.284 3.2 3.2 0 0 0-.565 2.493c-1.932-.487-3.975-.252-5.745.661s-3.147 2.441-3.871 4.297c-.392 1.01-.366 2.134.071 3.125s1.25 1.768 2.26 2.159a4.04 4.04 0 0 0 1.474.279c.823 0 1.627-.249 2.307-.714s1.203-1.124 1.501-1.892l4.055 1.575c1.077.417 2.231.601 3.384.54s2.281-.366 3.308-.895c.904-.466 1.601-1.253 1.956-2.206a4.08 4.08 0 0 0-.04-2.948 3.2 3.2 0 0 0 .914-1.289z",
          fill: "#ea9dc4"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M15.803 17.067l.091.032 3.326 1.291a2.44 2.44 0 0 0-.147-4.407 2.44 2.44 0 0 0-3.325 2.896c0 .064.032.126.055.187zm7.734 3.715a2.441 2.441 0 1 0 0-4.882 2.441 2.441 0 1 0 0 4.882z",
          fill: "#fff"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M23.292 18.022a.51.51 0 0 1 .834-.515 1.2 1.2 0 0 0-.039-.439c-.064-.232-.196-.439-.378-.596a1.21 1.21 0 0 0-1.341-.163 1.23 1.23 0 0 0-.511.487 1.21 1.21 0 0 0-.154.689c.018.24.107.469.255.658s.349.331.578.406.474.081.706.016c.142-.04.276-.105.396-.192a.51.51 0 0 1-.345-.352zm-5.442-2.118a.51.51 0 0 1 .611-.634.5.5 0 0 1 .224.119 1.2 1.2 0 0 0-.037-.439c-.065-.231-.198-.438-.381-.594s-.408-.254-.647-.283a1.21 1.21 0 0 0-1.204.613 1.21 1.21 0 0 0-.151.689c.019.239.108.468.257.657a1.21 1.21 0 0 0 .579.404 1.22 1.22 0 0 0 .706.015 1.25 1.25 0 0 0 .395-.192.51.51 0 0 1-.352-.357zM9.136 27.333a4.04 4.04 0 0 1-1.474-.279c-1.01-.392-1.823-1.168-2.26-2.159s-.463-2.115-.071-3.125c.786-2.017 2.339-3.64 4.319-4.513s4.226-.926 6.245-.146a4.08 4.08 0 0 1 1.375.842c.398.369.718.814.942 1.309s.347 1.029.362 1.571a4.09 4.09 0 0 1-.274 1.589 4.08 4.08 0 0 1-2.194 2.276c-.498.214-1.035.328-1.578.332s-1.081-.099-1.584-.304a4.09 4.09 0 0 1-1.501 1.892c-.679.465-1.484.714-2.307.714z",
          fill: "#181716"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }