

















import { Vue, Prop, Component } from 'nuxt-property-decorator';

@Component
export default class HasError extends Vue {
  @Prop({ default: () => [] }) readonly errors!: [];
}
