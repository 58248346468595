















import { Component, Prop, Vue, namespace } from 'nuxt-property-decorator';
import { ScrollPosition as SP } from '~/store/scroll';
import BaseBanner from '~/components/partials/BaseBanner/BaseBanner.vue';

interface ScrollPosition extends SP {} // Without this, typescript throws a warning that it cannot find the export

const ScrollStore = namespace('scroll');

@Component({
  components: {
    BaseBanner,
  },
})
export default class PromoBanner extends Vue {
  @ScrollStore.State('debounced') scrollDebounced!: ScrollPosition;

  @Prop({ default: '' }) readonly url!: string;
  @Prop({ default: 'success' }) readonly type!: string;
  @Prop({ default: 'Default' }) readonly gaLabel!: string;

  get scrolled(): boolean {
    return this.scrollDebounced.position > 0;
  }
}
