import { AxiosRequestConfig } from 'axios';
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { RootState } from '~/store';
import { Basket, BasketLine } from '~/types/basket';
import { getUserResourceType, UserType } from '~/helpers/api';
import { outOfStock } from '~/helpers/models/basketLine';
import { lines } from '~/helpers/models/basket';

export const types = {
  PUT_BASKET_AND_ID: 'PUT_BASKET_AND_ID',
  PUT_BASKET_ID: 'PUT_BASKET_ID',
  PUT_BASKET: 'PUT_BASKET',
  SET_BASKET_FETCHED: 'SET_BASKET_FETCHED',
  SET_BUSY: 'SET_BUSY',
  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
  PUT_BASKET_COUNT: 'PUT_BASKET_COUNT',
  PUT_BASKET_TOKENS: 'PUT_BASKET_TOKENS',
};

interface AddItemToBasketPayload {
  variantId: string;
  quantity: number;
}

// @todo This should live in the Whirli Client
interface AddItemToBasketParams {
  productVariantId: string;
  quantity: number;
  basketId?: string;
}

interface RemoveItemFromBasketPayload {
  basketLineId: string;
}

export interface ModuleState {
  id: string | null;
  data: Basket | null;
  count: number;
  tokensUsed: number;
  isFetched?: boolean;
  isBusy?: boolean;
  activeTab: number;
  logName: string;
}

export const state = (): ModuleState => ({
  id: null,
  data: null,
  count: 0,
  tokensUsed: 0,
  isFetched: false,
  isBusy: false,
  activeTab: 0,
  logName: 'basket',
});

export const getters: GetterTree<ModuleState, RootState> = {
  lazyLoading: (state) => !state.isFetched && state.isBusy,

  inStockLines: (state): Array<BasketLine> => {
    if (!state.data) return [];

    const basketLines: Array<BasketLine> = lines(state.data);
    return basketLines.filter((basketLine: BasketLine) => !outOfStock(basketLine));
  },

  outOfStockLines: (state): Array<BasketLine> => {
    if (!state.data) return [];

    const basketLines: Array<BasketLine> = lines(state.data);
    return basketLines.filter((basketLine: BasketLine) => outOfStock(basketLine));
  },

  hasOutOfStockLines: (_state, getters): boolean => !!getters.outOfStockLines.length,
};

export const actions: ActionTree<ModuleState, RootState> = {
  async initialState({ dispatch, state }) {
    this.$logger.store(`action`, `basket/initialState]`);

    if (state.isFetched) {
      this.$logger.debug('Basket already fetched.');
      return;
    }

    await dispatch('fetch');
  },

  fetch() {
    this.$logger.debug('Fetch must be implemented by child store. This is used to retrieve the basket.');
  },

  getBasketId(): string | null {
    this.$logger.debug(
      'getBasketId must be implemented by child store. This is sent in the basket requests.'
    );
    return null;
  },

  extraRequestConfig(): AxiosRequestConfig {
    this.$logger.debug(
      'extraRequestConfig must be implemented by child store. It is sent as the axios config.'
    );
    return {};
  },

  /**
   * Adds item as a basket line.
   * Will create a basket if no basketId provided.
   * Returns the Basket on success, and sets it up as the guest or user's current basket.
   */
  async addItemToBasket({ dispatch }, { variantId, quantity }: AddItemToBasketPayload) {
    this.$logger.store(`action`, `basket/addItemToBasket]`, { variantId, quantity });

    await dispatch('makeRequest', {
      requestName: 'addItemToBasket',
      request: async () => {
        const basketId: string | null = await dispatch('getBasketId');

        const params: AddItemToBasketParams = { productVariantId: variantId, quantity };
        if (basketId) params.basketId = basketId;

        const config = await dispatch('extraRequestConfig');
        const userType: UserType = getUserResourceType(this.$auth);

        return this.$whirli[userType].baskets.addLine(params, config);
      },
    });
  },

  /**
   * Updates basket line. An updated basket is returned.
   */
  async updateBasketLine(
    { dispatch },
    { basketLineId, quantity }: { basketLineId: string; quantity: number }
  ) {
    this.$logger.store(`action`, `basket/updateBasketLine]`, { basketLineId, quantity });

    await dispatch('makeRequest', {
      requestName: 'updateBasketLine',
      request: async () => {
        const config = await dispatch('extraRequestConfig');
        const userType: UserType = getUserResourceType(this.$auth);
        return this.$whirli[userType].baskets.updateLine(basketLineId, { quantity }, config);
      },
    });
  },

  /**
   * Remove item from basket. An updated basket is returned.
   */
  async removeItemFromBasket({ dispatch }, { basketLineId }: RemoveItemFromBasketPayload) {
    this.$logger.store(`action`, `basket/removeItemFromBasket`, { basketLineId });

    await dispatch('makeRequest', {
      requestName: 'removeItemFromBasket',
      request: async () => {
        const config = await dispatch('extraRequestConfig');
        const userType: UserType = getUserResourceType(this.$auth);
        return this.$whirli[userType].baskets.removeLine(basketLineId, config);
      },
    });
  },

  /**
   * Make a request to a basket endpoint. The endpoint must return a Basket resource under the data key.
   * On a success, the basket will be stored and the basket ID and count will be updated.
   *
   * This method decorates the request with logging, and sets the BUSY state on and off in the basket store.
   * It will also log any errors.
   */
  async makeRequest(
    { commit, dispatch },
    {
      request,
      requestName = 'makeRequest',
      saveBasket = true,
    }: { request: Function; requestName: string; saveBasket: boolean }
  ) {
    this.$logger.store(`action`, `basket/makeRequest`, { requestName });

    commit(types.SET_BUSY, true);

    let response;
    try {
      response = await request();
    } catch (error) {
      commit(types.SET_BUSY, false);
      throw error;
    }

    if (!response || !response.data) {
      commit(types.SET_BUSY, false);
      return;
    }

    if (saveBasket) {
      await dispatch('saveBasket', response.data);
    }

    commit(types.SET_BUSY, false);
  },

  saveBasket() {
    this.$logger.warn('saveBasket must be implemented by child store.');
  },
};

export const mutations: MutationTree<ModuleState> = {
  [types.PUT_BASKET_AND_ID](state, { id, basket }: { id: string | null; basket: Basket | null }) {
    state.id = id;
    state.data = basket;
  },
  [types.PUT_BASKET_ID](state, id: string | null) {
    state.id = id;
  },
  [types.PUT_BASKET](state, basket: Basket | null) {
    state.data = basket;
  },
  [types.SET_BASKET_FETCHED](state, fetched: boolean) {
    state.isFetched = fetched;
  },
  [types.SET_BUSY](state, isBusy: boolean) {
    state.isBusy = isBusy;
  },
  [types.SET_ACTIVE_TAB](state, activeTab: number) {
    state.activeTab = activeTab;
  },
  [types.PUT_BASKET_COUNT](state, count: number) {
    state.count = count;
  },
  [types.PUT_BASKET_TOKENS](state, tokensUsed: number) {
    state.tokensUsed = tokensUsed;
  },
};
