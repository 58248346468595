/*
 * ChildProfilesFilterBuilder constructs & hydrates the child profile data
 */
import { AgeRange, AgeRangeInterval } from '~/types/products/associations';
import { ChildProfile, ChildProfileWithBucket } from '~/types/child-profiles';

export default class ChildProfilesFilterBuilder {
  public profiles: ChildProfileWithBucket[] | undefined;

  protected ageRanges: AgeRange[] | undefined;

  profileIcons: string[];

  constructor() {
    this.profiles = [];
    this.profileIcons = [
      'ProfileAlligator',
      'ProfileBeaver',
      'ProfileFlamingo',
      'ProfilePug',
      'ProfileAlligatorAlt',
    ];

    this.ageRanges = [
      { rangeStart: 0, rangeEnd: 3, interval: 'months' },
      { rangeStart: 3, rangeEnd: 6, interval: 'months' },
      { rangeStart: 6, rangeEnd: 9, interval: 'months' },
      { rangeStart: 9, rangeEnd: 12, interval: 'months' },
      { rangeStart: 12, rangeEnd: 15, interval: 'months' },
      { rangeStart: 15, rangeEnd: 18, interval: 'months' },
      { rangeStart: 18, rangeEnd: 24, interval: 'months' },
      { rangeStart: 2, rangeEnd: 3, interval: 'years' },
      { rangeStart: 3, rangeEnd: 5, interval: 'years' },
      { rangeStart: 5, rangeEnd: 8, interval: 'years' },
    ];
  }

  get profileIconsList(): string[] {
    return this.profileIcons;
  }

  setupChildProfiles(childProfiles: ChildProfile[]): ChildProfileWithBucket[] {
    this.profiles = [];
    this.setProfileWithIcons(childProfiles);
    this.setFilterParams();
    return this.profiles;
  }

  setProfileWithIcons(childProfiles: ChildProfile[]): void {
    let index = 0;
    let checkedIndex = 0;
    childProfiles.map((profile: ChildProfile) => {
      (this.profiles as ChildProfileWithBucket[]).push({
        ...profile,
        icon: (this.profileIcons as string[])[index],
        checked: checkedIndex === 0,
        bucket: {
          name: '',
          slug: '',
          associationClass: 'age',
        },
      });
      index++;
      checkedIndex++;
      if (index === (this.profileIcons as string[]).length) index = 0;
    });
  }

  public setFilterParams(): void {
    (this.profiles as ChildProfileWithBucket[]).map((profile: ChildProfileWithBucket) => {
      const diffDate = (new Date() as any) - (new Date(profile.dob) as any);
      let diffInMonths: number = diffDate / (1000 * 60 * 60 * 24 * 30);
      diffInMonths = Math.ceil(diffInMonths);

      if (diffInMonths <= 24) {
        this.handleAgeRangeMapForInterval(profile, diffInMonths, 'months');
      } else if (diffInMonths >= 24) {
        let diffInYears: number = diffInMonths / 12;
        if (diffInYears > 8) diffInYears = 8;
        this.handleAgeRangeMapForInterval(profile, diffInYears, 'years');
      }
    });
  }

  public handleAgeRangeMapForInterval(
    profile: ChildProfileWithBucket,
    difference: number,
    interval: AgeRangeInterval
  ): void {
    const filteredAgeRanges: AgeRange[] = (this.ageRanges as AgeRange[]).filter((ageRange: AgeRange) => {
      return ageRange.interval === interval;
    });

    filteredAgeRanges.map((ageRange: AgeRange) => {
      if (difference >= ageRange.rangeStart && difference <= ageRange.rangeEnd) {
        const { rangeStart, rangeEnd, interval } = ageRange;
        profile.bucket.name = `${rangeStart.toString()} - ${rangeEnd.toString()} ${interval.toString()}`;
        profile.bucket.slug = `${rangeStart.toString()}-${rangeEnd.toString()}-${interval.toString()}`;
      }
    });
  }
}
