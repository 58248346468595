







































import { Component, Watch, namespace, mixins } from 'nuxt-property-decorator';
import SideDrawer from '~/components/containers/drawers/SideDrawer/SideDrawer.vue';
import ButtonIcon from '~/components/partials/icons/ButtonIcon/ButtonIcon.vue';
import FiltersPanel from '~/components/features/browse-toys/filters/FiltersPanel/FiltersPanel.vue';
import mq from '~/helpers/screen';
import { SMALL_DESK } from '~/constants/screen-breakpoints';
import FiltersMixin from '~/components/features/browse-toys/filters/FiltersMixin/FiltersMixin.vue';
import { ToggleDrawerPayload } from '~/store/drawers';
import { ResizeState as RS } from '~/store/resize';

interface ResizeState extends RS {} // Without this, typescript throws a warning that it cannot find the export

const ResizeStore = namespace('resize');
const ProductAssociationsStore = namespace('products/associations');
const DrawersStore = namespace('drawers');

@Component({
  components: {
    SideDrawer,
    ButtonIcon,
    FiltersPanel,
  },
})
export default class FiltersSideDrawer extends mixins(FiltersMixin) {
  @ResizeStore.State debounced!: ResizeState;
  @ProductAssociationsStore.Getter('totalSelected') numberOfFiltersApplied!: number;
  @DrawersStore.Action toggleSideDrawer!: (payload: ToggleDrawerPayload) => void;

  @Watch('isDesk')
  closeFiltersSideDrawerAtDesk() {
    if (this.isDesk) this.closeFiltersSideDrawer();
  }

  get isDesk(): boolean {
    if (!this.debounced.breakpoint) return false;

    return mq({ from: SMALL_DESK }, this.debounced.breakpoint);
  }

  closeFiltersSideDrawer() {
    this.toggleSideDrawer({ name: 'filters', isOpen: false });
  }

  clearFilters() {
    this.clearAll();
  }
}
