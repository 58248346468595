var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default", null, {
        group: {
          update: _vm.update,
          list: _vm.list,
          fixAccordionsOpen: _vm.fixAccordionsOpen
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }