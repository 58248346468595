var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
    [
      _c("path", {
        attrs: {
          d:
            "M20.5 5h-4.1v-.6C16.5 2 14.5 0 12 0 9.5 0 7.5 2 7.5 4.4V5h-4L2 22h20L20.5 5zM8.8 4.4c0-1.7 1.4-3.1 3.2-3.1 1.8 0 3.2 1.4 3.2 3.1V5H8.8v-.6z",
          "fill-rule": "evenodd",
          "clip-rule": "evenodd"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }