var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "11",
        height: "8",
        fill: "none",
        viewBox: "0 0 14 14",
        "xmlns:v": "https://vecta.io/nano"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M8.607 0l1.445 1.519L3.611 8 0 4.366l1.445-1.519 2.166 2.179L8.607 0z",
          fill: "#fff"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }