var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.error.statusCode === 404
    ? _c("PageNotFoundLayout")
    : _vm.error.statusCode === 503
    ? _c("MaintenanceLayout")
    : _c(
        "BaseSection",
        [
          _c(
            "BaseWrapper",
            [
              _c(
                "BaseText",
                {
                  staticClass: "u-margin-bottom-big",
                  attrs: { tag: "h1", size: "large-header" }
                },
                [
                  _vm._v(
                    "\n      Error: " + _vm._s(_vm.error.statusCode) + "\n    "
                  )
                ]
              ),
              _vm._v(" "),
              _c("BaseText", { attrs: { size: "sub-header" } }, [
                _vm._v(_vm._s(_vm.error.message))
              ])
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }