import { PaymentFrequency } from '~/types/subscription';

export interface Frequencies {
  [key: string]: PaymentFrequency;
}

export const frequencies: Frequencies = {
  monthly: {
    name: '1 month',
    interval: 1,
    unit: 'month',
    unitPlural: 'month',
    descriptive: 'monthly',
    shorthand: 'mo',
    longhand: 'month',
  },
  'every 3 months': {
    name: '3 month',
    interval: 3,
    unit: 'month',
    unitPlural: 'months',
    descriptive: 'every 3 months',
    shorthand: '3mo',
    longhand: 'every 3 months',
  },
  'half-yearly': {
    name: '6 month',
    interval: 6,
    unit: 'month',
    unitPlural: 'months',
    descriptive: 'half-yearly',
    shorthand: '6mo',
    longhand: 'half-year',
  },
  yearly: {
    name: '12 month',
    interval: 12,
    unit: 'month',
    unitPlural: 'months',
    descriptive: 'yearly',
    shorthand: 'yr',
    longhand: 'year',
  },
};
