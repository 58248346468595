var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "v-selected-filters" },
    [
      _c(
        "FlatAccordion",
        {
          staticClass: "v-selected-filters__accordion u-hide-from-smallDesk",
          attrs: {
            name: "selected-filter-accordion",
            size: "none",
            "is-fixed-open": !_vm.totalSelected,
            "is-open": _vm.accordionIsOpen
          },
          on: {
            "toggle-accordion": function($event) {
              _vm.accordionIsOpen = !_vm.accordionIsOpen
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "v-selected-filters__accordion-header",
              attrs: { slot: "header" },
              slot: "header"
            },
            [
              _c("SelectedFiltersCount", {
                attrs: { count: _vm.totalSelected }
              })
            ],
            1
          ),
          _vm._v(" "),
          !!_vm.totalSelected
            ? _c(
                "div",
                {
                  staticClass: "v-selected-filters__accordion-body",
                  attrs: { slot: "body" },
                  slot: "body"
                },
                [
                  _c("SelectedFiltersTags", {
                    attrs: { "selected-filters": _vm.selectedFilters },
                    on: { "filter:remove": _vm.handleRemove }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "BaseCard",
        {
          staticClass: "v-selected-filters__card u-hide-until-smallDesk",
          class: { "v-selected-filters__card--busy": _vm.busy },
          attrs: { padding: ["tiny", "tiny", "small", "tiny"] }
        },
        [
          _c(
            "BaseCluster",
            {
              staticClass: "v-selected-filters__card-header",
              attrs: { slot: "header", justify: "between", spacing: "none" },
              slot: "header"
            },
            [
              _c("SelectedFiltersCount", {
                attrs: { count: _vm.totalSelected }
              }),
              _vm._v(" "),
              !!_vm.totalSelected
                ? _c(
                    "button",
                    {
                      staticClass: "v-selected-filters__clear-btn",
                      class: {
                        "v-selected-filters__clear-btn--busy": _vm.busy
                      },
                      on: {
                        click: function($event) {
                          return _vm.clearAll()
                        },
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.clearAll()
                        }
                      }
                    },
                    [
                      _c(
                        "BaseText",
                        { attrs: { color: "secondary", tag: "span" } },
                        [_vm._v("Clear all")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          !!_vm.totalSelected
            ? _c(
                "div",
                { staticClass: "v-selected-filters__card-body" },
                [
                  _c("SelectedFiltersTags", {
                    attrs: { "selected-filters": _vm.selectedFilters },
                    on: { "filter:remove": _vm.handleRemove }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }