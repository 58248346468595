import Vue from 'vue';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '~/store';
import { ProductAssociationMetas } from '~/types/products/associations';
import { AssociationClasses, availableAssociationClasses } from '~/types/products/query';
import { Asset } from '~/types/products/products';

const types = {
  ADD_SELECTED_ASSOCIATION: 'ADD_SELECTED_ASSOCIATION',
  REMOVE_SELECTED_ASSOCIATION: 'REMOVE_SELECTED_ASSOCIATION',
  ADD_SELECTED_BUCKET: 'ADD_SELECTED_BUCKET',
  REMOVE_SELECTED_BUCKET: 'REMOVE_SELECTED_BUCKET',
  REMOVE_ALL: 'REMOVE_ALL',
  SET_CURRENT: 'SET_CURRENT',
  SET_SELECTED: 'SET_SELECTED',
  SET_HEADER_DATA: 'SET_HEADER_DATA',
};

export interface SelectedAssociations {
  [key: string]: Array<SelectedBucket>;
}

export interface SelectedBucket {
  name: string;
  slug: string;
  id: string;
  associationClass: AssociationClasses;
}

export interface ToggleSelectedPayload {
  selectedBucket: SelectedBucket;
  toggleTo?: boolean;
}

export interface HeaderData {
  title: string;
  description: string;
  image: Asset;
  associationClass: string;
  hasPage: boolean;
  slug: string;
}

export interface ModuleState {
  total: number;
  perPage: number;
  currentPage: number;
  lastPage: number;
  hideNotInStock: boolean;
  count: number;
  all: any;
  current: ProductAssociationMetas;
  selected: SelectedAssociations;
  headerData: HeaderData | null;
}

const defaults: ModuleState = {
  current: {},
  selected: {},
  headerData: null,
};

export const state = (): ModuleState => ({
  current: defaults.current,
  selected: defaults.selected,
  headerData: defaults.headerData,
});

export const getters: GetterTree<ModuleState, RootState> = {
  isSelected: (state) => (bucketSlug: string): boolean => {
    if (!Object.values(state.selected).length) return false;

    const selectedBuckets: Array<SelectedBucket> = Object.values(state.selected).flat();
    return !!selectedBuckets.find((aBucket: SelectedBucket) => aBucket.slug === bucketSlug);
  },

  totalSelected: (state): number =>
    Object.values(state.selected).reduce((count: number, association: any) => count + association.length, 0),

  selectedBuckets: (state): Array<SelectedBucket> => {
    return Object.values(state.selected).flat();
  },
};

export const actions: ActionTree<ModuleState, RootState> = {
  toggleSelected({ state, getters, commit }, { selectedBucket, toggleTo }: ToggleSelectedPayload) {
    this.$logger.store(`action`, `[products/associations/toggleSelected]`, { selectedBucket, toggleTo });

    const { associationClass } = selectedBucket;
    if (!associationClass) throw new Error('[associations/toggleSelected] associationClass is undefined.');

    if (!availableAssociationClasses.includes(associationClass)) {
      throw new Error(
        `[associations/toggleSelected] ${associationClass} is not in AvailableAssociationClasses.`
      );
    }

    const isSelected: boolean = getters.isSelected(selectedBucket.slug);

    if (toggleTo === isSelected) return;
    if (typeof toggleTo === 'undefined') toggleTo = !isSelected;

    if (toggleTo) {
      if (!state.selected[associationClass]) commit(types.ADD_SELECTED_ASSOCIATION, associationClass);
      commit(types.ADD_SELECTED_BUCKET, selectedBucket);
    } else {
      commit(types.REMOVE_SELECTED_BUCKET, selectedBucket);
      if (state.selected[associationClass].length === 0) {
        commit(types.REMOVE_SELECTED_ASSOCIATION, associationClass);
      }
    }
  },

  setHeaderData({ commit }, headerData: HeaderData) {
    this.$logger.store(`action`, `[products/associations/setHeaderData]`, { headerData });

    commit(types.SET_HEADER_DATA, headerData);
  },

  setCurrent({ commit }, current: ProductAssociationMetas) {
    this.$logger.store(`action`, `[products/associations/setCurrent]`, { current });

    commit(types.SET_CURRENT, current);
  },

  setSelected({ commit }, selected: SelectedAssociations) {
    this.$logger.store(`action`, `[products/associations/setSelected]`, { selected });

    commit(types.SET_SELECTED, selected);
  },

  removeAll({ commit }) {
    this.$logger.store(`action`, `[products/associations/removeAll]`);

    commit(types.REMOVE_ALL);
  },
};

export const mutations: MutationTree<ModuleState> = {
  [types.ADD_SELECTED_ASSOCIATION](state, associationClass: string) {
    Vue.set(state.selected, associationClass, []);
  },

  [types.REMOVE_SELECTED_ASSOCIATION](state, associationClass: string) {
    const prevState = state;
    delete prevState.selected[associationClass];
    state.selected = prevState.selected;
  },

  [types.ADD_SELECTED_BUCKET](state, selectedBucket: SelectedBucket) {
    state.selected[selectedBucket.associationClass].push(selectedBucket);
  },

  [types.REMOVE_SELECTED_BUCKET](state, selectedBucket: SelectedBucket) {
    state.selected[selectedBucket.associationClass] = state.selected[selectedBucket.associationClass].filter(
      (bucket) => bucket.slug !== selectedBucket.slug
    );
  },

  [types.REMOVE_ALL](state) {
    state.selected = {};
    state.headerData = defaults.headerData;
  },

  [types.SET_CURRENT](state, current: ProductAssociationMetas) {
    state.current = current;
  },

  [types.SET_SELECTED](state, selected: SelectedAssociations) {
    state.selected = selected;
  },

  [types.SET_HEADER_DATA](state, headerData: HeaderData) {
    state.headerData = headerData;
  },
};
