import {
  orderLinesProductTokensToPrice,
  productTokensToPrice,
  pinterestOrderLineProducts,
  pinterestProducts,
} from '../transformers/product';
import Events from './Events';
import { OrderLine } from '~/types/order';
import { Product } from '~/types/products/products';
import { getMajorityBrandName, productVariantSku } from '~/helpers/models/product';

export default class PinterestEvents extends Events {
  /**
   * Track people who complete transactions
   */
  checkout(lines: Array<OrderLine>): void {
    this.data.push({
      pinterest_line_items: null,
    });
    this.data.pushEvent('PinterestCheckout', {
      pinterest_order_value: orderLinesProductTokensToPrice(lines),
      pinterest_currency: 'GBP',
      pinterest_line_items: pinterestOrderLineProducts(lines),
    });
  }

  /**
   * Track people who add items to shopping carts
   *
   * @param {Product} product
   */
  addToCart(product: Product): void {
    this.data.pushEvent('PinterestAddToCart', {
      pinterest_order_value: productTokensToPrice(product),
      pinterest_currency: 'GBP',
      pinterest_order_quantity: 1,
      pinterest_product_id: productVariantSku(product),
      pinterest_product_category: getMajorityBrandName(product),
    });
  }

  /**
   * Track people who view primary pages, such as product pages and article pages - normal + LP pages
   *
   * @param {Product} product
   */
  pageVisit(product: Product): void {
    this.data.pushEvent('PinterestPageVisit', {
      pinterest_product_id: productVariantSku(product),
      pinterest_product_category: getMajorityBrandName(product),
    });
  }

  /**
   * Track people who sign up for your product or service - Subscribe
   *
   * @param {String} type
   * @param {String} value
   */
  signup(type: string, value: string): void {
    this.data.pushEvent('PinterestSignup', {
      pinterest_lead_type: type,
      pinterest_value: Number(value).toFixed(2),
    });
  }

  /**
   * Track people who show interest in your product or service - Register
   *
   * @param {String} type
   */
  lead(type: string): void {
    this.data.pushEvent('PinterestLead', {
      pinterest_lead_type: type,
    });
  }

  /**
   * Track people who view category pages - normal + LP pages
   *
   * @param {String} association
   * @param {Array<Product>} products
   */
  viewCategory(association: string, products: Array<Product>): void {
    this.data.push({
      pinterest_line_items: null,
    });
    this.data.pushEvent('PinterestViewCategory', {
      pinterest_association: association,
      pinterest_line_items: pinterestProducts(products),
    });
  }

  /**
   * Track people who perform searches on your website to look for specific products or store locations
   *
   * @param {String} type
   */
  search(term: string): void {
    this.data.pushEvent('PinterestSearch', {
      pinterest_search_query: term,
    });
  }

  /**
   * Track a custom event. Use this event name to track a special event that you want to include in your conversion reporting - Gifting
   *
   * @param {String} value
   */
  custom(value: string): void {
    this.data.pushEvent('PinterestCustom', {
      pinterest_value: Number(value).toFixed(2),
    });
  }
}
