import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '~/store/index';

const types = {
  PUT_BOTTOM_DRAWER_NAME: 'PUT_BOTTOM_DRAWER_NAME',
  PUT_BOTTOM_DRAWER_STATE: 'PUT_BOTTOM_DRAWER_STATE',
  PUT_SIDE_DRAWER_NAME: 'PUT_SIDE_DRAWER_NAME',
  PUT_SIDE_DRAWER_STATE: 'PUT_SIDE_DRAWER_STATE',
  PUT_TOGGLE_HIDE_BOTTOM: 'PUT_TOGGLE_HIDE_BOTTOM',
};

export interface ToggleDrawerPayload {
  name: string | null;
  isOpen: boolean;
}

interface ModuleState {
  currentBottomDrawer: string | null;
  isBottomDrawerOpen: boolean;
  currentSideDrawer: string | null;
  isSideDrawerOpen: boolean;
  toggleHideBottom: boolean;
}

export const state = (): ModuleState => ({
  currentBottomDrawer: null,
  isBottomDrawerOpen: false,
  currentSideDrawer: null,
  isSideDrawerOpen: false,
  toggleHideBottom: false,
});

export const actions: ActionTree<ModuleState, RootState> = {
  toggleBottomDrawer({ commit }, { name = null, isOpen }: ToggleDrawerPayload) {
    this.$logger.store(`action`, `[drawers/toggleBottomDrawer]`, { name, isOpen });

    commit(types.PUT_BOTTOM_DRAWER_NAME, { currentBottomDrawer: name });
    commit(types.PUT_BOTTOM_DRAWER_STATE, { isBottomDrawerOpen: isOpen });
  },
  toggleSideDrawer({ commit }, { name = null, isOpen }: ToggleDrawerPayload) {
    this.$logger.store(`action`, `[drawers/toggleSideDrawer]`, { name, isOpen });

    commit(types.PUT_SIDE_DRAWER_NAME, { currentSideDrawer: name });
    commit(types.PUT_SIDE_DRAWER_STATE, { isSideDrawerOpen: isOpen });
  },
};

export const mutations: MutationTree<ModuleState> = {
  [types.PUT_BOTTOM_DRAWER_NAME](state, { currentBottomDrawer }: Pick<ModuleState, 'currentBottomDrawer'>) {
    state.currentBottomDrawer = currentBottomDrawer;
  },
  [types.PUT_BOTTOM_DRAWER_STATE](state, { isBottomDrawerOpen }: Pick<ModuleState, 'isBottomDrawerOpen'>) {
    state.isBottomDrawerOpen = isBottomDrawerOpen;
  },
  [types.PUT_SIDE_DRAWER_NAME](state, { currentSideDrawer }: Pick<ModuleState, 'currentSideDrawer'>) {
    state.currentSideDrawer = currentSideDrawer;
  },
  [types.PUT_SIDE_DRAWER_STATE](state, { isSideDrawerOpen }: Pick<ModuleState, 'isSideDrawerOpen'>) {
    state.isSideDrawerOpen = isSideDrawerOpen;
  },
  [types.PUT_TOGGLE_HIDE_BOTTOM](state, toggle: boolean) {
    state.toggleHideBottom = toggle;
  },
};
