


















import { Vue, Component, Prop } from 'nuxt-property-decorator';

@Component({
  inheritAttrs: false,
})
export default class BaseToggleButton extends Vue {
  @Prop({ required: true }) readonly id!: string;
  @Prop({ default: false }) readonly on!: boolean;

  toggle() {
    this.$emit('toggled', !this.on);
  }
}
