import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '~/store/index';
import { AvailableSortOptions, SortOption } from '~/types/products/sort';

import { options, defaultSelected, selectOptions } from '~/config/products/sort';

const types = {
  SET_SELECTED_SORT: 'SET_SELECTED_SORT',
  SET_SORT_OPTIONS: 'SET_SORT_OPTIONS',
};

export interface ModuleState {
  options: Array<SortOption>;
  selected: SortOption;
}

const defaults: Omit<ModuleState, 'options'> = {
  selected: defaultSelected,
};

export const state = (): ModuleState => ({
  options,
  selected: defaults.selected,
});

export const actions: ActionTree<ModuleState, RootState> = {
  setSelectedSort({ commit }, { sort }: { sort: SortOption }) {
    this.$logger.store(`action`, `[products/sort/setSelectedSort]`, { sort });

    commit(types.SET_SELECTED_SORT, sort);
  },
  setSortOptions({ commit }, option?: AvailableSortOptions) {
    this.$logger.store(`action`, `[products/sort/setSortOptions]`, { option });

    commit(types.SET_SORT_OPTIONS, option);
  },

  async reset({ dispatch }) {
    await dispatch('setSelectedSort', { sort: defaults.selected });
  },

  disableSorting({ commit }) {
    commit(types.SET_SELECTED_SORT, null);
  },
};

export const mutations: MutationTree<ModuleState> = {
  SET_SELECTED_SORT(state, sort: SortOption) {
    state.selected = sort;
  },
  SET_SORT_OPTIONS(state, option?: AvailableSortOptions) {
    state.options = selectOptions(option);
  },
};
