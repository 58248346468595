// @todo We need to take the store modules resize.js and scroll.js and somehow add them inside this plugin
import extend from './extend';
import injectStoreInListenerActions from './event-listeners';

function forEachKey(object, callback) {
  return Object.keys(object).forEach(callback);
}

function bindActionToEventListener(event, action) {
  window.addEventListener(event, () => {
    action();
  });
}

export default function({ app, store }) {
  extend(app, {
    mounted() {
      this.registerEventListeners();
    },
    destroyed() {
      window.removeEventListener('load', this.attachEventListeners());
    },
    methods: {
      attachEventListeners(eventListeners) {
        forEachKey(eventListeners, (eventName) => {
          const actions = eventListeners[eventName];

          actions.forEach((action) => {
            bindActionToEventListener(eventName, action);
            action();
          });
        });
      },
      registerEventListeners() {
        const eventListeners = injectStoreInListenerActions(store);

        window.addEventListener('load', this.attachEventListeners(eventListeners));
      },
    },
  });
}
