import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '~/store/index';

import { bundlesFromTokens, getAddonsByName, getAddonsCost } from '~/helpers/subscriptionAddons';
import { frequencies } from '~/constants/pricing-plan-frequencies';
import {
  Subscription,
  PaymentFrequency,
  SubscriptionPricingPlan,
  MergedPlanAndPricingPlan,
  MergedAddonAndPricingPlan,
  SubscriptionAddonPricingPlan,
  TypeSubscriptionIntervalLengthDescriptive,
  Addon,
} from '~/types/subscription';
import { PERCENTAGE_VALUE_TYPE, GIFT_TYPE_NAME } from '~/constants/discount-types';
import { UserSubscription } from '~/types/user';
import {
  HALF_YEARLY_LONG,
  HALF_YEARLY_NUMBER,
  MONTHLY_LONG,
  MONTHLY_NUMBER,
  THREE_MONTHS_LONG,
  THREE_MONTHS_NUMBER,
  YEARLY_LONG,
  YEARLY_NUMBER,
} from '~/constants/subscription-intervals';
import { ADDITIONAL_TOKENS_NAME, DELIVERY_BOOSTER_NAME } from '~/constants/subscription-addons';
import { DiscountCodeParams, DiscountCode } from '~/types/discount-code';
import { userSubscription as getUserSubscription } from '~/helpers/models/user';

const types = {
  PUT_SUBSCRIPTION: 'PUT_SUBSCRIPTION',
  PUT_SUBSCRIPTION_PRICING_PLAN: 'PUT_SUBSCRIPTION_PRICING_PLAN',
  PUT_ADDONS: 'PUT_ADDONS',
  PUT_USER_SUBSCRIPTION_ID: 'PUT_USER_SUBSCRIPTION_ID',
  UPDATE_PAYMENT_FREQUENCY: 'UPDATE_PAYMENT_FREQUENCY',
  UPDATE_TOTAL_COST: 'UPDATE_TOTAL_COST',
  UPDATE_TOTAL_SUBSCRIPTION_AND_ADDONS_COST: 'UPDATE_TOTAL_SUBSCRIPTION_AND_ADDONS_COST',
  UPDATE_REMAINING_CREDIT: 'UPDATE_REMAINING_CREDIT',
  RESET_ALL: 'RESET_ALL',
  PUT_SELECTED_FROM_RECOMMENDER: 'PUT_SELECTED_FROM_RECOMMENDER',
  PUT_DISCOUNT: 'PUT_DISCOUNT',
};

export interface ModuleState {
  selectedFrequency: PaymentFrequency;
  selectedSubscription: Subscription | null;
  selectedPricingPlan: SubscriptionPricingPlan | null;
  addons: Array<MergedAddonAndPricingPlan>;
  userSubscriptionId: string | null;
  totalCost: number;
  subscriptionAndAddonsCost: number;
  remainingCredit: number;
  planSelectedFromRecommender: boolean;
  discount: number | null;
}

export const state = (): ModuleState => ({
  selectedFrequency: frequencies['half-yearly'],
  selectedSubscription: null,
  selectedPricingPlan: null,
  addons: [],
  userSubscriptionId: null,
  totalCost: 0,
  subscriptionAndAddonsCost: 0,
  remainingCredit: 0,
  planSelectedFromRecommender: false,
  discount: null,
});

export const getters: GetterTree<ModuleState, RootState> = {
  getSelectedPlanWithPricingPlan: (state): MergedPlanAndPricingPlan | null => {
    if (!state.selectedSubscription || !state.selectedPricingPlan) return null;

    return {
      ...state.selectedSubscription,
      subscriptionPricingPlan: state.selectedPricingPlan,
      subscriptionPricingPlanId: state.selectedPricingPlan.id,
    };
  },

  getAdditionalTokenAddonBundles: (state): number => {
    const additionalTokensAddon: MergedAddonAndPricingPlan | undefined = [
      ...state.addons,
    ].find((addon: MergedAddonAndPricingPlan) =>
      addon.name
        ? addon.name.includes('Additional Tokens')
        : addon.subscriptionAddonPricingPlan.name.includes('Additional Tokens')
    );

    return additionalTokensAddon ? bundlesFromTokens(additionalTokensAddon.quantity) : 0;
  },
  getAdditionalTokenAddons: (state): Array<MergedAddonAndPricingPlan> => {
    return getAddonsByName(ADDITIONAL_TOKENS_NAME, state.addons) || [];
  },
  getDeliveryBoosterAddons: (state): Array<MergedAddonAndPricingPlan> => {
    return getAddonsByName(DELIVERY_BOOSTER_NAME, state.addons) || [];
  },
};

export const actions: ActionTree<ModuleState, RootState> = {
  /**
   * We have to hydrate this module on page load with the logged in user's subscription information.
   * This is because they may have left the registration process after step 2 - having picked a subscription but not paid for it.
   * If they then go to /register we need to show them what subscription package and addons they have picked.
   */
  async initialState({ dispatch, commit, rootState, state }, manualConfig: boolean = false) {
    this.$logger.store(`action`, `[registration/subscription/initialState]`, { manualConfig });

    if (!this.$auth.user || !this.$auth.loggedIn) return;
    if (!this.$auth.user.userSubscriptions || !this.$auth.user.userSubscriptions.length) return;

    const userSubscription: UserSubscription | null = getUserSubscription(this.$auth.user);
    if (!userSubscription || !userSubscription.subscriptionPricingPlan) return;

    if (!state.selectedPricingPlan || !state.selectedSubscription || !state.userSubscriptionId) {
      commit(types.PUT_USER_SUBSCRIPTION_ID, userSubscription.id);
      commit(types.PUT_SUBSCRIPTION_PRICING_PLAN, userSubscription.subscriptionPricingPlan);
      commit(types.PUT_SUBSCRIPTION, userSubscription.subscriptionPricingPlan.subscription);
    }
    if (userSubscription.activeAddons && userSubscription.activeAddons.length) {
      // @todo Needs refactor when new addons are available. Also links to this ticket: [UWA-843](https://whirli.atlassian.net/browse/UWA-843)
      let selectedUserSubscriptionAddons: Array<MergedAddonAndPricingPlan> = [
        ...userSubscription.activeAddons,
      ];
      if (
        selectedUserSubscriptionAddons.length === 0 ||
        !selectedUserSubscriptionAddons[0].subscriptionAddonPricingPlan
      )
        return;

      /**
       * If the subscription pricing plans & addons haven't been requested yet, dispatch those events.
       * The subscription pricing plans & addons are required for determining what options should be preselected
       */
      if (rootState.subscriptions.subscriptionAddonPricingPlans.length === 0 && manualConfig) {
        await Promise.all([
          dispatch('subscriptions/hydrateSubscriptionsData', null, { root: true }),
          dispatch('subscriptions/hydratePricingPlansWithChange', null, { root: true }),
          dispatch('subscriptions/hydrateAddonsData', null, { root: true }),
        ]);
      }

      /**
       * IF store/subscriptions.ts store is hydrated:
       * - Update the currently selected addons id from the latest saved userSubscription.addons resource
       *
       * Filter to find the actual subscriptionAddon id from the UserSubscriptionAddon resource:
       * - Finds the Addon 'id' & 'name' from the <UserSubscriptionAddon>subscriptionAddonPricingPlan.subscriptionAddonId &
       *   replaces the <root>.id property with the return value.
       */
      if (
        (rootState.registration.progress.currentStep !== 1 &&
          rootState.subscriptions.subscriptionAddonPricingPlans.length > 0) ||
        (manualConfig && rootState.subscriptions.subscriptionAddonPricingPlans.length > 0)
      ) {
        selectedUserSubscriptionAddons = selectedUserSubscriptionAddons.map(
          (addon: MergedAddonAndPricingPlan) => {
            const subscriptionAddon: Addon = rootState.subscriptions.subscriptionAddonPricingPlans.find(
              (subscriptionAddon: SubscriptionAddonPricingPlan) =>
                subscriptionAddon.id === addon.subscriptionAddonPricingPlan.subscriptionAddonId
            );
            const newAddon: MergedAddonAndPricingPlan = Object.assign({}, addon, {
              id: subscriptionAddon.id,
              name: addon.subscriptionAddonPricingPlan.name,
              quantity: addon.quantity,
            });

            return newAddon;
          }
        );
      }
      await commit(types.PUT_ADDONS, selectedUserSubscriptionAddons);
    }

    /**
     * IF the user has previously selected & saved a plan
     * - Set the frequency to previously selected option
     */
    if (userSubscription.subscriptionPricingPlan && userSubscription.subscriptionPricingPlan.intervalLength) {
      switch (userSubscription.subscriptionPricingPlan.intervalLength) {
        case MONTHLY_NUMBER:
          dispatch('updatePaymentFrequency', MONTHLY_LONG.toLowerCase());
          break;
        case THREE_MONTHS_NUMBER:
          dispatch('updatePaymentFrequency', THREE_MONTHS_LONG.toLowerCase());
          break;
        case HALF_YEARLY_NUMBER:
          dispatch('updatePaymentFrequency', HALF_YEARLY_LONG.toLowerCase());
          break;
        case YEARLY_NUMBER:
          dispatch('updatePaymentFrequency', YEARLY_LONG.toLowerCase());
          break;
      }
    }
    await dispatch('updateTotalCost');
  },

  /**
   * Receive plan ID, find the relevant plan object from the subscriptions store module and save that.
   * Find the selected PricingPlan based on the selected payment frequency and subscription plan ID.
   */
  async putSubscription({ commit, dispatch, rootState, rootGetters }, subscriptionId: string) {
    this.$logger.store(`action`, `[registration/subscription/putSubscription]`, { subscriptionId });

    const subscription: Subscription | null = rootState.subscriptions.subscriptions.find(
      (plan: Subscription) => plan.id === subscriptionId
    );
    const filteredPlansByFrequency: Array<MergedPlanAndPricingPlan> =
      rootGetters['subscriptions/getFilteredPlansBySelectedFrequency'];

    if (!subscription || !filteredPlansByFrequency.length) return;

    const plan: MergedPlanAndPricingPlan | undefined = filteredPlansByFrequency.find(
      (plan: MergedPlanAndPricingPlan) => plan.id === subscriptionId
    );

    if (!plan) return;

    commit(types.PUT_SUBSCRIPTION, subscription);
    if (plan.subscriptionPricingPlan) {
      commit(types.PUT_SUBSCRIPTION_PRICING_PLAN, plan.subscriptionPricingPlan);
    }

    await dispatch('updateTotalCost');
  },

  /**
   * Receive pricingPlanId, find the relevant SubscriptionPricingPlan object from
   * the subscriptions store module and save that.
   */
  async putSubscriptionPricingPlan({ commit, dispatch, rootState }, pricingPlanId: string) {
    this.$logger.store(`action`, `[registration/subscription/putSubscriptionPricingPlan]`, { pricingPlanId });

    if (!rootState.subscriptions.subscriptionPricingPlans) {
      await dispatch('subscriptions/hydrateSubscriptionsData', null, { root: true });
    }

    const pricingPlan: SubscriptionPricingPlan | null = rootState.subscriptions.subscriptionPricingPlans.find(
      (plan: SubscriptionPricingPlan) => plan.id === pricingPlanId
    );

    if (!pricingPlan) return;

    commit(types.PUT_SUBSCRIPTION_PRICING_PLAN, pricingPlan);
    await dispatch('putSubscription', pricingPlan.subscriptionId);
  },

  async addAddon({ state, commit, dispatch, rootGetters }, addon: { id: string; quantity: number }) {
    this.$logger.store(`action`, `[registration/subscription/addAddon]`, {
      addonId: addon.id,
      quantity: addon.quantity,
    });

    const addonExists: MergedAddonAndPricingPlan | undefined = [...state.addons].find(
      (stateAddon: MergedAddonAndPricingPlan) => stateAddon.id === addon.id
    );

    if (addonExists) return;

    const filteredAddonsByFrequency: Array<MergedAddonAndPricingPlan> =
      rootGetters['subscriptions/getFilteredAddonsBySelectedFrequency'];

    const addonPlan: MergedAddonAndPricingPlan | undefined = [...filteredAddonsByFrequency].find(
      (plan: MergedAddonAndPricingPlan) => plan.id === addon.id
    );

    if (!addonPlan) return;

    addonPlan.quantity = addon.quantity;

    await commit(types.PUT_ADDONS, [...state.addons, addonPlan]);
    await dispatch('updateTotalCost');
  },

  /**
   * When the frequency is changed, the selected pricing plan no longer applies.
   * Find the new pricing plan and update the store.
   */
  async updatePaymentFrequency(
    { state, commit, dispatch },
    frequency: TypeSubscriptionIntervalLengthDescriptive
  ) {
    this.$logger.store(`action`, `[registration/subscription/updatePaymentFrequency]`, { frequency });
    commit(types.UPDATE_PAYMENT_FREQUENCY, frequencies[frequency]);

    if (!state.selectedSubscription || !state.selectedPricingPlan) return;
    await dispatch('putSubscription', state.selectedSubscription.id);
    const addons: Array<MergedAddonAndPricingPlan> = [...state.addons];

    addons.forEach(async (addon: MergedAddonAndPricingPlan) => {
      await dispatch('removeAddon', addon.id);
      await dispatch('addAddon', { id: addon.id, quantity: addon.quantity });
    });
  },

  async removeAddons({ commit, dispatch }) {
    this.$logger.store(`action`, `[registration/subscription/removeAddons]`);

    commit(types.PUT_ADDONS, []);
    await dispatch('updateTotalCost');
  },

  async removeAddon({ state, commit, dispatch }, addonId: string) {
    this.$logger.store(`action`, `[registration/subscription/removeAddon]`);

    const updatedAddons: Array<MergedAddonAndPricingPlan> = [...state.addons].filter(
      (addon: MergedAddonAndPricingPlan) => addon.id !== addonId
    );

    commit(types.PUT_ADDONS, updatedAddons);
    await dispatch('updateTotalCost');
  },

  updateTotalCost({ state, commit, rootState, getters }) {
    this.$logger.store(`action`, `[registration/subscription/updateTotalCost]`);

    if (!state.selectedPricingPlan) return;

    const additionalTokensAddonCost: number = getAddonsCost(getters.getAdditionalTokenAddons);
    const deliveryBoosterAddonCost: number = getAddonsCost(getters.getDeliveryBoosterAddons);

    let total: number = parseFloat(state.selectedPricingPlan.cost) + additionalTokensAddonCost;
    const subscriptionAndAddonsCost: number = total + deliveryBoosterAddonCost;
    const discountCode: DiscountCode = rootState.registration.discountCode.code;
    let discount: number | null = null;

    if (discountCode.value) {
      if (discountCode.valueTypeId === PERCENTAGE_VALUE_TYPE) {
        // Percentage is returned as an integer, so e.g. 15% is 1500.
        discount = (total * (discountCode.value / 100)) / 100;
        total = total * (1 - discountCode.value / 10000);
      } else {
        discount = discountCode.value;
        total = total - discountCode.value;
      }
    }
    total = total + deliveryBoosterAddonCost;

    let remainingValue: number = 0;
    if (total < 0) {
      if (discountCode.type === GIFT_TYPE_NAME) {
        remainingValue = Math.abs(total / 100);
      }
      total = 0;
    }

    commit(types.UPDATE_TOTAL_SUBSCRIPTION_AND_ADDONS_COST, subscriptionAndAddonsCost);
    commit(types.UPDATE_TOTAL_COST, total);
    commit(types.UPDATE_REMAINING_CREDIT, remainingValue);
    commit(types.PUT_DISCOUNT, discount);
  },

  putUserSubscriptionId({ commit }, id) {
    this.$logger.store(`action`, `[registration/subscription/putUserSubscriptionId]`, { id });

    commit(types.PUT_USER_SUBSCRIPTION_ID, id);
  },

  reset({ commit }) {
    this.$logger.store(`action`, `[registration/subscription/reset]`);

    commit(types.RESET_ALL);
  },
  async putDiscountCode({ state, dispatch, rootGetters }) {
    if (!state.selectedSubscription) return;
    const discountName = rootGetters['registration/discountCodeFromOffer/getCode'];

    if (discountName) {
      let deliveryBooster = null;
      if (state.addons.length === 1) {
        deliveryBooster = state.addons[0].id;
      }
      const params: DiscountCodeParams = {
        codeName: discountName,
        options: {
          frequency: state.selectedFrequency.interval,
          plan: state.selectedSubscription.id,
          type: 'subscription',
          deliveryBooster,
        },
      };
      await dispatch('registration/discountCode/fetchDiscountCode', params, { root: true });
    }
  },
};

export const mutations: MutationTree<ModuleState> = {
  [types.PUT_SUBSCRIPTION](state, selectedSubscription: Subscription) {
    state.selectedSubscription = selectedSubscription;
  },
  [types.PUT_SUBSCRIPTION_PRICING_PLAN](state, selectedPricingPlan: SubscriptionPricingPlan) {
    state.selectedPricingPlan = selectedPricingPlan;
  },
  [types.PUT_ADDONS](state, addons: Array<MergedAddonAndPricingPlan>) {
    state.addons = addons;
  },
  [types.UPDATE_PAYMENT_FREQUENCY](state, frequency: PaymentFrequency) {
    state.selectedFrequency = frequency;
  },
  [types.UPDATE_TOTAL_COST](state, total: number) {
    state.totalCost = total;
  },
  [types.PUT_USER_SUBSCRIPTION_ID](state, id: string) {
    state.userSubscriptionId = id;
  },
  [types.UPDATE_TOTAL_SUBSCRIPTION_AND_ADDONS_COST](state, cost: number) {
    state.subscriptionAndAddonsCost = cost;
  },
  [types.UPDATE_REMAINING_CREDIT](state, cost: number) {
    state.remainingCredit = cost;
  },
  [types.RESET_ALL](state) {
    state.selectedFrequency = frequencies['half-yearly'];
    state.selectedSubscription = null;
    state.selectedPricingPlan = null;
    state.addons = [];
    state.userSubscriptionId = null;
    state.totalCost = 0;
    state.subscriptionAndAddonsCost = 0;
    state.remainingCredit = 0;
    state.planSelectedFromRecommender = false;
  },
  [types.PUT_SELECTED_FROM_RECOMMENDER](state, selected: boolean) {
    state.planSelectedFromRecommender = selected;
  },
  [types.PUT_DISCOUNT](state, discount: number) {
    state.discount = discount;
  },
};
