import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '~/store/index';
import { separatePricingPlansFromPlans, separateAddonPricingPlansFromAddons } from '~/helpers/subscriptions';
import {
  Subscription,
  SubscriptionPricingPlan,
  Addon,
  SubscriptionAddonPricingPlan,
  MergedPlanAndPricingPlan,
  PaymentFrequency,
  MergedAddonAndPricingPlan,
  PlansWithPricingPlans,
  AddonsWithAddonPricingPlans,
} from '~/types/subscription';
import { HALF_YEARLY_NUMBER } from '~/constants/subscription-intervals';

const types = {
  PUT_SUBSCRIPTION_PLANS: 'PUT_SUBSCRIPTION_PLANS',
  PUT_SUBSCRIPTION_PRICING_PLANS: 'PUT_SUBSCRIPTION_PRICING_PLANS',
  PUT_SUBSCRIPTION_ADDONS: 'PUT_SUBSCRIPTION_ADDONS',
  PUT_SUBSCRIPTION_ADDONS_PRICING_PLANS: 'PUT_SUBSCRIPTION_ADDONS_PRICING_PLANS',
  PUT_SUBSCRIPTION_PRICING_PLAN_DISCOUNT_PERCENT: 'PUT_SUBSCRIPTION_PRICING_PLAN_DISCOUNT_PERCENT',
  SET_BUSY: 'SET_BUSY',
  SET_IS_FETCHED: 'SET_IS_FETCHED',
};

interface ModuleState {
  subscriptions: Array<Subscription>;
  subscriptionPricingPlans: Array<SubscriptionPricingPlan>;
  addons: Array<Addon>;
  subscriptionAddonPricingPlans: Array<SubscriptionAddonPricingPlan>;
  isFetched: boolean;
  isBusy: boolean;
  pricingPlanDiscountPercent: number | null;
}

export const state = (): ModuleState => ({
  subscriptions: [],
  subscriptionPricingPlans: [],
  addons: [],
  subscriptionAddonPricingPlans: [],
  isFetched: false,
  isBusy: false,
  pricingPlanDiscountPercent: null,
});

export const getters: GetterTree<ModuleState, RootState> = {
  /**
   * The individual subscription plans (interface: Subscription) don't have all of the information that we need to
   * display the pricing plans in the PricingPlanCarousel component.
   * This finds the pricing plans (interface: SubscriptionPricingPlan) with the same intervalLength as the selectedFrequency
   * (in registration/subscriptions module) and merges each pricing plan with its associated plan.
   * This getter is used for the PricingPlansCarousel and updates automatically when the selectedFrequency is changed.
   */
  getFilteredPlansBySelectedFrequency: (
    state,
    _getters,
    rootState
  ): Array<MergedPlanAndPricingPlan | Subscription> | [] => {
    if (!state.subscriptionPricingPlans.length) return [];

    const selectedPaymentFrequency: PaymentFrequency = rootState.registration.subscription.selectedFrequency;
    const pricingPlansByFrequency: Array<SubscriptionPricingPlan> = state.subscriptionPricingPlans.filter(
      (pricingPlan: SubscriptionPricingPlan) =>
        pricingPlan.intervalLength === selectedPaymentFrequency.interval
    );

    return state.subscriptions.map((plan: Subscription) => {
      const subscriptionPricingPlan: SubscriptionPricingPlan | undefined = pricingPlansByFrequency.find(
        (pricingPlan: SubscriptionPricingPlan) => pricingPlan.subscriptionId === plan.id
      );

      if (!subscriptionPricingPlan) return plan;

      if (state.pricingPlanDiscountPercent && selectedPaymentFrequency.interval === HALF_YEARLY_NUMBER) {
        const discount =
          (state?.pricingPlanDiscountPercent / 100) * parseFloat(subscriptionPricingPlan?.cost);
        subscriptionPricingPlan.discount = discount.toString();
      }

      return { ...plan, subscriptionPricingPlan };
    });
  },

  /**
   * The individual addons (interface: Addon) don't have all of the information that we need to display the correct addon information in TheDeliveryRadioGroup component.
   * This finds the addon pricing plans (interface: SubscriptionAddonPricingPlan) with the same intervalLength as the selectedFrequency (in registration/subscriptions module)
   * and merges each addon pricing plan with its associated addon.
   * This getter is used for the TheDeliveryRadioGroup and updates automatically when the selectedFrequency is changed.
   */
  getFilteredAddonsBySelectedFrequency: (
    state,
    _getters,
    rootState
  ): Array<MergedAddonAndPricingPlan> | Array<Addon> | [] => {
    if (!state.subscriptionAddonPricingPlans) return [];

    const selectedPaymentFrequency: PaymentFrequency = rootState.registration.subscription.selectedFrequency;

    const addonPricingPlansByFrequency: Array<SubscriptionAddonPricingPlan> = state.subscriptionAddonPricingPlans.filter(
      (addonPricingPlan: SubscriptionAddonPricingPlan) => {
        return addonPricingPlan.intervalLength === selectedPaymentFrequency.interval;
      }
    );

    return state.addons.map((addon: Addon) => {
      const addonPricingPlan: SubscriptionAddonPricingPlan | undefined = addonPricingPlansByFrequency.find(
        (pp) => pp.subscriptionAddonId === addon.id
      );
      if (!addonPricingPlan) return addon;
      return { ...addon, subscriptionAddonPricingPlan: addonPricingPlan };
    });
  },
};

export const actions: ActionTree<ModuleState, RootState> = {
  /**
   * The subscription plans and addons data are needed on page load for the PricingPlansCarousel
   * which appears on multiple pages.
   */
  async initialiseStore({ dispatch, commit, state }): Promise<any> {
    this.$logger.store(`action`, `[subscriptions/initialiseStore]`, { state });

    if (state.isBusy || state.isFetched) return;
    commit(types.SET_BUSY, true);
    try {
      await Promise.all([dispatch('hydrateSubscriptionsData'), dispatch('hydrateAddonsData')]);
      commit(types.SET_BUSY, false);
      commit(types.SET_IS_FETCHED, true);
    } catch (error) {
      commit(types.SET_BUSY, false);
      throw error;
    }
  },

  /**
   * Get the subscriptions data from the API.
   * Separate the pricingPlans from the plans and commit both separately to the store.
   * We sort the plans array in credits order, lowest to highest.
   */
  async hydrateSubscriptionsData({ commit }): Promise<any> {
    this.$logger.store(`action`, `[subscriptions/hydrateSubscriptionsData]`);

    if (this.$memCache.get('subscriptions') && this.$memCache.get('subscriptionsPricingPlans')) {
      commit(types.PUT_SUBSCRIPTION_PLANS, this.$memCache.get('subscriptions'));
      commit(types.PUT_SUBSCRIPTION_PRICING_PLANS, this.$memCache.get('subscriptionsPricingPlans'));
      return;
    }

    const response: any = await this.$whirli.subscriptions.all({
      params: { with: 'subscriptionPricingPlans.startingSubscriptionTier' },
    });

    const subscriptionData: PlansWithPricingPlans | null = separatePricingPlansFromPlans(response.data);
    if (!subscriptionData) return;

    subscriptionData.subscriptions.sort(
      (firstPlan: any, secondPlan: any) => firstPlan.credits - secondPlan.credits
    );

    commit(types.PUT_SUBSCRIPTION_PLANS, subscriptionData.subscriptions);
    commit(types.PUT_SUBSCRIPTION_PRICING_PLANS, subscriptionData.subscriptionPricingPlans);

    this.$memCache.put('subscriptions', subscriptionData.subscriptions);
    this.$memCache.put('subscriptionsPricingPlans', subscriptionData.subscriptionPricingPlans);

    return response;
  },

  async hydratePricingPlansWithChange({ commit }): Promise<any> {
    this.$logger.store(`action`, `[subscriptions/hydratePricingPlansWithChange]`);

    let response: any;
    try {
      response = await this.$whirli.subscriptions.pricingPlans.allWithChange();
    } catch (error) {
      throw error;
    }

    commit(types.PUT_SUBSCRIPTION_PRICING_PLANS, response.data);
  },

  /**
   * Get the addons data from the API.
   * Separate the addonPricingPlans from the addons and commit both separately to the store.
   */
  async hydrateAddonsData({ commit }): Promise<any> {
    this.$logger.store(`action`, `[subscriptions/hydrateAddonsData]`);

    if (this.$memCache.get('subscriptionAddons') && this.$memCache.get('subscriptionAddonPricingPlans')) {
      commit(types.PUT_SUBSCRIPTION_ADDONS, this.$memCache.get('subscriptionAddons'));
      commit(
        types.PUT_SUBSCRIPTION_ADDONS_PRICING_PLANS,
        this.$memCache.get('subscriptionAddonPricingPlans')
      );
      return;
    }

    const response: any = await this.$whirli.subscriptions.addons.all({
      params: { with: 'subscriptionAddonPricingPlans' },
    });
    const addonData: AddonsWithAddonPricingPlans | null = separateAddonPricingPlansFromAddons(response.data);
    if (!addonData) return;

    commit(types.PUT_SUBSCRIPTION_ADDONS, addonData.addons);
    commit(types.PUT_SUBSCRIPTION_ADDONS_PRICING_PLANS, addonData.addonPricingPlans);

    this.$memCache.put('subscriptionAddons', addonData.addons);
    this.$memCache.put('subscriptionAddonPricingPlans', addonData.addonPricingPlans);

    return response;
  },

  putSubscriptionPlans({ commit }, plans: Array<Subscription>): void {
    this.$logger.store(`action`, `[subscriptions/putSubscriptionPlans]`, { plans });

    commit(types.PUT_SUBSCRIPTION_PLANS, plans);
  },
  putSubscriptionPricingPlans({ commit }, pricingPlans: Array<SubscriptionPricingPlan>): void {
    this.$logger.store(`action`, `[subscriptions/putSubscriptionPricingPlans]`, { pricingPlans });

    commit(types.PUT_SUBSCRIPTION_PRICING_PLANS, pricingPlans);
  },
  putSubscriptionPricingPlanDiscountPercent({ commit }, discountPercent: number | null): void {
    commit(types.PUT_SUBSCRIPTION_PRICING_PLAN_DISCOUNT_PERCENT, discountPercent);
  },
  putSubscriptionAddons({ commit }, addons: Array<Addon>): void {
    this.$logger.store(`action`, `[subscriptions/putSubscriptionAddons]`, { addons });

    commit(types.PUT_SUBSCRIPTION_ADDONS, addons);
  },
  putSubscriptionAddonPricingPlans({ commit }, addonPricingPlans: Array<SubscriptionAddonPricingPlan>): void {
    this.$logger.store(`action`, `[subscriptions/putSubscriptionAddonPricingPlans]`, { addonPricingPlans });

    commit(types.PUT_SUBSCRIPTION_ADDONS_PRICING_PLANS, addonPricingPlans);
  },
  toggleBusy({ commit }, isBusy: boolean): void {
    this.$logger.store(`action`, `[subscriptions/toggleBusy]`, { isBusy });

    commit(types.SET_BUSY, isBusy);
  },
  resetStore({ commit }): void {
    this.$logger.store(`action`, `[subscriptions/resetStore]`);

    commit(types.SET_BUSY, false);
    commit(types.SET_IS_FETCHED, false);
  },
};

export const mutations: MutationTree<ModuleState> = {
  [types.PUT_SUBSCRIPTION_PLANS](state, subscriptions: Array<Subscription>): void {
    state.subscriptions = subscriptions;
  },
  [types.PUT_SUBSCRIPTION_PRICING_PLANS](state, pricingPlans: Array<SubscriptionPricingPlan>): void {
    state.subscriptionPricingPlans = pricingPlans;
  },
  [types.PUT_SUBSCRIPTION_PRICING_PLAN_DISCOUNT_PERCENT](state, discountPercent: number | null): void {
    state.pricingPlanDiscountPercent = discountPercent;
  },
  [types.PUT_SUBSCRIPTION_ADDONS](state, addons: Array<Addon>): void {
    state.addons = addons;
  },
  [types.PUT_SUBSCRIPTION_ADDONS_PRICING_PLANS](
    state,
    addonPricingPlans: Array<SubscriptionAddonPricingPlan>
  ): void {
    state.subscriptionAddonPricingPlans = addonPricingPlans;
  },
  [types.SET_BUSY](state, isBusy: boolean): void {
    state.isBusy = isBusy;
  },
  [types.SET_IS_FETCHED](state, isFetched: boolean): void {
    state.isFetched = isFetched;
  },
};
