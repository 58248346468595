import { ActionTree, GetterTree, MutationTree } from 'vuex';

// types
import {
  ChildProfile,
  CreateChildProfilePayload,
  ChildProfileWithBucket,
  ChildProfileUpdateCheckPayload,
  ChildProfileModalType,
} from '~/types/child-profiles';
import { RootState } from '~/store/index';
import { SelectedBucket } from '~/store/products/associations';

// helpers
import { setupLazy, LazyLoadableState } from '~/helpers/store/lazyLoad';
import ChildProfilesFilterBuilder from '~/helpers/store/child-profiles-filter-builder';
import { DEFAULT } from '~/constants/child-profiles';

const { lazyLoadableTypes, lazyLoadableActions, lazyLoadableState, lazyLoadableMutations } = setupLazy<
  ModuleState,
  RootState
>();

const profileFilterBuilder: ChildProfilesFilterBuilder = new ChildProfilesFilterBuilder();

const types = {
  ...lazyLoadableTypes,
  PUT_CHILD_PROFILES: 'PUT_CHILD_PROFILES',
  PUT_CHILD_PROFILES_WITH_BUCKET: 'PUT_CHILD_PROFILES_WITH_BUCKET',
  SET_BUSY: 'SET_BUSY',
  UPDATE_CHILD_PROFILE_LINES: 'UPDATE_CHILD_PROFILE_LINES',
  ADD_CHILD_PROFILE: 'ADD_CHILD_PROFILE',
  UPDATE_CHILD_PROFILE_CHECK: 'UPDATE_CHILD_PROFILE_CHECK',
  SET_MODAL_TYPE: 'SET_MODAL_TYPE',
  SET_ORIGINAL_EXPERIMENT_VARIANT: 'SET_ORIGINAL_EXPERIMENT_VARIANT',
  SET_SELECTED_BUCKET: 'SET_SELECTED_BUCKET',
};

interface ModuleState extends LazyLoadableState {
  childProfiles: ChildProfile[];
  childProfilesWithBucket: ChildProfileWithBucket[];
  modalType: ChildProfileModalType;
  isOriginalVariant: boolean;
  selectedBucket: SelectedBucket;
}

export const state = (): ModuleState => ({
  ...lazyLoadableState,
  childProfiles: [],
  childProfilesWithBucket: [],
  modalType: DEFAULT,
  isOriginalVariant: false,
  selectedBucket: {
    name: '',
    slug: '',
    associationClass: 'age',
  },
});

export const actions: ActionTree<ModuleState, RootState> = {
  ...lazyLoadableActions,

  setChildProfiles({ commit, dispatch }, childProfiles: ChildProfile[]): void {
    this.$logger.store(`action`, `[childProfiles/setChildProfiles]`);

    if (!this.$auth.loggedIn) return;

    commit(types.PUT_CHILD_PROFILES, childProfiles);
    dispatch('setChildProfilesWithBucket');
  },

  setChildProfilesWithBucket({ commit, state }): void {
    commit(types.PUT_CHILD_PROFILES_WITH_BUCKET, state.childProfiles);
  },

  async createChildProfile({ commit, dispatch }, payload: CreateChildProfilePayload): Promise<void> {
    commit(types.SET_BUSY, true);
    try {
      const response = await this.$whirli.users.childProfiles.create(payload);
      commit(types.ADD_CHILD_PROFILE, response.data);
      dispatch('setChildProfilesWithBucket');
    } catch (error) {
      throw error;
    } finally {
      commit(types.SET_BUSY, false);
    }
  },

  removeChildProfileFromList({ state, dispatch }, childProfileId: string): void {
    const newChildProfiles = state.childProfiles.filter((childProfile: ChildProfile) => {
      if (childProfile?.id === childProfileId) childProfileId = childProfile.id;
      else return childProfile;
    });

    dispatch('deleteChildProfile', {
      childProfileId,
      newChildProfiles,
    });
  },

  async deleteChildProfile({ commit, dispatch }, { childProfileId, newChildProfiles }): Promise<void> {
    commit(types.SET_BUSY, true);
    try {
      await this.$whirli.users.childProfiles.delete(childProfileId);
      commit(types.UPDATE_CHILD_PROFILE_LINES, newChildProfiles);
      dispatch('setChildProfilesWithBucket');
    } catch (error) {
      throw error;
    } finally {
      commit(types.SET_BUSY, false);
    }
  },

  updateChildProfileCheck({ commit }, payload: ChildProfileUpdateCheckPayload): void {
    commit(types.UPDATE_CHILD_PROFILE_CHECK, payload);
  },

  setModalType({ commit }, type: ChildProfileModalType): void {
    commit(types.SET_MODAL_TYPE, type);
  },

  setOriginalExperimentVariant({ commit }, payload: boolean): void {
    commit(types.SET_ORIGINAL_EXPERIMENT_VARIANT, payload);
  },

  async setFiltersForFirstProfile({ dispatch, state }): Promise<void> {
    if (state.childProfilesWithBucket.length > 0 && state.childProfilesWithBucket[0].checked) {
      await dispatch('toggleBucket', state.childProfilesWithBucket[0].bucket);
    }
  },

  async toggleBucket({ dispatch, getters, state, commit }, bucket: SelectedBucket): Promise<void> {
    commit('SET_SELECTED_BUCKET', bucket);
    if ((await getters.getProfilesWithDuplicateFilters.length) > 1) return;
    try {
      this.$gtm.analytics.event('Browse Toys', 'ChildProfileFilter', state.selectedBucket.name);

      await dispatch(
        'products/associations/toggleSelected',
        { selectedBucket: state.selectedBucket },
        { root: true }
      );

      await dispatch('products/makeQuery', { resetCurrentPage: true }, { root: true });
    } catch (error) {
      throw error;
    }
  },
};

export const mutations: MutationTree<ModuleState> = {
  ...lazyLoadableMutations,
  [types.PUT_CHILD_PROFILES](state, childProfiles: ChildProfile[]) {
    state.childProfiles = childProfiles;
  },
  [types.PUT_CHILD_PROFILES_WITH_BUCKET](state, childProfiles: ChildProfile[]) {
    state.childProfilesWithBucket = profileFilterBuilder.setupChildProfiles(
      childProfiles
    ) as ChildProfileWithBucket[];
  },
  [types.ADD_CHILD_PROFILE](state, childProfile) {
    state.childProfiles.push(childProfile);
  },
  [types.SET_BUSY](state, payload) {
    state.busy = payload;
  },
  [types.UPDATE_CHILD_PROFILE_LINES](state, newChildProfiles) {
    state.childProfiles = newChildProfiles;
  },
  [types.UPDATE_CHILD_PROFILE_CHECK](state, payload: ChildProfileUpdateCheckPayload) {
    const index: number = state.childProfilesWithBucket.findIndex(
      (profile: ChildProfileWithBucket) => profile.id === payload.childProfileId
    );
    state.childProfilesWithBucket[index].checked = payload.disable
      ? false
      : !state.childProfilesWithBucket[index].checked;
  },
  [types.SET_MODAL_TYPE](state, type: ChildProfileModalType) {
    state.modalType = type;
  },
  [types.SET_ORIGINAL_EXPERIMENT_VARIANT](state, payload: boolean) {
    state.isOriginalVariant = payload;
  },
  [types.SET_SELECTED_BUCKET](state, bucket: SelectedBucket) {
    state.selectedBucket = bucket;
  },
};

export const getters: GetterTree<ModuleState, RootState> = {
  formatNamesForResultsMeta(state): string {
    if (!state.childProfiles.length) return '';
    const names: string[] = [];
    state.childProfilesWithBucket.map((profile: ChildProfile) => {
      if (profile.checked) return names.push(profile.name);
    });

    const formattedNames = [names.slice(0, -1).join(', '), names.slice(-1)[0]].join(
      names.length < 2 ? '' : ' and '
    );
    return names.length ? 'for ' + formattedNames : '';
  },

  getNextProfileIcon(state): string {
    if (!state.childProfilesWithBucket.length) return 'ProfileAlligator';
    const lastProfile: ChildProfileWithBucket =
      state.childProfilesWithBucket[state.childProfilesWithBucket.length - 1];
    const profileIcons: string[] = profileFilterBuilder.profileIconsList;

    const index: number = profileIcons.indexOf(lastProfile.icon as string);
    if (index === profileIcons.length - 1) {
      return profileIcons[0];
    }
    return profileIcons[index + 1];
  },

  getProfilesWithDuplicateFilters(state): ChildProfileWithBucket[] {
    return state.childProfilesWithBucket.filter((profile: ChildProfileWithBucket) => {
      return profile.checked === true && profile.bucket.slug === state.selectedBucket.slug;
    });
  },
};
