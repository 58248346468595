import { hasOwnProp } from '~/helpers/objects';

export interface Theme {
  background: string;
  foreground: string;
  accent?: string;
  tinyForeground?: string;
}

export type ThemeParts = keyof Theme;

export type ThemeMap = Record<string, Theme>;

/**
 * Get the hex color codes that are used throughout the project.
 * These colors and names should match with
 * assets/scss/settings/_settings.colors.scss.
 */
export function colorFromTheme(colorName: string): string {
  const colors: Record<string, string> = {
    primary: '#70E2D2',
    'primary-light': '#A9EEE4',
    'primary-dark': '#20B7A8',
    secondary: '#3A0E7B',
    'secondary-light': '#5D3E99',
    'secondary-dark': '#280B5F',
    tertiary: '#FFF24F',
    'tertiary-light': '#FFF795',
    'tertiary-dark': '#F4E131',
    quaternary: '#FFB8ED',
    'quaternary-dark': '#F7A9E7',
    quinary: '#E8005F',
    'quinary-dark': '#D80062',
    'quinary-darker': '#B20443',
    black: '#000000',
    charcoal: '#4D4D4D',
    'grey-one': '#949494',
    'grey-two': '#CDCDCD',
    'light-grey': '#E2E2E2',
    'light-grey-one': '#E2E2E2',
    'light-grey-two': '#F9F9F9',
    transparent: 'transparent',
    white: '#FFFFFF',
  };

  if (!hasOwnProp(colors, colorName)) {
    throw new Error(`Color ${colorName} not found in color map.`);
  }

  return colors[colorName];
}

export const themeMap: ThemeMap = {
  primary: {
    background: 'primary',
    foreground: 'secondary',
    tinyForeground: 'charcoal',
  },
  'primary-dark': {
    background: 'primary-dark',
    foreground: 'secondary',
  },
  'primary-light': {
    background: 'primary-light',
    foreground: 'white',
  },
  secondary: {
    background: 'secondary',
    foreground: 'white',
    accent: 'tertiary',
  },
  'secondary-dark': {
    background: 'secondary-dark',
    foreground: 'white',
  },
  'secondary-light': {
    background: 'secondary-light',
    foreground: 'white',
  },
  tertiary: {
    background: 'tertiary',
    foreground: 'secondary',
    tinyForeground: 'charcoal',
  },
  'tertiary-darker': {
    background: 'tertiary-darker',
    foreground: 'secondary',
    tinyForeground: 'charcoal',
  },
  quaternary: {
    background: 'quaternary',
    foreground: 'secondary',
    tinyForeground: 'charcoal',
  },
  quinary: {
    background: 'quinary',
    foreground: 'white',
  },
  'quinary-darker': {
    background: 'quinary-darker',
    foreground: 'white',
  },
  white: {
    background: 'white',
    foreground: 'secondary',
  },
  charcoal: {
    background: 'charcoal',
    foreground: 'white',
  },
  'light-grey-one': {
    background: 'light-grey-one',
    foreground: 'secondary',
  },
  transparent: {
    background: 'transparent',
    foreground: 'transparent',
  },
};
