var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "v-whirli-logo" },
    [
      _vm.active
        ? _c(
            "WhirliLogoFrames",
            _vm._b(
              {
                attrs: { "current-frame": _vm.currentFrame },
                on: { loaded: _vm.logoLoaded }
              },
              "WhirliLogoFrames",
              _vm.colorAttrs,
              false
            )
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.active || !_vm.framesLoaded
        ? _c(
            "WhirliLogoPlaceholder",
            _vm._b({}, "WhirliLogoPlaceholder", _vm.colorAttrs, false)
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }