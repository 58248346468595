var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseCarousel", {
    key: _vm.componentKey,
    staticClass: "v-simple-carousel",
    class: {
      "v-simple-carousel--overflow-visible": _vm.overflowVisible && !_vm.isDesk
    },
    attrs: {
      loop: false,
      name: _vm.name,
      "navigation-top": "",
      options: _vm.options,
      "slides-per-view": _vm.slidesPerView,
      "initial-slide": _vm.initialSlide,
      "centered-slides": _vm.centeredSlides
    },
    scopedSlots: _vm._u(
      [
        {
          key: "navigation",
          fn: function() {
            return [
              !_vm.isDesk
                ? _c(
                    "div",
                    { staticClass: "v-simple-carousel__navigation-container" },
                    [
                      _c("ButtonIcon", {
                        staticClass:
                          "v-simple-carousel__btn v-simple-carousel__btn-previous",
                        attrs: {
                          id: _vm.name + "-prev",
                          "aria-label": "previous slide",
                          direction: "left",
                          icon: "ArrowCenter",
                          size: _vm.navBtnSize,
                          theme: "charcoal",
                          type: "button"
                        }
                      }),
                      _vm._v(" "),
                      _vm.navigationText
                        ? _c("BaseText", { staticClass: "u-color-charcoal" }, [
                            _vm._v(_vm._s(_vm.navigationText))
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("ButtonIcon", {
                        staticClass:
                          "v-simple-carousel__btn v-simple-carousel__btn-next",
                        attrs: {
                          id: _vm.name + "-next",
                          "aria-label": "next slide",
                          direction: "right",
                          icon: "ArrowCenter",
                          size: _vm.navBtnSize,
                          theme: "charcoal",
                          type: "button"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          },
          proxy: true
        },
        {
          key: "slides",
          fn: function() {
            return [_vm._t("default")]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }