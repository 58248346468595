const middleware = {}

middleware['maintenance'] = require('../middleware/maintenance.js')
middleware['maintenance'] = middleware['maintenance'].default || middleware['maintenance']

middleware['redirectHomePage'] = require('../middleware/redirectHomePage.js')
middleware['redirectHomePage'] = middleware['redirectHomePage'].default || middleware['redirectHomePage']

middleware['redirectIfCannotChangeSubscription'] = require('../middleware/redirectIfCannotChangeSubscription.js')
middleware['redirectIfCannotChangeSubscription'] = middleware['redirectIfCannotChangeSubscription'].default || middleware['redirectIfCannotChangeSubscription']

middleware['redirectIfNoSelectedSubscription'] = require('../middleware/redirectIfNoSelectedSubscription.js')
middleware['redirectIfNoSelectedSubscription'] = middleware['redirectIfNoSelectedSubscription'].default || middleware['redirectIfNoSelectedSubscription']

middleware['redirectInProduction'] = require('../middleware/redirectInProduction.js')
middleware['redirectInProduction'] = middleware['redirectInProduction'].default || middleware['redirectInProduction']

middleware['redirectLoggedInUsers'] = require('../middleware/redirectLoggedInUsers.js')
middleware['redirectLoggedInUsers'] = middleware['redirectLoggedInUsers'].default || middleware['redirectLoggedInUsers']

middleware['redirectLoggedInUsersWithActiveSubscription'] = require('../middleware/redirectLoggedInUsersWithActiveSubscription.js')
middleware['redirectLoggedInUsersWithActiveSubscription'] = middleware['redirectLoggedInUsersWithActiveSubscription'].default || middleware['redirectLoggedInUsersWithActiveSubscription']

middleware['redirectLoggedInUsersWithNoActiveSubscription'] = require('../middleware/redirectLoggedInUsersWithNoActiveSubscription.js')
middleware['redirectLoggedInUsersWithNoActiveSubscription'] = middleware['redirectLoggedInUsersWithNoActiveSubscription'].default || middleware['redirectLoggedInUsersWithNoActiveSubscription']

middleware['redirectReferral'] = require('../middleware/redirectReferral.js')
middleware['redirectReferral'] = middleware['redirectReferral'].default || middleware['redirectReferral']

middleware['redirectRegistrationStep'] = require('../middleware/redirectRegistrationStep.js')
middleware['redirectRegistrationStep'] = middleware['redirectRegistrationStep'].default || middleware['redirectRegistrationStep']

middleware['redirectRegistrationStepAccount'] = require('../middleware/redirectRegistrationStepAccount.js')
middleware['redirectRegistrationStepAccount'] = middleware['redirectRegistrationStepAccount'].default || middleware['redirectRegistrationStepAccount']

middleware['redirectRegistrationStepBilling'] = require('../middleware/redirectRegistrationStepBilling.js')
middleware['redirectRegistrationStepBilling'] = middleware['redirectRegistrationStepBilling'].default || middleware['redirectRegistrationStepBilling']

export default middleware
