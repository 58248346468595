









import { Vue, Component, Prop, namespace } from 'nuxt-property-decorator';
import ButtonIcon from '~/components/partials/icons/ButtonIcon/ButtonIcon.vue';
import { SelectedFilter as SF } from '~/store/products/filters';
import { ProductAssociationMeta, ProductAssociationMetas } from '~/types/products/associations';

// Define interfaces or types used in the component
interface SelectedFilter extends SF {}

const AssociationsStore = namespace('products/associations');

interface FilteredItem {
  key: string;
  name: string;
  slug: string;
  id: string;
  internalName: string;
}

@Component({
  components: { ButtonIcon },
})
export default class FiltersTag extends Vue {
  @Prop({ required: true }) readonly selectedFilter!: SelectedFilter;

  @AssociationsStore.State current!: ProductAssociationMetas;

  get associations(): ProductAssociationMeta[] {
    if (!this.current) return [];
    return Object.values(this.current).filter((association) => association.active);
  }

  removeFilter() {
    this.$emit('filter:remove', this.selectedFilter);
  }

  mounted() {
    console.log('filter:remove', this.selectedFilter);
  }

  get filterText(): string {
    // Association
    if (this.selectedFilter.name) return this.selectedFilter.name;

    // Implement logic to return appropriate filter text based on selectedFilter
    if (this.selectedFilter.type) {
      const typeName: string =
        this.selectedFilter.type === 'current_value' ? 'tokens' : this.selectedFilter.type;
      const upperRange: string = this.selectedFilter.to ? this.selectedFilter.to.toString() : '100+';
      return `${this.selectedFilter.from}-${upperRange} ${typeName}`;
    }
    // Search term
    if (this.selectedFilter.search) return this.selectedFilter.search;
    return '';
  }
}
