import { Plugin } from '@nuxt/types';
import { isset } from '~/helpers/validators';

const plugin: Plugin = function({ app, req, route }): void {
  const COOKIE_TIME: number = 604800; // 7 DAYS
  const referrer = process.client ? window.document.referrer : req.headers.referer;
  const hostname = process.client ? location.hostname : req.headers.host;
  let clearCookies: boolean = false;

  const queryParams: string[] = [
    'discountCode',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'gclid',
    'tduid',
  ];

  queryParams.forEach((queryParam) => {
    if (isset(route.query[queryParam])) clearCookies = true;
  });

  if (clearCookies) {
    app.$cookies.remove('referrer');
  }

  if (isset(referrer) && isset(hostname)) {
    // @ts-ignore We check already for undefined in isset()
    if (!referrer.includes(hostname)) {
      clearCookies = true;
      app.$cookies.set('referrer', referrer, COOKIE_TIME);
    }
  }

  queryParams.forEach((queryParam) => {
    if (clearCookies) {
      app.$cookies.remove(queryParam);
    }

    if (isset(route.query[queryParam])) {
      app.$cookies.set(queryParam, route.query[queryParam], COOKIE_TIME);
    }
  });
};

export default plugin;
