import { hasActiveSubscription } from '~/helpers/models/user';
import {
  SELECT_AGE_STEP,
  PLAN_RECOMMENDER_STEP,
  DELIVERY_BOOSTER_STEP,
  USER_DETAILS_STEP,
  BILLING_STEP,
  SUCCESS_STEP,
} from '~/constants/registration-steps-new';

/**
 * Knowing what step the user is on (https://whirli.atlassian.net/browse/WAPI-196):
 * Step 1 - User is not logged in.
 * Step 2 - user_subscriptions relationship on the user object is empty or doesn’t exist. We will not return cancelled subscriptions in the response.
 * Step 3 - User has user_subscriptions relationship assigned but none of them is active. When userSubscription is not active status_id column will be equal to 0.
 *
 * @todo Type the user object, using the types from whirli-client
 */
export function getCurrentStep(
  user: any,
  selectedPricingPlan: any,
  addons: any,
  basketCount: number
): number {
  // If there's no user account but they've selected a pricing plan in another route (aka '/toys')
  if (!user && selectedPricingPlan && selectedPricingPlan.id && !addons.length) return DELIVERY_BOOSTER_STEP;

  // If there's no user account or selected pricing plan but they've added a toy to the basket on browse-toys
  if (!user && !selectedPricingPlan && basketCount > 0) return PLAN_RECOMMENDER_STEP;

  // If they've already selected a plan and delivery booster but not created an account
  if (!user && selectedPricingPlan && selectedPricingPlan.id && addons.length) return USER_DETAILS_STEP;

  // If there is no user account or any selected subscriptions or anything in the basket
  if (!user) return SELECT_AGE_STEP;

  // If they've created an account but the subscription hasn't been set in the BE (they're changing their choice for ex)
  if (!user.userSubscriptions || !user.userSubscriptions.length || !hasActiveSubscription(user))
    return PLAN_RECOMMENDER_STEP;

  if (user.userSubscriptions[0].statusId === 0) return BILLING_STEP;

  return SUCCESS_STEP;
}

export function registrationPageCheck(routeName: any): boolean {
  if (!routeName) return false;
  return routeName.split('-')[0] === 'registration';
}

export function subTermsAndConditionsPageCheck(routeName: any): boolean {
  if (!routeName) return false;
  return routeName === 'subscription-terms-and-conditions';
}
