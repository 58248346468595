






















import { Vue, Component, Prop } from 'nuxt-property-decorator';

interface Attributes {
  to?: string;
}

@Component
export default class BaseMediaItem extends Vue {
  @Prop({ default: null }) readonly url!: string;

  get bodyTag(): string {
    return this.url ? 'BaseLink' : 'div';
  }

  get attributes(): Attributes {
    const attributes: Attributes = {};
    if (this.url) attributes.to = this.url;
    return attributes;
  }
}
