var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShown
    ? _c(
        "div",
        { staticClass: "v-base-banner" },
        [
          _c(
            "BaseText",
            {
              staticClass: "v-base-banner__text",
              class: {
                "v-base-banner__text--close-active": _vm.canClose
              },
              attrs: {
                size: _vm.size,
                uppercase: _vm.uppercase,
                color: _vm.textColor
              }
            },
            [_vm._t("default")],
            2
          ),
          _vm._v(" "),
          _vm.canClose
            ? _c(
                "button",
                {
                  staticClass: "v-base-banner__close",
                  attrs: { "aria-label": "close" },
                  on: { click: _vm.closeBanner }
                },
                [
                  _c("BaseIcon", {
                    attrs: { icon: "Cross", size: "mini", theme: _vm.iconTheme }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }