var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseInput", {
    attrs: {
      id: _vm.id,
      errors: _vm.errors,
      name: _vm.name,
      required: _vm.required
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function() {
            return [_vm._t("default")]
          },
          proxy: true
        },
        {
          key: "input",
          fn: function(slotProps) {
            return [
              _c(
                "div",
                {
                  staticClass: "v-input__input-container",
                  class: _vm.inputClasses
                },
                [
                  _c(
                    "input",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "v-input__text-input",
                          class: _vm.inputClasses,
                          attrs: {
                            id: _vm.id,
                            "aria-describedby": slotProps.errorId,
                            "aria-label": _vm.ariaLabel,
                            "aria-labelledby": _vm.ariaLabelledby,
                            autocomplete: _vm.autocomplete,
                            name: _vm.name,
                            placeholder: _vm.placeholder,
                            required: _vm.required,
                            tabindex: _vm.tabindex,
                            type: _vm.type,
                            maxlength: _vm.maxLength
                          }
                        },
                        "input",
                        _vm.$attrs,
                        false
                      ),
                      _vm.inputListeners
                    )
                  ),
                  _vm._v(" "),
                  _vm.$slots.icon
                    ? _vm._t("icon")
                    : _vm.errors.length
                    ? _c(
                        "span",
                        { staticClass: "v-input__icon" },
                        [
                          _c("DiscIcon", {
                            attrs: {
                              icon: "Info",
                              size: "small",
                              theme: "quinary-darker"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }