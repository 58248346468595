import { CheckoutConfig } from '~/types/basket';

// Messages from API:
// https://github.com/whirli/whirli-api/blob/master/app/Constants/CheckoutStatusMessage.php
export const CHECKOUT_OK = 'Proceed to checkout';
export const CHECKOUT_RETURN_TOYS = 'Only returning toys';
export const BASKET_EMPTY = 'The basket is empty';
export const ITEM_OUT_OF_STOCK_MESSAGE = 'Basket contains out of stock toy';
export const ITEMS_OUT_OF_STOCK_MESSAGE = 'Basket contains out of stock toys';
export const USER_NOT_LOGGED_IN = 'You are not logged in';
export const USER_SUBSCRIPTION_NOT_ACTIVE = "You don't have an active subscription";
export const NOT_ENOUGH_TOKENS = "You don't have enough tokens";
export const ORDER_ALREADY_IN_PROGRESS = 'You have an active order';
export const RTRN_ALREADY_IN_PROGRESS = 'You have an active return';

// UWA user facing messages:
export const checkoutMessage: { [key: string]: CheckoutConfig } = {
  [CHECKOUT_OK]: { text: CHECKOUT_OK, href: '/checkout', disabled: false },
  [CHECKOUT_RETURN_TOYS]: { text: 'Return Toys', href: '/checkout', disabled: false },
  [BASKET_EMPTY]: { text: 'Add toys', href: '/toys', disabled: false },
  [ITEM_OUT_OF_STOCK_MESSAGE]: { text: ITEM_OUT_OF_STOCK_MESSAGE, href: '', disabled: true },
  [ITEMS_OUT_OF_STOCK_MESSAGE]: { text: ITEMS_OUT_OF_STOCK_MESSAGE, href: '', disabled: true },
  [USER_NOT_LOGGED_IN]: { text: 'Log in / Register', href: '/register', disabled: false },
  [USER_SUBSCRIPTION_NOT_ACTIVE]: { text: 'Try Whirli', href: '/register', disabled: false },
  [NOT_ENOUGH_TOKENS]: {
    text: 'Insufficient tokens. Click to see plans and upgrade',
    href: '/account/change',
    disabled: false,
  },
  [ORDER_ALREADY_IN_PROGRESS]: { text: ORDER_ALREADY_IN_PROGRESS, href: '', disabled: true },
  [RTRN_ALREADY_IN_PROGRESS]: { text: RTRN_ALREADY_IN_PROGRESS, href: '', disabled: true },
};

export const defaultCheckoutConfig: CheckoutConfig = checkoutMessage[BASKET_EMPTY];
