import { SortOption, AvailableSortOptions } from '~/types/products/sort';
import { featureToggleConfig } from '~/config/nuxt/modules/featureToggle';

const oldOptions: Array<SortOption> = [
  {
    text: 'Most Popular (Default)',
    sort: {
      orderBy: 'popularity',
      sortedBy: 'desc',
    },
    default: true,
  },
  {
    text: 'Alphabetically: A > Z',
    sort: {
      orderBy: 'name',
      sortedBy: 'asc',
    },
  },
  {
    text: 'Alphabetically: Z > A',
    sort: {
      orderBy: 'name',
      sortedBy: 'desc',
    },
  },
  {
    text: 'Tokens: Low > High',
    sort: {
      orderBy: 'tokens',
      sortedBy: 'asc',
    },
  },
  {
    text: 'Tokens: High > Low',
    sort: {
      orderBy: 'tokens',
      sortedBy: 'desc',
    },
  },
  {
    text: 'Recently Added',
    sort: {
      orderBy: 'published_at',
      sortedBy: 'desc',
    },
  },
];

const reviewOptions: Array<SortOption> = [
  {
    text: 'Most Popular (Default)',
    sort: {
      orderBy: 'most_popular_score',
      sortedBy: 'desc',
    },
    default: true,
  },
  {
    text: 'Avg Toy Rating',
    sort: {
      orderBy: 'aggregate_rating',
      sortedBy: 'desc',
    },
  },
  {
    text: 'Recently Added',
    sort: {
      orderBy: 'published_at',
      sortedBy: 'desc',
    },
  },
  {
    text: 'Tokens: Low > High',
    sort: {
      orderBy: 'current_value',
      sortedBy: 'asc',
    },
  },
  {
    text: 'Tokens: High > Low',
    sort: {
      orderBy: 'current_value',
      sortedBy: 'desc',
    },
  },
];

const newOptions: Array<SortOption> = [
  {
    text: 'Most Popular (Default)',
    sort: {
      orderBy: 'most_popular_score',
      sortedBy: 'desc',
    },
    default: true,
  },
  {
    text: 'Alphabetically: A > Z',
    sort: {
      orderBy: 'name',
      sortedBy: 'asc',
    },
  },
  {
    text: 'Alphabetically: Z > A',
    sort: {
      orderBy: 'name',
      sortedBy: 'desc',
    },
  },
  {
    text: 'Tokens: Low > High',
    sort: {
      orderBy: 'tokens',
      sortedBy: 'asc',
    },
  },
  {
    text: 'Tokens: High > Low',
    sort: {
      orderBy: 'tokens',
      sortedBy: 'desc',
    },
  },
  {
    text: 'Recently Added',
    sort: {
      orderBy: 'published_at',
      sortedBy: 'desc',
    },
  },
];

export const options: Array<SortOption> = getOptions();
export const selectOptions: (option?: AvailableSortOptions) => Array<SortOption> = selectOptionsList;

// The below vars are designed to allow us to easily move sort options to the API later and still be able to get the right default option
const defaultSortOption: SortOption | undefined = options.find((sort) => sort.default === true);

export const defaultSelected: SortOption = defaultSortOption || options[0];

function selectOptionsList(option?: AvailableSortOptions): Array<SortOption> {
  if (option === 'old') return oldOptions;
  if (option === 'review') return reviewOptions;
  if (option === 'most_popular_score') return newOptions;
  return getOptions();
}

function getOptions(): Array<SortOption> {
  if (featureToggleConfig.toggles.reviewToysPage) {
    return reviewOptions;
  } else {
    return featureToggleConfig.toggles.popularScore ? newOptions : oldOptions;
  }
}
