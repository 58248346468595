import { baseStyle } from './styles';

let stripe;
const publicKey = process.env.stripePublicKey;

function createElementsContainer() {
  return stripe.elements();
}

function createElement(elementsContainer, elementType, options) {
  options.style = Object.assign({}, baseStyle, options.style || {});

  return elementsContainer.create(elementType, options);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default async (ctx, inject) => {
  // eslint-disable-next-line no-console
  if (!publicKey) console.warn('No stripe key found in nuxt env - not initialising Stripe');

  const loadStripe = require('@stripe/stripe-js').loadStripe;

  stripe = await loadStripe(publicKey || '');

  stripe = Object.assign(stripe, { createElementsContainer, createElement });

  inject('stripe', stripe);
};
