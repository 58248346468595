var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BlockAccordion",
    {
      staticClass: "v-token-slider-accordion",
      attrs: { theme: "light-grey-two", name: "tokens", "is-open": _vm.isOpen },
      on: {
        "toggle-accordion": function($event) {
          return _vm.$emit("toggle-accordion")
        }
      }
    },
    [
      _c(
        "BaseText",
        {
          attrs: { slot: "header", size: "button", color: "secondary" },
          slot: "header"
        },
        [_vm._v("\n    Tokens\n  ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: {
            "v-token-slider-accordion__body": true,
            "v-token-slider-accordion__body--busy": _vm.busy
          },
          attrs: { slot: "body" },
          slot: "body"
        },
        [
          _c("BaseRangeSlider", {
            attrs: {
              value: _vm.rangeSliderValues,
              min: _vm.rangeStart,
              max: _vm.rangeEnd,
              disabled: _vm.busy
            },
            on: { "range-slider:change": _vm.setTokensRange }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }