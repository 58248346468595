import { isExpired } from '~/helpers/date';

export interface StorageItem<Data> {
  data: Data;
  maxAge: number | null;
  createdAt: number;
}

export function dataWithExpiry<Data>(data: Data, maxAge: number | null = null): StorageItem<Data> {
  return {
    data,
    maxAge,
    createdAt: Date.now(),
  };
}

export function dataOrExpired<Data>(storageItem: StorageItem<Data> | null): Data | null {
  if (storageItem && storageItem.maxAge && isExpired(storageItem.createdAt, storageItem.maxAge)) {
    storageItem = null;
  }

  return storageItem ? storageItem.data : null;
}
