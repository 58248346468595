
import 'reflect-metadata';
import { Vue, Component, namespace } from 'nuxt-property-decorator';
import { Campaign } from '~/types/campaigns';

import { isFlagSetForQATestDate, getQATestDate } from '~/helpers/campaigns';
import { getCurrentDateAsNumber, getDateFromStringAsNumber } from '~/helpers/date-time';

import { Referral } from '~/types/referral';
import { ReferralReferer, ReferralReferee, ReferralExample } from '~/constants/referral';

const CampaignsStore = namespace('campaigns');
const ReferStore = namespace('refer');

@Component
export default class CampaignsMixin extends Vue {
  @CampaignsStore.State doubleUpReferral!: Campaign;
  @CampaignsStore.Getter currentDate!: (campaignKey: string) => any;
  @ReferStore.State('userType') userType!: number;

  isCampaignActive(campaign: Campaign): boolean {
    const currentDate: number = isFlagSetForQATestDate(this.$route, campaign.key)
      ? getQATestDate(this.$route)
      : getCurrentDateAsNumber();
    const campaignStart: number = getDateFromStringAsNumber(campaign.start);
    const campaignEnd: number = getDateFromStringAsNumber(campaign.end);

    return (
      currentDate >= campaignStart && currentDate <= campaignEnd && campaign.userType === this.userTypeId
    );
  }

  get isDoubleUpReferralCampaignActive(): boolean {
    return this.isCampaignActive(this.doubleUpReferral);
  }

  get userTypeId(): number {
    return this.$auth.user ? this.$auth.user.typeId : this.userType;
  }

  get doubleUpCampaignBonuses(): Referral {
    return {
      referer: {
        monetary: this.isDoubleUpReferralCampaignActive ? '£20' : ReferralReferer[this.userTypeId].MONETARY,
      },
      referee: {
        monetary: this.isDoubleUpReferralCampaignActive ? '£5' : ReferralReferee[this.userTypeId].MONETARY,
        percentage: this.isDoubleUpReferralCampaignActive
          ? '15%'
          : ReferralReferee[this.userTypeId].PERCENTAGE,
      },
      example: {
        preOffer: ReferralExample.PRE_OFFER,
        postOffer: this.isDoubleUpReferralCampaignActive ? '£6.30' : ReferralExample.POST_OFFER,
      },
    };
  }
}
