export function capitalizeFirstLetter(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function sanitizeText(text: string): string {
  return text
    .toUpperCase()
    .split(' ')
    .join('-');
}
