






import { Vue, Prop, Component } from 'nuxt-property-decorator';
import { Size } from '~/helpers/sizes';
import { padding } from '~/helpers/styles';

type PaddingProperties = Size | Array<Size>;

@Component
export default class BaseBox extends Vue {
  @Prop({ default: 'div' }) readonly tag!: string;
  @Prop({ default: 'base', type: [String, Array] }) readonly padding!: PaddingProperties;

  get classes(): Array<string> {
    if (this.padding.length === 1 || typeof this.padding === 'string') {
      return [padding(this.padding as string)];
    }
    if (this.padding.length === 2) {
      return [
        padding(this.padding[0], 'top'),
        padding(this.padding[1], 'right'),
        padding(this.padding[0], 'bottom'),
        padding(this.padding[1], 'left'),
      ];
    }
    if (this.padding.length === 3) {
      return [
        padding(this.padding[0], 'top'),
        padding(this.padding[1], 'right'),
        padding(this.padding[2], 'bottom'),
        padding(this.padding[1], 'left'),
      ];
    }
    if (this.padding.length === 4) {
      return [
        padding(this.padding[0], 'top'),
        padding(this.padding[1], 'right'),
        padding(this.padding[2], 'bottom'),
        padding(this.padding[3], 'left'),
      ];
    }
    return [];
  }
}
