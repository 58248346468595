var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.disabledNavigation
    ? _c("footer", { staticClass: "v-footer" }, [
        _c(
          "div",
          { staticClass: "v-footer__container" },
          [
            _c("BaseWrapper", [
              _c(
                "div",
                { staticClass: "v-footer__body" },
                [
                  _c(
                    "BaseLayout",
                    { attrs: { "fill-direction": "between" } },
                    [
                      _c(
                        "BaseLayoutItem",
                        { attrs: { r: "1/1", "small-desk": "2/12" } },
                        [
                          _c(
                            "div",
                            { staticClass: "v-footer__body-brand" },
                            [
                              _c("WhirliLogo", {
                                staticClass: "v-logo",
                                attrs: {
                                  background: "primary",
                                  foreground: "tertiary"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("BaseVerticalSpacer", {
                            attrs: {
                              default: "big",
                              tablet: "large",
                              "small-desk": "none"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "BaseLayoutItem",
                        { attrs: { r: "1/1", "small-desk": "8/12" } },
                        [
                          _c(
                            "div",
                            { staticClass: "v-footer__body-content" },
                            [
                              _c("BaseAccordionGroup", {
                                staticClass: "v-footer__body-content-sections",
                                attrs: {
                                  "fix-open-breakpoint": "tablet",
                                  single: true
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var group = ref.group
                                        return _vm._l(_vm.footerLinks, function(
                                          section,
                                          index
                                        ) {
                                          return _c(
                                            "FlatAccordion",
                                            {
                                              key: index,
                                              attrs: {
                                                "arrow-theme": group.list.includes(
                                                  section.name
                                                )
                                                  ? "primary"
                                                  : "white",
                                                name: section.name,
                                                "is-open": group.list.includes(
                                                  section.name
                                                ),
                                                "is-fixed-open":
                                                  group.fixAccordionsOpen,
                                                "border-theme": group.fixAccordionsOpen
                                                  ? "none"
                                                  : "secondary-light",
                                                size: "small"
                                              },
                                              on: {
                                                "toggle-accordion": function(
                                                  $event
                                                ) {
                                                  return group.update(
                                                    section.name
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "h5",
                                                {
                                                  class:
                                                    "u-text--sub-header-alt u-color-" +
                                                    (group.list.includes(
                                                      section.name
                                                    ) || group.fixAccordionsOpen
                                                      ? "primary"
                                                      : "white"),
                                                  attrs: { slot: "header" },
                                                  slot: "header"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(section.header) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "BaseBox",
                                                {
                                                  attrs: {
                                                    slot: "body",
                                                    padding: [
                                                      "base",
                                                      "none",
                                                      "none",
                                                      "none"
                                                    ]
                                                  },
                                                  slot: "body"
                                                },
                                                [
                                                  _c(
                                                    "BaseList",
                                                    { attrs: { bare: true } },
                                                    _vm._l(
                                                      section.links,
                                                      function(
                                                        link,
                                                        childIndex
                                                      ) {
                                                        return _c(
                                                          "BaseListItem",
                                                          {
                                                            key: childIndex,
                                                            class: {
                                                              "u-margin-bottom-tiny":
                                                                childIndex !==
                                                                section.links
                                                                  .length -
                                                                  1
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "BaseLink",
                                                              {
                                                                staticClass:
                                                                  "u-text--body",
                                                                attrs: {
                                                                  to: link.to
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    link.text
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        })
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1038867630
                                )
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("BaseVerticalSpacer", {
                    attrs: {
                      default: "intermediate",
                      tablet: "huge",
                      "small-desk": "massive"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "v-footer__footer" },
                [
                  _c(
                    "BaseList",
                    {
                      staticClass: "v-footer__social-links",
                      attrs: { bare: true }
                    },
                    _vm._l(_vm.socialLinks, function(link, index) {
                      return _c(
                        "BaseListItem",
                        { key: index, staticClass: "v-footer__social-link" },
                        [
                          _c("ButtonIcon", {
                            attrs: {
                              tag: "a",
                              href: link.to,
                              icon: link.icon,
                              "aria-label": link.icon,
                              target: "_blank",
                              rel: "noopener nofollow",
                              theme: "white",
                              size: "medium"
                            }
                          })
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("PaymentMethods", {
                    staticClass: "v-footer__payment-methods"
                  }),
                  _vm._v(" "),
                  _c(
                    "BaseText",
                    { attrs: { color: "white", size: "small-body" } },
                    [
                      _vm._v(
                        "\n          © " +
                          _vm._s(_vm.currentYear) +
                          " Whirli. All rights reserved.\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }