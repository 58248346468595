import Vue from 'vue';
import { NUMBER_FORMATS } from '~/constants/number-formats';
import { ddMyyyy, ddmmyyyy } from '~/helpers/date-time';

const locale = 'en-GB';
const currency = NUMBER_FORMATS[locale].currency;

export function tokensToPrice(value: number): string {
  return filterPenniesToPounds(value * 100);
}

export function filterCurrency(value: string | number): string {
  return `${currency.symbol}${value}`;
}

export function filterPenniesToPounds(value: number | string): string {
  if (typeof value === 'string') return value;

  return (value / 100).toFixed(2);
}

export function filterParseFixedPenniesToPounds(value: number | string): string {
  if (typeof value === 'string') return value;

  return (value / 100).toFixed(2);
}

export function costBreakdown(price: number | string, zeroCostText = 'FREE'): string {
  return price === 0 ? zeroCostText : filterCurrency(filterPenniesToPounds(price));
}

export function dateFormat(date: string | Date): string {
  return ddMyyyy(date) || '-';
}

export function dateFormatLongform(date: string | Date): string {
  return ddMyyyy(date, true) || '-';
}

export function dateToDDMMYYYY(date: string | Date): string {
  return ddmmyyyy(date) || '-';
}

Vue.filter('dateFormat', dateFormat);
Vue.filter('dateFormatLongform', dateFormatLongform);
Vue.filter('tokensToPrice', tokensToPrice);
Vue.filter('currency', filterCurrency);
Vue.filter('penniesToPounds', filterPenniesToPounds);
Vue.filter('parseFixedPenniesToPound', filterParseFixedPenniesToPounds);
Vue.filter('costBreakdown', costBreakdown);
Vue.filter('dateToDDMMYYYY', dateToDDMMYYYY);
