// @todo remove when whirli-client@1.0.0^
// eslint-disable-next-line import/no-named-default
import { default as ClientAssetTransform } from 'whirli-client/dist/Interfaces/asset/AssetTransform';
import Asset from 'whirli-client/dist/Interfaces/asset/Asset';
import { AssetTransform } from '~/types/products/products';
import { AssetSize } from '~/types/asset';
import { getImage } from '~/helpers/models/product';

export function url(assetTransform: AssetTransform): string | null {
  return assetTransform.url || null;
}

export function getTransformUrlFromAsset(asset: Asset, size: AssetSize): string | null {
  if (!asset?.transforms?.length) return null;

  const assetTransform: ClientAssetTransform | undefined = asset.transforms?.find(
    (assetTransform: ClientAssetTransform) => assetTransform.size && assetTransform.size === size
  );

  return assetTransform?.url ?? getImage([asset]);
}
