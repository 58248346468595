var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 32 32" } },
    [
      _c("path", {
        attrs: {
          d:
            "M4.5 8.4c-.3.5-.5 1-.5 1.6v12c0 .5.1 1 .4 1.4l7.6-8.5-7.5-6.5zm14.4 7.5L16.5 18c-.1.1-.3.2-.5.2s-.4-.1-.5-.2l-2.4-2.1-7.7 8.6c.5.3 1 .5 1.6.5h17.9c.6 0 1.1-.2 1.6-.5l-7.6-8.6zm-2.9.6l10.4-9.1c-.5-.3-.9-.4-1.5-.4H7c-.5 0-1 .1-1.4.4L16 16.5zM27.9 22c0 .5-.1 1-.4 1.4L20 15l7.5-6.6c.3.5.5 1 .5 1.6v12z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }