






import 'reflect-metadata';
import { Vue, Component, Watch, Prop, namespace } from 'nuxt-property-decorator';
import { addKeyupEventListener, removeKeyupEventListener } from '~/helpers/keyboard';
import { focusTrap } from '~/helpers/focus-trap';
import { ESCAPE_KEY } from '~/constants/keypress-codes';
import BaseSideDrawer from '~/components/partials/drawers/BaseSideDrawer/BaseSideDrawer.vue';

const drawersStore = namespace('drawers');
const overlayStore = namespace('overlay');
const scrollStore = namespace('scroll');

@Component({
  components: {
    BaseSideDrawer,
  },
})
export default class SideDrawer extends Vue {
  @Prop({ required: true }) readonly name!: string;

  @drawersStore.Action toggleSideDrawer!: Function;
  @drawersStore.State currentSideDrawer!: string | null;
  @drawersStore.State isSideDrawerOpen!: boolean;
  @overlayStore.Action toggleOverlay!: Function;
  @scrollStore.Action setScrollDisabled!: Function;

  @Watch('isOpen')
  setBackgroundAriaHidden() {
    this.$nextTick(() => {
      this.toggleOverlay({ usedBy: this.name });
      this.setScrollDisabled(this.isOpen);

      // Add aria-hidden to <main> for accessibility.
      if (!document) return;
      const main: HTMLElement | null = document.querySelector('main');
      if (!main) return;
      main.setAttribute('aria-hidden', String(this.isOpen));

      // Create focus trap in the SideDrawer for tabbing accessibility.
      const sideDrawer: HTMLElement | null = document.querySelector('#side-drawer');
      if (sideDrawer) focusTrap(sideDrawer);
    });
  }

  mounted() {
    addKeyupEventListener(ESCAPE_KEY, this.close);
    if (this.isOpen) this.setBackgroundAriaHidden();
  }

  destroyed() {
    removeKeyupEventListener(ESCAPE_KEY, this.close);
  }

  close() {
    this.toggleSideDrawer({ isOpen: false });
  }

  get isOpen(): boolean {
    return this.currentSideDrawer === this.name && this.isSideDrawerOpen;
  }
}
