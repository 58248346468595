











import { Component, Prop, Vue } from 'nuxt-property-decorator';

type Spacing =
  | 'micro'
  | 'mini'
  | 'teeny'
  | 'tiny'
  | 'small'
  | 'base'
  | 'medium'
  | 'intermediate'
  | 'big'
  | 'large'
  | 'huge'
  | 'massive'
  | 'immense';

@Component
export default class BaseVerticalSpacer extends Vue {
  @Prop({ default: false, type: [String, Boolean] }) readonly default!: Spacing | boolean;

  @Prop({ default: false, type: [String, Boolean] }) readonly tablet!: Spacing | boolean;

  @Prop({ default: false, type: [String, Boolean] }) readonly smallDesk!: Spacing | boolean;
}
