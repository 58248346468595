var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
    [
      _c("path", {
        attrs: {
          d:
            "M15.5 7L17 8.5 13.5 12l3.5 3.5-1.5 1.5-3.5-3.5L8.5 17 7 15.5l3.5-3.5L7 8.5 8.5 7l3.5 3.5L15.5 7z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }