













import { Vue, Prop, Component } from 'nuxt-property-decorator';
import { validateTheme } from '~/helpers/validators';

const availableSizes: string[] = [
  'base',
  'base-no-bottom-padding',
  'small',
  'medium',
  'intermediate',
  'big',
  'large',
  'large-bottom',
  'huge',
  'home-page',
  'toy-description',
  'none',
];
const availableTags: string[] = ['div', 'section', 'article', 'header'];

type AvailableSizes = typeof availableSizes[number];
type AvailableTags = typeof availableTags[number];

/**
 * A BaseSection spans the whole width of the page with vertical padding and sets the background colour.
 * https://zeroheight.com/2496e2581/p/41fd7a/t/8963c0
 */
@Component
export default class BaseSection extends Vue {
  @Prop({ default: 'article', type: String, validator: (value) => availableTags.includes(value) })
  readonly tag!: AvailableTags;

  @Prop({ default: 'base', type: String, validator: (value) => availableSizes.includes(value) })
  readonly size!: AvailableSizes;

  @Prop({ default: 'primary', type: String, validator: (value) => validateTheme(value) })
  readonly theme!: string;
}
