import { Basket, BasketLine } from '~/types/basket';
import { tokens, discountedValue } from '~/helpers/models/basketLine';

export function itemCount(basket: Basket): number {
  const basketLines = lines(basket);

  if (!basketLines.length) return 0;

  return basketLines.reduce((total: number, basketLine: BasketLine) => total + basketLine.quantity, 0);
}

export function lines(basket: Basket): Array<BasketLine> {
  if (!basket.basketLines) return [];

  return basket.basketLines;
}

export function hasLines(basket: Basket | null): boolean {
  if (!basket) return false;
  return !!itemCount(basket);
}

export function totalTokens(basket: Basket): number {
  const basketLines: Array<BasketLine> = lines(basket);

  if (!basketLines.length) return 0;

  return basketLines.reduce((total: number, basketLine: BasketLine) => {
    const tokenValue: number | null = discountedValue(basketLine) || tokens(basketLine);
    return tokenValue ? total + tokenValue * basketLine.quantity : total;
  }, 0);
}
