import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '~/store/index';

const types = {
  SET_MODAL_STATE: 'SET_MODAL_STATE',
};

export interface ModuleState {
  isModalOpen: boolean;
}

export const state = (): ModuleState => ({
  isModalOpen: false,
});

export const actions: ActionTree<ModuleState, RootState> = {
  toggleModal({ commit }, isModalOpen: boolean) {
    this.$logger.store(`action`, `[modal/toggleModal]`, { isModalOpen });

    commit(types.SET_MODAL_STATE, { isModalOpen });
  },
};

export const mutations: MutationTree<ModuleState> = {
  [types.SET_MODAL_STATE](state, { isModalOpen }: Pick<ModuleState, 'isModalOpen'>) {
    state.isModalOpen = isModalOpen;
  },
};
