import ArrowCenter from './svg/IconArrowCenter.vue';
import ArrowSide from './svg/IconArrowSide.vue';
import Bag from './svg/IconBag.vue';
import GiftBag from './svg/IconGiftBag.vue';
import Bin from './svg/IconBin.vue';
import Calendar from './svg/IconCalendar.vue';
import Copy from './svg/IconCopy.vue';
import Cross from './svg/IconCross.vue';
import CrossTwo from './svg/IconCrossTwo.vue';
import Delete from './svg/IconDelete.vue';
import Email from './svg/IconEmail.vue';
import Error from './svg/IconError.vue';
import Facebook from './svg/IconFacebook.vue';
import Heart from './svg/IconHeart.vue';
import HeartFilled from './svg/IconHeartFilled.vue';
import GoogleReview from './svg/IconGoogleReview.vue';
import Info from './svg/IconInfo.vue';
import Instagram from './svg/IconInstagram.vue';
import Messenger from './svg/IconMessenger.vue';
import Options from './svg/IconOptions.vue';
import PDF from './svg/IconPDF.vue';
import Pound from './svg/IconPound.vue';
import Play from './svg/IconPlay.vue';
import Profile from './svg/IconProfile.vue';
import Search from './svg/IconSearch.vue';
import Tick from './svg/IconTick.vue';
import TickAlt from './svg/IconTickAlt.vue';
import Token from './svg/IconToken.vue';
import Toys from './svg/IconToys.vue';
import TrustpilotReview from './svg/IconTrustpilotReview.vue';
import Twitter from './svg/IconTwitter.vue';
import VisibilityOff from './svg/IconVisibilityOff.vue';
import VisibilityOn from './svg/IconVisibilityOn.vue';
import Warning from './svg/IconWarning.vue';
import Website from './svg/IconWebsite.vue';
import Whatsapp from './svg/IconWhatsapp.vue';
import SnowFlake from './svg/SnowFlake.vue';
import ProfileAlligator from './svg/IconProfileAlligator.vue';
import ProfileBeaver from './svg/IconProfileBeaver.vue';
import ProfileFlamingo from './svg/IconProfileFlamingo.vue';
import ProfilePug from './svg/IconProfilePug.vue';
import ProfileAlligatorAlt from './svg/IconProfileAlligatorAlt.vue';
import ProfileBeaverAlt from './svg/IconProfileBeaverAlt.vue';

import LoadingSpinner from '~/components/partials/LoadingSpinner/LoadingSpinner.vue';

const icons = {
  ArrowCenter,
  ArrowSide,
  Bag,
  ToyBag: Bag,
  GiftBag,
  Bin,
  Calendar,
  Copy,
  Cross,
  CrossTwo,
  Delete,
  Email,
  Error,
  Facebook,
  Heart,
  HeartFilled,
  GoogleReview,
  Info,
  Instagram,
  LoadingSpinner,
  Messenger,
  Options,
  PDF,
  Pound,
  Play,
  Profile,
  Search,
  Tick,
  TickAlt,
  Token,
  Toys,
  TrustpilotReview,
  Twitter,
  VisibilityOff,
  VisibilityOn,
  Warning,
  Website,
  Whatsapp,
  SnowFlake,
  ProfileAlligator,
  ProfileAlligatorAlt,
  ProfileBeaver,
  ProfileBeaverAlt,
  ProfileFlamingo,
  ProfilePug,
};

export default icons;

export type IconName = keyof typeof icons;
