var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseDropdown",
    {
      class: "v-about-dropdown--" + _vm.theme,
      attrs: {
        theme: _vm.theme,
        visible: _vm.visible,
        "caret-position": "left"
      },
      on: {
        "clicked-away": function($event) {
          return _vm.$emit("close-about-dropdown")
        }
      }
    },
    [
      _c(
        "BaseBox",
        { attrs: { padding: ["mini"] } },
        [
          _c(
            "BaseList",
            { staticClass: "v-about-dropdown__list", attrs: { bare: true } },
            _vm._l(_vm.links, function(link, index) {
              return _c(
                "BaseListItem",
                { key: index, staticClass: "v-about-dropdown__list-item" },
                [
                  _c(
                    "BaseLink",
                    {
                      staticClass: "v-about-dropdown__link",
                      class: _vm.linkClasses,
                      attrs: { to: link.to },
                      nativeOn: {
                        click: function($event) {
                          return _vm.$emit("close-about-dropdown")
                        }
                      }
                    },
                    [
                      _c("BaseIcon", {
                        staticClass: "v-about-dropdown__link-arrow",
                        class: _vm.linkClasses,
                        attrs: {
                          icon: "ArrowCenter",
                          size: "small",
                          direction: "right"
                        }
                      }),
                      _vm._v(
                        "\n          " + _vm._s(link.text) + "\n          "
                      ),
                      link.tagText
                        ? _c(
                            "div",
                            { staticClass: "v-about-dropdown__tag" },
                            [
                              _c(
                                "BaseTag",
                                {
                                  attrs: {
                                    theme: "tertiary",
                                    tilt: "right",
                                    tag: "span",
                                    padding: ["micro"]
                                  }
                                },
                                [
                                  _c(
                                    "BaseText",
                                    { attrs: { size: "small-body" } },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(link.tagText) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }