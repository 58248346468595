export const sizesMap = {
  none: '0',
  micro: '0.4rem',
  mini: '0.8rem',
  teeny: '1.2rem',
  tiny: '1.6rem',
  small: '2.0rem',
  base: '2.4rem',
  medium: '3.2rem',
  intermediate: '4.0rem',
  big: '4.8rem',
  large: '5.6rem',
  huge: '7.2rem',
  massive: '8.0rem',
  immense: '9.6rem',
};

export type Size =
  | 'none'
  | 'micro'
  | 'mini'
  | 'teeny'
  | 'tiny'
  | 'small'
  | 'base'
  | 'medium'
  | 'intermediate'
  | 'big'
  | 'large'
  | 'huge'
  | 'massive'
  | 'immense';
