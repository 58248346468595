// import Vue from 'vue';
import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '~/store/index';
import { hasActiveSubscription } from '~/helpers/models/user';
import { RefereeTypeId } from '~/constants/referral';

const types = {
  PUT_REFERRAL_CODE: 'PUT_REFERRAL_CODE',
  PUT_REFERRED_BY_NAME: 'PUT_REFERRAL_BY_NAME',
  PUT_REFERRED_USER_TYPE: 'PUT_REFERRED_USER_TYPE',
};

export interface ReferState {
  referralCode: string | null;
  referredBy: string | null;
  userType: number;
}

export const state = (): ReferState => ({
  referralCode: null,
  referredBy: null,
  userType: RefereeTypeId.NORMAL,
});

export const actions: ActionTree<ReferState, RootState> = {
  initialiseStore({ dispatch }, referralData?: ReferState): void {
    if (hasActiveSubscription(this.$auth.user)) return;

    const code: string | null =
      referralData?.referralCode || this.$storage.getUniversal('codeType-referral-code');
    const by: string | null = referralData?.referredBy || this.$storage.getUniversal('codeType-referral-By');
    const userType: number | null =
      referralData?.userType || this.$storage.getUniversal('codeType-referral-userType');

    if (code) dispatch('setReferralCode', code);
    if (referralData?.referralCode)
      this.$storage.setUniversal('codeType-referral-code', referralData.referralCode);

    if (by) dispatch('setReferredBy', by);
    if (referralData?.referredBy) this.$storage.setUniversal('codeType-referral-by', referralData.referredBy);

    if (userType) dispatch('setReferredUserType', userType);
    if (referralData?.userType)
      this.$storage.setUniversal('codeType-referral-userType', referralData.userType);
  },
  setReferralCode({ commit }, referralCode: string): void {
    commit(types.PUT_REFERRAL_CODE, referralCode);
  },
  setReferredBy({ commit }, referredBy: string): void {
    commit(types.PUT_REFERRED_BY_NAME, referredBy);
  },
  setReferredUserType({ commit }, userType: number): void {
    commit(types.PUT_REFERRED_USER_TYPE, userType);
  },
  resetReferralData({ commit }): void {
    this.$storage.removeUniversal('codeType-referral-code');
    commit(types.PUT_REFERRAL_CODE, null);

    this.$storage.removeUniversal('codeType-referral-by');
    commit(types.PUT_REFERRED_BY_NAME, null);
  },
};

export const mutations: MutationTree<ReferState> = {
  [types.PUT_REFERRAL_CODE](state: ReferState, referralCode: string): void {
    state.referralCode = referralCode;
  },
  [types.PUT_REFERRED_BY_NAME](state: ReferState, referredBy: string): void {
    state.referredBy = referredBy;
  },
  [types.PUT_REFERRED_USER_TYPE](state: ReferState, userType: number): void {
    state.userType = userType;
  },
};
