var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseLink",
    {
      attrs: { to: _vm.url },
      nativeOn: {
        click: function($event) {
          return _vm.$gtm.analytics.event("Promo banner", "click", _vm.gaLabel)
        }
      }
    },
    [
      _c(
        "BaseBanner",
        _vm._b(
          {
            staticClass: "v-promo-banner",
            attrs: {
              justify: "center",
              type: _vm.type,
              "can-close": false,
              align: "middle"
            }
          },
          "BaseBanner",
          _vm.$attrs,
          false
        ),
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }