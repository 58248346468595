var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "v-toggle-btn" }, [
    _vm.$slots.default
      ? _c(
          "label",
          { staticClass: "v-toggle-btn__label", attrs: { for: _vm.id } },
          [_vm._t("default")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "button",
      _vm._b(
        {
          staticClass: "v-toggle-btn__pill",
          attrs: { id: _vm.id, "aria-checked": _vm.on, role: "switch" },
          on: { click: _vm.toggle }
        },
        "button",
        _vm.$attrs,
        false
      ),
      [
        _c("span", {
          staticClass: "v-toggle-btn__toggle",
          class: { "v-toggle-btn__toggle--active": _vm.on }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }