













import { Component, mixins } from 'nuxt-property-decorator';
import CanShowWhirliLogo from '~/components/partials/logo/WhirliLogoFrames/CanShowWhirliLogo.vue';

@Component
export default class WhirliLogoFrames extends mixins(CanShowWhirliLogo) {}
