export function padding(unit: string, side: string = ''): string {
  const padding = `u-padding${side ? `-${side}` : ''}`;

  return unit === 'base' ? padding : `${padding}-${unit}`;
}

export function margin(unit: string, side: string = ''): string {
  const margin = `u-margin${side ? `-${side}` : ''}`;

  return unit === 'base' ? margin : `${margin}-${unit}`;
}
