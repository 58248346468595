





















import 'reflect-metadata';
import { mixins, Prop, Component, namespace } from 'nuxt-property-decorator';
import FiltersMixin from '../FiltersMixin/FiltersMixin.vue';
import { Bucket } from '~/types/products/associations';
import { registrationPageCheck } from '~/helpers/registration-step-new';

const AssociationsStore = namespace('products/associations');

@Component
export default class BucketCheckbox extends mixins(FiltersMixin) {
  @Prop({ required: true }) readonly bucket!: Bucket;

  @AssociationsStore.Getter isSelected!: boolean;

  get isRegistrationPage(): boolean {
    return registrationPageCheck(this.$route.name);
  }
}
