import { redirectWithoutCaching } from '~/helpers/redirect';

export default function(context) {
  const { store } = context;

  store.dispatch('registration/subscription/initialState', false);

  if (!store.state.registration.subscription.selectedPricingPlan) {
    return redirectWithoutCaching(context, '/pick-toys');
  }
}
