// import Vue from 'vue';
import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '~/store/index';
import { BREAKPOINT_MAP, SMALL_DESK, TABLET, BreakpointName } from '~/constants/screen-breakpoints';
import mq from '~/helpers/screen';

const types = {
  RESIZE_UPDATE_DEBOUNCED: 'RESIZE_UPDATE_DEBOUNCED',
  RESIZE_UPDATE_LAZY_DEBOUNCED: 'RESIZE_UPDATE_LAZY_DEBOUNCED',
};

const DEBOUNCED: string = 'DEBOUNCED';
const LAZY_DEBOUNCED: string = 'LAZY_DEBOUNCED';

interface ScreenSize {
  height: number;
  width: number;
}

const BREAKPOINTS = {
  [BREAKPOINT_MAP.mobile.pixels]: BREAKPOINT_MAP.mobile.name,
  [BREAKPOINT_MAP.tablet.pixels]: BREAKPOINT_MAP.tablet.name,
  [BREAKPOINT_MAP.smallDesk.pixels]: BREAKPOINT_MAP.smallDesk.name,
  [BREAKPOINT_MAP.largeDesk.pixels]: BREAKPOINT_MAP.largeDesk.name,
  [BREAKPOINT_MAP.hugeDesk.pixels]: BREAKPOINT_MAP.hugeDesk.name,
};

type Breakpoint = keyof typeof BREAKPOINTS;

export interface ResizeState extends ScreenSize {
  breakpoint: BreakpointName | null;
  isMobile: boolean;
  isTablet: boolean;
  isDesk: boolean;
}

function getResizeState(dimensions: ScreenSize): ResizeState {
  let lowestBP: string | null = null;

  Object.keys(BREAKPOINTS).forEach((breakpoint: string) => {
    const pixels: Breakpoint = parseInt(breakpoint, 10);
    if (pixels <= dimensions.width) {
      lowestBP = BREAKPOINTS[pixels];
    }
  });

  return {
    breakpoint: lowestBP,
    height: dimensions.height,
    width: dimensions.width,
    isMobile: mq({ until: TABLET }, String(lowestBP)),
    isTablet: mq({ from: TABLET }, String(lowestBP)),
    isDesk: mq({ from: SMALL_DESK }, String(lowestBP)),
  };
}

const initialState: ResizeState = getResizeState({
  height: 0, // window.innerHeight
  width: 0, // window.innerWidth
});

export interface ModuleState {
  debounced: ResizeState;
  lazyDebounced: ResizeState;
}

/**
 * The resize module provides throttled access to the window's resize event.
 * It provides two entries, one more lazily throttled than the other. The lazy
 * one is ideal for more heavier callback scripts.
 */
export const state = (): ModuleState => ({
  debounced: initialState,
  lazyDebounced: initialState,
});

export const actions: ActionTree<ModuleState, RootState> = {
  update({ commit }, newState: { type: string; dimensions: ScreenSize }) {
    // this.$logger.store(`action`, `[resize/update]`, { newState }); disabled for performance reasons, enable to debug scrolling

    const resizeState = getResizeState(newState.dimensions);

    switch (newState.type) {
      case DEBOUNCED:
        commit(types.RESIZE_UPDATE_DEBOUNCED, resizeState);
        break;
      case LAZY_DEBOUNCED:
        commit(types.RESIZE_UPDATE_LAZY_DEBOUNCED, resizeState);
        break;
      default:
    }
  },
};

export const mutations: MutationTree<ModuleState> = {
  [types.RESIZE_UPDATE_DEBOUNCED](state, resizeState: ResizeState) {
    state.debounced = { ...state.debounced, ...resizeState };
  },
  [types.RESIZE_UPDATE_LAZY_DEBOUNCED](state, resizeState) {
    state.lazyDebounced = { ...state.lazyDebounced, ...resizeState };
  },
};
