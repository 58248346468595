var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    {
      tag: "component",
      staticClass: "v-cluster-container",
      class: { "v-cluster-container--overflow-visible": _vm.overflowVisible }
    },
    [_c("div", { class: _vm.classes }, [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }