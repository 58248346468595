var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "v-input" },
    [
      _c(
        "div",
        { class: _vm.innerClasses },
        [
          _vm.$slots.default
            ? _c(
                "label",
                { staticClass: "v-input__label", attrs: { for: _vm.id } },
                [_vm._t("default")],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._t("input", null, { errorId: _vm.errorId })
        ],
        2
      ),
      _vm._v(" "),
      _c("HasError", { attrs: { id: _vm.errorId, errors: _vm.errors } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }