export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const monthNamesLongForm = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function transformToDate(date: Date | string): Date {
  let newDate: Date;

  if (!(date instanceof Date)) {
    newDate = new Date(date.replace(' ', 'T'));
  } else {
    newDate = date;
  }

  return newDate;
}

function dateOrdinal(day: number): string {
  if ([1, 21, 31].includes(day)) return 'st';
  if ([2, 22].includes(day)) return 'nd';
  if ([3, 23].includes(day)) return 'rd';
  return 'th';
}

export function ddMyyyy(date: Date | string, longForm: boolean = false): string | null {
  if (!date) return null;
  const newDate: Date = transformToDate(date);

  const dd = newDate.getDate();
  const ordinal = longForm ? dateOrdinal(dd) : '';
  const month = longForm ? monthNamesLongForm[newDate.getMonth()] : monthNames[newDate.getMonth()];
  const year = newDate.getFullYear();

  return `${dd}${ordinal} ${month} ${year}`;
}

export function ddmmyyyy(date: Date | string): string {
  const newDate: string | null = yyyymmdd(date);
  if (!newDate) return '';

  return newDate
    .split('-')
    .reverse()
    .join('/');
}

/**
 * Formats a date object to yyyy-mm-dd
 * @param {Date} date
 * @returns {string | null} Formatted date string yyyy-mm-dd
 */
export function yyyymmdd(date: Date | string): string | null {
  if (!date) return null;
  const newDate: Date = transformToDate(date);

  return newDate.toISOString().split('T')[0];
}

export function getUTCFullDateAsNumber(date: Date): number {
  const year = `${date.getUTCFullYear()}`;
  const month = `${date.getUTCMonth()}`.padStart(2, '0');
  const day = `${date.getUTCDate()}`.padStart(2, '0');
  const hours = `${date.getHours()}`.padStart(2, '0');
  const minutes = `${date.getMinutes()}`.padStart(2, '0');
  const seconds = `${date.getUTCSeconds()}`.padStart(2, '0');

  return parseInt(`${year}${month}${day}${hours}${minutes}${seconds}`);
}

export function getCurrentDateAsNumber() {
  const currentDate = new Date();
  return getUTCFullDateAsNumber(currentDate);
}

export function getDateFromStringAsNumber(dateString: string) {
  const date = new Date(dateString);
  return getUTCFullDateAsNumber(date);
}

export function isCurrentDateActive(startDate: string, endDate: string): boolean {
  const currentDate: number = getCurrentDateAsNumber();
  const start: number = getDateFromStringAsNumber(startDate);
  const end: number = getDateFromStringAsNumber(endDate);

  return currentDate >= start && currentDate <= end;
}

export function isCurrentDateExpired(endDate: string): boolean {
  return getCurrentDateAsNumber() > getDateFromStringAsNumber(endDate);
}
