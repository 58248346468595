var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseTag",
    {
      staticClass: "v-filters-tag",
      attrs: { padding: ["none", "teeny", "none", "micro"] }
    },
    [
      _c("ButtonIcon", {
        attrs: { icon: "Cross", size: "small", theme: "white" },
        on: { click: _vm.removeFilter }
      }),
      _vm._v(" "),
      _c(
        "BaseText",
        {
          staticClass: "v-filters-tag__text",
          attrs: { color: "white", tag: "span" }
        },
        [_vm._v("\n    " + _vm._s(_vm.filterText) + "\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }