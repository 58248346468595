






import 'reflect-metadata';
import { Vue, Prop, Component } from 'nuxt-property-decorator';

@Component
export default class BaseList extends Vue {
  @Prop({ default: false }) readonly bare!: boolean;
  @Prop({ default: 'ul', validator: (value) => ['ul', 'ol', 'dl'].includes(value), type: String })
  readonly tag!: string;

  get classes() {
    return {
      'v-list--bare': this.bare,
    };
  }
}
