var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    _vm._b(
      {
        tag: "component",
        staticClass: "v-text",
        class: [
          {
            "u-text--responsive": _vm.responsive,
            "u-text--center": _vm.center,
            "u-text--center-until-desk": _vm.centerUntilSmallDesk,
            "u-bold": _vm.bold,
            "u-uppercase": _vm.uppercase,
            "u-capitalize": _vm.capitalize,
            "u-underline": _vm.underline,
            "u-line-through": _vm.lineThrough
          }
        ],
        attrs: {
          "data-color": _vm.color,
          "data-size": _vm.size,
          itemprop: _vm.tag == "h1" ? "name" : false
        }
      },
      "component",
      _vm.$attrs,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }