
























import { Prop, Component, mixins } from 'nuxt-property-decorator';
import accordionsMixin from '../accordionsMixin.vue';

const availableThemes: string[] = ['secondary', 'light-grey-two', 'charcoal'];
const availableAlignments: string[] = ['left', 'center'];

/**
 * ### **Features:**
 * - Themes - secondary, light-grey-two
 * - Name - Must be unique!!
 *
 * ### **Other notes:**
 * The `name` prop must be unique! This is for accessibility and if there are more than one accordion on the page!
 */
@Component
export default class BlockAccordion extends mixins(accordionsMixin) {
  @Prop({ default: '0' }) readonly translateIcon!: string;
  @Prop({ default: 'secondary', validator: (value) => availableThemes.includes(value) })
  readonly theme!: string;

  @Prop({ default: 'left', validator: (value) => availableAlignments.includes(value) })
  readonly alignment!: string;

  get arrowColor(): string {
    return this.theme === 'secondary' || this.theme === 'charcoal' ? 'white' : 'secondary';
  }

  get classes() {
    return [{ [`v-block-accordion--${this.theme}`]: this.theme }];
  }
}
