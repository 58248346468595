import { HttpClientResponse } from 'whirli-client/dist/Interfaces/HttpClient';
import { GlobalInjects } from '~/index.d';

export function submitAttributionSource(
  sourceableType: string,
  sourceableId: string,
  app: GlobalInjects,
  discountCode: string | null = null
): HttpClientResponse {
  let finalDiscountCode = app.$cookies.get('discountCode');
  if (discountCode) {
    finalDiscountCode = discountCode;
  }
  return app.$whirli.attributionSource.create({
    sourceable_type: sourceableType,
    sourceable_id: sourceableId,
    referrer: app.$cookies.get('referrer'),
    discount_code: finalDiscountCode,
    utm_source: app.$cookies.get('utm_source'),
    utm_medium: app.$cookies.get('utm_medium'),
    utm_campaign: app.$cookies.get('utm_campaign'),
    utm_content: app.$cookies.get('utm_content'),
    gclid: app.$cookies.get('gclid'),
    tduid: app.$cookies.get('tduid'),
  });
}
