var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseSection",
    { staticClass: "v-maintenance" },
    [
      _c(
        "BaseWrapper",
        [
          _c(
            "BaseLayout",
            { attrs: { "fill-direction": "center" } },
            [
              _c(
                "BaseLayoutItem",
                { attrs: { tablet: "3/4", "small-desk": "2/3" } },
                [_c("WhirliLogo", { staticClass: "v-maintenance__logo" })],
                1
              ),
              _vm._v(" "),
              _c(
                "BaseLayoutItem",
                { attrs: { tablet: "3/4", "small-desk": "2/3" } },
                [
                  _c(
                    "BaseText",
                    {
                      staticClass: "v-maintenance__header",
                      attrs: { tag: "h2", size: "large-header", center: true }
                    },
                    [
                      _vm._v(
                        "\n          Toy Sharing Subscription Box.\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "BaseText",
                    {
                      staticClass: "v-maintenance__header",
                      attrs: { tag: "h3", size: "large-header", center: true }
                    },
                    [_vm._v("\n          Play the Whirli way.\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "BaseText",
                    {
                      staticClass: "v-maintenance__sub-header",
                      attrs: { size: "body", color: "charcoal", center: true }
                    },
                    [
                      _vm._v(
                        "\n          We're just undergoing a quick scheduled maintenance while we release new goodies! Be back very\n          soon.\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("BaseAnimation", {
        staticClass: "v-maintenance__animation-character",
        attrs: { "initial-state": { animationName: "pingo-flamingo-1" } }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }