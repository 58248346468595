













import 'reflect-metadata';
import { Vue, Prop, Component, Watch } from 'nuxt-property-decorator';
import { Properties as CSSProperties } from 'csstype';
import {
  LottiePlayer,
  Animation,
  AnimationConfig,
  AnimationInitialState,
  AnimationInitialStateWithAnimationName,
  AnimationInitialStateWithDynamicData,
  AnimationInitialStateWithPath,
  AnimationInitialStateWithData,
} from './AnimationTypes';
import { importDefaultAnimation } from '~/components/partials/animations/defaultAnimations';

type InitialState =
  | AnimationInitialStateWithAnimationName
  | AnimationInitialStateWithDynamicData
  | AnimationInitialStateWithPath
  | AnimationInitialStateWithData;

interface Command {
  method: string | null;
  arguments: Array<any>;
}

const defaultState: AnimationInitialState = {
  autoplay: true,
  loop: true,
  renderer: 'svg',
  rendererSettings: {
    progressiveLoad: true,
  },
};

const emptyPlayCommand: Command = {
  method: null,
  arguments: [],
};

/**
 * The BaseAnimation component is used for rendering a Lottie JSON animation file in real-time. It can be rendered
 * as an SVG, on a canvas, or in HTML, and is then animated by the Lottie player.
 *
 * Loading an Animation
 *
 * An animation can be loaded in 3 ways:
 * 1. Providing the name of one of the default animations (recommended)
 * 2. By providing a Function that returns a Promise, and would resolve with the animationData (recommended)
 * 3. A relative path to the JSON animation file
 * 4. The raw JSON animation data itself
 *
 * Examples of each:
 *
 * 1.
 *     <BaseAnimation :initial-state="{ animationName: 'beaver-pop-up' }" />
 *
 * 2.
 *     <BaseAnimation :initial-state="{ dynamicData: beaverAnimation }" />
 *     ...
 *     beaverAnimation = () => import('~/assets/animations/beaver-pop-up.json');
 *
 * 3.
 *     <BaseAnimation :initial-state="{ path: '~/assets/animations/beaver-pop-up.json' }" />
 *
 * 4.
 *     <BaseAnimation :initial-state="{ animationData: beaverAnimation }" />
 *     ...
 *     import beaverAnimation from '~/assets/animations/beaver-pop-up.json';
 *
 * Providing a function to load the data asynchronously is recommended for several reasons:
 * - The server does not need to load the animation when initially rendering the page in SSR (speed + size improvement)
 * - The user does not need to download any animations they do not see on the page (speed, size and DOM size improvement)
 *
 * However, there will be a delay between the user first reaching the animation on the page, and the animation
 * appearing and beginning to play, as it must first be downloaded by the user. This can be improved with a placeholder.
 *
 * Placeholder
 *
 * A placeholder can be provided, which will be shown when the animation has not loaded yet. This can be useful to
 * avoid the animation popping in from empty space, or for serverside rendering. We recommend supplying an SVG of the
 * first frame of an animation as the placeholder, but any valid HTML is accepted.
 *
 * A placeholder is provided simply by putting content into the placeholder slot.
 *
 *     <BaseAnimation :initial-state="{ animationName: 'beaver-pop-up' }">
 *       <BeaverSvg #placeholder />
 *     </BaseAnimation>
 *
 * Performance
 *
 * The BaseAnimation provides extra functionality on top of the regular Lottie player. Firstly, when the animation
 * is not visible it will automatically pause (visibility is deemed by an IntersectionObserver). When visible again,
 * it will resume playing.
 *
 * In cases where a non-standard play command has been issued - such as playSegments - the same command will be
 * re-issued to resume playing.
 *
 * Furthermore, if the calling code has paused the animation, it will not automatically resume when scrolled into
 * the viewport, and will remain paused until a new play command is issued by the parent.
 *
 * @todo Check if the 'playCommand' is really needed - it may not be, depending on how smart Lottie is.
 * @todo Don't load non-autplay animations until their first play call
 */
@Component
export default class BaseAnimation extends Vue {
  /** The options that can't be changed once the animation is created */
  @Prop({ default: () => ({}) })
  readonly initialState!: InitialState;

  @Prop({ default: null })
  readonly height?: string;

  @Prop({ default: null })
  readonly width?: string;

  /** The animation object used by parent components to interact with this animation. It is emitted to the parent. */
  anim: Animation = {
    animationItem: null,
    play: () => this.setPlayCommand('play'),
    pause: () => {
      this.anim.call('pause');
      this.clearPlayCommand();
    },
    stop: () => {
      this.anim.call('stop');
      this.clearPlayCommand();
    },
    goToAndStop: (...args) => this.setPlayCommand('goToAndStop', ...args),
    goToAndPlay: (...args) => this.setPlayCommand('goToAndPlay', ...args),
    playSegments: (...args) => this.setPlayCommand('playSegments', ...args),
    call: (method: any, ...args: Array<any>) => {
      if (!this.anim.animationItem) return null;
      // @ts-ignore @todo Type the method argument properly
      return this.anim.animationItem[method](...args);
    },
  };

  /** What command should be called when the animation is allowed to resume playing, e.g. when scrolled into view */
  playCommand: Command = emptyPlayCommand;

  /** Whether the animation data has been loaded by the client already */
  loaded: boolean = false;

  /** Whether the animation is visible on screen */
  visible: boolean = false;

  get style(): CSSProperties {
    return {
      height: this.height ? `${this.height}` : '100%',
      width: this.width ? `${this.width}` : '100%',
      overflow: 'hidden',
      margin: '0 auto',
    };
  }

  get shouldPlay(): boolean {
    if (process.env.pauseAnimations === 'true') return false;

    return this.loaded && this.visible;
  }

  @Watch('shouldPlay')
  onShouldPlayChanged(shouldPlay: boolean) {
    if (!this.anim.animationItem) return;

    if (!shouldPlay) {
      this.anim.call('pause');
    }

    this.checkAndSendPlayCommand();
  }

  @Watch('playCommand', { deep: true })
  checkAndSendPlayCommand() {
    if (this.shouldPlay && this.playCommand.method) {
      this.sendPlayCommand();
    }
  }

  sendPlayCommand() {
    // @ts-ignore @todo fix the method call typings
    this.anim.call(this.playCommand.method, ...this.playCommand.arguments);
  }

  async onIntersect(isIntersecting: boolean) {
    this.visible = isIntersecting;
    if (this.visible && !this.loaded) {
      // @todo If there's no play command yet, we don't need to load
      await this.loadAnimation();
    }
  }

  async loadAnimation() {
    if (!this.$refs.renderContainer) return;
    if (process.env.disableAnimations === 'true') return;

    const importLottie = import('lottie-web') as any;

    const state: InitialState = Object.assign({}, defaultState, this.initialState);

    if (state.animationName) {
      try {
        // @ts-ignore @todo state is coming across as type 'never' but not sure why - need to fix (@jamie)
        state.animationData = await importDefaultAnimation(state.animationName);
      } catch (e) {
        this.$logger.warn(
          'Tried to load default BaseAnimation ' + state.animationName + ' but name not found.'
        );
        return;
      }
      // @ts-ignore @todo state is coming across as type 'never' but not sure why - need to fix (@jamie)
    } else if (state.dynamicData) {
      // @ts-ignore @todo state is coming across as type 'never' but not sure why - need to fix (@jamie)
      state.animationData = JSON.parse(await state.dynamicData());
    }

    const config: AnimationConfig = Object.assign({}, state, {
      container: this.$refs.renderContainer as Element,
    });
    if (config.autoplay) {
      this.setPlayCommand('play');
    }
    const lottie: LottiePlayer = await importLottie;
    this.anim.animationItem = lottie.loadAnimation(config);
    if (process.env.pauseAnimations === 'true') {
      this.anim.pause();
    }
    this.loaded = true;
    this.$emit('animation-loaded', this.anim);
  }

  setPlayCommand(method: string | null = null, ...args: Array<any>) {
    this.playCommand = {
      method,
      arguments: args,
    };
  }

  clearPlayCommand() {
    this.playCommand = emptyPlayCommand;
  }

  mounted() {
    if (this.initialState.animationData) {
      // Data is already downloaded and in memory so we may as well load the animation now
      this.loadAnimation();
    }
    this.$emit('animation-mounted', this.anim);
  }

  destroyed() {
    this.anim.call('stop');
    this.anim.call('destroy');
  }
}
