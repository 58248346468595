import { AuthContext } from '~/index.d.ts';

export default function({ store, $auth, $axios, $storage }: AuthContext) {
  $auth.onError(async (error: any) => {
    const { route, app }: AuthContext = arguments[0];
    app.$logger.onAuthError(error); // @todo nuxt auth module fires onError twice, see listeners in core file in nuxt auth repo

    if (route.name === 'login' || route.name === 'register') return;
    await store.dispatch('user/logout', { redirect: false });
  });
  const user = $storage.getLocalStorage('user');
  const token = $storage.getLocalStorage('token');

  if (token != null && user != null) {
    $axios.setToken(token, 'Bearer');
    $auth.setUser(user);
  }
}
