var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseCarousel", {
    staticClass: "v-paginated-carousel",
    class: { "v-paginated-carousel--elaborate": _vm.elaborate },
    attrs: {
      loop: _vm.loop,
      name: _vm.name,
      "navigation-top": !_vm.isDesk && !_vm.elaborate,
      options: _vm.options,
      pagination: _vm.pagination,
      "show-navigation": _vm.showNavigation,
      "slides-per-view": _vm.slidesPerView
    },
    scopedSlots: _vm._u(
      [
        {
          key: "slides",
          fn: function() {
            return [
              _vm._t("default"),
              _vm._v(" "),
              _c("div", { staticClass: "u-margin-bottom-medium" })
            ]
          },
          proxy: true
        },
        {
          key: "navigation",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "v-paginated-carousel__navigation-container" },
                [
                  _c("ButtonIcon", {
                    staticClass:
                      "v-paginated-carousel__btn v-paginated-carousel__btn-previous",
                    attrs: {
                      id: _vm.name + "-prev",
                      "aria-label": "previous slide",
                      direction: "left",
                      disc: _vm.navBtnSettings.disc,
                      icon: "ArrowCenter",
                      size: _vm.navBtnSettings.size,
                      theme: _vm.navBtnSettings.theme,
                      type: "button"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", {
                    class: _vm.showNavigation
                      ? "v-paginated-carousel__pagination v-paginated-carousel__pagination--" +
                        _vm.name
                      : "v-paginated-carousel__pagination--" + _vm.name
                  }),
                  _vm._v(" "),
                  _c("ButtonIcon", {
                    staticClass:
                      "v-paginated-carousel__btn v-paginated-carousel__btn-next",
                    attrs: {
                      id: _vm.name + "-next",
                      "aria-label": "next slide",
                      direction: "right",
                      disc: _vm.navBtnSettings.disc,
                      icon: "ArrowCenter",
                      size: _vm.navBtnSettings.size,
                      theme: _vm.navBtnSettings.theme,
                      type: "button"
                    }
                  })
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }