var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "40",
        height: "40",
        viewBox: "0 0 40 40",
        fill: "none",
        "xmlns:v": "https://vecta.io/nano"
      }
    },
    [
      _c("circle", {
        attrs: {
          cx: "20.661",
          cy: "20.215",
          r: "19.5",
          fill: "#20b7a8",
          stroke: "#fff"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M22.895 7.388s6.431.606 7.945 5.003c1.1 3.194-.61 5.699-2.973 6.406-2.509.751-5.114.107-5.875-3.295s.909-8.114.909-8.114h-.006z",
          fill: "#5b2311"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M26.82 26.353c-.53 0-1.049-.147-1.501-.424s-.817-.675-1.057-1.147-.343-1.002-.3-1.531a2.87 2.87 0 0 1 .546-1.461c1.429-1.946 3.571-3.246 5.957-3.614s4.821.225 6.77 1.649a2.87 2.87 0 0 1 .619 4.01 2.87 2.87 0 0 1-4.01.619c-.721-.527-1.622-.746-2.505-.61s-1.676.616-2.205 1.336a2.86 2.86 0 0 1-1.018.862c-.402.203-.847.308-1.298.306l.001.005z",
          fill: "#ea997d"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          d:
            "M5.93 33.743c-.071-.599-.101-1.203-.114-1.79a10.79 10.79 0 0 1 .733-4.146 17.9 17.9 0 0 0 1.021-9.11c-.208-1.402-.139-2.831.206-4.206s.956-2.668 1.8-3.807 1.905-2.099 3.12-2.827a10.79 10.79 0 0 1 3.965-1.417l.838-.088a.95.95 0 0 0 .471-.856.81.81 0 0 1 .778-.838.81.81 0 0 1 .838.778c.01.278-.025.555-.105.821a1.55 1.55 0 0 0 .981-1.181 1.33 1.33 0 0 1 1.537-1.039 1.33 1.33 0 0 1 1.08 1.509c-.104.578-.327 1.128-.656 1.614a10.78 10.78 0 0 1 6.494 8.364v.006a39.53 39.53 0 0 1-2.255 20.109 10.79 10.79 0 0 1-2.264 3.55 10.8 10.8 0 0 1-.879.82c-.936.136-1.888.205-2.857.205-5.829 0-11.075-2.494-14.731-6.472z",
          fill: "#df8e73"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M23.099 24.154c-.419 0-.833-.083-1.22-.243s-.738-.395-1.034-.692-.53-.648-.69-1.035-.242-.802-.241-1.22v-1.383c-.017-.429.052-.856.204-1.258s.384-.767.681-1.077.654-.556 1.048-.724.819-.255 1.248-.255.854.087 1.248.255.751.414 1.048.724.529.676.681 1.077.222.829.204 1.258v1.383c0 .845-.335 1.654-.933 2.252s-1.407.933-2.252.933l.005.005z",
          fill: "#1d1d1b"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          d:
            "M1.58 26.225a19.99 19.99 0 0 1-.919-6.01l.008-.56.185-.104c1.051-.571 2.204-.93 3.393-1.056s2.392-.016 3.539.323 2.216.901 3.146 1.653a2.87 2.87 0 0 1 .403 4.015 2.87 2.87 0 0 1-4.011.446 3.37 3.37 0 0 0-2.473-.731 3.37 3.37 0 0 0-2.266 1.233c-.269.333-.609.602-.995.786z",
          fill: "#ea997d"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M13.564 7.388s-6.431.606-7.944 5.003c-1.1 3.194.609 5.699 2.971 6.406 2.509.751 5.116.107 5.875-3.295s-.909-8.114-.909-8.114h.007zm12.342 14.313a2.57 2.57 0 0 1-1.818-.757c-.2-.198-.469-.31-.751-.31s-.551.111-.751.31a2.58 2.58 0 1 1-3.651-3.651 6.23 6.23 0 0 1 8.802 0c.361.361.606.821.705 1.322s.048 1.02-.147 1.491-.526.875-.95 1.159-.923.436-1.434.436h-.006z",
          fill: "#5b2311"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M25.905 16.815a1.82 1.82 0 0 1-1.818 1.818 1.82 1.82 0 0 1-1.818-1.818h3.636zm2.206-5.951a2.96 2.96 0 0 0-2.701 1.752c-.618-.293-1.298-.435-1.982-.413a4.32 4.32 0 0 0-1.952.536 2.96 2.96 0 0 0-5.676 1.56 2.96 2.96 0 0 0 5.877-.327 3.15 3.15 0 0 1 3.477-.191v.043a2.96 2.96 0 0 0 5.05 2.092 2.96 2.96 0 0 0-2.092-5.05h-.001z",
          fill: "#1d1d1b"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M19.454 11.576l-2.977 2.975a2.37 2.37 0 0 0 .576.939 2.36 2.36 0 0 0 .939.576l2.977-2.975a2.37 2.37 0 0 0-.576-.939 2.36 2.36 0 0 0-.939-.576zm9.387 0l-2.975 2.975a2.36 2.36 0 0 0 1.515 1.515l2.975-2.975a2.36 2.36 0 0 0-1.515-1.515z",
          fill: "#3c3c3b"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M22.741 22.306c-.379-.125-.788-.13-1.171-.014s-.719.348-.965.663a3.38 3.38 0 0 0 .39.412c-.163.354-.247.74-.245 1.13 0 1.222.779 2.208 1.742 2.208s1.742-.99 1.742-2.208c0-1.116-.649-2.036-1.497-2.187l.005-.004z",
          fill: "#bf1b49"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }