






import { Vue, Component, Prop } from 'nuxt-property-decorator';
import mq from '~/helpers/screen';

@Component
export default class BaseAccordionGroup extends Vue {
  @Prop({ default: false }) readonly single!: boolean;
  @Prop({ default: null }) readonly fixOpenBreakpoint?: string;

  list: Array<string> = [];

  update(name: string): void {
    if (this.list.includes(name)) {
      this.list.splice(this.list.indexOf(name), 1);
      return;
    }
    if (this.single) {
      this.clearList();
    }
    this.list.push(name);
  }

  clearList(): void {
    this.list = [];
  }

  get fixAccordionsOpen() {
    const currentScreenSize: string = this.$store.state.resize.debounced.breakpoint;
    return this.fixOpenBreakpoint ? mq({ from: this.fixOpenBreakpoint }, currentScreenSize) : false;
  }
}
