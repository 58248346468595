




import 'reflect-metadata';
import { Prop, Component, mixins } from 'nuxt-property-decorator';
import iconMixin from '../iconMixin.vue';
import icons, { IconName as IN } from '../icons';

type IconName = IN; // Without this, typescript throws a warning that it cannot find the export

export const availableSizes: Array<string> = [
  'mini',
  'teeny',
  'tiny',
  'small',
  'base',
  'medium',
  'intermediate',
  'big',
];

@Component({
  components: { ...icons },
})
export default class BaseIcon extends mixins(iconMixin) {
  @Prop({ type: String, required: true, validator: (value) => Object.keys(icons).includes(value) })
  readonly icon!: IconName;

  @Prop({ default: 'teeny', validator: (value) => availableSizes.includes(value) })
  readonly size!: string;

  get iconClasses() {
    return {
      [`v-icon--${this.direction}`]:
        (this.direction && this.icon === 'ArrowCenter') || this.icon === 'ArrowSide',
      [`v-icon--${this.size}`]: this.size,
      [`u-color-${this.theme}`]: this.theme,
    };
  }

  get iconComponent() {
    if (!icons[this.icon]) {
      throw new Error(`${this.icon} does not exist.`);
    }

    // @todo This shouldn't be done in this component, but the Bag Icons are a bit convoluted, so this was put here
    // due to us being short on time and not having time to refactor the Bag Icons to be more manageable.
    // So the todo is to move this logic out of BaseIcon and in to the higher level components.
    if (this.icon === 'Bag' && this.$gifts.giftMode.isOn()) return icons.GiftBag;

    return icons[this.icon];
  }
}
