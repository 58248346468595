



































import 'reflect-metadata';
import { Vue, Watch, Component } from 'nuxt-property-decorator';

// Helpers
import { addKeyupEventListener, removeKeyupEventListener } from '~/helpers/keyboard';
import { hasActiveSubscription } from '~/helpers/models/user';
import { getIsUserChristmasPageExpired, getIsGuestChristmasPageExpired } from '~/helpers/navigation';

// Types
import { NavLinks } from '~/types/navigation';

@Component
export default class NavHamburgerMenu extends Vue {
  @Watch('isOpen')
  setBackgroundAriaHidden() {
    if (!document) return;
    const main = document.querySelector('main');
    if (!main) return;
    main.setAttribute('aria-hidden', this.isOpen);
  }

  mounted() {
    addKeyupEventListener(27, this.toggleMenu);
  }

  destroyed() {
    removeKeyupEventListener(27, this.toggleMenu);
  }

  get isOpen() {
    return this.$store.state.navigation.isHamburgerMenuOpen;
  }

  toggleMenu() {
    this.$emit('toggleHamburgerMenu');
  }

  get hasActiveSubscription(): boolean {
    if (this.$auth.user) {
      return hasActiveSubscription(this.$auth.user);
    }
    return false;
  }

  get showCtaButton(): boolean {
    if (!this.$auth.loggedIn) return true;
    return !hasActiveSubscription(this.$auth.user);
  }

  get ctaButtonText(): string {
    if (!this.$auth.loggedIn) return 'GET STARTED';
    if (!hasActiveSubscription(this.$auth.user)) return 'Continue registration';
    return '';
  }

  get links(): Array<Array<NavLinks>> {
    const seasonalLink: string | null = this.hasActiveSubscription
      ? !getIsUserChristmasPageExpired()
        ? '/christmas'
        : null
      : !getIsGuestChristmasPageExpired()
      ? '/offers/christmas'
      : null;
    const seasonalNavLabel: string = 'Christmas';

    const links: Array<Array<NavLinks>> = [
      [{ to: '/', text: 'Home' }],
      [
        { to: '/toys', text: 'Toys' },
        {
          to:
            '/toys/all?page=1&category=prams%3Bhigh-chairs%3Bkids-luggage%3Bchild-carriers%3Bbouncers&orderBy=most_popular_score&sortedBy=desc',
          text: 'Baby Equipment',
        },
        { to: '/lists', text: 'Favourites / Gift List' },
        { to: '/how-it-works', text: 'How it works' },
        { to: '/pricing', text: 'Pricing' },
        { to: '/gift', text: 'Gift Whirli' },
        { to: '/gift/redeem', text: 'Redeem a Gift' },
        { to: '/reviews', text: 'Reviews' },
        { to: '/blog', text: 'Blog' },
      ],
      [
        { to: '/contact-us', text: 'Contact us' },
        { to: '/frequently-asked-questions', text: 'FAQs' },
        { to: '/careers', text: 'Careers' },
      ],
    ];

    if (seasonalLink) links[1].splice(5, 0, { to: seasonalLink, text: seasonalNavLabel });

    return links;
  }
}
