import axios, { CancelTokenSource } from 'axios';
import { ActionTree, MutationTree } from 'vuex';
import { ModuleState as RangeModuleState } from './range';
import { ModuleState as FiltersModuleState } from './filters';
import { ModuleState as AssociationsModuleState, SelectedAssociations } from './associations';
import { ModuleState as PaginationModuleState } from './pagination';
import { ModuleState as SearchModuleState } from './search';
import { ModuleState as SortModuleState } from './sort';
import { RootState } from '~/store';
import { BrowserQueryParams, QueryParams } from '~/types/products/query';
import { Product } from '~/types/products/products';
import ProductQuery from '~/helpers/product-queries';
import { createProductsUrlWithQuery } from '~/helpers/url';
import { SortOption } from '~/types/products/sort';
import { getSortOptionByParams } from '~/helpers/sort';

const types = {
  SET_BUSY: 'SET_BUSY',
  SET_HAS_ERROR: 'SET_HAS_ERROR',
  PUT_PRODUCTS: 'PUT_PRODUCTS',
  SET_HIDE_PREVIOUS: 'SET_HIDE_PREVIOUS',
  SET_PRODUCTS_COUNT: 'SET_PRODUCTS_COUNT',
  SET_HIDE_NOT_IN_STOCK: 'SET_HIDE_NOT_IN_STOCK',
  SET_LOADING: 'SET_LOADING',
};

type ApiResponse<T = any> = {
  data: T;
  meta: T;
  success: T;
  errors: T;
  messages: T;
};
type CustomAxiosResponse<T = any> = ApiResponse<ApiResponse<T>>;

const productQuery: ProductQuery = new ProductQuery();
interface QueryParamsForToysAll {
  [key: string]: string | string[];
}
let cancelTokenSource: CancelTokenSource | null = null;
export interface MakeQueryPayload {
  resetCurrentPage?: boolean;
  shouldUpdateUrl?: boolean;
  pagePath?: string;
  defaultapi?: boolean;
}

export interface RightPayload {
  resetCurrentPage?: boolean;
  shouldUpdateUrl?: boolean;
  pagePath?: string;
  params?: { [key: string]: any };
  clearall?: boolean;
}

export interface ModuleState {
  all: Array<Product>;
  busy: boolean;
  count: number;
  hidePrevious: boolean;
  hideNotInStock: boolean;
  range: RangeModuleState;
  filters: FiltersModuleState;
  associations: AssociationsModuleState;
  pagination: PaginationModuleState;
  search: SearchModuleState;
  sort: SortModuleState;
  hasError: boolean;
  loading: boolean;
}

export const state = (): Omit<
  ModuleState,
  'filters' | 'associations' | 'pagination' | 'search' | 'sort' | 'range'
> => ({
  all: [],
  busy: false,
  loading: true,
  count: 0,
  hidePrevious: false,
  hideNotInStock: false,
  hasError: false,
});

export const actions: ActionTree<ModuleState, RootState> = {
  /**
   * Several processes need to be done before hydrating the store
   * with the filters selected with the URL query params, e.g. `/toys/all?brand=hasbro&category=babies%20and%20toddlers&age=0-6%20months`
   * - Filter out any params that aren't accepted, e.g `toys/all?foo=bar`
   * - Parse associations into the correct structure (SelectedAssociations) to save to products/associations module
   * - Add sort, pagination, search params if they exist to their relevant store modules.
   */
  async parseQueryParams({ commit, dispatch }, queryParams): Promise<void> {
    this.$logger.store(`action`, `[products/parseQueryParams]`, { queryParams });

    if (!queryParams || !Object.keys(queryParams).length) return;

    const jobs: Array<Promise<void>> = [];
    const filteredParams: Partial<BrowserQueryParams> = productQuery.removeUnavailable(queryParams);

    const associations: SelectedAssociations = productQuery.transformToSelectedAssociations(filteredParams);
    if (associations && Object.keys(associations).length) {
      jobs.push(dispatch('associations/setSelected', associations));
    }

    if (filteredParams.from) {
      const parsedRange: RangeModuleState = productQuery.transformToRange({
        from: filteredParams.from,
        to: filteredParams.to,
      });
      jobs.push(dispatch('range/setRange', parsedRange));
    }

    if (filteredParams.orderBy || filteredParams.sortedBy) {
      const sortOption: SortOption | null = getSortOptionByParams({
        orderBy: filteredParams.orderBy || '',
        sortedBy: filteredParams.sortedBy || '',
      });
      if (sortOption) jobs.push(dispatch('sort/setSelectedSort', { sort: sortOption }));
    }

    if (filteredParams.page) {
      jobs.push(dispatch('pagination/setCurrentPage', parseInt(filteredParams.page)));
    }

    if (filteredParams.search) {
      jobs.push(dispatch('search/setTerm', { term: filteredParams.search }));
    }

    if (filteredParams.hidePrevious) {
      const shouldHidePrevious: boolean = filteredParams.hidePrevious === 'true';
      commit(types.SET_HIDE_PREVIOUS, shouldHidePrevious);
    }

    if (filteredParams.hideNotInStock) {
      const shouldHideNotInStock: boolean = filteredParams.hideNotInStock === 'true';
      commit(types.SET_HIDE_NOT_IN_STOCK, shouldHideNotInStock);
    }

    await Promise.all(jobs);
  },

  async rightFilter(
    { commit, dispatch },
    {
      resetCurrentPage = false,
      shouldUpdateUrl = true,
      pagePath = '/toys/all',
      params,
      clearall,
    }: RightPayload = {}
  ): Promise<void> {
    commit(types.SET_BUSY, true);
    commit(types.SET_LOADING, true);
    let filters: any;
    try {
      filters = await dispatch('filters/refreshFilters', resetCurrentPage);
    } catch (error) {
      commit(types.SET_BUSY, false);
      throw error;
    }
    let url: any;
    const queryParams: QueryParams = productQuery.api.makeQueryParams(filters);
    if (
      window.location.href.includes('toys/brand') ||
      window.location.href.includes('toys/age') ||
      window.location.href.includes('toys/category')
    ) {
      shouldUpdateUrl = false;
    }
    if (shouldUpdateUrl) {
      const userFacingParams: Partial<BrowserQueryParams> = productQuery.transformFiltersToParams(filters);
      if (clearall) {
        url = '/toys/all?clearFilters=all';
        this.$router.push(url);
      } else {
        url = createProductsUrlWithQuery(this.$router.app.$route.name as string, pagePath, userFacingParams);
        this.$router.push(url);
      }
    }
    try {
      queryParams.associations = 1;
      const toCamelCase = (str: string): string => str.replace(/_([a-z])/g, (_, p1) => p1.toUpperCase());
      const convertKeys = (obj: any): any => {
        if (Array.isArray(obj)) {
          return obj.map((item) => convertKeys(item));
        } else if (typeof obj === 'object' && obj !== null) {
          const newObj: { [key: string]: any } = {};
          for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
              const newKey = toCamelCase(key);
              newObj[newKey] = convertKeys(obj[key]);
            }
          }
          return newObj;
        }
        return obj;
      };
      if (cancelTokenSource) {
        cancelTokenSource.cancel('Operation canceled due to new request.');
      }
      // Create a new CancelToken source
      cancelTokenSource = axios.CancelToken.source();
      let response: any;
      const apiUseHttps = process.env.protocol?.toLocaleLowerCase() === 'true';
      const protocol = apiUseHttps ? 'https' : 'http';
      const data: any = params;

      if (!clearall) {
        if (filters.hideNotInStock) {
          data.hideNotInStock = filters.hideNotInStock;
        }
        if (filters.search) {
          data.search = filters.search;
        }
        if (filters.hidePrevious) {
          data.hidePrevious = filters.hidePrevious;
        }
        if (filters.sort && filters.sort.sort.orderBy) {
          data.orderBy = filters.sort.sort.orderBy;
        }
        if (filters.sort && filters.sort.sort.orderBy) {
          data.sortedBy = filters.sort.sort.sortedBy;
        }
        if (filters.range && filters.range.from) {
          data.tokenfrom = `${filters.range.from}`;
        }
        if (filters.range && filters.range.to) {
          if (filters.range.to.toString() !== '100') {
            data.tokento = `${filters.range.to}`;
          }
        }
      }
      try {
        if (
          window.location.href.includes('toys/brand') ||
          window.location.href.includes('toys/age') ||
          window.location.href.includes('toys/category')
        ) {
          const apiUrl = `${protocol}://${process.env.baseHost}:${process.env.basePort}/api/guest/filter/productFilter`;
          response = await axios.get(apiUrl, {
            params: data,
            cancelToken: cancelTokenSource.token,
          });
        } else if (window.location.href.includes('toys/all')) {
          const url = window.location.href;
          const urlObject = new URL(url);
          const queryParams = new URLSearchParams(urlObject.search);
          const toysAll: QueryParamsForToysAll = {};
          const excludedParams = new Set(['clearFilters']);
          queryParams.forEach((value, key) => {
            // Skip excluded parameters
            if (excludedParams.has(key)) {
              return;
            }
            if (key === 'from') {
              if (value !== '0') {
                toysAll.tokenfrom = value;
              }
            } else if (key === 'to') {
              if (value !== '100') {
                toysAll.tokento = value;
              }
            } else {
              toysAll[key] = value;
            }
          });
          const apiUrl = `${protocol}://${process.env.baseHost}:${process.env.basePort}/api/guest/filter/productFilter`;
          response = await axios.get(apiUrl, {
            params: toysAll,
            cancelToken: cancelTokenSource.token,
          });
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Skipping Request canceled', error.message);
        } else {
          console.error('Skipping Error fetching data:', error);
        }
      }

      const responseConverted = convertKeys(response.data);
      const updatedResponce = responseConverted.data;
      await dispatch('storeQueryResultsRight', { response: updatedResponce, meta: response.data.meta });
      commit(types.SET_HAS_ERROR, false);
      commit(types.SET_LOADING, false);
    } catch (error) {
      commit(types.SET_BUSY, false);
      commit(types.SET_LOADING, false);
      commit(types.SET_HAS_ERROR, true);
      throw error;
    }
    commit(types.SET_BUSY, false);
  },

  async storeQueryResultsRight({ commit, dispatch }, { response, meta }: any) {
    const jobs = [
      dispatch('setProductsCount', meta.total),
      dispatch('pagination/setPerPage', meta.perPage),
      dispatch('pagination/setCurrentPage', meta.currentPage),
      dispatch('pagination/setLastPage', meta.lastPage),
    ];
    await Promise.all(jobs);
    commit(types.PUT_PRODUCTS, response);
  },

  async leftFilter(
    { commit, dispatch },
    { resetCurrentPage = false, shouldUpdateUrl = false, pagePath = '/toys/all' }: MakeQueryPayload = {}
  ): Promise<void> {
    commit(types.SET_BUSY, true);

    let filters: any;
    try {
      filters = await dispatch('filters/refreshFilters', resetCurrentPage);
    } catch (error) {
      commit(types.SET_BUSY, false);
      throw error;
    }

    const queryParams: QueryParams = productQuery.api.makeQueryParams(filters);

    if (shouldUpdateUrl) {
      const userFacingParams: Partial<BrowserQueryParams> = productQuery.transformFiltersToParams(filters);
      const url = createProductsUrlWithQuery(
        this.$router.app.$route.name as string,
        pagePath,
        userFacingParams
      );
      this.$router.push(url);
    }

    this.$logger.store(`action`, `[products/makeQuery] Query params`, { queryParams });

    const apiUseHttps = process.env.protocol?.toLocaleLowerCase() === 'true';
    const protocol = apiUseHttps ? 'https' : 'http';

    // Make API requests concurrently
    const apiUrls = [
      `${protocol}://${process.env.baseHost}:${process.env.basePort}/api/guest/filter/productFilterList/age`,
      `${protocol}://${process.env.baseHost}:${process.env.basePort}/api/guest/filter/productFilterList/skill`,
      `${protocol}://${process.env.baseHost}:${process.env.basePort}/api/guest/filter/productFilterList/category`,
      `${protocol}://${process.env.baseHost}:${process.env.basePort}/api/guest/filter/productFilterList/brand`,
    ];

    const response: any = {
      meta: {},
    };

    try {
      const [
        customApiResponseAge,
        customApiResponseSkill,
        customApiResponseCategory,
        customApiResponseBrand,
      ] = await Promise.all(apiUrls.map((url) => axios.get(url)));

      const mapData = (data: any[], key: string) =>
        data.map((item: any) => ({
          active: item.active,
          association: {
            ageGroup: item.age_group,
            associationClass: item.association_class,
            position: item.position,
            resourceType: item.resource_type,
            slug: item.slug,
            ...(key === 'skill' && { skillGroup: item.skill_group }),
          },
          internalName: item.internal_name,
          name: item.name,
          productsCount: item.products_count,
          id: item.id,
          hasPage: true,
          slug: item.slug,
          headerDescription: item.descreption,
          position: item.position,
          ageGroup: item.age_group,
          resourceType: item.resource_type,
          associationClass: item.association_class,
        }));

      response.meta.associations = {
        age: {
          active: true,
          buckets: mapData(customApiResponseAge.data.data.age, 'age'),
          bucketsCount: customApiResponseAge.data.data.total_records,
          key: 'productAgeRanges',
          name: 'Age',
        },
        skill: {
          active: true,
          buckets: mapData(customApiResponseSkill.data.data.skill, 'skill'),
          bucketsCount: customApiResponseSkill.data.data.total_records,
          key: 'productSkills',
          name: 'Skills',
        },
        category: {
          active: true,
          buckets: mapData(customApiResponseCategory.data.data.category, 'category'),
          bucketsCount: customApiResponseCategory.data.data.total_records,
          key: 'productCategories',
          name: 'Category',
        },
        brand: {
          active: true,
          buckets: mapData(customApiResponseBrand.data.data.brand, 'brand'),
          bucketsCount: customApiResponseBrand.data.data.total_records,
          key: 'productBrands',
          name: 'Brand',
        },
      };

      await dispatch('storeQueryResultsLeft', { response });
      commit(types.SET_HAS_ERROR, false);
    } catch (error) {
      commit(types.SET_BUSY, false);
      throw error;
    }
  },

  async storeQueryResultsLeft({ dispatch }, { response }: any) {
    this.$logger.store(`action`, `[products/storeQueryResults]`, { response });
    const jobs = [
      dispatch('associations/setCurrent', response.meta.associations),
      dispatch('setProductsCount', response.meta.total),
      dispatch('pagination/setPerPage', response.meta.perPage),
      dispatch('pagination/setCurrentPage', response.meta.currentPage),
      dispatch('pagination/setLastPage', response.meta.lastPage),
      dispatch('associations/setHeaderData', response.meta.headerData),
      dispatch('search/setSpellingSuggestions', response.meta.suggestions),
    ];
    await Promise.all(jobs);
  },

  async makeQuery(
    { commit, dispatch }: ActionContext<any, any>,
    {
      resetCurrentPage = false,
      shouldUpdateUrl = true,
      pagePath = '/toys/all',
      defaultapi = false, // added defaultapi parameter
    }: MakeQueryPayload = {}
  ): Promise<void> {
    commit(types.SET_BUSY, true);
    commit(types.SET_LOADING, true);

    let filters: any;
    let searchFilter: any;
    try {
      filters = await dispatch('filters/refreshFilters', resetCurrentPage);
      searchFilter = filters;
    } catch (error) {
      commit(types.SET_BUSY, false);
      throw error;
    }

    const queryParams: QueryParams = productQuery.api.makeQueryParams(filters);
    if (
      window.location.href.includes('toys/brand') ||
      window.location.href.includes('toys/age') ||
      window.location.href.includes('toys/category')
    ) {
      shouldUpdateUrl = false;
    }
    if (shouldUpdateUrl) {
      const userFacingParams: Partial<BrowserQueryParams> = productQuery.transformFiltersToParams(filters);
      const url = createProductsUrlWithQuery(
        this.$router.app.$route.name as string,
        pagePath,
        userFacingParams
      );
      this.$router.push(url);
    }
    this.$logger.store(`action`, `[products/makeQuery] Query params`, { queryParams });

    try {
      if (cancelTokenSource) {
        cancelTokenSource.cancel('Operation canceled due to new request.');
      }
      cancelTokenSource = axios.CancelToken.source();

      queryParams.associations = 1;
      const toCamelCase = (str: string): string => str.replace(/_([a-z])/g, (_, p1) => p1.toUpperCase());
      const convertKeys = (obj: any): any => {
        if (Array.isArray(obj)) {
          return obj.map((item) => convertKeys(item));
        } else if (typeof obj === 'object' && obj !== null) {
          const newObj: { [key: string]: any } = {};
          for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
              const newKey = toCamelCase(key);
              newObj[newKey] = convertKeys(obj[key]);
            }
          }
          return newObj;
        }
        return obj;
      };

      let meta: any = {};
      let response: any = {};

      const apiUseHttps = process.env.protocol?.toLocaleLowerCase() === 'true';
      const protocol = apiUseHttps ? 'https' : 'http';

      const params: { [key: string]: any } = {
        ...(searchFilter.sort?.sort && { orderBy: searchFilter.sort.sort.orderBy }),
        ...(searchFilter.perPage && { per_page: searchFilter.perPage }),
        ...(searchFilter.sort?.sort?.sortedBy && { sortedBy: searchFilter.sort.sort.sortedBy.toUpperCase() }),
        ...(searchFilter.hideNotInStock && { hideNotInStock: searchFilter.hideNotInStock }),
        ...(searchFilter.hidePrevious && { hidePrevious: searchFilter.hidePrevious }),
        ...(searchFilter.search && { search: searchFilter.search }),
        ...(searchFilter.range &&
          searchFilter.range.from !== undefined && { tokenfrom: searchFilter.range.from }),
        ...(searchFilter.range && searchFilter.range.to !== undefined && { tokento: searchFilter.range.to }),
        ...(searchFilter.page && { page: searchFilter.page }),
      };

      for (const key in searchFilter.associations) {
        if (
          Object.prototype.hasOwnProperty.call(searchFilter.associations, key) &&
          Array.isArray(searchFilter.associations[key]) &&
          searchFilter.associations[key].length > 0
        ) {
          params[key] = searchFilter.associations[key].map((item: any) => item.slug).join(';');
        }
      }
      const hasNonPageParams =
        Object.keys(params).filter((key) => {
          return key !== 'page' && !(key === 'hideNotInStock' && params[key] === true);
        }).length > 0;

      if (
        (window.location.href.includes('toys/all') ||
          window.location.href.includes('toys/brand') ||
          window.location.href.includes('toys/age') ||
          window.location.href.includes('toys/category')) &&
        hasNonPageParams
      ) {
        // check for defaultapi
        const apiUrl = `${protocol}://${process.env.baseHost}:${process.env.basePort}/api/guest/filter/productFilter`;
        response = await axios.get(apiUrl, { params, cancelToken: cancelTokenSource.token });
        meta = response.data?.meta || {};
      }
      response.meta = response.meta || {};
      response.meta.associations = response.meta.associations || {};

      const responseConverted = convertKeys(response.data || {});
      response.data = responseConverted.data || {};
      if (!defaultapi) {
        const apiUrls = [
          `${protocol}://${process.env.baseHost}:${process.env.basePort}/api/guest/filter/productFilterList/age`,
          `${protocol}://${process.env.baseHost}:${process.env.basePort}/api/guest/filter/productFilterList/skill`,
          `${protocol}://${process.env.baseHost}:${process.env.basePort}/api/guest/filter/productFilterList/brand`,
          `${protocol}://${process.env.baseHost}:${process.env.basePort}/api/guest/filter/productFilterList/category`,
        ];

        const [
          customApiResponseAge,
          customApiResponseSkill,
          customApiResponseBrand,
          customApiResponseCategory,
        ] = await Promise.all(apiUrls.map((url) => axios.get(url, { cancelToken: cancelTokenSource.token })));

        const mapData = (data: any[], key: string): AssociationData[] =>
          data.map((item: any) => ({
            active: item.active,
            association: {
              ageGroup: item.age_group,
              associationClass: item.association_class,
              position: item.position,
              resourceType: item.resource_type,
              slug: item.slug,
              ...(key === 'skill' && { skillGroup: item.skill_group }),
            },
            internalName: item.internal_name,
            name: item.name,
            productsCount: item.products_count,
            id: item.id,
            hasPage: true,
            slug: item.slug,
            headerDescription: item.descreption,
            position: item.position,
            ageGroup: item.age_group,
            resourceType: item.resource_type,
            associationClass: item.association_class,
          }));

        response.meta.associations.age = {
          active: true,
          buckets: mapData(customApiResponseAge.data.data.age, 'age'),
          bucketsCount: customApiResponseAge.data.data.total_records,
          key: 'productAgeRanges',
          name: 'Age',
        };

        response.meta.associations.skill = {
          active: true,
          buckets: mapData(customApiResponseSkill.data.data.skill, 'skill'),
          bucketsCount: customApiResponseSkill.data.data.total_records,
          key: 'productSkills',
          name: 'Skills',
        };

        response.meta.associations.category = {
          active: true,
          buckets: mapData(customApiResponseCategory.data.data.category, 'category'),
          bucketsCount: customApiResponseCategory.data.data.total_records,
          key: 'productCategories',
          name: 'Category',
        };

        response.meta.associations.brand = {
          active: true,
          buckets: mapData(customApiResponseBrand.data.data.brand, 'brand'),
          bucketsCount: customApiResponseBrand.data.data.total_records,
          key: 'productBrands',
          name: 'Brand',
        };

        response = {
          data: response.data,
          meta: {
            associations:
              Object.keys(response.meta.associations).length > 0 ? response.meta.associations : {},
            currentPage: meta.currentPage,
            from: 1,
            headerData: null,
            lastPage: meta.lastPage,
            perPage: meta.perPage,
            prevPageUrl: null,
            to: 1,
            total: meta.total,
            suggestions: [],
          },
          success: true,
          errors: {},
          messages: {},
        };
      }
      window.scrollTo(0, 0);
      await dispatch('storeQueryResults', { response });
      commit(types.SET_HAS_ERROR, false);
      commit(types.SET_LOADING, false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
        commit(types.SET_LOADING, false);
      } else {
        commit(types.SET_BUSY, false);
        commit(types.SET_LOADING, false);
        commit(types.SET_HAS_ERROR, true);
        throw error;
      }
    }
    commit(types.SET_BUSY, false);
  },

  async storeQueryResults({ commit, dispatch }, { response }: any) {
    this.$logger.store(`action`, `[products/storeQueryResults]`, { response });
    if (response.meta?.associations && Object.keys(response.meta.associations).length > 0) {
      await dispatch('associations/setCurrent', response.meta.associations);
    }
    const jobs = [
      response.meta?.total !== undefined
        ? dispatch('setProductsCount', response.meta.total)
        : Promise.resolve(),
      response.meta?.perPage !== undefined
        ? dispatch('pagination/setPerPage', response.meta.perPage)
        : Promise.resolve(),
      response.meta?.currentPage !== undefined
        ? dispatch('pagination/setCurrentPage', response.meta.currentPage)
        : Promise.resolve(),
      response.meta?.lastPage !== undefined
        ? dispatch('pagination/setLastPage', response.meta.lastPage)
        : Promise.resolve(),
      response.meta?.headerData
        ? dispatch('associations/setHeaderData', response.meta.headerData)
        : Promise.resolve(),
      response.meta?.suggestions
        ? dispatch('search/setSpellingSuggestions', response.meta.suggestions)
        : Promise.resolve(),
    ];
    commit(types.PUT_PRODUCTS, response.data);
    await Promise.all(jobs);
  },

  async resetAll({ dispatch }) {
    this.$logger.store(`action`, `[products/resetAll]`);

    const jobs = [
      dispatch('associations/removeAll'),
      dispatch('range/resetRange'),
      dispatch('search/reset'),
      dispatch('filters/clearFilters'),
      dispatch('pagination/reset'),
      dispatch('toggleHidePrevious', false),
      dispatch('toggleHideNotInStock', true),
    ];
    await Promise.all(jobs);
  },

  toggleHidePrevious({ commit }, shouldHidePrevious: boolean) {
    this.$logger.store(`action`, `[products/toggleHidePrevious]`, { shouldHidePrevious });

    commit(types.SET_HIDE_PREVIOUS, shouldHidePrevious);
  },

  toggleHideNotInStock({ commit }, shouldHideNotInStock: boolean) {
    this.$logger.store(`action`, `[products/toggleHideNotInStock]`, { shouldHideNotInStock });

    commit(types.SET_HIDE_NOT_IN_STOCK, shouldHideNotInStock);
  },

  setProductsCount({ commit }, count: number) {
    this.$logger.store(`action`, `[products/setProductsCount]`, { count });

    commit(types.SET_PRODUCTS_COUNT, count);
  },
};

export const mutations: MutationTree<ModuleState> = {
  [types.SET_BUSY](state, isBusy: boolean) {
    state.busy = isBusy;
  },
  [types.SET_LOADING](state, isLoading: boolean) {
    state.loading = isLoading;
  },
  [types.SET_HAS_ERROR](state, hasError: boolean) {
    state.hasError = hasError;
  },
  [types.PUT_PRODUCTS](state, products: any) {
    state.all = products;
  },
  [types.SET_HIDE_PREVIOUS](state, shouldHidePrevious: boolean) {
    state.hidePrevious = shouldHidePrevious;
  },
  [types.SET_HIDE_NOT_IN_STOCK](state, shouldHideNotInStock: boolean) {
    state.hideNotInStock = shouldHideNotInStock;
  },
  [types.SET_PRODUCTS_COUNT](state, count: number) {
    state.count = count;
  },
};
