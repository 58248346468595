// @ts-ignore
import Form from 'vform';
// @ts-ignore
import { token } from 'gen-uid';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '~/store/index';
import { stepsMeta, StepMeta, successMeta } from '~/config/gift/checkout/stepMeta';
import * as CHECKOUT_STEP from '~/constants/gifting/checkoutSteps';
import { DeliveryOption } from '~/types/delivery-option';
import { Checkout } from '~/types/basket';
import { deleteKeysWithEmptyStrings } from '~/helpers/objects';
import { GiftOrderBreakdown } from '~/types/gift';
import { DELIVERY_ADDON, GIFTING } from '~/constants/shipping-addon-types';
import { submitAttributionSource } from '~/helpers/attribution-source';
import { GIFT_TYPE } from '~/constants/sourceable-types';

export const types = {
  SET_AS_GUEST: 'SET_AS_GUEST',
  SET_BUSY: 'SET_BUSY',
  SET_STEP_META: 'SET_STEP_META',
  SET_USER_DETAILS: 'SET_USER_DETAILS',
  SET_DELIVERY_DETAILS: 'SET_DELIVERY_DETAILS',
  SET_STRIPE_TOKEN: 'SET_STRIPE_TOKEN',
  SET_IDEMPOTENCY_KEY: 'SET_IDEMPOTENCY_KEY',
  SET_GIFTING_TERMS: 'SET_GIFTING_TERMS',
  SET_ERRORS: 'SET_ERRORS',
  SET_SUCCESS_ORDER: 'SET_SUCCESS_ORDER',
  SET_DELIVERY_OPTIONS: 'SET_DELIVERY_OPTIONS',
};

export interface CheckoutUserDetails {
  firstName: string;
  lastName: string;
  email: string;
}

export interface CheckoutDeliveryDetails {
  toGiftRecipient: boolean;
  firstName: string;
  lastName: string;
  email: string;
  address1: string;
  address2: string;
  city: string;
  postcode: string;
  message: string;
  deliveryOption: DeliveryOption | null;
  nominatedDeliveryDate: Date | null;
  electronicDeliveryDate: Date | null;
}

export interface ModuleState {
  asGuest: boolean;
  busy: boolean;
  giftingTerms: boolean;
  stripeToken: string | null;
  idempotencyKey: string | null;
  stepMeta: StepMeta;
  giftValue: number;
  userDetails: CheckoutUserDetails;
  deliveryDetails: CheckoutDeliveryDetails;
  deliveryOptions: Array<DeliveryOption>;
  errors: Record<string, Array<string>>;
  successOrderSummary: GiftOrderBreakdown | null;
}

export const state = (): ModuleState => ({
  asGuest: false,
  busy: false,
  giftingTerms: false,
  stripeToken: null,
  idempotencyKey: null,
  stepMeta: stepsMeta[CHECKOUT_STEP.GUEST_OR_USER],
  giftValue: 0,
  userDetails: {
    firstName: '',
    lastName: '',
    email: '',
  },
  deliveryDetails: {
    toGiftRecipient: false,
    firstName: '',
    lastName: '',
    email: '',
    address1: '',
    address2: '',
    city: '',
    postcode: '',
    message: '',
    deliveryOption: null,
    nominatedDeliveryDate: null,
    electronicDeliveryDate: null,
  },
  deliveryOptions: [],
  errors: {},
  successOrderSummary: null,
});

export const getters: GetterTree<ModuleState, RootState> = {
  checkoutHint: (_state, _getters, rootState): Checkout | null => {
    if (rootState.gift.gift && rootState.gift.gift.checkout) {
      return rootState.gift.gift.checkout;
    }

    return {
      canCheckout: false,
      message: 'You need to create a gift first',
    };
  },

  giftOrderSummary: (_state, _getters, rootState, rootGetters): GiftOrderBreakdown => {
    return {
      costItems: rootGetters['gift/costBreakdown'],
      toyAllowance: rootGetters['gift/toyAllowanceBreakdown'],
      totalCost: rootGetters['gift/totalCost'],
      giftBasketValue: rootGetters['gift/basket/value'],
      basket: rootState.gift.basket.data,
    };
  },
  deliveryAddons(state): Array<DeliveryOption> | undefined {
    return state.deliveryOptions.filter((addon) => addon.typeId === DELIVERY_ADDON);
  },
  giftingAndDeliveryAddons(state): Array<DeliveryOption> | undefined {
    const giftingAddon = state.deliveryOptions.filter((addon) => addon.typeId === GIFTING);
    const deliveryAddons = state.deliveryOptions.filter((addon) => addon.typeId === DELIVERY_ADDON);
    return giftingAddon.concat(deliveryAddons);
  },
};

export const actions: ActionTree<ModuleState, RootState> = {
  async initialiseCheckout({ commit, dispatch, getters, rootState }) {
    this.$logger.store(`action`, `[gift/checkout/initialiseCheckout]`);

    const getGift = dispatch('gift/initialiseModule', null, { root: true });
    commit(types.SET_IDEMPOTENCY_KEY, token());

    const step = this.$auth.loggedIn ? CHECKOUT_STEP.DELIVERY_DETAILS : CHECKOUT_STEP.GUEST_OR_USER;

    if (this.$auth.loggedIn) {
      commit(types.SET_USER_DETAILS, {
        firstName: this.$auth.user.firstname,
        lastName: this.$auth.user.lastname,
        email: this.$auth.user.email,
      });
    }

    await getGift;
    if (rootState.gift.gift === null || !getters.checkoutHint.canCheckout) {
      const message: string = `Cannot checkout because ${
        rootState.gift.gift === null ? 'Gift does not exist' : getters.checkoutHint.message
      }`;

      this.$logger.store(`action`, `[gift/checkout/initialiseCheckout] ${message}`);
      return this.$router.push('/gift/checkout/error');
    }

    await dispatch('setStep', { stepName: step, replace: true });
  },
  async initialiseGiftShippingAddons({ commit }) {
    this.$logger.store(`action`, `[gift/checkout/initialiseGiftShippingAddons]`);
    let response;
    try {
      response = await this.$whirli.guests.shippingAddons.all({
        params: {
          isGift: true,
          match: 'type_id:1;type_id:4',
        },
      });
    } catch (error) {
      throw error;
    }

    if (response.data) {
      commit(types.SET_DELIVERY_OPTIONS, response.data);
    } else {
      throw new Error('No shipping addons could be retrieved - please refresh and try again.');
    }
  },
  async proceedAsGuest({ commit, dispatch }) {
    this.$logger.store(`action`, `[gift/checkout/proceedAsGuest]`);

    const setStep = dispatch('setStep', { stepName: CHECKOUT_STEP.ABOUT_YOU });
    commit(types.SET_AS_GUEST, true);
    await setStep;
  },
  async proceedToDeliveryDetails({ dispatch }) {
    this.$logger.store(`action`, `[gift/checkout/proceedToDeliveryDetails]`);

    await dispatch('setStep', { stepName: CHECKOUT_STEP.DELIVERY_DETAILS });
  },
  async proceedToBilling({ dispatch }) {
    this.$logger.store(`action`, `[gift/checkout/proceedToBilling]`);

    await dispatch('setStep', { stepName: CHECKOUT_STEP.BILLING });
  },
  async submitCheckout({ commit, dispatch, rootState }) {
    this.$logger.store(`action`, `[gift/checkout/submitCheckout] Submitting checkout`);

    if (rootState.gift.gift === null || !rootState.gift.gift.id) {
      throw new Error('No active gift found - please create one on the gifting page');
    }
    commit(types.SET_BUSY, true);

    const data = await dispatch('getGiftPayData');

    let response = null;

    try {
      if (this.$auth.loggedIn) {
        response = await this.$whirli.users.gifts.placeOrder(rootState.gift.gift.id, data);
      } else {
        const cachedGift = this.$gifts.guest.getCachedGift();
        if (!cachedGift) {
          throw new Error(
            'Cannot purchase gift as guest - no gift access token found. Please create a new gift and try again.'
          );
        }

        data.guestToken = cachedGift.accessToken;

        response = await this.$whirli.guests.gifts.placeOrder(rootState.gift.gift.id, data);
      }

      if (response.success) {
        if (response.data && response.data.requiresAction) {
          const result = await this.$stripe.confirmCardPayment(response.data.paymentIntentClientSecret);
          if (!result.paymentIntent || result.paymentIntent.status !== 'succeeded') {
            throw new Error('2Factor authentication failed. Please try again or contact Whirli for support.');
          }
          response = await this.$whirli.guests.paymentIntents.succeeded(result.paymentIntent.id);
        }
      }
    } catch (error) {
      commit(types.SET_IDEMPOTENCY_KEY, token());
      if (error.response && error.response.data && error.response.data.errors) {
        commit(types.SET_ERRORS, new Form().extractErrors(error.response));
      } else {
        commit(types.SET_ERRORS, [error.message]);
      }
      commit(types.SET_BUSY, false);
      return;
    }
    if (response.data && response.data.value) {
      const revenue = (response.data.value / 100).toFixed(2);

      this.$gtm.analytics.event('Gifting', 'With toys', response.data.hasToys ? 'Yes' : 'No');
      this.$gtm.purchaseGift(revenue);
      this.$gtm.login({
        ...response.data,
        email: response.data.billingEmail,
        firstname: response.data.billingFirstname,
        lastname: response.data.billingLastname,
        addresses: [],
      });
      if (response.data.hasToys) {
        const products = [];
        response.data.giftBasket.basket.basketLines.forEach((basketLine) => {
          products.push({
            productName: basketLine.productVariant.product.name,
            sku: basketLine.productVariant.product.sku,
            slug: basketLine.productVariant.product.slug,
            price: basketLine.productVariant.product.tokens.toFixed(2),
            quantity: basketLine.quantity,
          });
        });
        this.$gtm.purchaseGiftToy(
          ((response.data.value + response.data.shippingFee) / 100).toFixed(2),
          products,
          (response.data.shippingFee / 100).toFixed(2),
          revenue
        );
      }
    }

    submitAttributionSource(GIFT_TYPE, response.data.id, this, data.discountCode);
    await dispatch('setSuccessOrderSummary');

    commit(types.SET_STEP_META, successMeta);

    this.$gifts.giftMode.toggle(false);
    await this.$gifts.guest.clearCachedGift();

    await this.$router.push(successMeta.url);

    commit(types.SET_BUSY, false);

    this.$logger.store(`action`, `[gift/checkout/submitCheckout] Checkout successful`);
  },
  getGiftPayData({ state, rootState }) {
    const payload = {
      idempotencyKey: state.idempotencyKey,
      stripeToken: state.stripeToken,
      discountCode: rootState.gift.discountCode.code.name,
      billingFirstname: state.userDetails.firstName,
      billingLastname: state.userDetails.lastName,
      billingEmail: state.userDetails.email,

      toGiftRecipient: state.deliveryDetails.toGiftRecipient,
      shippingFirstname: state.deliveryDetails.firstName,
      shippingLastname: state.deliveryDetails.lastName,
      shippingEmail: state.deliveryDetails.email,
      shippingAddress: state.deliveryDetails.address1,
      shippingAddressTwo: state.deliveryDetails.address2,
      shippingCity: state.deliveryDetails.city,
      shippingZip: state.deliveryDetails.postcode,
      message: state.deliveryDetails.message,
      shippingAddonId: state.deliveryDetails.deliveryOption ? state.deliveryDetails.deliveryOption.id : null,
      nominatedDeliveryDate: state.deliveryDetails.nominatedDeliveryDate
        ? state.deliveryDetails.nominatedDeliveryDate.toDateString()
        : '',
      electronicDeliveryDate: state.deliveryDetails.electronicDeliveryDate
        ? state.deliveryDetails.electronicDeliveryDate.toDateString()
        : null,
      giftingTerms: state.giftingTerms,
    };

    return deleteKeysWithEmptyStrings(payload);
  },
  async backToAboutYou({ dispatch }) {
    this.$logger.store(`action`, `[gift/checkout/backToAboutYou]`);

    await dispatch('setStep', { stepName: CHECKOUT_STEP.ABOUT_YOU });
  },
  async backToDeliveryDetails({ dispatch }) {
    this.$logger.store(`action`, `[gift/checkout/backToDeliveryDetails]`);

    await dispatch('setStep', { stepName: CHECKOUT_STEP.DELIVERY_DETAILS });
  },
  async setStep({ commit }, { stepName, replace }: { stepName: number; replace: boolean }) {
    this.$logger.store(`action`, `[gift/checkout/setStep]`, { stepName, replace });

    const stepMeta = stepsMeta[stepName];

    commit(types.SET_STEP_META, stepMeta);

    const method = replace ? 'replace' : 'push';
    if (stepMeta.url !== this.$router.currentRoute.path) {
      await this.$router[method](stepMeta.url);
    }
  },

  async setSuccessOrderSummary({ commit, getters, rootGetters }) {
    this.$logger.store(`action`, `[gift/checkout/setSuccessOrderSummary]`);

    const order = await getters.giftOrderSummary;
    order.discountValue = rootGetters['gift/discountCode/getValue'];
    order.discountValueTypeId = rootGetters['gift/discountCode/getValueTypeId'];
    commit(types.SET_SUCCESS_ORDER, order);
  },
};

export const mutations: MutationTree<ModuleState> = {
  [types.SET_AS_GUEST](state, asGuest: boolean) {
    state.asGuest = asGuest;
  },
  [types.SET_BUSY](state, busy: boolean) {
    state.busy = busy;
  },
  [types.SET_STEP_META](state, stepMeta: StepMeta) {
    state.stepMeta = stepMeta;
  },
  [types.SET_USER_DETAILS](state, userDetails: CheckoutUserDetails) {
    state.userDetails = { ...state.userDetails, ...userDetails };
  },
  [types.SET_DELIVERY_DETAILS](state, deliveryDetails: Partial<CheckoutDeliveryDetails>) {
    state.deliveryDetails = { ...state.deliveryDetails, ...deliveryDetails };
  },
  [types.SET_STRIPE_TOKEN](state, { stripeToken }: { stripeToken: string | null }) {
    state.stripeToken = stripeToken;
  },
  [types.SET_IDEMPOTENCY_KEY](state, idempotencyKey: string | null) {
    state.idempotencyKey = idempotencyKey;
  },
  [types.SET_GIFTING_TERMS](state, terms: boolean) {
    state.giftingTerms = terms;
  },
  [types.SET_ERRORS](state, errors: any) {
    state.errors = errors;
  },
  [types.SET_SUCCESS_ORDER](state, order: GiftOrderBreakdown) {
    state.successOrderSummary = order;
  },
  [types.SET_DELIVERY_OPTIONS](state, options: Array<DeliveryOption>) {
    state.deliveryOptions = options;
  },
};
