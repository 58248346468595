import { render, staticRenderFns } from "./NavIconLink.vue?vue&type=template&id=140dc1fd&scoped=true&"
import script from "./NavIconLink.vue?vue&type=script&lang=ts&"
export * from "./NavIconLink.vue?vue&type=script&lang=ts&"
import style0 from "./NavIconLink.vue?vue&type=style&index=0&id=140dc1fd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "140dc1fd",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/whirli-uwa/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('140dc1fd')) {
      api.createRecord('140dc1fd', component.options)
    } else {
      api.reload('140dc1fd', component.options)
    }
    module.hot.accept("./NavIconLink.vue?vue&type=template&id=140dc1fd&scoped=true&", function () {
      api.rerender('140dc1fd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/prefabs/navigation/NavIconLink/NavIconLink.vue"
export default component.exports