import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '~/store/index';
import { Toy } from '~/types/toybox';
import { tokensInToybox } from '~/helpers/models/user';
import { DESIGNATED_FOR_RETURN } from '~/constants/stock-status-ids';

const types = {
  PUT_TOYBOX: 'PUT_TOYBOX',
  SET_TOYBOX_FETCHED: 'SET_TOYBOX_FETCHED',
  SET_BUSY: 'SET_BUSY',
  SET_TOKENS_USED: 'SET_TOKENS_USED',
  PUT_RETURN_ITEM: 'PUT_RETURN_ITEM',
  REMOVE_RETURN_ITEM: 'REMOVE_RETURN_ITEM',
  UPDATE_ITEM_STATUS: 'UPDATE_ITEM_STATUS',
};

interface ModuleState {
  items: Array<Toy>;
  isFetched?: boolean;
  isBusy?: boolean;
  tokensUsed?: number;
}

export const state = (): ModuleState => ({
  items: [],
  isFetched: false,
  isBusy: false,
  tokensUsed: 0, // Tokens in toybox - DESIGNATED_FOR_RETURN
});

// @TODO getter for items FOR return and IN return.

export const actions: ActionTree<ModuleState, RootState> = {
  /**
   * Hydrate toybox module with initial state data, fetched when the toybox is loaded.
   * @param {function} commit
   * @param {object} rootState
   * @param {object} state
   */
  async initialState({ commit, dispatch, rootState, state }) {
    this.$logger.store(`action`, `[toybox/initialState]`, { state });

    if (state.isFetched || !rootState.auth.loggedIn) return;

    let response: any;
    commit(types.SET_BUSY, true);

    try {
      response = await this.$whirli.users.toybox.get();
    } catch (error) {
      commit(types.SET_BUSY, true);
      return new Error(error);
    }

    if (!response || !response.data) {
      commit(types.SET_BUSY, true);
      return;
    }

    if (response.meta.checkout) {
      dispatch('updateCheckoutStatus', response.meta.checkout);
    }

    const toybox: any = response.data;
    const tokensUsed: number = tokensInToybox(this.$auth.user);

    commit(types.SET_TOKENS_USED, tokensUsed);
    commit(types.PUT_TOYBOX, toybox);
    commit(types.SET_TOYBOX_FETCHED, true);

    commit(types.SET_BUSY, false);
  },

  /**
   * Designates toy for return.
   * @param {function} commit
   * @param {object} stockId stock ID of product from Toybox to be returned.
   */
  async designateToyForReturn({ commit, dispatch }, { stockId }: { stockId: string }) {
    this.$logger.store(`action`, `[toybox/designateToyForReturn]`, { stockId });

    if (!stockId) return new Error('[toybox/designateToyForReturn] stockId is undefined.');

    commit(types.SET_BUSY, true);
    try {
      const response = await this.$whirli.users.toybox.designateToyForReturn(stockId);

      if (response.httpCode < 300 && response.data && response.data.user) {
        const tokensUsed = tokensInToybox(response.data.user);
        commit(types.SET_TOKENS_USED, tokensUsed);
        if (response.data.checkout) {
          dispatch('updateCheckoutStatus', response.data.checkout);
        }
      }

      commit(types.UPDATE_ITEM_STATUS, response.data);
      commit(types.SET_BUSY, false);
    } catch (error) {
      // @TODO add system message when implemented.
      commit(types.SET_BUSY, false);
      throw error;
    }
  },

  updateCheckoutStatus({ commit }, checkout) {
    this.$logger.store(`action`, `[toybox/updateCheckoutStatus]`, { checkout });

    commit(
      'checkout/PUT_STATUS_AND_MESSAGE',
      { status: checkout.canCheckout, message: checkout.message },
      { root: true }
    );
  },

  /**
   * Un-designates toy from return.
   * @param {function} commit
   * @param {object} stockId stock ID of product from Toybox to be returned.
   */
  async undesignateToyForReturn({ commit, dispatch, state }, { stockId }: { stockId: string }) {
    this.$logger.store(`action`, `[toybox/undesignateToyForReturn]`, { stockId });

    if (!stockId) return new Error('[toybox/undesignateToyForReturn] stockId is undefined.');

    commit(types.SET_BUSY, true);
    try {
      const response = await this.$whirli.users.toybox.undesignateToyForReturn(stockId);
      if (response.httpCode < 300 && response.data && response.data.user) {
        const tokensUsed = tokensInToybox(response.data.user);
        commit(types.SET_TOKENS_USED, tokensUsed);
        if (response.data.checkout) {
          dispatch('updateCheckoutStatus', response.data.checkout);
        }
      }

      commit(types.UPDATE_ITEM_STATUS, response.data);
      commit(types.SET_BUSY, false);

      if (state.items.filter((toy) => toy.statusId === DESIGNATED_FOR_RETURN).length === 0) {
        await dispatch('checkout/validateCanCheckout', null, { root: true });
      }
    } catch (error) {
      // @TODO add system message when implemented.
      commit(types.SET_BUSY, false);
      throw error;
    }
  },

  /**
   * Set tokens used in toybox minus toys DESIGNATED_FOR_RETURN
   * @param commit
   * @param tokensUsed
   */
  setTokensUsed({ commit }, tokensUsed: number) {
    this.$logger.store(`action`, `[toybox/setTokensUsed]`, { tokensUsed });

    commit(types.SET_TOKENS_USED, tokensUsed);
  },
};

export const mutations: MutationTree<ModuleState> = {
  [types.PUT_TOYBOX](state, toybox: Array<Toy>) {
    state.items = toybox;
  },
  [types.SET_TOYBOX_FETCHED](state, fetched: boolean) {
    state.isFetched = fetched;
  },
  [types.SET_BUSY](state, isBusy: boolean) {
    state.isBusy = isBusy;
  },
  [types.SET_TOKENS_USED](state, tokensUsed: number) {
    state.tokensUsed = tokensUsed;
  },
  [types.UPDATE_ITEM_STATUS](state, stockItem) {
    const index = state.items.findIndex((item) => item.id === stockItem.id);
    state.items[index].statusId = stockItem.statusId;
  },
};
