import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '~/store/index';

const types = {
  PUT_OVERLAY_STATE: 'PUT_OVERLAY_STATE',
  REMOVE_OVERLAY_USER: 'REMOVE_OVERLAY_USER',
  RESET_OVERLAY_STATE: 'RESET_OVERLAY_STATE',
};

export interface ToggleTypes {
  coverNav?: boolean;
  usedBy: string;
}

interface ModuleState {
  coverNav: boolean;
  usedBy: Array<string>;
}

export const state = (): ModuleState => ({
  coverNav: true,
  usedBy: [],
});

export const actions: ActionTree<ModuleState, RootState> = {
  toggleOverlay({ state, commit }, { coverNav = true, usedBy }: ToggleTypes) {
    this.$logger.store(`action`, `[pageConfig/initialState]`, { coverNav, usedBy });
    if (!usedBy || !usedBy.length) throw new Error('[overlay/toggleOverlay] usedBy is undefined.');
    if (state.usedBy.includes(usedBy)) {
      const updatedUsedBy: Array<string> = state.usedBy.filter((user: string) => user !== usedBy);
      commit(types.PUT_OVERLAY_STATE, { coverNav, usedBy: updatedUsedBy });
    } else {
      commit(types.PUT_OVERLAY_STATE, { coverNav, usedBy: [...state.usedBy, usedBy] });
    }
  },
};

export const mutations: MutationTree<ModuleState> = {
  [types.PUT_OVERLAY_STATE](state, newState: ModuleState) {
    state.coverNav = newState.coverNav;
    state.usedBy = newState.usedBy;
  },
};
