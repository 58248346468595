













































































































































































































































































































import _ from 'lodash';
import 'reflect-metadata';
import { Component, mixins, namespace, Watch } from 'nuxt-property-decorator';
import NavMixin from '../navMixin.vue';
import NavBagPill from '../NavBagPill/NavBagPill.vue';
import NavDropdown from './NavDropdown.vue';
import NavAccountDropdown from './NavAccountDropdown.vue';
import WhirliLogo from '~/components/partials/logo/WhirliLogo/WhirliLogo.vue';
import ButtonIcon from '~/components/partials/icons/ButtonIcon/ButtonIcon.vue';
import { MakeQueryPayload } from '~/store/products';
import { NavLinks } from '~/types/navigation';
import { hasActiveSubscription } from '~/helpers/models/user';
import { getIsUserChristmasPageExpired, getIsGuestChristmasPageExpired } from '~/helpers/navigation';
type availableDropdowns = 'aboutDropdownOpen' | 'accountDropdownOpen';

const BasketStore = namespace('basket');
const GiftBasketStore = namespace('gift/basket');
const RegProgressStore = namespace('registration/progress');
const ProductsStore = namespace('products');
const SearchStore = namespace('products/search');

@Component({
  components: {
    ButtonIcon,
    NavDropdown,
    NavAccountDropdown,
    NavBagPill,
    WhirliLogo,
  },
  props: {
    dataBand: {
      type: Array,
      default: () => [],
    },
    username: {
      type: String,
    },
    products: {
      type: Array,
      default: () => [],
    },
    searchInitiated: {
      type: Boolean,
      default: () => [],
    },
  },
})
export default class NavDesktopBar extends mixins(NavMixin) {
  aboutDropdownOpen: boolean = false;
  accountDropdownOpen: boolean = false;
  giftDropdownOpen: boolean = false;

  @BasketStore.Getter('hasOutOfStockLines') basketHasOutOfStockLines!: boolean;
  @GiftBasketStore.Getter('hasOutOfStockLines') giftHasOutOfStockLines!: boolean;
  @ProductsStore.Action makeQuery!: (payload: MakeQueryPayload) => void;
  @SearchStore.State term!: string;
  @SearchStore.Action setTerm!: (payload: { term: string }) => void;
  @RegProgressStore.State currentStep!: number;

  disabledNavigation: boolean = false;
  searchInitiated: boolean = false;

  searchQuery: string = '';

  filteredSuggestions: Array<{
    name: string;
    tokens: number;
    discountedValue: number | null;
    url: string;
    slug: string;
  }> = [];

  created() {
    this.toggleNavigation();
  }

  @Watch('$route.path')
  toggleNavigation(): void {
    this.disabledNavigation = this.$navigation.disabled();
  }

  showSearchInput: Boolean = false;

  toggleSearchInput() {
    this.showSearchInput = !this.showSearchInput;
    if (this.showSearchInput) {
      this.$nextTick(() => {
        const input = this.$el.querySelector('.search-input') as HTMLInputElement;
        input.focus();
      });
    }
  }

  showSuggestionList: boolean = false;

  showSuggestions() {
    this.showSuggestionList = true;
  }

  closeSuggestions() {
    this.showSuggestionList = false;
  }

  handleOutsideClick(event) {
    if (!this.$el.contains(event.target)) {
      this.closeSuggestions();
      this.searchQuery = '';
      this.products = [];
      this.searchInitiated = false;
    }
  }

  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  }

  clearSuggestions() {
    this.products = [];
    // this.brand = [];
    // this.category = [];
    this.searchQuery = '';
  }

  debouncedInput = _.debounce(this.callDebouncedFilterSuggestions, 300);

  callDebouncedFilterSuggestions() {
    this.searchInitiated = false;
    this.$emit('search-input', this.searchQuery);
  }

  highlightMatch(text) {
    if (!this.searchQuery) {
      return text;
    }
    const regex = new RegExp(`(${this.searchQuery})`, 'gi');
    return text.replace(regex, '<strong>$1</strong>');
  }

  handleScroll(event: Event) {
    const target = event.target as HTMLElement;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      this.$emit('load-more');
    }
  }

  handleEnterKey() {
    try {
      const searchData = this.searchQuery.trim();
      this.showSuggestionList = false;
      this.searchInitiated = true;
      this.$router.push({ path: '/toys/all', query: { search: searchData, page: 1 } });
      this.setTerm({ term: searchData });
      this.$emit('product-search-bar:input');
      this.$emit('search-initiated'); // Emit the event here
    } catch (error) {
      console.error('Error handling search:', error);
    }
  }

  clearSearch() {
    this.searchQuery = '';
    this.showSearchInput = false;
    this.showSuggestionList = false;
    this.filteredSuggestions = [];
    this.products = [];
    this.searchInitiated = false;
  }

  get isHomepage() {
    return this.$route.name === 'index';
  }

  get isPickToysPage(): boolean {
    return this.$route.name === 'registration-pick-toys';
  }

  get classes() {
    return {
      'v-nav-desktop-bar--scrolled': this.scrolled,
      [`v-nav-desktop-bar--${this.defaultTheme.bg}`]: this.defaultTheme.bg,
    };
  }

  get linkClasses() {
    return {
      'u-text--button': true,
      [`u-color-${this.defaultTheme.fg}`]: this.defaultTheme.fg,
    };
  }

  get hasOutOfStockLines(): boolean {
    return this.$gifts.giftMode.isOn() ? this.giftHasOutOfStockLines : this.basketHasOutOfStockLines;
  }

  get navAboutLinks(): NavLinks[] {
    return [
      { to: '/how-it-works', text: 'How it works' },
      { to: '/pricing', text: 'Pricing' },
      { to: '/reviews', text: 'Reviews' },
    ];
  }

  get navGiftLinks(): NavLinks[] {
    return [
      { to: '/gift', text: 'Gift Whirli', tagText: '10% off' },
      { to: '/gift/redeem', text: 'Redeem gift card' },
    ];
  }

  get hasActiveSubscription(): boolean {
    if (this.$auth.user) {
      return hasActiveSubscription(this.$auth.user);
    }
    return false;
  }

  get christmasLink(): string | null {
    return this.hasActiveSubscription
      ? !getIsUserChristmasPageExpired()
        ? '/christmas'
        : null
      : !getIsGuestChristmasPageExpired()
      ? '/offers/christmas'
      : null;
  }

  toggleDropdown(dropdown: availableDropdowns, isOpen: boolean) {
    this[dropdown] = isOpen;
  }
}
