












import { Vue, Component, Prop } from 'nuxt-property-decorator';

import { sizesMap } from '~/helpers/sizes';

const availableAlign = ['top', 'middle', 'bottom', 'baseline', 'stretch', 'normal'];
const availableJustify = ['center', 'left', 'right', 'between', 'around', 'evenly'];
const availableDirection = ['row', 'row-reverse', 'column', 'column-reverse'];
const availableWrap = ['nowrap', 'wrap', 'wrap-reverse', 'inherit', 'initial', 'unset'];
const availableSpacing = Object.keys(sizesMap);
const availableStackUntil = [null, 'tablet', 'smallDesk'];

/**
 * The cluster solves a common problem of grouping undetermined width, non-block elements together compactly, but with
 * consistent spacing to the sides and above or below the component. It does this without leaving extra space on the
 * end components, by stripping it away with a negative margin.
 *
 * It is great for grouping list items, buttons or anything else that needs consistent spacing.
 *
 * As it can position elements vertically and horizontally within a container, it can also be used as a direct flexbox
 * replacement. Justify will horizontally position and align will vertically position. It can be used to position
 * elements at opposite ends of a container with justify: space-between, or evenly along the container with
 * justify: space-around.
 */

@Component
export default class BaseCluster extends Vue {
  @Prop({ default: 'middle', validator: (value) => availableAlign.includes(value) })
  readonly align!: string;

  @Prop({ default: 'row', validator: (value) => availableDirection.includes(value) })
  readonly direction!: string;

  @Prop({ default: false })
  readonly rightFromTablet!: boolean;

  @Prop({ default: false })
  readonly leftFromSmallDesk!: boolean;

  @Prop({ default: false })
  readonly rightFromSmallDesk!: boolean;

  @Prop({ default: false })
  readonly betweenFromSmallDesk!: boolean;

  @Prop({ default: null, validator: (value) => availableStackUntil.includes(value) })
  readonly stackUntil!: string;

  @Prop({ default: 'center', validator: (value) => availableJustify.includes(value) })
  readonly justify!: string;

  @Prop({ default: 'base', validator: (value) => availableSpacing.includes(value) })
  readonly spacing!: string;

  @Prop({ default: 'div' })
  readonly tag!: string;

  @Prop({ default: 'nowrap', validator: (value) => availableWrap.includes(value) })
  readonly wrap!: string;

  @Prop({ default: false })
  readonly overflowVisible!: boolean;

  get classes(): object {
    return {
      'v-cluster': true,
      [`v-cluster--stack-until-${this.stackUntil}`]: this.stackUntil,
      'v-cluster--right-from-tablet': this.rightFromTablet,
      'v-cluster--left-from-smallDesk': this.leftFromSmallDesk,
      'v-cluster--right-from-smallDesk': this.rightFromSmallDesk,
      'v-cluster--between-from-smallDesk': this.betweenFromSmallDesk,
      [`v-cluster--${this.direction}`]: this.direction,
      [`v-cluster--${this.align}`]: this.align,
      [`v-cluster--${this.justify}`]: this.justify,
      [`v-cluster--${this.spacing}`]: this.spacing,
      [`v-cluster--wrap-${this.wrap}`]: this.wrap,
    };
  }
}
