import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _13e078a6 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _b56a0bf6 = () => interopDefault(import('../pages/account/change/index.vue' /* webpackChunkName: "pages/account/change/index" */))
const _41c67848 = () => interopDefault(import('../pages/account/change/summary/index.vue' /* webpackChunkName: "pages/account/change/summary/index" */))
const _591a35d5 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _1266e8f8 = () => interopDefault(import('../pages/careers/index.vue' /* webpackChunkName: "pages/careers/index" */))
const _771f0a79 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _0ce7bd85 = () => interopDefault(import('../pages/christmas/index.vue' /* webpackChunkName: "pages/christmas/index" */))
const _4511f45f = () => interopDefault(import('../pages/cleaning-and-safety/index.vue' /* webpackChunkName: "pages/cleaning-and-safety/index" */))
const _d21ca744 = () => interopDefault(import('../pages/contact-us/index.vue' /* webpackChunkName: "pages/contact-us/index" */))
const _0be3e648 = () => interopDefault(import('../pages/forgot-password/index.vue' /* webpackChunkName: "pages/forgot-password/index" */))
const _40b5a0d9 = () => interopDefault(import('../pages/frequently-asked-questions/index.vue' /* webpackChunkName: "pages/frequently-asked-questions/index" */))
const _414455e3 = () => interopDefault(import('../pages/gift/index.vue' /* webpackChunkName: "pages/gift/index" */))
const _07e048b5 = () => interopDefault(import('../pages/gifting/index.vue' /* webpackChunkName: "pages/gifting/index" */))
const _0b400da2 = () => interopDefault(import('../pages/gifting-terms-and-conditions/index.vue' /* webpackChunkName: "pages/gifting-terms-and-conditions/index" */))
const _54f721b0 = () => interopDefault(import('../pages/how-it-works/index.vue' /* webpackChunkName: "pages/how-it-works/index" */))
const _5d2ff450 = () => interopDefault(import('../pages/lists/index.vue' /* webpackChunkName: "pages/lists/index" */))
const _9d464ea8 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _2c5b8b05 = () => interopDefault(import('../pages/my-playtime/index.vue' /* webpackChunkName: "pages/my-playtime/index" */))
const _35333ac2 = () => interopDefault(import('../pages/page-list/index.vue' /* webpackChunkName: "pages/page-list/index" */))
const _4c5c826e = () => interopDefault(import('../pages/pricing/index.vue' /* webpackChunkName: "pages/pricing/index" */))
const _00a23d0a = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _4020a503 = () => interopDefault(import('../pages/refer/index.vue' /* webpackChunkName: "pages/refer/index" */))
const _58a20e2a = () => interopDefault(import('../pages/refer-a-friend/index.vue' /* webpackChunkName: "pages/refer-a-friend/index" */))
const _0ae0c8d4 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _5f8cb3a8 = () => interopDefault(import('../pages/registration/index.vue' /* webpackChunkName: "pages/registration/index" */))
const _67ff6cde = () => interopDefault(import('../pages/reviews/index.vue' /* webpackChunkName: "pages/reviews/index" */))
const _113513a7 = () => interopDefault(import('../pages/subscription-terms-and-conditions/index.vue' /* webpackChunkName: "pages/subscription-terms-and-conditions/index" */))
const _4de44d48 = () => interopDefault(import('../pages/toys/index.vue' /* webpackChunkName: "pages/toys/index" */))
const _1a33e8fc = () => interopDefault(import('../pages/website-terms-of-use/index.vue' /* webpackChunkName: "pages/website-terms-of-use/index" */))
const _74f957e2 = () => interopDefault(import('../pages/why-whirli/index.vue' /* webpackChunkName: "pages/why-whirli/index" */))
const _75acdac5 = () => interopDefault(import('../pages/gift/checkout.vue' /* webpackChunkName: "pages/gift/checkout" */))
const _0eab2115 = () => interopDefault(import('../pages/gift/checkout/about-you.vue' /* webpackChunkName: "pages/gift/checkout/about-you" */))
const _43bb343a = () => interopDefault(import('../pages/gift/checkout/account.vue' /* webpackChunkName: "pages/gift/checkout/account" */))
const _9ffbb19e = () => interopDefault(import('../pages/gift/checkout/billing.vue' /* webpackChunkName: "pages/gift/checkout/billing" */))
const _5f3abea4 = () => interopDefault(import('../pages/gift/checkout/delivery.vue' /* webpackChunkName: "pages/gift/checkout/delivery" */))
const _c040d084 = () => interopDefault(import('../pages/gift/checkout/error.vue' /* webpackChunkName: "pages/gift/checkout/error" */))
const _5ee940b9 = () => interopDefault(import('../pages/gift/checkout/success.vue' /* webpackChunkName: "pages/gift/checkout/success" */))
const _2a5e79db = () => interopDefault(import('../pages/gift/redeem.vue' /* webpackChunkName: "pages/gift/redeem" */))
const _a9c3767a = () => interopDefault(import('../pages/lp/blackfriday.vue' /* webpackChunkName: "pages/lp/blackfriday" */))
const _88516928 = () => interopDefault(import('../pages/lp/discount.vue' /* webpackChunkName: "pages/lp/discount" */))
const _30c664c8 = () => interopDefault(import('../pages/lp/flashsale.vue' /* webpackChunkName: "pages/lp/flashsale" */))
const _90847a5e = () => interopDefault(import('../pages/lp/lastmingift.vue' /* webpackChunkName: "pages/lp/lastmingift" */))
const _3dde43c1 = () => interopDefault(import('../pages/lp/offer.vue' /* webpackChunkName: "pages/lp/offer" */))
const _266f25f4 = () => interopDefault(import('../pages/lp/timeout.vue' /* webpackChunkName: "pages/lp/timeout" */))
const _773d789a = () => interopDefault(import('../pages/offers/12MFIRST50/index.vue' /* webpackChunkName: "pages/offers/12MFIRST50/index" */))
const _a8d0462c = () => interopDefault(import('../pages/offers/1MFIRST50/index.vue' /* webpackChunkName: "pages/offers/1MFIRST50/index" */))
const _46eca06c = () => interopDefault(import('../pages/offers/3MFIRST50/index.vue' /* webpackChunkName: "pages/offers/3MFIRST50/index" */))
const _a02874a2 = () => interopDefault(import('../pages/offers/6MFIRST50/index.vue' /* webpackChunkName: "pages/offers/6MFIRST50/index" */))
const _6198fb47 = () => interopDefault(import('../pages/offers/bluelightcard/index.vue' /* webpackChunkName: "pages/offers/bluelightcard/index" */))
const _2c838d86 = () => interopDefault(import('../pages/offers/christmas/index.vue' /* webpackChunkName: "pages/offers/christmas/index" */))
const _6029601c = () => interopDefault(import('../pages/offers/delivery-booster/index.vue' /* webpackChunkName: "pages/offers/delivery-booster/index" */))
const _73c6a744 = () => interopDefault(import('../pages/offers/dm/index.vue' /* webpackChunkName: "pages/offers/dm/index" */))
const _23bf093e = () => interopDefault(import('../pages/offers/dowtk/index.vue' /* webpackChunkName: "pages/offers/dowtk/index" */))
const _5d597d25 = () => interopDefault(import('../pages/offers/example/index.vue' /* webpackChunkName: "pages/offers/example/index" */))
const _466ee770 = () => interopDefault(import('../pages/offers/gms/index.vue' /* webpackChunkName: "pages/offers/gms/index" */))
const _d4769bb8 = () => interopDefault(import('../pages/offers/green-friday/index.vue' /* webpackChunkName: "pages/offers/green-friday/index" */))
const _5e4f3e9e = () => interopDefault(import('../pages/offers/influencers/index.vue' /* webpackChunkName: "pages/offers/influencers/index" */))
const _90261d18 = () => interopDefault(import('../pages/offers/insert/index.vue' /* webpackChunkName: "pages/offers/insert/index" */))
const _891784ec = () => interopDefault(import('../pages/offers/jimmysjobs/index.vue' /* webpackChunkName: "pages/offers/jimmysjobs/index" */))
const _057c0e12 = () => interopDefault(import('../pages/offers/motherkind/index.vue' /* webpackChunkName: "pages/offers/motherkind/index" */))
const _07a28375 = () => interopDefault(import('../pages/offers/MVC/index.vue' /* webpackChunkName: "pages/offers/MVC/index" */))
const _5dfa6732 = () => interopDefault(import('../pages/offers/new-10/index.vue' /* webpackChunkName: "pages/offers/new-10/index" */))
const _4e971c74 = () => interopDefault(import('../pages/offers/new-20/index.vue' /* webpackChunkName: "pages/offers/new-20/index" */))
const _3be57ef4 = () => interopDefault(import('../pages/offers/nhs/index.vue' /* webpackChunkName: "pages/offers/nhs/index" */))
const _68c826ac = () => interopDefault(import('../pages/offers/PLATFORM20/index.vue' /* webpackChunkName: "pages/offers/PLATFORM20/index" */))
const _7079cc0b = () => interopDefault(import('../pages/offers/PLATFORM30/index.vue' /* webpackChunkName: "pages/offers/PLATFORM30/index" */))
const _4c10c603 = () => interopDefault(import('../pages/offers/savoo/index.vue' /* webpackChunkName: "pages/offers/savoo/index" */))
const _678e87f6 = () => interopDefault(import('../pages/offers/scummymummies/index.vue' /* webpackChunkName: "pages/offers/scummymummies/index" */))
const _53e44cf4 = () => interopDefault(import('../pages/offers/sign-up-with-15-off/index.vue' /* webpackChunkName: "pages/offers/sign-up-with-15-off/index" */))
const _d0939a2c = () => interopDefault(import('../pages/offers/summer/index.vue' /* webpackChunkName: "pages/offers/summer/index" */))
const _66d22fce = () => interopDefault(import('../pages/offers/swap/index.vue' /* webpackChunkName: "pages/offers/swap/index" */))
const _e0358d46 = () => interopDefault(import('../pages/offers/thirty/index.vue' /* webpackChunkName: "pages/offers/thirty/index" */))
const _dee18e14 = () => interopDefault(import('../pages/offers/thirty-halfyear/index.vue' /* webpackChunkName: "pages/offers/thirty-halfyear/index" */))
const _49abfae0 = () => interopDefault(import('../pages/offers/thirty-month/index.vue' /* webpackChunkName: "pages/offers/thirty-month/index" */))
const _7d2308fa = () => interopDefault(import('../pages/offers/thirty-year/index.vue' /* webpackChunkName: "pages/offers/thirty-year/index" */))
const _3ff99709 = () => interopDefault(import('../pages/offers/toybox12/index.vue' /* webpackChunkName: "pages/offers/toybox12/index" */))
const _0dea31b8 = () => interopDefault(import('../pages/offers/toybox6/index.vue' /* webpackChunkName: "pages/offers/toybox6/index" */))
const _8f8f6592 = () => interopDefault(import('../pages/offers/toybox6-new/index.vue' /* webpackChunkName: "pages/offers/toybox6-new/index" */))
const _67b78d91 = () => interopDefault(import('../pages/offers/toytrunk/index.vue' /* webpackChunkName: "pages/offers/toytrunk/index" */))
const _b458f1cc = () => interopDefault(import('../pages/offers/try-for-5/index.vue' /* webpackChunkName: "pages/offers/try-for-5/index" */))
const _207f9200 = () => interopDefault(import('../pages/offers/two-months-free/index.vue' /* webpackChunkName: "pages/offers/two-months-free/index" */))
const _6217d622 = () => interopDefault(import('../pages/offers/update-preferences/index.vue' /* webpackChunkName: "pages/offers/update-preferences/index" */))
const _3cbf26ba = () => interopDefault(import('../pages/offers/vcloud/index.vue' /* webpackChunkName: "pages/offers/vcloud/index" */))
const _e0676fd4 = () => interopDefault(import('../pages/offers/vouchercode/index.vue' /* webpackChunkName: "pages/offers/vouchercode/index" */))
const _2100feca = () => interopDefault(import('../pages/registration/account/index.vue' /* webpackChunkName: "pages/registration/account/index" */))
const _59d5faed = () => interopDefault(import('../pages/registration/ages/index.vue' /* webpackChunkName: "pages/registration/ages/index" */))
const _ff1be4d0 = () => interopDefault(import('../pages/registration/billing/index.vue' /* webpackChunkName: "pages/registration/billing/index" */))
const _c7f1ad96 = () => interopDefault(import('../pages/registration/categories/index.vue' /* webpackChunkName: "pages/registration/categories/index" */))
const _97b3f4e0 = () => interopDefault(import('../pages/registration/delivery-booster/index.vue' /* webpackChunkName: "pages/registration/delivery-booster/index" */))
const _181a8c1e = () => interopDefault(import('../pages/registration/pick-toys/index.vue' /* webpackChunkName: "pages/registration/pick-toys/index" */))
const _879c3dc0 = () => interopDefault(import('../pages/registration/success/index.vue' /* webpackChunkName: "pages/registration/success/index" */))
const _0f06175a = () => interopDefault(import('../pages/toys/all/index.vue' /* webpackChunkName: "pages/toys/all/index" */))
const _3abf3ffe = () => interopDefault(import('../pages/lp/toy/_slug.vue' /* webpackChunkName: "pages/lp/toy/_slug" */))
const _07a2c8c2 = () => interopDefault(import('../pages/lp/toys/_association/_slug/index.vue' /* webpackChunkName: "pages/lp/toys/_association/_slug/index" */))
const _51ffbd50 = () => interopDefault(import('../pages/blog/_slug/index.vue' /* webpackChunkName: "pages/blog/_slug/index" */))
const _97b8f6d4 = () => interopDefault(import('../pages/gift-list/_slug.vue' /* webpackChunkName: "pages/gift-list/_slug" */))
const _60464398 = () => interopDefault(import('../pages/reset-password/_id.vue' /* webpackChunkName: "pages/reset-password/_id" */))
const _d5e5d5ce = () => interopDefault(import('../pages/toy/_slug.vue' /* webpackChunkName: "pages/toy/_slug" */))
const _733c558c = () => interopDefault(import('../pages/toys/_association/_slug/index.vue' /* webpackChunkName: "pages/toys/_association/_slug/index" */))
const _db107c5c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _13e078a6,
    name: "account",
    children: [{
      path: "change",
      component: _b56a0bf6,
      name: "account-change"
    }, {
      path: "change/summary",
      component: _41c67848,
      name: "account-change-summary"
    }]
  }, {
    path: "/blog",
    component: _591a35d5,
    name: "blog"
  }, {
    path: "/careers",
    component: _1266e8f8,
    name: "careers"
  }, {
    path: "/checkout",
    component: _771f0a79,
    name: "checkout"
  }, {
    path: "/christmas",
    component: _0ce7bd85,
    name: "christmas"
  }, {
    path: "/cleaning-and-safety",
    component: _4511f45f,
    name: "cleaning-and-safety"
  }, {
    path: "/contact-us",
    component: _d21ca744,
    name: "contact-us"
  }, {
    path: "/forgot-password",
    component: _0be3e648,
    name: "forgot-password"
  }, {
    path: "/frequently-asked-questions",
    component: _40b5a0d9,
    name: "frequently-asked-questions"
  }, {
    path: "/gift",
    component: _414455e3,
    name: "gift"
  }, {
    path: "/gifting",
    component: _07e048b5,
    name: "gifting"
  }, {
    path: "/gifting-terms-and-conditions",
    component: _0b400da2,
    name: "gifting-terms-and-conditions"
  }, {
    path: "/how-it-works",
    component: _54f721b0,
    name: "how-it-works"
  }, {
    path: "/lists",
    component: _5d2ff450,
    name: "lists"
  }, {
    path: "/login",
    component: _9d464ea8,
    name: "login"
  }, {
    path: "/my-playtime",
    component: _2c5b8b05,
    name: "my-playtime"
  }, {
    path: "/page-list",
    component: _35333ac2,
    name: "page-list"
  }, {
    path: "/pricing",
    component: _4c5c826e,
    name: "pricing"
  }, {
    path: "/privacy-policy",
    component: _00a23d0a,
    name: "privacy-policy"
  }, {
    path: "/refer",
    component: _4020a503,
    name: "refer"
  }, {
    path: "/refer-a-friend",
    component: _58a20e2a,
    name: "refer-a-friend"
  }, {
    path: "/register",
    component: _0ae0c8d4,
    name: "register"
  }, {
    path: "/registration",
    component: _5f8cb3a8,
    name: "registration"
  }, {
    path: "/reviews",
    component: _67ff6cde,
    name: "reviews"
  }, {
    path: "/subscription-terms-and-conditions",
    component: _113513a7,
    name: "subscription-terms-and-conditions"
  }, {
    path: "/toys",
    component: _4de44d48,
    name: "toys"
  }, {
    path: "/website-terms-of-use",
    component: _1a33e8fc,
    name: "website-terms-of-use"
  }, {
    path: "/why-whirli",
    component: _74f957e2,
    name: "why-whirli"
  }, {
    path: "/gift/checkout",
    component: _75acdac5,
    name: "gift-checkout",
    children: [{
      path: "about-you",
      component: _0eab2115,
      name: "gift-checkout-about-you"
    }, {
      path: "account",
      component: _43bb343a,
      name: "gift-checkout-account"
    }, {
      path: "billing",
      component: _9ffbb19e,
      name: "gift-checkout-billing"
    }, {
      path: "delivery",
      component: _5f3abea4,
      name: "gift-checkout-delivery"
    }, {
      path: "error",
      component: _c040d084,
      name: "gift-checkout-error"
    }, {
      path: "success",
      component: _5ee940b9,
      name: "gift-checkout-success"
    }]
  }, {
    path: "/gift/redeem",
    component: _2a5e79db,
    name: "gift-redeem"
  }, {
    path: "/lp/blackfriday",
    component: _a9c3767a,
    name: "lp-blackfriday"
  }, {
    path: "/lp/discount",
    component: _88516928,
    name: "lp-discount"
  }, {
    path: "/lp/flashsale",
    component: _30c664c8,
    name: "lp-flashsale"
  }, {
    path: "/lp/lastmingift",
    component: _90847a5e,
    name: "lp-lastmingift"
  }, {
    path: "/lp/offer",
    component: _3dde43c1,
    name: "lp-offer"
  }, {
    path: "/lp/timeout",
    component: _266f25f4,
    name: "lp-timeout"
  }, {
    path: "/offers/12MFIRST50",
    component: _773d789a,
    name: "offers-12MFIRST50"
  }, {
    path: "/offers/1MFIRST50",
    component: _a8d0462c,
    name: "offers-1MFIRST50"
  }, {
    path: "/offers/3MFIRST50",
    component: _46eca06c,
    name: "offers-3MFIRST50"
  }, {
    path: "/offers/6MFIRST50",
    component: _a02874a2,
    name: "offers-6MFIRST50"
  }, {
    path: "/offers/bluelightcard",
    component: _6198fb47,
    name: "offers-bluelightcard"
  }, {
    path: "/offers/christmas",
    component: _2c838d86,
    name: "offers-christmas"
  }, {
    path: "/offers/delivery-booster",
    component: _6029601c,
    name: "offers-delivery-booster"
  }, {
    path: "/offers/dm",
    component: _73c6a744,
    name: "offers-dm"
  }, {
    path: "/offers/dowtk",
    component: _23bf093e,
    name: "offers-dowtk"
  }, {
    path: "/offers/example",
    component: _5d597d25,
    name: "offers-example"
  }, {
    path: "/offers/gms",
    component: _466ee770,
    name: "offers-gms"
  }, {
    path: "/offers/green-friday",
    component: _d4769bb8,
    name: "offers-green-friday"
  }, {
    path: "/offers/influencers",
    component: _5e4f3e9e,
    name: "offers-influencers"
  }, {
    path: "/offers/insert",
    component: _90261d18,
    name: "offers-insert"
  }, {
    path: "/offers/jimmysjobs",
    component: _891784ec,
    name: "offers-jimmysjobs"
  }, {
    path: "/offers/motherkind",
    component: _057c0e12,
    name: "offers-motherkind"
  }, {
    path: "/offers/MVC",
    component: _07a28375,
    name: "offers-MVC"
  }, {
    path: "/offers/new-10",
    component: _5dfa6732,
    name: "offers-new-10"
  }, {
    path: "/offers/new-20",
    component: _4e971c74,
    name: "offers-new-20"
  }, {
    path: "/offers/nhs",
    component: _3be57ef4,
    name: "offers-nhs"
  }, {
    path: "/offers/PLATFORM20",
    component: _68c826ac,
    name: "offers-PLATFORM20"
  }, {
    path: "/offers/PLATFORM30",
    component: _7079cc0b,
    name: "offers-PLATFORM30"
  }, {
    path: "/offers/savoo",
    component: _4c10c603,
    name: "offers-savoo"
  }, {
    path: "/offers/scummymummies",
    component: _678e87f6,
    name: "offers-scummymummies"
  }, {
    path: "/offers/sign-up-with-15-off",
    component: _53e44cf4,
    name: "offers-sign-up-with-15-off"
  }, {
    path: "/offers/summer",
    component: _d0939a2c,
    name: "offers-summer"
  }, {
    path: "/offers/swap",
    component: _66d22fce,
    name: "offers-swap"
  }, {
    path: "/offers/thirty",
    component: _e0358d46,
    name: "offers-thirty"
  }, {
    path: "/offers/thirty-halfyear",
    component: _dee18e14,
    name: "offers-thirty-halfyear"
  }, {
    path: "/offers/thirty-month",
    component: _49abfae0,
    name: "offers-thirty-month"
  }, {
    path: "/offers/thirty-year",
    component: _7d2308fa,
    name: "offers-thirty-year"
  }, {
    path: "/offers/toybox12",
    component: _3ff99709,
    name: "offers-toybox12"
  }, {
    path: "/offers/toybox6",
    component: _0dea31b8,
    name: "offers-toybox6"
  }, {
    path: "/offers/toybox6-new",
    component: _8f8f6592,
    name: "offers-toybox6-new"
  }, {
    path: "/offers/toytrunk",
    component: _67b78d91,
    name: "offers-toytrunk"
  }, {
    path: "/offers/try-for-5",
    component: _b458f1cc,
    name: "offers-try-for-5"
  }, {
    path: "/offers/two-months-free",
    component: _207f9200,
    name: "offers-two-months-free"
  }, {
    path: "/offers/update-preferences",
    component: _6217d622,
    name: "offers-update-preferences"
  }, {
    path: "/offers/vcloud",
    component: _3cbf26ba,
    name: "offers-vcloud"
  }, {
    path: "/offers/vouchercode",
    component: _e0676fd4,
    name: "offers-vouchercode"
  }, {
    path: "/registration/account",
    component: _2100feca,
    name: "registration-account"
  }, {
    path: "/registration/ages",
    component: _59d5faed,
    name: "registration-ages"
  }, {
    path: "/registration/billing",
    component: _ff1be4d0,
    name: "registration-billing"
  }, {
    path: "/registration/categories",
    component: _c7f1ad96,
    name: "registration-categories"
  }, {
    path: "/registration/delivery-booster",
    component: _97b3f4e0,
    name: "registration-delivery-booster"
  }, {
    path: "/registration/pick-toys",
    component: _181a8c1e,
    name: "registration-pick-toys"
  }, {
    path: "/registration/success",
    component: _879c3dc0,
    name: "registration-success"
  }, {
    path: "/toys/all",
    component: _0f06175a,
    name: "toys-all"
  }, {
    path: "/lp/toy/:slug?",
    component: _3abf3ffe,
    name: "lp-toy-slug"
  }, {
    path: "/lp/toys/:association?/:slug?",
    component: _07a2c8c2,
    name: "lp-toys-association-slug"
  }, {
    path: "/blog/:slug",
    component: _51ffbd50,
    name: "blog-slug"
  }, {
    path: "/gift-list/:slug",
    component: _97b8f6d4,
    name: "gift-list-slug"
  }, {
    path: "/reset-password/:id?",
    component: _60464398,
    name: "reset-password-id"
  }, {
    path: "/toy/:slug?",
    component: _d5e5d5ce,
    name: "toy-slug"
  }, {
    path: "/toys/:association/:slug?",
    component: _733c558c,
    name: "toys-association-slug"
  }, {
    path: "/",
    component: _db107c5c,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
