













import { Component, mixins, Prop } from 'nuxt-property-decorator';
import InputMixin from '../inputMixin.vue';
import HasError from '../HasError/HasError.vue';

@Component({
  components: {
    HasError,
  },
})
export default class BaseInput extends mixins(InputMixin) {
  @Prop({ default: () => [], type: [String, Object, Array] }) readonly innerClasses!: string | object | [];

  get errorId() {
    if (this.errors) return `${this.id}-error`;
  }
}
