var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
    [
      _c("g", { attrs: { "fill-rule": "evenodd", "clip-rule": "evenodd" } }, [
        _c("path", { attrs: { fill: "#00b67a", d: "M0 0h24v24H0z" } }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            fill: "#fff",
            d:
              "M12 16.1l4.9 2.9-1.3-5.6L20 9.7l-5.8-.5L12 4 9.8 9.2 4 9.7l4.4 3.7L7.1 19z"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }