var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    { tag: "span", attrs: { name: "u-fade-in", mode: "out-in" } },
    [
      _vm.loading
        ? _vm._t("loading")
        : _vm.error
        ? _vm._t("error")
        : _vm._t("content")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }