export function focusTrap(container: HTMLElement, elements: string[] = []) {
  const defaultElements =
    'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])';
  const additionalElements = elements.join(', ');
  const query = elements.length ? `${defaultElements}, ${additionalElements}` : defaultElements;
  const focusableElements = container.querySelectorAll(query) as NodeListOf<HTMLElement>;
  const firstFocusableElement: HTMLElement = focusableElements[0];
  const lastFocusableElement: HTMLElement = focusableElements[focusableElements.length - 1];
  const KEYCODE_TAB: number = 9;

  if (!firstFocusableElement) {
    return;
  }
  firstFocusableElement.focus();

  container.addEventListener('keydown', function(event) {
    const isTabPressed: boolean = event.key === 'Tab' || event.keyCode === KEYCODE_TAB;

    if (!isTabPressed) return;

    if (event.shiftKey) {
      // If the user presses shift + tab, go backwards.
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus();
        event.preventDefault();
      }
    } else if (document.activeElement === lastFocusableElement) {
      // Otherwise, go forwards.
      firstFocusableElement.focus();
      event.preventDefault();
    }
  });
}
