








import { Vue, Prop, Component } from 'nuxt-property-decorator';
import { colorFromTheme } from '~/helpers/colors';
import { Size } from '~/helpers/sizes';

type PaddingProperties = Size | Array<Size>;

const availableThemes: string[] = ['light-grey-one', 'grey-two', 'secondary', 'primary', 'primary-dark'];

@Component
export default class BaseCard extends Vue {
  @Prop({ default: 'div' }) readonly tag!: string;
  @Prop({ default: 'base', type: [String, Array] }) readonly padding!: PaddingProperties;
  @Prop({ default: true }) readonly border!: boolean;
  @Prop({ default: '1px' }) readonly borderWidth!: string;
  @Prop({ default: 'light-grey-one', validator: (value) => availableThemes.includes(value) })
  readonly borderTheme!: string;

  get styles(): object {
    if (this.border) {
      return {
        borderWidth: this.borderWidth,
        borderStyle: 'solid',
        borderColor: colorFromTheme(this.borderTheme),
      };
    } else {
      return {};
    }
  }
}
