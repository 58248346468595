import { ActionTree, GetterTree, MutationTree } from 'vuex';

export interface LazyLoadableState {
  busy: boolean;
  fetched: boolean;
}

export function setupLazy<ModuleState, RootState>() {
  const lazyLoadableTypes = {
    SET_BUSY: 'SET_BUSY',
    SET_FETCHED: 'SET_FETCHED',
  };

  const lazyLoadableState: LazyLoadableState = {
    busy: false,
    fetched: false,
  };

  const lazyLoadableGetters: GetterTree<LazyLoadableState, RootState> = {
    lazyLoading: (state) => !state.fetched && state.busy,
  };

  const lazyLoadableActions: ActionTree<LazyLoadableState, RootState> = {
    async initLazyLoadModule({ state, commit }: any, callback: () => Promise<any>): Promise<void> {
      if (state.fetched || state.busy) return;

      commit(lazyLoadableTypes.SET_BUSY, true);

      try {
        await callback();
      } catch (error) {
        commit(lazyLoadableTypes.SET_FETCHED, false);
        commit(lazyLoadableTypes.SET_BUSY, false);
        throw error;
      }

      commit(lazyLoadableTypes.SET_FETCHED, true);
      commit(lazyLoadableTypes.SET_BUSY, false);
    },
  };

  const lazyLoadableMutations: MutationTree<LazyLoadableState> = {
    [lazyLoadableTypes.SET_BUSY](state: any, busy: boolean) {
      state.busy = busy;
    },
    [lazyLoadableTypes.SET_FETCHED](state: any, fetched: boolean) {
      state.fetched = fetched;
    },
  };

  return {
    lazyLoadableTypes,
    lazyLoadableState,
    lazyLoadableGetters,
    lazyLoadableActions,
    lazyLoadableMutations,
  };
}
