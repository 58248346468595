import { ValueOf } from '~/utilities/types';

export const PROCEED = 0;
export const ADD_TOYS = 10;

export const proceedMap = {
  [PROCEED]: 'Proceed to checkout',
  [ADD_TOYS]: 'Add toys',
};

export type ProceedMapKey = keyof typeof proceedMap;
export type ProceedMapValue = ValueOf<typeof proceedMap>;

export function canProceed(checkoutCode: number): boolean {
  return checkoutCode === PROCEED;
}

export function checkoutText(checkoutCode: keyof typeof proceedMap): ProceedMapValue {
  return proceedMap[checkoutCode];
}
