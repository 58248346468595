/**
 * redirects to the active registration step - /registration/{step}
 *
 * step 1 - ages
 * step 2 - categories
 * step 3 - pick toys
 * step 4 - delivery booster
 * step 5 - account
 * step 6 - billing
 */
import { redirectWithoutCaching } from '~/helpers/redirect';

export default function(context) {
  const { app, store } = context;

  return redirectWithoutCaching(context, registrationStep({ app, store }));
}

export function registrationStep({ app, store }) {
  const user = app.$auth.user;
  const selectedPricingPlan = store.state.registration.subscription.selectedPricingPlan;
  const addons = store.state.registration.subscription.addons;
  const basketCount = store.state.basket.count;

  if (user) {
    return user.userSubscriptions && user.userSubscriptions.length && user.userSubscriptions[0].statusId === 0
      ? '/registration/billing'
      : '/registration/pick-toys';
  }
  if (selectedPricingPlan && selectedPricingPlan.id) {
    return addons.length ? '/registration/account' : '/registration/delivery-booster';
  }
  return basketCount > 0 ? '/registration/pick-toys' : '/registration/ages';
}
