var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseInput", {
    staticClass: "v-checkbox",
    class: _vm.checkboxClasses,
    attrs: {
      id: _vm.id,
      "inner-classes": "v-checkbox__inner",
      errors: _vm.errors,
      name: _vm.name,
      required: _vm.required
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function() {
            return [_vm._t("default")]
          },
          proxy: true
        },
        {
          key: "input",
          fn: function(slotProps) {
            return [
              _c("span", { staticClass: "v-checkbox__underlay" }, [
                _c(
                  "input",
                  _vm._g(
                    _vm._b(
                      {
                        staticClass: "v-checkbox__input",
                        attrs: {
                          id: _vm.id,
                          "aria-describedby": slotProps.errorId,
                          "aria-label": _vm.ariaLabel,
                          "aria-labelledby": _vm.ariaLabelledby,
                          name: _vm.name,
                          required: _vm.required,
                          tabindex: _vm.tabindex,
                          type: "checkbox"
                        },
                        domProps: { checked: _vm.checked }
                      },
                      "input",
                      _vm.$attrs,
                      false
                    ),
                    _vm.listeners
                  )
                ),
                _vm._v(" "),
                _c("span", { staticClass: "v-checkbox__overlay" }),
                _vm._v(" "),
                _c("div", {
                  ref: "renderElement",
                  staticClass: "v-checkbox__checkbox",
                  attrs: { "aria-checked": _vm.checked, role: "checkbox" }
                })
              ])
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }