import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '~/store/index';
import { setupLazy, LazyLoadableState } from '~/helpers/store/lazyLoad';
import { ProductListLine, GiftProductList } from '~/types/product-lists';
import { User } from '~/types/user';
import { FAVOURITES, GIFT } from '~/constants/product-lists';

const { lazyLoadableTypes, lazyLoadableState, lazyLoadableActions, lazyLoadableMutations } = setupLazy<
  ModuleState,
  RootState
>();

const types = {
  ...lazyLoadableTypes,
  PUT_FAVOURITES: 'PUT_FAVOURITES',
  PUT_GIFTS: 'PUT_GIFTS',
  UPDATE_FAVOURITES_LINES: 'UPDATE_FAVOURITES_LINES',
  UPDATE_GIFT_LIST_LINES: 'UPDATE_GIFT_LIST_LINES',
  SET_IS_UPDATED: 'SET_IS_UPDATED',
  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
  SET_PUBLIC_GIFT_LIST_DATA: 'SET_PUBLIC_GIFT_LIST_DATA',
};

export interface UpdateListsPayload {
  listIds?: Array<string>;
  productVariantId?: string;
}

export interface AddToListPayload {
  productListId?: string;
  productVariantId?: string;
}

export interface ProductList {
  id?: string;
  slug?: string;
  lines: Array<ProductListLine>;
  type?: number;
  user?: User;
}

interface ModuleState extends LazyLoadableState {
  favourites: ProductList;
  gifts: ProductList;
  listsUpdated: boolean;
  activeTab: number;
  giftList: GiftProductList;
}

export const state = (): ModuleState => ({
  ...lazyLoadableState,
  favourites: {
    lines: [],
  },
  gifts: {
    lines: [],
  },
  listsUpdated: false,
  activeTab: 0,
  giftList: {},
});

export const getters: GetterTree<ModuleState, RootState> = {
  favouritedProductVariantIds: (state): Array<string> => {
    if (!state.favourites || !state.favourites.lines || !state.favourites.lines.length) return [];

    return state.favourites.lines.map((line) => line.productVariant.id);
  },
  giftsProductVariantIds: (state): Array<string> => {
    if (!state.gifts || !state.gifts.lines || !state.gifts.lines.length) return [];

    return state.gifts.lines.map((line) => line.productVariant.id);
  },
};

export const actions: ActionTree<ModuleState, RootState> = {
  ...lazyLoadableActions,

  async initialiseStore({ dispatch }): Promise<any> {
    this.$logger.store(`action`, `[productLists/initialiseStore]`);

    if (!this.$auth.loggedIn) return;

    await dispatch('getUserLists');
  },

  async getUserLists({ dispatch }): Promise<any> {
    this.$logger.store(`action`, `[productLists/getUserLists]`);

    if (!this.$auth.loggedIn) return;

    const response = await this.$whirli.users.productLists.getAll({
      params: { with: 'activeLines.productVariant.product.assets.transforms' },
    });
    dispatch('setListsData', response);

    return response;
  },

  addToFavourites({ dispatch, state }, productVariantId: string) {
    this.$logger.store(`action`, `[productLists/addToFavourites]`, { productVariantId });

    const payload: AddToListPayload = { productListId: state.favourites.id, productVariantId };
    dispatch('addToList', { listType: types.PUT_FAVOURITES, payload });
  },

  addToGiftList({ dispatch, state }, productVariantId: string) {
    this.$logger.store(`action`, `[productLists/addToGiftList]`, { productVariantId });

    const payload: AddToListPayload = { productListId: state.gifts.id, productVariantId };
    dispatch('addToList', { listType: types.PUT_GIFTS, payload });
  },

  async addToList({ commit }, { listType, payload }) {
    this.$logger.store(`action`, `[productLists/addToList]`, { listType, payload });

    commit(types.SET_BUSY, true);
    try {
      const response = await this.$whirli.users.productLists.addListLine(payload, {
        params: { with: 'activeLines.productVariant.product.assets.transforms' },
      });
      commit(listType, response.data);
      commit(types.SET_BUSY, false);
    } catch (error) {
      commit(types.SET_BUSY, false);
      throw error;
    }
  },

  removeFromFavourites({ dispatch, state }, productVariantId) {
    this.$logger.store(`action`, `[productLists/removeFromFavourites]`, { productVariantId });

    // Pull out all of the productVariantIds of the lines array and add them as keys of a new object.
    // The request payload will then find the matching productVariantId and then it's corresponding listLineId
    const variantIdListLines = state.favourites.lines.reduce<Record<string, ProductListLine>>(
      (productListLines, productListLine) => {
        productListLines[productListLine.productVariant.id] = productListLine;
        return productListLines;
      },
      {}
    );
    dispatch('removeFromList', {
      id: variantIdListLines[productVariantId].id,
      lines: state.favourites.lines,
      listType: types.UPDATE_FAVOURITES_LINES,
    });
  },

  removeFromGiftList({ dispatch, state }, productVariantId) {
    this.$logger.store(`action`, `[productLists/removeFromGiftList]`, { productVariantId });

    const variantIdListLines = state.gifts.lines.reduce<Record<string, ProductListLine>>(
      (productListLines, productListLine) => {
        productListLines[productListLine.productVariant.id] = productListLine;
        return productListLines;
      },
      {}
    );
    dispatch('removeFromList', {
      id: variantIdListLines[productVariantId].id,
      lines: state.gifts.lines,
      listType: types.UPDATE_GIFT_LIST_LINES,
    });
  },

  async removeFromList({ commit }, { id, lines, listType }) {
    this.$logger.store(`action`, `[productLists/removeFromList]`, { id, lines, listType });

    commit(types.SET_BUSY, true);
    try {
      const response = await this.$whirli.users.productLists.deleteListLine(id);
      const newProductList = lines.filter(
        (line: any) => line.productVariant.id !== response.data.productVariantId
      );

      commit(listType, newProductList);
      commit(types.SET_BUSY, false);
    } catch (error) {
      commit(types.SET_BUSY, false);
      throw error;
    }
  },

  async updateUserLists({ commit, dispatch }, payload) {
    this.$logger.store(`action`, `[productLists/updateUserLists]`, { payload });

    commit(types.SET_BUSY, true);
    try {
      const response = await this.$whirli.users.productLists.updateAll(payload);
      dispatch('setListsData', response);
      dispatch('setIsUpdated');
      commit(types.SET_BUSY, false);
    } catch (error) {
      commit(types.SET_BUSY, false);
      throw error;
    }
  },

  moveToFavourites({ dispatch, state }, listLineId) {
    this.$logger.store(`action`, `[productLists/moveToFavourites]`, { listLineId });

    dispatch('moveToOtherList', { listLineId, productListId: state.favourites.id });
  },

  moveToGiftList({ dispatch, state }, listLineId) {
    this.$logger.store(`action`, `[productLists/moveToGiftList]`, { listLineId });

    dispatch('moveToOtherList', { listLineId, productListId: state.gifts.id });
  },

  async moveToOtherList({ commit, dispatch }, { listLineId, productListId }) {
    this.$logger.store(`action`, `[productLists/moveToOtherList]`, { listLineId, productListId });

    commit(types.SET_BUSY, true);
    try {
      const response = await this.$whirli.users.productLists.updateListLine(
        listLineId,
        { productListId },
        {
          params: { with: 'activeLines.productVariant.product.assets.transforms' },
        }
      );
      dispatch('setListsData', response);
      commit(types.SET_BUSY, false);
    } catch (error) {
      commit(types.SET_BUSY, false);
      throw error;
    }
  },

  setListsData({ commit }, response) {
    this.$logger.store(`action`, `[productLists/setListsData]`, { response });

    const lists: Array<ProductList> = response.data;
    const favourites: ProductList | undefined = lists.find((list: ProductList) => list.type === FAVOURITES);
    const gifts: ProductList | undefined = lists.find((list: ProductList) => list.type === GIFT);
    commit(types.PUT_FAVOURITES, favourites);
    commit(types.PUT_GIFTS, gifts);
  },

  setIsUpdated({ commit }) {
    this.$logger.store(`action`, `[productLists/setIsUpdated]`);

    commit(types.SET_IS_UPDATED, true);
    setTimeout(() => {
      commit(types.SET_IS_UPDATED, false);
    }, 2000);
  },

  async getGiftList({ commit }, payload: string) {
    try {
      const response = await this.$whirli.productLists.get(payload);
      commit(types.SET_PUBLIC_GIFT_LIST_DATA, response.data);
    } catch (error) {
      throw error;
    }
  },
};

export const mutations: MutationTree<ModuleState> = {
  ...lazyLoadableMutations,
  [types.PUT_FAVOURITES](state, favourite) {
    state.favourites = favourite;
  },
  [types.UPDATE_FAVOURITES_LINES](state, lines) {
    state.favourites.lines = lines;
  },
  [types.UPDATE_GIFT_LIST_LINES](state, lines) {
    state.gifts.lines = lines;
  },
  [types.PUT_GIFTS](state, gift) {
    state.gifts = gift;
  },
  [types.SET_BUSY](state, payload) {
    state.busy = payload;
  },
  [types.SET_IS_UPDATED](state, payload) {
    state.listsUpdated = payload;
  },
  [types.SET_ACTIVE_TAB](state, activeTab: number) {
    state.activeTab = activeTab;
  },
  [types.SET_PUBLIC_GIFT_LIST_DATA](state, giftList: Partial<ProductList>): void {
    state.giftList = {
      list: giftList,
      listLines: giftList.lines,
      userName: giftList.user?.firstname,
    };
  },
};
