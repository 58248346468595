




















import 'reflect-metadata';
import { Component, mixins, Prop } from 'nuxt-property-decorator';
import buttonMixin from '../buttonMixin.vue';
import BaseFixedCTA from '~/components/primitives/BaseFixedCTA/BaseFixedCTA.vue';

@Component({
  inheritAttrs: false,
  components: { BaseFixedCTA },
})
export default class TheFixedButtonCTA extends mixins(buttonMixin) {
  @Prop({ default: 15000 }) readonly delay!: number;
  @Prop({ default: null }) readonly right!: number;
}
