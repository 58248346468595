var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
    [
      _c("path", {
        attrs: {
          d:
            "M10.3 2c4.6 0 8.3 3.7 8.3 8.3 0 1.9-.7 3.7-1.8 5.1l5.2 5.2-1.4 1.4-5.2-5.2c-1.4 1.1-3.2 1.8-5.1 1.8-4.6 0-8.3-3.7-8.3-8.3S5.7 2 10.3 2zm0 2C6.8 4 4 6.8 4 10.3s2.8 6.3 6.3 6.3 6.3-2.8 6.3-6.3S13.8 4 10.3 4z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }