import Vue from 'vue';
import { Context, Plugin } from '@nuxt/types';
import { CreateGiftPayload } from '~/store/gift';
import { dataWithExpiry, dataOrExpired } from '~/helpers/storage';
import {
  PROCEED,
  ProceedMapKey,
  ProceedMapValue,
  canProceed,
  checkoutText,
} from '~/components/features/basket/TheGiftBasket/GiftCanProceed';

export interface GiftService {
  giftMode: {
    toggle: (toggle?: boolean | null) => void;
    isOn: () => boolean;
  };
  create: (payload: CreateGiftPayload) => void;
  guest: {
    getCachedGift: () => CachedGift | null;
    cacheGift: (giftId: string, accessToken: string, maxAge?: number | null) => void;
    clearCachedGift: () => Promise<[any, any]>;
  };
  basket: {
    id: () => Promise<string | null>;
  };
  checkout: {
    code: () => ProceedMapKey;
    canProceed: () => ReturnType<typeof canProceed>;
    canProceedMessage: () => ProceedMapValue;
  };
}

export interface CachedGift {
  id: string;
  accessToken: string;
}

export const STORAGE_GIFT_MODE = 'settingsGiftMode';
export const STORAGE_CACHED_GIFT = 'settingsCachedGift';
export const STORAGE_GIFT_BASKET_ID = 'giftBasketId';
export const STORAGE_GIFT_BASKET_COUNT = 'basketCount';

function createGiftService({ app, store }: Context): GiftService {
  function checkoutCode(): ProceedMapKey {
    return PROCEED;
  }

  const giftService: GiftService = {
    giftMode: {
      toggle(toggle = null) {
        if (toggle === null) toggle = !app.$storage.getUniversal(STORAGE_GIFT_MODE);

        app.$storage.setUniversal(STORAGE_GIFT_MODE, toggle);
      },
      isOn() {
        return app.$storage.getUniversal(STORAGE_GIFT_MODE);
      },
    },
    create(payload) {
      return store.dispatch('gift/create', payload);
    },
    guest: {
      getCachedGift() {
        let cachedGift: CachedGift | null;

        try {
          cachedGift = dataOrExpired<CachedGift>(app.$storage.getUniversal(STORAGE_CACHED_GIFT));
        } catch (e) {
          giftService.giftMode.toggle(false);
          store.dispatch('clearCachedGift');
          store.dispatch('basket/clearCachedBasket');

          return null;
        }

        return cachedGift;
      },
      cacheGift(giftId, accessToken, maxAge = null) {
        const cachedGift: CachedGift = { id: giftId, accessToken };

        app.$storage.setUniversal(STORAGE_CACHED_GIFT, dataWithExpiry(cachedGift, maxAge));
      },
      clearCachedGift() {
        return Promise.all([
          store.dispatch('gift/clearCachedGift'),
          store.dispatch('gift/basket/clearCachedBasket'),
        ]);
      },
    },
    basket: {
      id: () => store.dispatch('gift/basket/getBasketId'),
    },
    checkout: {
      code: () => checkoutCode(),
      canProceed: () => canProceed(checkoutCode()),
      canProceedMessage: () => checkoutText(checkoutCode()),
    },
  };

  return Vue.observable(giftService);
}

const plugin: Plugin = function(context, inject): void {
  inject('gifts', createGiftService(context));
};

export default plugin;
