












import { Vue, Prop, Component } from 'nuxt-property-decorator';
import { SlidesPerView } from '~/types/carousels.ts';
import { BREAKPOINT_MAP } from '~/constants/screen-breakpoints';

@Component
export default class BaseCarousel extends Vue {
  @Prop({ default: true }) readonly loop!: boolean;
  @Prop({ default: false }) readonly navigationTop!: boolean;
  @Prop({ required: true }) readonly name!: string;
  @Prop({ default: null }) readonly options?: any;
  @Prop({ default: null }) readonly pagination!: any;
  @Prop({ default: () => ({ mobile: 1, tablet: 2, desk: 2 }) }) readonly slidesPerView!: SlidesPerView;
  @Prop({ default: 0 }) readonly initialSlide!: number;

  loaded: boolean = false;

  mounted() {
    this.loaded = true;
  }

  get customOptions(): object {
    const defaultOptions = {
      loop: this.loop,
      initialSlide: this.initialSlide,
      watchOverflow: true,
      slidesPerView: this.slidesPerView.mobile,
      spaceBetween: 16,
      breakpoints: {
        [BREAKPOINT_MAP.mobile.pixels]: {
          slidesPerView: this.slidesPerView.mobile,
          spaceBetween: 8,
        },
        [BREAKPOINT_MAP.tablet.pixels]: {
          slidesPerView: this.slidesPerView.tablet,
          spaceBetween: 24,
        },
        [BREAKPOINT_MAP.smallDesk.pixels]: {
          slidesPerView: this.slidesPerView.desk,
          spaceBetween: 24,
        },
      },
      navigation: {
        prevEl: `#${this.name}-prev`,
        nextEl: `#${this.name}-next`,
      },
    };
    if (this.pagination) Object.assign(defaultOptions, { pagination: this.pagination });
    if (this.options) Object.assign(defaultOptions, this.options);
    return defaultOptions;
  }
}
