var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 32 32" } },
    [
      _c("path", {
        attrs: {
          d:
            "M22.1 20.3c-.3.7-1.3 1.3-2.1 1.5-.6.1-1.3.2-3.8-.8-3.2-1.3-5.2-4.5-5.4-4.7-.2-.3-1.3-1.8-1.3-3.3s.8-2.3 1.1-2.6c.3-.3.7-.4 1.1-.4h.4c.3 0 .5 0 .7.5.3.6.9 2.2 1 2.3.1.2.2.4 0 .6-.1.2-.2.3-.3.5l-.5.5c-.1.2-.3.4-.1.7.2.3.8 1.3 1.7 2.2 1.2 1.1 2.2 1.4 2.5 1.6.3.1.6.1.8-.1.2-.3.5-.7.8-1.1.2-.3.5-.3.8-.2.3.1 1.8.9 2.1 1 .3.2.5.2.6.4.1 0 .1.6-.1 1.4M15.8 5C9.8 5 5 9.8 5 15.8c0 2.4.8 4.6 2.1 6.3l-1.3 4 4.1-1.3c1.7 1.1 3.7 1.8 5.9 1.8 5.9 0 10.8-4.8 10.8-10.8S21.7 5 15.8 5"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }