
import 'reflect-metadata';
import { Vue, Component, namespace } from 'nuxt-property-decorator';
import { NavTheme, LogoTheme } from './interfaces';
import { themeMap } from '~/helpers/colors';
import { ScrollPosition as SP } from '~/store/scroll';
import zendesk from '~/helpers/zendesk';

interface ScrollPosition extends SP {} // Without this, typescript throws a warning that it cannot find the export

const DrawersStore = namespace('drawers');
const ScrollStore = namespace('scroll');
const BasketStore = namespace('basket');
const PageStore = namespace('pageConfig');

@Component
export default class NavMixin extends Vue {
  basketDrawerName: string = 'basket';

  @DrawersStore.Action toggleSideDrawer!: (payload: { name: string | null; isOpen: boolean }) => void;
  @DrawersStore.State isSideDrawerOpen!: boolean;
  @DrawersStore.State currentSideDrawer!: string | null;
  @ScrollStore.State('debounced') scrollDebounced!: ScrollPosition;
  @ScrollStore.Action setScrollDisabled!: (isDisabled: boolean) => void;
  @BasketStore.State('count') numberOfItemsInBasket!: number;
  @PageStore.State('pageTheme') theme!: string;

  get state() {
    return this.$store.state;
  }

  get logoTheme(): LogoTheme {
    if (this.scrolled) return { fg: 'secondary', bg: 'primary' };

    switch (this.theme) {
      case 'secondary':
        return { fg: 'tertiary', bg: 'primary' };
      case 'tertiary':
      case 'white':
        return { fg: 'secondary', bg: 'primary' };
      default:
        return { fg: 'secondary', bg: 'tertiary' };
    }
  }

  get defaultTheme(): NavTheme {
    const theme = themeMap[this.theme];

    const navTheme = {
      bg: theme ? theme.background : themeMap.primary.background,
      fg: theme ? theme.foreground : themeMap.primary.foreground,
    };
    if (this.theme === 'secondary') {
      if (themeMap.secondary.accent) navTheme.fg = themeMap.secondary.accent;
    }
    if (this.scrolled) {
      navTheme.bg = themeMap.white.background;
      navTheme.fg = themeMap.white.foreground;
    }

    return navTheme;
  }

  get userLoggedIn(): boolean {
    return this.$auth.loggedIn;
  }

  get scrolled(): boolean {
    return this.scrollDebounced.position > 100;
  }

  get isBasketOpen(): boolean {
    return this.isSideDrawerOpen && this.currentSideDrawer === this.basketDrawerName;
  }

  toggleBasket() {
    this.toggleSideDrawer({ name: this.basketDrawerName, isOpen: !this.isBasketOpen });
    zendesk.toggleLauncher(false);
  }
}
