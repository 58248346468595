import { SortOption, SortParams } from '~/types/products/sort';
import { options } from '~/config/products/sort';

export function getSortOptionByParams(sortParams: SortParams): SortOption | null {
  return (
    options.find(
      (option: SortOption) =>
        option.sort.orderBy === sortParams.orderBy && option.sort.sortedBy === sortParams.sortedBy
    ) || null
  );
}
