







import { Prop, Component, mixins } from 'nuxt-property-decorator';
import iconMixin from '../iconMixin.vue';
import { themeMap } from '~/helpers/colors';

export const availableSizes: Array<string> = ['small', 'base', 'medium'];

@Component
export default class TheBagIcon extends mixins(iconMixin) {
  @Prop({ default: 'small', validator: (value) => availableSizes.includes(value) })
  readonly size!: string;

  get textClasses() {
    const color = themeMap[this.theme].foreground;
    return {
      [`u-color-${color}`]: color,
    };
  }
}
