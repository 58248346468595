import { Route } from 'vue-router';
import { Plugin } from '@nuxt/types';

const plugin: Plugin = function({ app }): void {
  if (process.browser) {
    if (app.router) {
      // set the user id
      app.router.afterEach((to: Route) => {
        const user = app.$auth.user;

        if (user) {
          app.$gtm.user.auth(user);
        }

        if (!document.getElementById('CookieBot')) {
          const script = document.createElement('script');
          script.setAttribute('id', 'CookieBot');
          script.setAttribute('data-cbid', 'eab1686c-596e-4406-99ba-6a574771b50c');
          script.setAttribute('async', '');
          script.defer = true;
          script.onload = () => {
            app.$gtm.analytics.pageView(to.fullPath);
          };
          script.onerror = () => {
            app.$sentry.captureException(new Error('Cannot load cookie bot'));
          };
          document.body.appendChild(script);
          script.setAttribute('src', 'https://consent.cookiebot.com/uc.js');
        } else {
          app.$gtm.analytics.pageView(to.fullPath);
        }
      });
    }
  }
};

export default plugin;
