
import 'reflect-metadata';
import { Vue, Prop, Component } from 'nuxt-property-decorator';
import { availableTags, availableSizes, availableColors } from '~/helpers/text';

type AvailableTags = typeof availableTags[number];
type AvailableColors = typeof availableColors[number];
type AvailableSizes = typeof availableSizes[number];

@Component
export default class BaseText extends Vue {
  @Prop({ default: true }) readonly responsive!: boolean;
  @Prop({ default: false }) readonly uppercase!: boolean;
  @Prop({ default: false }) readonly capitalize!: boolean;
  @Prop({ default: false }) readonly underline!: boolean;
  @Prop({ default: false }) readonly lineThrough!: boolean;
  @Prop({ default: false }) readonly center!: boolean;
  @Prop({ default: false }) readonly centerUntilSmallDesk!: boolean;
  @Prop({ default: false }) readonly bold!: boolean;
  @Prop({ default: 'p', type: String, validator: (value) => availableTags.includes(value) })
  readonly tag!: AvailableTags;

  @Prop({ default: 'body', type: String, validator: (value) => availableSizes.includes(value) })
  readonly size!: AvailableSizes;

  @Prop({
    default: 'secondary',
    type: String,
    validator: (value) => availableColors.includes(value),
  })
  readonly color!: AvailableColors;
}
