



































































import { Vue, Component, namespace, Watch } from 'nuxt-property-decorator';

import SideDrawer from '~/components/containers/drawers/SideDrawer/SideDrawer.vue';
import BaseTab from '~/components/partials/tabs/BaseTab/BaseTab.vue';
import { ToggleDrawerPayload } from '~/store/drawers';
import { Basket, CheckoutConfig } from '~/types/basket';
import { BASKET_TAB, RETURNS_TAB } from '~/constants/toy-basket-tabs';
import { subscriptionTokens } from '~/helpers/models/user';
import { checkoutMessage, defaultCheckoutConfig } from '~/constants/checkout-status-messages';
import zendesk from '~/helpers/zendesk';
import { registrationPageCheck } from '~/helpers/registration-step-new';
import { focusTrap } from '~/helpers/focus-trap';

const basketStore = namespace('basket');
const toyboxStore = namespace('toybox');
const CheckoutStore = namespace('checkout');
const GiftStore = namespace('gift');
const drawersStore = namespace('drawers');

@Component({
  components: {
    BaseTab,
    SideDrawer,
    BaseBasket: () =>
      import(
        '~/components/partials/BaseBasket/BaseBasket.vue' /* webpackChunkName: "TheToyBasket" */ /* prefetch: true */
      ),
    TheGiftBasket: () =>
      import(
        '~/components/features/basket/TheGiftBasket/TheGiftBasket.vue' /* webpackChunkName: "TheToyBasket" */ /* prefetch: true */
      ),
    TheBasketTab: () =>
      import('./TheBasketTab/TheBasketTab.vue' /* webpackChunkName: "TheToyBasket" */ /* prefetch: true */),
    TheReturnsTab: () =>
      import('./TheReturnsTab/TheReturnsTab.vue' /* webpackChunkName: "TheToyBasket" */ /* prefetch: true */),
    TokenSectionBar: () =>
      import(
        '~/components/features/TokenSectionBar/TokenSectionBar.vue' /* webpackChunkName: "TheToyBasket" */ /* prefetch: true */
      ),
  },
})
export default class TheToyBasket extends Vue {
  BASKET_TAB = BASKET_TAB;
  RETURNS_TAB = RETURNS_TAB;

  @basketStore.Action('initialState') basketInitialState!: () => Promise<void>;
  @basketStore.State('data') basket!: Basket | null;
  @basketStore.State('isFetched') hasBasketBeenFetched!: boolean;
  @basketStore.State('isBusy') isBasketBusy!: boolean;
  @toyboxStore.Action('initialState') toyboxInitialState!: () => Promise<void>;
  @toyboxStore.State('isFetched') hasToyboxBeenFetched!: boolean;
  @toyboxStore.State('isBusy') isToyboxBusy!: boolean;
  @GiftStore.Action('initialiseModule') initialiseGiftModule!: () => Promise<void>;
  @drawersStore.Action toggleSideDrawer!: (payload: ToggleDrawerPayload) => Promise<void>;
  @drawersStore.Mutation PUT_TOGGLE_HIDE_BOTTOM!: (toggle: boolean) => void;
  @drawersStore.State currentSideDrawer!: string | null;
  @drawersStore.State isSideDrawerOpen!: boolean;
  @CheckoutStore.State checkoutStatus!: boolean;
  @CheckoutStore.State checkoutMessage!: string;

  zendesk = zendesk;
  newRegistrationConfig: CheckoutConfig = {
    text: 'Next',
    href: '',
    disabled: false,
  };

  updated() {
    // Create focus trap in the SideDrawer for tabbing accessibility.
    const sideDrawer: HTMLElement | null = document.querySelector('#side-drawer');
    if (sideDrawer) focusTrap(sideDrawer);
  }

  // @TODO fetch will be used when component is async component rendering is used.
  @Watch('basketIsOpen')
  async fetchBasketAndToybox() {
    if (!this.basketIsOpen) return;
    // We don't want to fetch the basket and toybox every time the user opens the basket.
    // This is to save on requests so we only fetch on the first time they open the basket.
    const jobs: Array<Promise<any>> = [];

    const shouldFetchBasket = !this.hasBasketBeenFetched || !this.isBasketBusy;
    const shouldFetchToybox = this.$auth.loggedIn && (!this.hasToyboxBeenFetched || !this.isToyboxBusy);

    if (shouldFetchBasket) jobs.push(this.basketInitialState());
    if (shouldFetchToybox) jobs.push(this.toyboxInitialState());
    jobs.push(this.initialiseGiftModule());

    try {
      await Promise.all(jobs);
    } catch (error) {
      throw error;
    }
  }

  get basketIsOpen(): boolean {
    return this.currentSideDrawer === 'basket' && this.isSideDrawerOpen;
  }

  get maxTokens(): number {
    return subscriptionTokens(this.$auth.user);
  }

  get checkoutButtonConfig(): CheckoutConfig {
    if (this.isRegistrationPage) return this.newRegistrationConfig;
    if (!this.checkoutMessage) return defaultCheckoutConfig;
    return checkoutMessage[this.checkoutMessage] || defaultCheckoutConfig;
  }

  get isRegistrationPage(): boolean {
    return registrationPageCheck(this.$route.name);
  }

  onClickHandler() {
    if (this.isRegistrationPage) {
      this.toggleSideDrawer({ name: 'basket', isOpen: false });
      this.PUT_TOGGLE_HIDE_BOTTOM(false);
      return;
    }
    if (this.checkoutButtonConfig.disabled || !this.checkoutButtonConfig.href) return;
    this.toggleSideDrawer({ name: 'basket', isOpen: false });
  }

  refreshCheckout() {
    if (this.$route.name === 'checkout') {
      this.$store.dispatch('checkout/initialiseOrder');
    }
    zendesk.toggleLauncher(true);
  }
}
