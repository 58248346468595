import { FacebookUser } from '../types/user';
import { User } from '~/types/user';
import { AddressDetails } from '~/types/user/addresses';

/**
 * Creates and returns the Facebook user object
 *
 * @param user {User}
 */
export function facebookbUser(user: User): FacebookUser {
  const fbUser: FacebookUser = {
    external_id: user.id,
    em: user.email.toLowerCase(),
    fn: user.firstname.toLowerCase(),
    ln: '',
    cn: 'gb',
  };
  if (user.addresses.length) {
    let address = user.addresses.find((address: AddressDetails) => address.default === true);

    if (!address) {
      address = user.addresses[0];
    }
    fbUser.ct = address.city;
    fbUser.ph = address.phoneNumber;
    fbUser.zp = address.zip;
  }
  return fbUser;
}
