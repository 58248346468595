export const availableSizes = [
  'large-header',
  'section-header',
  'hero-header',
  'sub-header',
  'sub-header-responsive',
  'sub-header-responsive-alt',
  'sub-header-alt',
  'small-header',
  'extra-small-header',
  'button',
  'body-bold',
  'body',
  'small-body',
  'mobile-navigation',
  'icon',
];

export const availableTags = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'p',
  'strong',
  'i',
  'em',
  'span',
  'BaseListItem',
  'div',
  'time',
];
export const availableColors = [
  'primary',
  'primary-dark',
  'secondary',
  'white',
  'charcoal',
  'quinary-darker',
  'light-grey-one',
  'inherit',
  'quinary',
];
