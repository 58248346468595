var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseSection",
    { staticClass: "v-page-not-found" },
    [
      _c(
        "BaseWrapper",
        [
          _c(
            "BaseLayout",
            { attrs: { "fill-direction": "center" } },
            [
              _c(
                "BaseLayoutItem",
                { attrs: { tablet: "3/4", "small-desk": "2/3" } },
                [
                  _c(
                    "BaseText",
                    {
                      staticClass: "v-page-not-found__header",
                      attrs: { tag: "h1", size: "large-header", center: true }
                    },
                    [
                      _vm._v(
                        "\n          Oops. The page you’re looking for can’t be found.\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "BaseText",
                    {
                      staticClass: "v-page-not-found__sub-header",
                      attrs: { size: "body", color: "charcoal", center: true }
                    },
                    [
                      _vm._v(
                        "\n          You may have followed an outdated link or typed in an incorrect URL. Please get in touch with\n          customer services for further help.\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "BaseCluster",
                    {
                      staticClass: "v-page-not-found__controls",
                      attrs: { "stack-until": "tablet" }
                    },
                    [
                      _c(
                        "BaseButton",
                        { attrs: { wide: true, tag: "nuxt-link", to: "/" } },
                        [_vm._v("Back to homepage")]
                      ),
                      _vm._v(" "),
                      _c(
                        "BaseButton",
                        {
                          attrs: {
                            type: "line",
                            tag: "nuxt-link",
                            to: "/contact-us"
                          }
                        },
                        [_vm._v("Contact us")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("BaseAnimation", {
        staticClass: "v-page-not-found__animation-character",
        attrs: { "initial-state": { animationName: "pingo-flamingo-1" } }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }