
















import { Vue, Prop, Component } from 'nuxt-property-decorator';

@Component
export default class BaseWrapper extends Vue {
  @Prop({ default: false }) readonly overflowHidden!: boolean;
}
