import { ActionTree, MutationTree } from 'vuex';
import WaitSpot from 'whirli-client/dist/Interfaces/waitspot/WaitSpot';
import { RootState } from '~/store/index';
import { setupLazy, LazyLoadableState } from '~/helpers/store/lazyLoad';
import { CreateWaitSpotPayload } from '~/types/wait-spot';

const { lazyLoadableTypes, lazyLoadableActions, lazyLoadableState, lazyLoadableMutations } = setupLazy<
  ModuleState,
  RootState
>();

const types = {
  ...lazyLoadableTypes,
  PUT_WAITSPOTS: 'PUT_WAITSPOTS',
  SET_BUSY: 'SET_BUSY',
  UPDATE_WAITSPOT_LINES: 'UPDATE_WAITSPOT_LINES',
  ADD_WAITSPOT: 'ADD_WAITSPOT',
};

interface ModuleState extends LazyLoadableState {
  waitSpots: Array<WaitSpot>;
}

export const state = (): ModuleState => ({
  ...lazyLoadableState,
  waitSpots: [],
});

export const actions: ActionTree<ModuleState, RootState> = {
  ...lazyLoadableActions,

  async initialiseStore({ dispatch }): Promise<void> {
    if (!this.$auth.loggedIn) return;

    await dispatch('getUserWaitSpots');
  },

  async getUserWaitSpots({ commit }): Promise<any> {
    this.$logger.store(`action`, `[waitSpots/getUserWaitSpots]`);

    if (!this.$auth.loggedIn) return;

    const response = await this.$whirli.users.waitSpots.all({
      params: { with: 'productVariant.product.assets.transforms' },
    });
    commit(types.PUT_WAITSPOTS, response.data);

    return response;
  },

  async createWaitSpot({ commit }, payload: CreateWaitSpotPayload): Promise<void> {
    commit(types.SET_BUSY, true);
    try {
      const response = await this.$whirli.users.waitSpots.create(payload);

      commit(types.ADD_WAITSPOT, response.data);
    } catch (error) {
      throw error;
    } finally {
      commit(types.SET_BUSY, false);
    }
  },

  removeWaitSpotFromList({ state, dispatch }, productVariantId: string): void {
    let waitSpotId: string | undefined;
    const newWaitSpots = state.waitSpots.filter((waitSpot: WaitSpot) => {
      if (waitSpot.productVariant?.id === productVariantId) waitSpotId = waitSpot.id;
      else return waitSpot;
    });

    dispatch('deleteWaitSpot', {
      waitSpotId,
      newWaitSpots,
    });
  },

  async deleteWaitSpot({ commit }, { waitSpotId, newWaitSpots }): Promise<void> {
    commit(types.SET_BUSY, true);
    try {
      await this.$whirli.users.waitSpots.delete(waitSpotId);
      commit(types.UPDATE_WAITSPOT_LINES, newWaitSpots);
    } catch (error) {
      throw error;
    } finally {
      commit(types.SET_BUSY, false);
    }
  },
};

export const mutations: MutationTree<ModuleState> = {
  ...lazyLoadableMutations,
  [types.PUT_WAITSPOTS](state, waitSpots) {
    state.waitSpots = waitSpots;
  },
  [types.ADD_WAITSPOT](state, waitSpot) {
    state.waitSpots.push(waitSpot);
  },
  [types.SET_BUSY](state, payload) {
    state.busy = payload;
  },
  [types.UPDATE_WAITSPOT_LINES](state, newWaitSpots) {
    state.waitSpots = newWaitSpots;
  },
};
