import DataLayer from './data/DataLayer';
import AdwordsEvents from './events/AdwordsEvents';
import AnalyticsEvents from './events/AnalyticsEvents';
import EcommerceEvents from './events/EcommerceEvents';
import FacebookEvents from './events/FacebookEvents';
import PinterestEvents from './events/PinterestEvents';
import TradeDoublerEvents from './events/TradeDoublerEvents';
import AwinEvents from './events/AwinEvents';
import UserEvents from './events/UserEvents';
import { Product, ProductVariant } from '~/types/products/products';
import { User } from '~/types/user';
import { GlobalInjects } from '~/index.d';

export default class GoogleTagManager {
  app: GlobalInjects;

  data: DataLayer;
  adwords: AdwordsEvents;
  analytics: AnalyticsEvents;
  ecommerce: EcommerceEvents;
  facebook: FacebookEvents;
  pinterest: PinterestEvents;
  tradeDoubler: TradeDoublerEvents;
  awin: AwinEvents;
  user: UserEvents;

  constructor(app: GlobalInjects) {
    this.app = app;

    this.data = new DataLayer(this.app);
    this.adwords = new AdwordsEvents(this.data);
    this.analytics = new AnalyticsEvents(this.data);
    this.ecommerce = new EcommerceEvents(this.data);
    this.facebook = new FacebookEvents(this.data);
    this.pinterest = new PinterestEvents(this.data);
    this.tradeDoubler = new TradeDoublerEvents(this.data);
    this.awin = new AwinEvents(this.data);
    this.user = new UserEvents(this.data);
  }

  register(): void {
    this.facebook.completeRegistration('Register', true, 0);
    this.facebook.lead('Registration', 'Registration form');
    this.analytics.pushEvent('RegistrationSuccess');
    this.pinterest.lead('Registration');
  }

  login(user: User): void {
    this.user.auth(user);
    this.analytics.pushEvent('LoginSuccess');
  }

  initiateCheckout(type: string): void {
    this.ecommerce.checkoutStep(1, 'Normal');
    this.facebook.initiateCheckout(type);
  }

  checkoutStep(step: number): void {
    this.analytics.pushEvent('CheckoutRegistrationStep', { form_step: step });
    this.ecommerce.checkoutStep(step, 'Normal');
  }

  viewProduct(product: Product, variant: ProductVariant): void {
    this.ecommerce.productDetail(product, 'Product Page');
    this.facebook.viewContent(product.name, variant.sku, 'Product Page', product.tokens.toString() + '.00');
    this.adwords.viewItem(product, variant);
    this.pinterest.pageVisit(product);
  }

  viewLandingProduct(product: Product, variant: ProductVariant): void {
    this.ecommerce.productDetail(product, 'Product Landing Page');
    this.facebook.viewContent(
      product.name,
      variant.sku + '-lp',
      'Product Landing Page',
      product.tokens.toString() + '.00'
    );
    this.adwords.viewItem(product, variant);
    this.pinterest.pageVisit(product);
  }

  addToCart(product: Product, variant: ProductVariant, contentCategory: string): void {
    this.ecommerce.addToCart(product);
    this.facebook.addToCart(product.name, variant.sku, contentCategory, product.tokens.toString() + '.00');
    this.adwords.addToCart(product, variant);
    this.pinterest.addToCart(product);
  }

  purchaseSubscription(
    plan: string,
    planAndPeriod: string,
    revenue: string,
    subscriptionPayment: string,
    predictedLifetimeValue: string,
    id: string = '',
    discountCode: string = ''
  ): void {
    this.ecommerce.purchase({
      id: new Date().getTime(),
      affiliation: 'Subscription',
      revenue,
      value: revenue,
      currency: 'GBP',
      tax: 0.0,
      shipping: 0.0,
    });
    this.facebook.purchase('Checkout', revenue);
    this.facebook.subscription(planAndPeriod, subscriptionPayment, predictedLifetimeValue);
    this.analytics.pushEvent('PurchaseSubscription', {
      event_category: 'Subscription',
      event_action: 'Plan',
      event_label: planAndPeriod,
      currency: 'GPB',
      value: revenue,
      revenue,
    });
    this.pinterest.signup('Subscription', revenue);
    this.tradeDoubler.purchase(id, revenue, discountCode, plan);
    this.awin.purchase(id, revenue, discountCode);
    this.ecommerce.subscriptionPurchase({
      currency: 'GBP',
      revenue,
      value: revenue,
    });
  }

  purchaseGift(revenue: string): void {
    this.ecommerce.purchase({
      id: new Date().getTime(),
      affiliation: 'Gifting',
      currency: 'GBP',
      value: revenue,
      tax: 0.0,
      shipping: 0.0,
    });
    this.ecommerce.giftPurchase({
      id: new Date().getTime(),
      affiliation: 'Gifting',
      value: revenue,
      currency: 'GBP',
      revenue,
    });
    this.facebook.purchase('Gifting Checkout', revenue);
    this.analytics.event('Purchase Gift', 'purchase', revenue);
    this.analytics.pushEvent('GiftingSuccess', {
      currency: 'GBP',
      event_value: revenue,
      event_currency: 'GBP',
      price: revenue,
    });
    this.pinterest.custom(revenue);
  }

  purchaseGiftToy(revenue: string, products: [], shippingFee: string, basketTotal: string) {
    this.ecommerce.purchaseGiftToy(
      {
        id: new Date().getTime(),
        affiliation: 'Gifting',
        revenue,
        value: revenue,
        currency: 'GBP',
        shipping: shippingFee,
        basketTotal,
      },
      products
    );
  }

  purchaseToy(productName: string, revenue: string): void {
    this.ecommerce.purchase({
      id: new Date().getTime(),
      affiliation: 'Keep Toy',
      currency: 'GBP',
      value: revenue,
      tax: 0.0,
      shipping: 0.0,
    });
    this.ecommerce.toyPurchase({
      currency: 'GBP',
      revenue,
      value: revenue,
    });
    this.facebook.purchase('Keep Toy', revenue);
    this.analytics.event('Keep Toy', 'Purchase', productName, revenue);
    this.analytics.pushEvent('KeepToySuccess');
  }

  deliveryOrReturnPurchase(revenue: string): void {
    this.ecommerce.deliveryOrReturnPurchase({
      currency: 'GBP',
      revenue,
      value: revenue,
    });
  }

  subscriptionPurchase(revenue: string): void {
    this.ecommerce.subscriptionPurchase({
      currency: 'GBP',
      revenue,
      value: revenue,
    });
  }

  subscriptionUpdatePurchase(revenue: string): void {
    this.ecommerce.subscriptionUpdatePurchase({
      currency: 'GBP',
      revenue,
      value: revenue,
    });
  }
}
