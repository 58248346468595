var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", { attrs: { d: "M0 0h24v24H0z" } }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M16.5 3C19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35l-3.1-2.82C4.686 14.666 2 11.86 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3zm0 2a3.99 3.99 0 0 0-2.826 1.22l-.151.167L12 8.175l-1.523-1.788A3.989 3.989 0 0 0 7.5 5C5.525 5 4 6.525 4 8.5c0 .778.212 1.544.666 2.386l.134.24c.893 1.556 2.272 3.014 5.905 6.34l1.299 1.183 1.011-.919c3.955-3.618 5.348-5.09 6.26-6.734l.065-.121c.45-.837.66-1.6.66-2.375C20 6.525 18.475 5 16.5 5z",
            fill: "currentColor",
            "fill-rule": "nonzero"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }