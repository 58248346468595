var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16" } },
    [
      _c("path", {
        attrs: {
          d:
            "M11.46 3.5L13 5 9.54 8.5 13 12l-1.54 1.5L8 10l-3.46 3.5L3 12l3.46-3.5L3 5l1.54-1.5L8 7z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }