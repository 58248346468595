var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._b(
      {
        staticClass: "v-nav-mobile-bar",
        class: _vm.classes,
        attrs: { id: "mobile-navigation" }
      },
      "div",
      _vm.$attrs,
      false
    ),
    [
      !_vm.disabledNavigation
        ? _c(
            "BaseList",
            {
              staticClass: "v-nav-mobile-bar__nav-items",
              attrs: { bare: true }
            },
            [
              _c(
                "BaseListItem",
                { staticClass: "v-nav-mobile-bar__nav-item" },
                [
                  _c(
                    "NavIconLink",
                    {
                      staticClass: "v-nav-mobile-bar__link",
                      attrs: {
                        icon: "Hamburger",
                        theme: _vm.navBarTheme.fg,
                        "aria-pressed": _vm.isHamburgerMenuOpen,
                        active: _vm.isHamburgerMenuOpen,
                        type: "button",
                        tag: "button"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.toggleHamburgerMenu(
                            !_vm.isHamburgerMenuOpen
                          )
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.isHamburgerMenuOpen ? "Close" : "Menu") +
                          "\n      "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("NavHamburgerMenu", {
                on: { toggleHamburgerMenu: _vm.toggleHamburgerMenu }
              }),
              _vm._v(" "),
              _c(
                "BaseListItem",
                { staticClass: "v-nav-mobile-bar__nav-item" },
                [
                  _c(
                    "NavIconLink",
                    {
                      staticClass: "v-nav-mobile-bar__link",
                      attrs: {
                        icon: "Toys",
                        to: "/toys",
                        theme: _vm.navBarTheme.fg
                      }
                    },
                    [_vm._v("\n        TOYS\n      ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "BaseListItem",
                { staticClass: "v-nav-mobile-bar__nav-item--center" },
                [
                  _c(
                    "nuxt-link",
                    {
                      staticClass: "v-nav-mobile-bar__link",
                      attrs: { to: "/", "aria-label": "Go to home page" }
                    },
                    [
                      _c("WhirliLogo", {
                        staticClass: "v-nav-mobile-bar__logo",
                        attrs: {
                          background: _vm.logoColor.bg,
                          foreground: _vm.logoColor.fg
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "BaseListItem",
                { staticClass: "v-nav-mobile-bar__nav-item" },
                [
                  _c(
                    "NavIconLink",
                    {
                      staticClass: "v-nav-mobile-bar__link",
                      attrs: { icon: "Search", type: "button", tag: "button" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.handleClick($event)
                        }
                      }
                    },
                    [_vm._v("\n        Search\n      ")]
                  ),
                  _vm._v(" "),
                  _vm.showSearchInput
                    ? _c(
                        "div",
                        {
                          staticClass: "search-container input-box-come",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "input-wrapper" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.searchQuery,
                                    expression: "searchQuery"
                                  }
                                ],
                                ref: "searchInput",
                                staticClass: "search-input",
                                attrs: {
                                  type: "text",
                                  placeholder: "Search..."
                                },
                                domProps: { value: _vm.searchQuery },
                                on: {
                                  input: [
                                    function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.searchQuery = $event.target.value
                                    },
                                    _vm.debouncedInput
                                  ],
                                  focus: _vm.showSuggestions,
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleEnterKey($event)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm.searchQuery && _vm.searchQuery.length
                                ? _c("NavIconLink", {
                                    staticClass: "v-search-bar__clear-btn",
                                    attrs: {
                                      icon: "CrossTwo",
                                      type: "button",
                                      tag: "button"
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.clearSearch($event)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.showSuggestionList
                            ? _c(
                                "ul",
                                {
                                  staticClass: "suggestions-list",
                                  class: {
                                    scrollable:
                                      _vm.filteredSuggestions.length > 6
                                  },
                                  on: {
                                    click: _vm.closeSuggestions,
                                    scroll: _vm.handleScroll
                                  }
                                },
                                [
                                  _vm.products.length
                                    ? [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "suggestion-item head-line-text suggestion-text"
                                          },
                                          [
                                            _vm._v(
                                              "\n              PRODUCTS\n            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(_vm.products, function(
                                          product,
                                          index
                                        ) {
                                          return _c(
                                            "li",
                                            {
                                              key: index,
                                              staticClass: "suggestion-item"
                                            },
                                            [
                                              _c(
                                                "BaseLink",
                                                {
                                                  staticClass:
                                                    "v-nav-desktop-bar__link no-uppercase",
                                                  attrs: {
                                                    to: "/toy/" + product.slug
                                                  },
                                                  on: {
                                                    click: _vm.closeSuggestions
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "product-info"
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "product-image",
                                                        attrs: {
                                                          src: product.url,
                                                          alt: "Product Image"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "product-details"
                                                        },
                                                        [
                                                          _c("p", {
                                                            staticClass:
                                                              "highlight-text",
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                _vm.highlightMatch(
                                                                  product.name
                                                                )
                                                              )
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "product-price"
                                                            },
                                                            [
                                                              _c("DiscIcon", {
                                                                staticClass:
                                                                  "price-image",
                                                                attrs: {
                                                                  icon: "Token",
                                                                  theme:
                                                                    "secondary",
                                                                  size: "teeny"
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              product.discountedValue
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "product-discounted-value"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "strikethrough"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              product.tokens
                                                                            )
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "text-dicscount-value"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              product.discountedValue
                                                                            ) +
                                                                              " Tokens"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "product-discounted-value"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          product.tokens
                                                                        ) +
                                                                          " Tokens"
                                                                      )
                                                                    ]
                                                                  )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        })
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.searchInitiated &&
                                  _vm.products.length === 0
                                    ? [
                                        _c("div", [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "suggestion-item head-line-text suggestion-text product-not-found"
                                            },
                                            [
                                              _vm._v(
                                                "\n                No products found for term'" +
                                                  _vm._s(_vm.searchQuery) +
                                                  "'\n              "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "BaseListItem",
                { staticClass: "v-nav-mobile-bar__nav-item" },
                [
                  _vm.userLoggedIn
                    ? [
                        _c(
                          "NavIconLink",
                          {
                            staticClass: "v-nav-mobile-bar__link",
                            attrs: {
                              icon: _vm.isDropdownMenuOpen
                                ? "Cross"
                                : "Profile",
                              theme: _vm.navBarTheme.fg,
                              "aria-pressed": _vm.isDropdownMenuOpen,
                              active: _vm.isDropdownMenuOpen,
                              "native-type": "button",
                              tag: "button"
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.toggleDropdownMenu(
                                  !_vm.isDropdownMenuOpen
                                )
                              }
                            }
                          },
                          [_vm._v("\n          Account\n        ")]
                        ),
                        _vm._v(" "),
                        _c("NavDropdownMenu", {
                          on: { toggleDropdownMenu: _vm.toggleDropdownMenu }
                        })
                      ]
                    : _c(
                        "NavIconLink",
                        {
                          staticClass: "v-nav-mobile-bar__link",
                          attrs: {
                            icon: "Profile",
                            theme: _vm.navBarTheme.fg,
                            to: "/register",
                            tag: "nuxt-link"
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.registerButtonClick($event)
                            }
                          }
                        },
                        [_vm._v("\n        Subscribe\n      ")]
                      )
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "BaseListItem",
                { staticClass: "v-nav-mobile-bar__nav-item" },
                [
                  _c(
                    "NavIconLink",
                    {
                      staticClass: "v-nav-mobile-bar__link",
                      attrs: {
                        "aria-label": "Open bag",
                        icon: "Bag",
                        "show-warning-badge": _vm.hasOutOfStockLines,
                        theme: _vm.navBarTheme.fg,
                        "native-type": "button",
                        tag: "button",
                        number: _vm.$gifts.giftMode.isOn()
                          ? null
                          : _vm.numberOfItemsInBasket
                      },
                      nativeOn: {
                        click: function($event) {
                          return (function() {
                            _vm.toggleHamburgerMenu(false)
                            _vm.toggleBasket()
                          })($event)
                        }
                      }
                    },
                    [
                      _vm.userHasActiveSubscription
                        ? _c("DiscIcon", {
                            staticClass: "v-nav-mobile-bar__token-icon",
                            attrs: {
                              theme: _vm.navBarTheme.fg,
                              icon: "Token",
                              size: "teeny"
                            }
                          })
                        : _vm._e(),
                      _vm._v(
                        "\n        " + _vm._s(_vm.bagButtonText) + "\n      "
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "BaseList",
            {
              staticClass:
                "v-nav-mobile-bar__nav-items v-nav-mobile-bar__nav-items--center",
              attrs: { bare: true }
            },
            [
              _c(
                "BaseListItem",
                { staticClass: "v-nav-mobile-bar__nav-item--center" },
                [
                  _c(
                    "nuxt-link",
                    {
                      staticClass: "v-nav-mobile-bar__link",
                      class: { "u-hide-until-smallDesk": !_vm.showLogo },
                      attrs: {
                        to: "/?fromRegistration=1",
                        "aria-label": "Go to home page"
                      }
                    },
                    [
                      _c("WhirliLogo", {
                        staticClass: "v-nav-mobile-bar__logo",
                        attrs: {
                          background: _vm.logoColor.bg,
                          foreground: _vm.logoColor.fg
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.isPickToysPage
                ? _c(
                    "BaseListItem",
                    { staticClass: "v-nav-mobile-bar__basket-step-3" },
                    [
                      _c(
                        "NavIconLink",
                        {
                          staticClass: "v-nav-mobile-bar__link",
                          attrs: {
                            "aria-label": "Open bag",
                            icon: "Bag",
                            "show-warning-badge": _vm.hasOutOfStockLines,
                            theme: _vm.navBarTheme.fg,
                            "native-type": "button",
                            tag: "button",
                            number: _vm.$gifts.giftMode.isOn()
                              ? null
                              : _vm.numberOfItemsInBasket
                          },
                          nativeOn: {
                            click: function($event) {
                              return (function() {
                                _vm.toggleHamburgerMenu(false)
                                _vm.toggleBasket()
                              })($event)
                            }
                          }
                        },
                        [
                          _vm.userHasActiveSubscription
                            ? _c("DiscIcon", {
                                staticClass: "v-nav-mobile-bar__token-icon",
                                attrs: {
                                  theme: _vm.navBarTheme.fg,
                                  icon: "Token",
                                  size: "teeny"
                                }
                              })
                            : _vm._e(),
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.bagButtonText) +
                              "\n      "
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }