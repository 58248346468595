











import 'reflect-metadata';
import { Vue, Prop, Component } from 'nuxt-property-decorator';
import { addKeyupEventListener, removeKeyupEventListener } from '~/helpers/keyboard';

const availableThemes: string[] = ['primary', 'secondary', 'tertiary', 'quinary', 'white', 'quaternary'];

@Component
export default class BaseDropdown extends Vue {
  @Prop({ default: false }) readonly visible!: boolean;
  @Prop({ default: 'left', validator: (value) => ['left', 'right'].includes(value) })
  readonly caretPosition!: string;

  @Prop({ default: 'secondary', validator: (value) => availableThemes.includes(value) })
  readonly theme!: string;

  mounted() {
    addKeyupEventListener(27, this.clickedAway);
  }

  destroyed() {
    removeKeyupEventListener(27, this.clickedAway);
  }

  get classes() {
    return {
      [`v-drop-down--${this.theme}`]: this.theme,
      [`v-drop-down--caret-${this.caretPosition}`]: this.caretPosition,
    };
  }

  clickedAway() {
    this.$emit('clicked-away');
  }
}
