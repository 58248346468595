/**
 * Add all animation data here. These will later be lazy loaded on request.
 */
import { hasOwnProp } from '~/helpers/objects';

const animations = {
  'toys-marquee': 'toys-marquee.json',
  'how-it-works-step-1': 'how-it-works-step-1.json',
  'how-it-works-step-2': 'how-it-works-step-2.json',
  'how-it-works-step-3': 'how-it-works-step-3.json',
  'how-it-works-step-4': 'how-it-works-step-4.json',
  'how-it-works-tokens': 'how-it-works-tokens.json',
  'doug-pug-1': 'doug-pug-1.json',
  'doug-pug-2': 'doug-pug-2.json',
  'pingo-flamingo-1': 'pingo-flamingo-1.json',
  'pingo-flamingo-2': 'pingo-flamingo-2.json',
  'pingo-flamingo-3': 'pingo-flamingo-3.json',
  'slater-aligator-1': 'slater-aligator-1.json',
  'slater-aligator-2': 'slater-aligator-2.json',
  gifting: 'gifting.json',
  'gifting-pink': 'gifting-pink.json',
  'beaver-pop-up': 'beaver-pop-up.json',
  'checkbox-square-secondary': 'checkbox-square-secondary.json',
  'checkbox-square-charcoal': 'checkbox-square-charcoal.json',
  'checkbox-circle-secondary': 'checkbox-circle-secondary.json',
  'checkbox-circle-charcoal': 'checkbox-circle-charcoal.json',
  'christmas-star': 'christmas-star.json',
  'christmas-tree': 'christmas-tree.json',
};

export type DefaultAnimationName = keyof typeof animations;

export async function importDefaultAnimation(animationName: DefaultAnimationName) {
  if (!hasOwnProp(animations, animationName)) {
    throw new Error('No animation found with name ' + animationName);
  }

  return JSON.parse(JSON.stringify(await import('~/assets/animations/' + animations[animationName])));
}

export const animationNames = Object.keys(animations);
