var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "v-bag-icon" },
    [
      _c("BaseIcon", {
        staticClass: "v-bag-icon__graphic",
        attrs: { icon: "Bag", size: _vm.size, theme: _vm.theme }
      }),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.number || _vm.number === 0,
              expression: "number || number === 0"
            }
          ],
          staticClass: "v-bag-icon__number",
          class: _vm.textClasses
        },
        [_vm._v(_vm._s(_vm.number))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }