import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '~/store/index';

const types = {
  PUT_PAGE_THEME: 'PUT_PAGE_THEME',
  PUT_SHOW_BANNER: 'PUT_SHOW_BANNER',
};

interface ModuleState {
  pageTheme: string;
  showBanner: boolean;
}

export const state = (): ModuleState => ({
  pageTheme: 'primary',
  showBanner: false,
});

export const actions: ActionTree<ModuleState, RootState> = {
  initialState({ commit }, initialState: { theme: string }) {
    this.$logger.store(`action`, `[pageConfig/initialState]`, { initialState });

    commit(types.PUT_PAGE_THEME, { pageTheme: initialState.theme });
  },
  updatePageTheme({ commit }, newTheme: string) {
    this.$logger.store(`action`, `[pageConfig/updatePageTheme]`, { newTheme });

    commit(types.PUT_PAGE_THEME, { pageTheme: newTheme });
  },
  // remove together with GreenFriday feature-toggle
  getShowGreenFridayBanner({ commit }) {
    const currentTime: number = new Date().getTime();
    const showBanner: boolean =
      currentTime > new Date(2021, 10, 26, 0, 1).getTime() &&
      currentTime < new Date(2021, 10, 30, 23, 59).getTime();
    commit(types.PUT_SHOW_BANNER, { showBanner });
  },
};

export const mutations: MutationTree<ModuleState> = {
  [types.PUT_PAGE_THEME](state, { pageTheme }) {
    state.pageTheme = pageTheme;
  },
  [types.PUT_SHOW_BANNER](state, { showBanner }) {
    state.showBanner = showBanner;
  },
};
