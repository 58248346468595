var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 32 32" } },
    [
      _c("path", {
        attrs: {
          d: "M20.713 4H8.102A2.1 2.1 0 006 6.102v14.712h2.102V6.102h12.61V4z"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M23.865 8.204h-11.56a2.1 2.1 0 00-2.101 2.101v14.713c0 1.161.94 2.102 2.101 2.102h11.56a2.1 2.1 0 002.102-2.102V10.305c0-1.16-.94-2.101-2.102-2.101zm0 16.814h-11.56V10.305h11.56v14.713z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }