var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "v-accordion", class: [_vm.classes, _vm.$attrs.class] },
    [
      _c(
        _vm.buttonElementType,
        {
          tag: "component",
          staticClass: "v-accordion__header-button",
          attrs: {
            id: _vm.name + "-header-id",
            "aria-expanded": _vm.open,
            "aria-controls": _vm.name + "-body-id"
          },
          on: {
            click: function($event) {
              _vm.disabled ? null : _vm.$emit("toggle-accordion")
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "v-accordion__header" },
            [
              _vm._t("base-accordion-header"),
              _vm._v(" "),
              !_vm.disabled
                ? _c(
                    "span",
                    {
                      style:
                        "transform: translateX(" + _vm.translateIcon + "px)"
                    },
                    [
                      _c("BaseIcon", {
                        attrs: {
                          icon: "ArrowCenter",
                          direction: _vm.open ? "up" : "down",
                          classes: "v-accordion__header-icon",
                          size: "base",
                          theme: _vm.arrowTheme
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "v-accordion__body",
          style: "--max-height: " + _vm.accordionHeight,
          attrs: {
            id: _vm.name + "-body-id",
            role: "region",
            "aria-labelledby": _vm.name + "-header-id",
            "aria-hidden": !_vm.open
          }
        },
        [
          _c(
            "div",
            { ref: "accordionBody" },
            [_vm._t("base-accordion-body")],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }