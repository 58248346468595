import { hasActiveSubscription } from '~/helpers/models/user';
import { redirectWithoutCaching } from '~/helpers/redirect';

export default function(context) {
  const { app } = context;

  if (app.$auth.user && hasActiveSubscription(app.$auth.user)) {
    return redirectWithoutCaching(context, '/');
  }
}
