
















import 'reflect-metadata';
import { Vue, Component, Watch, State, Action } from 'nuxt-property-decorator';
import TheOverlay from '~/components/containers/TheOverlay/TheOverlay.vue';
import FiltersSideDrawer from '~/components/features/browse-toys/filters/FiltersSideDrawer/FiltersSideDrawer.vue';
import TheToyBasketContainer from '~/components/features/basket/TheToyBasketContainer/TheToyBasketContainer.vue';
import TheNotifications from '~/components/features/notifications/TheNotifications.vue';
import TheFixedButtonCTA from '~/components/partials/buttons/TheFixedButtonCTA/TheFixedButtonCTA.vue';
import SignupModal from '~/components/features/subscription/SignupModal/SignupModal.vue';
import { hasActiveSubscription } from '~/helpers/models/user';
import { RootState } from '~/store';
import { KeepAliveProps } from '~/types/Vue/keepAlive';
import ReferralsBanner from '~/components/prefabs/banners/ReferralsBanner/ReferralsBanner.vue';
import InfoBanner from '~/components/prefabs/banners/InfoBanner/InfoBanner.vue';
import OffersBanner from '~/components/prefabs/banners/OffersBanner/OffersBanner.vue';
import ZenDeskWrapper from '~/components/features/live-chat/ZenDesk/ZenDeskWrapper.vue';

@Component({
  components: {
    OffersBanner,
    InfoBanner,
    ReferralsBanner,
    TheOverlay,
    FiltersSideDrawer,
    TheToyBasketContainer,
    TheNotifications,
    TheFixedButtonCTA,
    ZenDeskWrapper,
    SignupModal,
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  errorCaptured(this: DefaultLayout, err: Error) {
    this.$logger.onRenderError(err);
    return false;
  },
})
export default class DefaultLayout extends Vue {
  keepAliveProps: KeepAliveProps = {
    exclude: ['Checkout', 'Redeem'],
    max: 15,
  };

  inMaintenanceMode: boolean = process.env.maintenanceModeOn === 'true';
  showChristmasMessage: boolean = false;

  @State((store: RootState) => store.storage.settingsGiftMode) giftMode!: boolean;
  @Action('pageConfig/updatePageTheme') updatePageTheme!: (newTheme: string) => void;
  @Action('pageConfig/getShowGreenFridayBanner') getShowGreenFridayBanner!: () => void;

  get userHasActiveSubscription(): boolean {
    return hasActiveSubscription(this.$auth.user);
  }

  async fetch(): Promise<void> {
    await this.updatePageTheme(this.pageTheme);
    this.getShowGreenFridayBanner();
    if (this.$auth.user) {
      await this.$store.dispatch('childProfiles/setChildProfiles', this.$auth.user.childProfiles);
    }
  }

  @Watch('currentRouteName')
  onCurrentRouteNameChanged() {
    this.updatePageTheme(this.pageTheme);
  }

  @Watch('scrollDisabled')
  updateBodyTag() {
    if (!document) return;
    const body = document.querySelector('body');
    if (!body) return;
    const classMethod = this.scrollDisabled ? 'add' : 'remove';
    body.classList[classMethod]('u-scroll--disabled');
  }

  get hasActiveSubscription(): boolean {
    return hasActiveSubscription(this.$auth.user);
  }

  get shouldDisplayOffersBanner(): boolean {
    const currentDate = new Date();
    const startDate = new Date('2023-11-11');
    const endDate = new Date('2024-01-02');
    return currentDate >= startDate && currentDate <= endDate;
  }

  mounted() {
    this.setViewportHeightUnit();
    window.addEventListener('resize', () => {
      this.setViewportHeightUnit();
    });

    this.$nextTick(() => {
      if (this.$route.hash) {
        const strippedHash = this.$route.hash.replace('#', '');
        const element = document.querySelector(`[name="${strippedHash}"], [id="${strippedHash}"]`);

        if (element) {
          this.$scrollTo(element, 250, { offset: -150 });
        }
      }
    });
  }

  setViewportHeightUnit() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  get displayFixedCTA(): boolean {
    const path = this.$route.path;
    const pagesToShow = ['/how-it-works', '/reviews'];
    return !this.userHasActiveSubscription && pagesToShow.some((page) => path === page);
  }

  get currentRouteName(): string | null | undefined {
    return this.$route.name;
  }

  get scrollDisabled(): boolean {
    return this.$store.state.scroll.disabled;
  }

  get pageTheme(): string {
    switch (this.$route.name) {
      case 'pricing':
      case 'reviews':
        return 'secondary';
      case 'careers':
      case 'contact-us':
      case 'refer-a-friend':
      case 'lists':
      case 'gift-list-slug':
        return 'tertiary';
      case 'gift':
      case 'gift-buy':
        return 'quaternary';
      case 'christmas':
        return 'quinary-darker';
      case 'checkout':
      case 'gifting-terms-and-conditions':
      case 'privacy-policy':
      case 'subscription-terms-and-conditions':
      case 'website-terms-of-use':
      case 'toy-slug':
      case 'lp-toy-slug':
      case 'blog':
        return 'white';
      case 'toys':
      case 'toys-all':
      case 'gift-redeem':
        if (this.giftMode) return 'quaternary';
        return 'primary';
      case 'offers-christmas':
        return 'primary-dark';
      case 'index':
        return 'white';
      case 'page-list':
        return 'white';
      default:
        return 'primary';
    }
  }
}
