import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { BaseData, ExceptionData, RequestData, ResponseData } from '../interfaces';

/**
 * Generates reports containing the request / response / error data
 */
export class Report {
  public base(): BaseData {
    return {
      axios: true,
      id: this.id(),
      start: this.timestamp(),
      process: process.browser ? 'browser' : 'server',
    };
  }

  public performance(start: number): Partial<BaseData> {
    return {
      end: this.timestamp(),
      timing: Number((this.timestamp() - start).toFixed()),
    };
  }

  public request(config: AxiosRequestConfig): RequestData {
    const { method, url, params, headers, baseURL } = config;

    return {
      method: (method || '').toUpperCase(),
      url: `${baseURL}${url}`,
      params,
      headers,
      body: null, // @todo data,
    };
  }

  public response(response: AxiosResponse): ResponseData {
    // eslint-disable-next-line camelcase
    const { http_code, success, errors, messages, meta } = response.data;
    const { headers, status, statusText } = response;

    return {
      httpCode: http_code,
      success,
      errors,
      messages,
      meta,
      data: null, // @todo data
      resHeaders: headers,
      status,
      statusText,
    };
  }

  public exception(error: AxiosError): ExceptionData {
    const { code, message, name } = error;

    return {
      exception: this.message(error),
      code,
      message,
      name,
      isAxiosError: error.isAxiosError,
    };
  }

  protected message(error: AxiosError): string {
    const { response } = error;

    if (response) {
      const {
        data: {
          errors: { message: messages },
        },
      } = response;
      return messages ? messages.join(' - ') : error.message;
    }
    return error.message;
  }

  protected timestamp(): number {
    if (typeof performance !== 'undefined') {
      return performance.now();
    }
    return Date.now();
  }

  protected id(): string {
    return Math.round(Math.random() * 36 ** 6).toString(36);
  }
}
