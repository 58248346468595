import { ActionTree, MutationTree, GetterTree } from 'vuex';
import { RootState } from '~/store/index';
import {
  types,
  state as BState,
  getters as BGetters,
  actions as BActions,
  mutations as BMutations,
  ModuleState as BModuleState,
} from '~/helpers/store/BasketStore';
import { Basket } from '~/types/basket';
import { itemCount, totalTokens } from '~/helpers/models/basket';
import { AxiosRequestConfig } from '~/node_modules/axios';

export interface ModuleState extends BModuleState {}

const basketState = {};

const basketActions: ActionTree<ModuleState, RootState> = {
  async fetch({ dispatch }) {
    this.$logger.store(`action`, `[basket/fetch]`);

    await dispatch('makeRequest', {
      requestName: this.$auth.user ? 'fetchUserActiveBasket' : 'fetchGuestBasketById',
      request: async () => await dispatch(this.$auth.user ? 'getUserBasketData' : 'getGuestBasketData'),
    });
  },

  async getUserBasketData({ commit }): Promise<any> {
    this.$logger.store(`action`, `[basket/getUserBasketData]`);

    try {
      return await this.$whirli.users.baskets.getActive();
    } catch (error) {
      if (error.response && error.response.status === 404) {
        commit(types.SET_BASKET_FETCHED, true);
        return null;
      }
      throw error;
    }
  },

  async getGuestBasketData({ commit, dispatch }): Promise<any> {
    this.$logger.store(`action`, `[basket/getGuestBasketData]`);

    if (!this.$storage.getUniversal('basketId')) return null;

    try {
      return await this.$whirli.guests.baskets.get(this.$storage.getUniversal('basketId'));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        commit(types.SET_BASKET_FETCHED, true);
        dispatch('clearCachedBasket');
        return null;
      }
      throw error;
    }
  },

  getBasketId({ state }): string | null {
    this.$logger.store(`action`, `[basket/getBasketId]`);

    return state.id || this.$storage.getUniversal('basketId') || null;
  },

  saveBasket({ commit, dispatch }, basket) {
    this.$logger.store(`action`, `[basket/saveBasket]`, { basket });
    const discountedTotalTokens: number = totalTokens(basket);
    const totalTokensValue: number = discountedTotalTokens > 0 ? discountedTotalTokens : basket.totalTokens;

    commit(types.PUT_BASKET_AND_ID, { id: basket.id, basket });
    commit(types.PUT_BASKET_TOKENS, totalTokensValue);
    if (basket.checkout) {
      commit(
        'checkout/PUT_STATUS_AND_MESSAGE',
        {
          status: basket.checkout.canCheckout,
          message: basket.checkout.message,
        },
        { root: true }
      );
    }
    commit(types.SET_BASKET_FETCHED, true);
    dispatch('updateBasketCount', { basket });
    this.$storage.setUniversal('basketId', basket.id);
  },

  updateBasketCount({ commit }, { basket, count = 0 }: { basket?: Basket; count?: number }) {
    this.$logger.store(`action`, `[basket/updateBasketCount]`, { basket, count });

    if (!count && basket) count = itemCount(basket);

    this.$storage.setUniversal('basketCount', count);
    commit(types.PUT_BASKET_COUNT, count);
  },

  updateTokensUsed({ commit }, tokensUsed: number) {
    this.$logger.store(`action`, `[basket/updateTokensUsed]`, { tokensUsed });

    commit(types.PUT_BASKET_TOKENS, tokensUsed);
  },

  extraRequestConfig(): AxiosRequestConfig {
    this.$logger.store(`action`, `[basket/extraRequestConfig]`);

    return {};
  },

  clearCachedBasket() {
    this.$logger.store(`action`, `[basket/clearCachedBasket]`);

    this.$storage.removeUniversal('basketId');
    this.$storage.removeUniversal('basketCount');
  },

  resetBasket({ commit }) {
    this.$logger.store(`action`, `[basket/resetBasket]`);

    commit(types.PUT_BASKET_AND_ID, { id: null, basket: null });
    commit(types.PUT_BASKET_TOKENS, 0);
    commit(types.SET_BASKET_FETCHED, false);
    commit(types.PUT_BASKET_COUNT, 0);
    this.$storage.removeUniversal('basketId');
    this.$storage.removeUniversal('basketCount');
  },
};

const basketMutations: MutationTree<ModuleState> = {};

export const state = () => Object.assign({}, BState(), basketState);

export const getters: GetterTree<ModuleState, RootState> = Object.assign({}, BGetters);

export const actions: ActionTree<ModuleState, RootState> = Object.assign({}, BActions, basketActions);

export const mutations: MutationTree<ModuleState> = Object.assign({}, BMutations, basketMutations);
