import { ProductVariant } from '~/types/products/products';
import {
  getRoute as productUrl,
  image as productImage,
  name as productName,
  slug as productSlug,
  tokens as productTokens,
  getStock as productStock,
  discountedValue as productDiscountedValue,
} from '~/helpers/models/product';
import { Basket, BasketLine } from '~/types/basket';

export function productVariantId(productVariant: ProductVariant): string | null {
  return productVariant ? productVariant.id : null;
}

export function url(productVariant: ProductVariant): string {
  return productVariant.product ? productUrl(productVariant.product) : '';
}

export function image(productVariant: ProductVariant): string | null {
  return productVariant.product ? productImage(productVariant.product) : null;
}

export function name(productVariant: ProductVariant): string | null {
  return productVariant.product ? productName(productVariant.product) : null;
}

export function slug(productVariant: ProductVariant): string | null {
  return productVariant.product ? productSlug(productVariant.product) : null;
}

export function tokens(productVariant: ProductVariant): number | null {
  return productVariant.product ? productTokens(productVariant.product) : null;
}

export function outOfStock(productVariant: ProductVariant): boolean {
  return productVariant.stock === 0;
}

export function remainingStockIsInBasket(basket: Basket, productVariant: ProductVariant): boolean {
  const basketLine: BasketLine | null =
    basket.basketLines.find((basketLine) => basketLine.productVariant.id === productVariant.id) || null;

  return basketLine ? productStock(productVariant) - basketLine.quantity <= 0 : false;
}

export function discountedValue(productVariant: ProductVariant): number | null {
  return productVariant.product ? productDiscountedValue(productVariant.product) : null;
}
