


















































import 'reflect-metadata';
import { Component, mixins, namespace, Prop } from 'nuxt-property-decorator';
import FiltersAccordion from '../FiltersAccordion/FiltersAccordion.vue';
import SelectedFilters from '../SelectedFilters/SelectedFilters.vue';
import TokenSliderAccordion from '../TokenSliderAccordion/TokenSliderAccordion.vue';
import FiltersMixin from '../FiltersMixin/FiltersMixin.vue';
import { Bucket, ProductAssociationMeta, ProductAssociationMetas } from '~/types/products/associations';
import BaseToggleButton from '~/components/partials/buttons/BaseToggleButton/BaseToggleButton.vue';
import { SelectedAssociations as SA, SelectedBucket } from '~/store/products/associations';

interface SelectedAssociations extends SA {} // Without this, typescript throws a warning that it cannot find the export

const AssociationsStore = namespace('products/associations');
const ProductsStore = namespace('products');
@Component({
  components: {
    BaseToggleButton,
    SelectedFilters,
    FiltersAccordion,
    TokenSliderAccordion,
  },
})
export default class FiltersPanel extends mixins(FiltersMixin) {
  @Prop() associationsProp!: { [key: string]: ProductAssociationMeta };
  @ProductsStore.State hidePrevious!: boolean;
  @ProductsStore.State hideNotInStock!: boolean;
  @ProductsStore.State('busy') productsBusy!: boolean;
  @ProductsStore.Action toggleHidePrevious!: (shouldHidePrevious: boolean) => void;
  @ProductsStore.Action toggleHideNotInStock!: (shouldHideNotInStock: boolean) => void;
  @AssociationsStore.State current!: ProductAssociationMetas;
  @AssociationsStore.State selected!: SelectedAssociations;

  get associations(): Array<ProductAssociationMeta> {
    if (!this.current) return Object.values(this.associationsProp);
    const currentAssociations =
      Object.values(this.current).length > 0
        ? Object.values(this.current)
        : Object.values(this.associationsProp);
    return currentAssociations.filter((association) => association.active);
  }

  async toggleProductsNotPreviouslyOrdered() {
    try {
      await this.toggleHidePrevious(!this.hidePrevious);
      await this.refreshProducts();
    } catch (error) {
      throw error;
    }
  }

  async toggleProductsNotInStock() {
    try {
      this.$gtm.analytics.event('Browse Toys', 'Hide not in stock', this.hideNotInStock ? 'Yes' : 'No');

      await this.toggleHideNotInStock(!this.hideNotInStock);
      await this.refreshProducts();
    } catch (error) {
      throw error;
    }
  }

  isOpen(association: ProductAssociationMeta): boolean {
    let selected = false;
    Object.keys(this.selected || {}).forEach((key: string) => {
      const associationSlugs = association.buckets.map((bucket: Bucket) => bucket.slug);
      const selectedSlugs = this.selected[key].map((selected: SelectedBucket) => selected.slug);

      if (associationSlugs.some((s) => selectedSlugs.includes(s))) {
        selected = true;
      }
    });
    return selected;
  }
}
