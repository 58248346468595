import { ActionTree } from 'vuex';
import { Context } from '@nuxt/types';
import { NuxtStorage } from '@nuxtjs/universal-storage';
import { Route } from 'vue-router';
import { Auth } from '~/index.d';
import { ModuleState as GiftModuleState } from '~/store/gift';
import { ModuleState as ProductsModuleState } from '~/store/products';
import { ModuleState as RegistrationModuleState } from '~/store/registration';
import { ModuleState as CheckoutModuleState } from '~/store/checkout';
import { tokensInToybox } from '~/helpers/models/user';

interface NuxtStorageWithState extends NuxtStorage {
  settingsGiftMode: boolean;
}

export interface RootState {
  auth: Auth;
  storage: NuxtStorageWithState;
  products: ProductsModuleState;
  gift: GiftModuleState;
  registration: RegistrationModuleState;
  checkout: CheckoutModuleState;
  basket: any;
  drawers: any;
  navigation: any;
  pageConfig: any;
  resize: any;
  scroll: any;
  subscriptions: any;
  toybox: any;
  route: Route;
  zendesk: any;
}

export const actions: ActionTree<RootState, RootState> = {
  async nuxtServerInit({ dispatch }, { app }: Context) {
    // pass app as a second argument to the logger to debug the entire app object
    this.$logger.store(`action`, `[root/nuxtServerInit]`);

    const jobs = [];
    let basketCount!: number;
    if (app.$auth.loggedIn && app.$auth.user) {
      basketCount = app.$auth.user.basketQuantity;
      jobs.push(dispatch('basket/updateTokensUsed', app.$auth.user.activeBasketTotalTokens));

      const tokensUsed: number = tokensInToybox(app.$auth.user);
      jobs.push(dispatch('toybox/setTokensUsed', tokensUsed));
    } else {
      basketCount = app.$storage.getUniversal('basketCount') || 0;
    }
    jobs.push(dispatch('basket/updateBasketCount', { count: basketCount }));

    await Promise.all(jobs);
  },
};

export interface ClientResponse {
  data: object;
}
