var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.$auth.loggedIn && _vm.$route.name !== "register"
    ? _c(
        "BaseBanner",
        _vm._b(
          {
            staticClass: "v-promo-banner",
            attrs: {
              justify: "center",
              type: _vm.type,
              "can-close": false,
              align: "middle"
            }
          },
          "BaseBanner",
          _vm.$attrs,
          false
        ),
        [
          _vm.isMobile
            ? _c(
                "PaginatedCarousel",
                {
                  attrs: {
                    elaborate: false,
                    loop: true,
                    "show-navigation": false,
                    "slides-per-view": { mobile: 1, tablet: 1, desk: 1 }
                  }
                },
                [
                  _c(
                    "CarouselSlide",
                    [
                      _c(
                        "BaseLayoutItem",
                        { staticClass: "u-text--center" },
                        [
                          _c(
                            "BaseLink",
                            {
                              staticClass: "secondary-color",
                              attrs: { to: "/how-it-works", underline: true }
                            },
                            [_vm._v("\n          HOW IT WORKS\n        ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "CarouselSlide",
                    [
                      _c(
                        "BaseLayoutItem",
                        { staticClass: "u-text--center" },
                        [
                          _c(
                            "BaseLink",
                            {
                              staticClass: "secondary-color",
                              attrs: {
                                to:
                                  "/frequently-asked-questions#how-do-i-make-a-swap",
                                underline: true
                              }
                            },
                            [_vm._v("\n          DELIVERY\n        ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "BaseLayout",
            {
              staticStyle: { display: "block" },
              attrs: { "fill-direction": "center" }
            },
            [
              !_vm.isMobile
                ? _c(
                    "BaseLayoutItem",
                    { staticClass: "u-text--center", attrs: { tablet: "1/2" } },
                    [
                      _c(
                        "BaseLink",
                        {
                          staticClass: "secondary-color",
                          attrs: { to: "/how-it-works", underline: true }
                        },
                        [_vm._v("\n        HOW IT WORKS\n      ")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isMobile
                ? _c(
                    "BaseLayoutItem",
                    { staticClass: "u-text--center", attrs: { tablet: "1/2" } },
                    [
                      _c(
                        "BaseLink",
                        {
                          staticClass: "secondary-color",
                          attrs: {
                            to:
                              "/frequently-asked-questions#how-do-i-make-a-swap",
                            underline: true
                          }
                        },
                        [_vm._v("\n        DELIVERY\n      ")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }