




















































































import 'reflect-metadata';
import { Vue, Component, Watch } from 'nuxt-property-decorator';
import WhirliLogo from '~/components/partials/logo/WhirliLogo/WhirliLogo.vue';
import ButtonIcon from '~/components/partials/icons/ButtonIcon/ButtonIcon.vue';
import FlatAccordion from '~/components/partials/accordions/FlatAccordion/FlatAccordion.vue';
import PaymentMethods from '~/components/prefabs/paymentMethods/PaymentMethods.vue';

@Component({
  components: {
    WhirliLogo,
    ButtonIcon,
    FlatAccordion,
    PaymentMethods,
  },
})
export default class TheFooter extends Vue {
  socialLinks: Record<string, any>[] = [
    { to: 'https://www.facebook.com/GiveItAWhirli/', icon: 'Facebook' },
    { to: 'https://www.instagram.com/giveitawhirli/?hl=en', icon: 'Instagram' },
    { to: 'https://twitter.com/GiveItAWhirli', icon: 'Twitter' },
  ];

  disabledNavigation: boolean = false;

  created() {
    this.toggleNavigation();
  }

  @Watch('$route.path')
  toggleNavigation(): void {
    this.disabledNavigation = this.$navigation.disabled();
  }

  get footerLinks() {
    const links = [
      {
        name: 'use-whirli-accordion',
        header: 'Use Whirli',
        links: [
          { to: '/toys', text: 'Browse toys' },
          { to: '/pricing', text: 'Pricing' },
          { to: '/gift', text: 'Gifting' },
        ],
      },
      {
        name: 'about-us-accordion',
        header: 'About us',
        links: [
          { to: '/how-it-works', text: 'How it works' },
          { to: '/reviews', text: 'Reviews' },
          { to: '/blog', text: 'Blog' },
        ],
      },
      {
        name: 'support-accordion',
        header: 'Support',
        links: [
          { to: '/contact-us', text: 'Contact us' },
          { to: '/frequently-asked-questions', text: 'Frequently Asked Questions' },
          {
            to: 'https://support.whirli.com/hc/en-gb/articles/360016990517-10-Toy-return-and-refund-policy',
            text: 'Returns and Refund Policy',
          },
          { to: '/careers', text: 'Careers' },
        ],
      },
      {
        name: 'legal-accordion',
        header: 'Legal',
        links: [
          { to: '/privacy-policy', text: 'Privacy and Cookie Policy' },
          { to: '/website-terms-of-use', text: 'Website Terms of Use' },
        ],
      },
    ];

    return links;
  }

  get currentYear(): number {
    return new Date().getFullYear();
  }
}
