import Events from './Events';
import { Product } from '~/types/products/products';
import { getMajorityBrandName } from '~/helpers/models/product';

export default class EcommerceEvents extends Events {
  /**
   * Pushes a product page view to the dataLayer.
   *
   * @param {Product} product
   * @param {String} actionField
   */
  productDetail(product: Product, list: string) {
    if (product.productAssociations && product.productAssociations.length > 0) {
      product.productAssociations.forEach((association, index) => {
        setTimeout(() => {
          this.data.pushEvent('productDetail', {
            ecommerce: {
              detail: {
                actionField: { list },
                products: [
                  {
                    id: product.id,
                    name: product.name,
                    price: product.tokens.toFixed(2),
                    brand: getMajorityBrandName(product),
                    category: association.name,
                    image: product.location,
                  },
                ],
              },
            },
          });
        }, index * 100);
      });
    } else {
      let categoriesNames = '';
      if (product.productAssociations) {
        categoriesNames = product.productAssociations
          .map(function(association) {
            return association.name;
          })
          .join(', ');
      }
      this.data.pushEvent('productDetail', {
        ecommerce: {
          detail: {
            actionField: { list },
            products: [
              {
                id: product.id,
                name: product.name,
                price: product.tokens.toFixed(2),
                brand: getMajorityBrandName(product),
                categories: categoriesNames,
                image: product.location,
              },
            ],
          },
        },
      });
    }
  }

  /**
   * Pushes a product click view to the dataLayer.
   * @todo not used? list = 'Browse Toys'
   *
   * @param {Product} product
   * @param {String} list
   */
  productClick(product: Product, list: string) {
    let categoriesNames = '';
    if (product.productAssociations) {
      categoriesNames = product.productAssociations
        .map(function(association) {
          return association.name;
        })
        .join(', ');
    }
    this.data.pushEvent('productClick', {
      ecommerce: {
        click: {
          actionField: { list },
          products: [
            {
              id: product.id,
              name: product.name,
              price: product.tokens.toFixed(2),
              brand: getMajorityBrandName(product),
              categories: categoriesNames,
              image: product.location,
            },
          ],
        },
      },
    });
  }

  /**
   * Pushes an add to cart action to the dataLayer.
   *
   * @param {Product} product
   * @param {Number} quantity
   */
  addToCart(product: Product, quantity: number = 1) {
    this.data.pushEvent('addToCart', {
      ecommerce: {
        currencyCode: 'GBP',
        add: {
          products: [
            {
              id: product.id,
              name: product.name,
              price: product.tokens.toFixed(2),
              brand: getMajorityBrandName(product),
              quantity,
            },
          ],
        },
      },
    });
  }

  /**
   * Pushes a remove from cart action to the dataLayer.
   *
   *
   * @param {Product} product
   * @param {Number} quantity
   */
  removeFromCart(product: Product, quantity = 1) {
    this.data.pushEvent('removeFromCart', {
      ecommerce: {
        remove: {
          products: [
            {
              id: product.id,
              name: product.name,
              price: product.tokens.toFixed(2),
              brand: getMajorityBrandName(product),
              quantity,
            },
          ],
        },
      },
    });
  }

  /**
   * Updates a product quantity in the cart
   *
   * @param {Product} product
   * @param {number} product
   * @param {number} product
   */
  updateCart(product: Product, current: number, quantity: number) {
    if (quantity < current) {
      this.removeFromCart(product, current - quantity);
    }
    if (quantity > current) {
      this.addToCart(product, quantity - current);
    }
  }

  /**
   * Pushes a checkout step action to the dataLayer.
   *
   * @param {Number} step
   * @param {String} option
   * @param {Product[]} products
   */
  checkoutStep(step: number, option: string, products: Product[] = []) {
    this.data.pushEvent('checkout', {
      ecommerce: {
        checkout: {
          actionField: {
            step,
            option,
          },
          products,
        },
      },
    });
  }

  /**
   * Pushes a checkout option to the dataLayer.
   *
   * @param {Number} actionField
   */
  checkoutOption(actionField: object = {}) {
    this.data.pushEvent('checkoutOption', {
      ecommerce: {
        checkout_option: {
          actionField,
        },
      },
    });
  }

  /**
   * Pushes a checkout option to the dataLayer.
   *
   * @param {Number} actionField
   */
  purchase(actionField: object = {}, products = []) {
    this.data.pushEvent('purchase', {
      ecommerce: {
        purchase: {
          actionField,
          products,
        },
      },
    });
  }

  purchaseGiftToy(actionField: object = {}, products = []) {
    this.data.pushEvent('purchaseGiftToy', {
      ecommerce: {
        purchase: {
          actionField,
          products,
        },
      },
    });
  }

  giftPurchase(actionField: object = {}, products = []) {
    this.data.pushEvent('Gift Purchase', {
      ecommerce: {
        purchase: {
          actionField,
          products,
        },
      },
    });
  }

  subscriptionPurchase(actionField: object = {}, products = []) {
    this.data.pushEvent('Subscription Purchase', {
      ecommerce: {
        purchase: {
          actionField,
          products,
        },
      },
    });
  }

  subscriptionUpdatePurchase(actionField: object = {}, products = []) {
    this.data.pushEvent('Subscription Update Purchase', {
      ecommerce: {
        purchase: {
          actionField,
          products,
        },
      },
    });
  }

  deliveryOrReturnPurchase(actionField: object = {}, products = []) {
    this.data.pushEvent('Delivery/Return Purchase', {
      ecommerce: {
        purchase: {
          actionField,
          products,
        },
      },
    });
  }

  toyPurchase(actionField: object = {}, products = []) {
    this.data.pushEvent('Toy Purchase', {
      ecommerce: {
        purchase: {
          actionField,
          products,
        },
      },
    });
  }
}
