var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseBox",
    { style: _vm.styles, attrs: { padding: _vm.padding, tag: _vm.tag } },
    [
      _vm.$slots.header ? _vm._t("header") : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.$slots.footer ? _vm._t("footer") : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }