import { render, staticRenderFns } from "./BaseVerticalSpacer.vue?vue&type=template&id=d655e932&scoped=true&functional=true&"
import script from "./BaseVerticalSpacer.vue?vue&type=script&lang=ts&"
export * from "./BaseVerticalSpacer.vue?vue&type=script&lang=ts&"
import style0 from "./BaseVerticalSpacer.vue?vue&type=style&index=0&id=d655e932&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "d655e932",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/whirli-uwa/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d655e932')) {
      api.createRecord('d655e932', component.options)
    } else {
      api.rerender('d655e932', component.options)
    }
    module.hot.accept("./BaseVerticalSpacer.vue?vue&type=template&id=d655e932&scoped=true&functional=true&", function () {
      api.rerender('d655e932', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/primitives/BaseVerticalSpacer/BaseVerticalSpacer.vue"
export default component.exports