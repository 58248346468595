var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
    [
      _c("path", {
        attrs: {
          d:
            "M19 17.7c-.1 0-.1 0 0 0H17.3V19H19c.3 0 .6-.3.6-.6 0-.4-.2-.7-.6-.7zm0-1.7c0-.3-.3-.6-.6-.6h-1.1v1.1h1.5c0-.1.2-.3.2-.5zm2.2-4.3h-4.1c1.5 0 2.7-1.3 2.7-2.8V2.7c0-1.5-1.2-2.7-2.7-2.7h-6.2C9.4 0 8.1 1.2 8.1 2.7v6.2c0 .6.2 1.1.5 1.5l-.1-.1c-.5-.5-1.2-.7-1.9-.7s-1.4.3-1.9.8L.9 14.2c-1.1 1.1-1.1 2.8 0 3.9l3.8 3.8c.5.5 1.2.8 1.9.8s1.4-.3 1.9-.8l3.8-3.8c.4-.4.7-.9.7-1.4v3.2c0 1.5 1.3 2.8 2.8 2.8h5.4c1.5 0 2.7-1.3 2.7-2.8v-5.4c0-1.6-1.2-2.8-2.7-2.8zm-6.8-9.4c.9 0 1.8.4 2.5 1 .3.2.3.7 0 .9-.2.3-.7.3-.9 0-.4-.4-1-.6-1.6-.6-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2c.7 0 1.3-.3 1.7-.8.2-.2.6-.2.9 0 .3.2.3.6.1.9-.7.8-1.6 1.2-2.7 1.2-1.9 0-3.5-1.6-3.5-3.5-.1-1.9 1.5-3.5 3.5-3.5zm-3.7 14c-.1.3-.5.5-.8.4l-.9-.3-2 2 .3 1c.1.3-.1.7-.4.8h-.2c-.3 0-.5-.2-.6-.4l-1.7-5.3c-.1-.2 0-.5.2-.7.2-.2.4-.2.7-.1l5.2 1.8c.1.1.3.5.2.8zm2.3-1.9v1.1c-.1-.5-.3-1-.7-1.4l-2.9-2.9c.4.3 1 .5 1.5.5h4.9c-1.5 0-2.8 1.2-2.8 2.7zm6 5.9h-2.4c-.4 0-.7-.3-.7-.7v-5c0-.4.3-.6.7-.6h1.7c1 0 1.9.9 1.9 1.9 0 .3-.1.6-.2.8.5.3.8.9.8 1.6.1 1.1-.7 2-1.8 2zM6.4 17.1L7.5 16l-1.7-.6.6 1.7z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }