var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseText",
    {
      staticClass: "v-form-error",
      attrs: { "aria-live": "polite", role: "alert", "aria-atomic": "true" }
    },
    [
      _vm.errors.length
        ? _vm._l(_vm.errors, function(error, index) {
            return _c(
              "BaseText",
              {
                key: index,
                attrs: {
                  color: "quinary-darker",
                  tag: "span",
                  size: "small-body"
                }
              },
              [_vm._v("\n      " + _vm._s(error) + "\n      "), _c("br")]
            )
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }