








import 'reflect-metadata';
import { Vue, Prop, Component } from 'nuxt-property-decorator';

@Component
export default class LoadingContentSwapper extends Vue {
  @Prop({ default: false }) readonly loading!: boolean;
  @Prop({ default: null }) readonly error!: boolean | object;
  @Prop({ default: false }) readonly group!: boolean;

  get tag(): string {
    return this.group ? 'transition-group' : 'transition';
  }
}
