var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "v-loading-spinner",
      class: _vm.loadingSpinnerClasses,
      staticStyle: {
        margin: "auto",
        background: "none",
        display: "block",
        "shape-rendering": "auto"
      },
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "25 25 50 50",
        preserveAspectRatio: "xMidYMid"
      }
    },
    _vm._l(_vm.spinnerPoints, function(point, index) {
      return _c(
        "g",
        { key: index, attrs: { transform: "rotate(" + point.rotate + ")" } },
        [
          _c(
            "rect",
            {
              attrs: {
                x: "47",
                y: "24",
                rx: "3",
                ry: "6",
                width: "6",
                height: "12"
              }
            },
            [
              _c("animate", {
                attrs: {
                  attributeName: "opacity",
                  values: "1;0",
                  keyTimes: "0;1",
                  dur: "1s",
                  begin: point.begin,
                  repeatCount: "indefinite"
                }
              })
            ]
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }