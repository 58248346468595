var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "img",
    _vm._b(
      {
        directives: [
          {
            name: "intersect",
            rawName: "v-intersect.once",
            value: { handler: _vm.onIntersect, options: _vm.intersectOptions },
            expression: "{ handler: onIntersect, options: intersectOptions }",
            modifiers: { once: true }
          }
        ],
        ref: "image",
        staticClass: "v-image",
        class:
          ((_obj = {}),
          (_obj["v-image--" + _vm.objectFit] = _vm.objectFit),
          _obj),
        attrs: { alt: _vm.alt, lazy: _vm.loadingStatus },
        on: {
          load: function($event) {
            return _vm.$emit("image:load")
          },
          error: function($event) {
            return _vm.$emit("image:error")
          }
        }
      },
      "img",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }