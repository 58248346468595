var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "div",
    _vm._b(
      {
        staticClass: "v-layout__item",
        class: [_vm.data.class, _vm.data.staticClass],
        attrs: {
          "data-ratio": _vm.props.r,
          "data-mobile": _vm.props.mobile,
          "data-tablet": _vm.props.tablet,
          "data-smallDesk": _vm.props.smallDesk,
          "data-largeDesk": _vm.props.largeDesk,
          "data-hugeDesk": _vm.props.hugeDesk
        }
      },
      "div",
      _vm.data.attrs,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }