var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: _vm.pillClasses,
      attrs: {
        type: "button",
        "aria-label": _vm.number !== null ? _vm.number + " items in bag" : ""
      }
    },
    [
      _c("DiscIcon", {
        attrs: {
          number: _vm.number,
          icon: "Bag",
          theme: _vm.theme.fg,
          type: "button",
          size: "intermediate"
        }
      }),
      _vm._v(" "),
      _vm.showMeta
        ? _c(
            "span",
            {
              staticClass: "v-nav-bag-pill__tokens-wrapper u-text--small-body",
              class: "u-color-" + _vm.theme.fg
            },
            [
              _c("DiscIcon", {
                attrs: { icon: "Token", theme: _vm.theme.fg, size: "teeny" }
              }),
              _vm._v(
                "\n    " +
                  _vm._s(_vm.totalTokensUsed) +
                  "/" +
                  _vm._s(_vm.$auth.user.subscriptionTokens) +
                  "\n  "
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }