// @ts-ignore
import { Asset as ClientAsset } from 'whirli-client/dist/Interfaces/asset/Asset';
import { Asset, Product, ProductVariant, Schema } from '~/types/products/products';
import { ProductAssociation } from '~/types/products/associations';
import { image as assetImage } from '~/helpers/models/Asset';
import { AssetKind } from '~/constants/assets';
import { ProductAssetsByKind } from '~/helpers/assets';

// @todo This should take a Product if it's in the Products helper, rather than an array of assets
// @todo replace with use of `image` method below
// @deprecated
export function getImage(assets: Array<Asset | ClientAsset>): string | null {
  if (!assets?.length) return null;
  if (!assets[0]?.transforms?.length) return null;

  return assets[0].transforms[0].url;
}

export function getRoute(product: Product): string {
  if (product.slug) return `/toy/${product.slug}`;
  return product.route ? `/toy/${product.route.slug}` : '';
}

export function getProductVariant(product?: Product): ProductVariant | null {
  if (!product?.productVariants.length) return null;
  return product.productVariants[0];
}

// @todo This should belong in the ProductVariant helper, not the product helper
// @deprecated
export function getStock(productVariant: ProductVariant): number {
  if (!productVariant) return 0;
  return productVariant.stock;
}

export function outOfStock(productVariant: ProductVariant): boolean {
  return getStock(productVariant) === 0;
}

export function image(product: Product): string | null {
  if (!product?.assets?.length) return null;

  return assetImage(product.assets[0]);
}

export function name(product: Product): string | null {
  return product.name || null;
}

export function slug(product: Product): string | null {
  return product.slug || null;
}

export function tokens(product: Product): number | null {
  return product.tokens || null;
}

export function discountedValue(product: Product): number | null {
  return product.discountedValue || null;
}

export function productVariantSku(product?: Product): string | null {
  const productVariant: ProductVariant | null = getProductVariant(product);
  return productVariant ? productVariant.sku : null;
}

/**
 * Turns a recommendedAge of a product into the correct string to show on the front end.
 * 0 months - "From birth"
 * 1 months - "1 month+"
 * 6 months - "6 months+"
 * 12 months - "1 year+"
 * 18 months - "18 months+"
 * 24 months - "2 years+"
 * 36 months - "3 years+"
 */
export function formattedRecommendedAge(product: Product): string {
  const ageInMonths: number = product.recommendedAge;

  switch (true) {
    case ageInMonths === 0:
      return 'From birth';
    case ageInMonths === 1:
      return '1 month+';
    case ageInMonths === 18:
      return '18 months+';
    case ageInMonths < 12:
      return `${ageInMonths} months+`;
    case ageInMonths < 24:
      return '1 year+';
    default:
      return `${Math.floor(ageInMonths / 12)} years+`;
  }
}

export function getBrands(product: Product): Array<ProductAssociation> | null {
  if (!product?.productAssociations?.length) return null;

  return product.productAssociations.filter(
    (productAssociation: ProductAssociation) => productAssociation.associationClass === 'brand'
  );
}

export function getMajorityBrand(product: Product): ProductAssociation | null {
  const brands: Array<ProductAssociation> | null = getBrands(product);

  if (!brands?.length) return null;

  return brands.reduce((prev: ProductAssociation, current: ProductAssociation) => {
    return prev.productsCount > current.productsCount ? prev : current;
  });
}

export function getMajorityBrandName(product: Product): string | null {
  const brand = getMajorityBrand(product);
  return brand ? brand.name : null;
}

export function getManufacturerName(product?: Product): string | null {
  if (!product?.productAssociations?.length) return null;

  const manufacturer: ProductAssociation | null =
    product.productAssociations.find(
      (association: ProductAssociation) => association.associationClass === 'manufacturer'
    ) || null;

  return manufacturer ? manufacturer.name : null;
}

/**
 * Turns a string of features separated with "\n" or "\r" or "|" into an array.
 */
export function formatFeatures(product: Product): Array<string> {
  if (!product || !product.features) return [];

  if (product.features.includes('\\r')) {
    return product.features.split(/\\r/);
  } else if (product.features.includes('|')) {
    return product.features.split('|');
  } else {
    return product.features.split(/\\n/);
  }
}

export function getProductSchema(
  product: Product,
  productVariant: ProductVariant,
  path: string,
  stock: number,
  price: string,
  ratingValue?: string,
  reviewCount?: string
) {
  const schema: Schema = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    productID: productVariant.sku,
    name: product.name,
    sku: productVariant.sku,
    description: product.description.split('|').join('. '),
    url: 'https://whirli.com' + path,
    image: ProductAssetsByKind(product, AssetKind.IMAGE).length ? product.assets[0].url : '',
    brand: {
      '@type': 'Brand',
      name: getMajorityBrandName(product) || '',
    },
    offers: {
      '@type': 'Offer',
      url: 'https://whirli.com' + path,
      price,
      priceCurrency: 'GBP',
      priceValidUntil: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
        .toISOString()
        .split('T')[0],
      itemCondition: 'https://schema.org/UsedCondition',
      availability: 'http://schema.org/' + (!stock ? 'OutOfStock' : 'InStock'),
    },
    additionalProperty: [
      {
        '@type': 'PropertyValue',
        propertyID: 'item_group_id',
        value: productVariant.sku,
      },
    ],
  };
  if (product.gtin) {
    schema.gtin = product.gtin;
  }
  if (ratingValue && reviewCount !== '0') {
    schema.aggregateRating = {
      '@type': 'AggregateRating',
      ratingValue,
      reviewCount,
      bestRating: '5',
      worstRating: '1',
    };
  }
  return schema;
}
