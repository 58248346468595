







































import 'reflect-metadata';
import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { NavLinks } from '~/types/navigation';
import { hasActiveSubscription } from '~/helpers/models/user';

@Component
export default class NavDropdown extends Vue {
  @Prop({ default: false }) readonly visible!: boolean;
  @Prop({ default: 'secondary' }) readonly theme!: string;
  @Prop({ default: () => [] }) readonly links!: NavLinks[];

  get textColor() {
    return this.theme === 'tertiary' || this.theme === 'white' ? 'secondary' : 'white';
  }

  get linkClasses() {
    return {
      'u-text--button': true,
      [`u-color-${this.textColor}`]: this.textColor,
    };
  }

  get hasActiveSubscription(): boolean {
    if (this.$auth.user) {
      return hasActiveSubscription(this.$auth.user);
    }
    return false;
  }
}
