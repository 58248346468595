var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 32 32" } },
    [
      _c("path", {
        attrs: {
          d:
            "M17.8 10.6v2.5h4.1l-.5 3.9h-3.5v10h-4.3V16.9H10V13h3.5v-3c0-.7.1-1.4.4-2 .6-1.6 1.8-2.5 3.5-2.8.8-.2 1.5-.2 2.3-.2.7 0 2.3.1 2.3.1v3.5h-2.1c-.3 0-.6 0-.8.1-.7.1-1.1.5-1.1 1.2-.2.2-.2.4-.2.7"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }