












import 'reflect-metadata';
import { Prop, Component, Vue } from 'nuxt-property-decorator';
import FiltersTag from '../../FiltersTag/FiltersTag.vue';
import { SelectedFilter as SF } from '~/store/products/filters';

interface SelectedFilter extends SF {}

@Component({
  components: {
    FiltersTag,
  },
})
export default class SelectedFiltersTags extends Vue {
  @Prop({ required: true }) readonly selectedFilters!: Array<SelectedFilter>;
}
