var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    _vm._b(
      { tag: "component", staticClass: "v-tag", class: _vm.classes },
      "component",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "BaseBox",
        { attrs: { padding: _vm.padding, border: false } },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }