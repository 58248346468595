var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 12 12" } },
    [
      _c("path", {
        attrs: {
          d:
            "M5.5 8c.3-.2.4-.4.5-.7.1-.2.1-.5.1-.7h1.3v-1H6c-.1-.2-.1-.5-.1-.7 0-.5.2-.9 1-.9.4 0 .7.1.9.2L8.1 3c-.3-.1-.7-.2-1.3-.2-1.4 0-2.4.8-2.4 2.1 0 .3 0 .6.1.8h-.9v1h1v.4c0 .7-.6 1.2-1.2 1.4v.8h4.9V8.1H5.5z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }