var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
    [
      _c("path", {
        attrs: {
          d: "M4 11.6l8 8 8-8-1.4-1.3-5.6 5.6V4.5h-2v11.4l-5.5-5.6L4 11.6z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }