
























































import 'reflect-metadata';
import { Vue, Component, Watch } from 'nuxt-property-decorator';
import TheModal from '~/components/containers/TheModal/TheModal.vue';
import {
  SIGNUP_MODAL_ACTIVE_PAGES,
  SIGNUP_MODAL_TIMER,
  SIGNUP_MODAL_SESSION_KEY,
} from '~/constants/signup-modal';

@Component({
  components: {
    TheModal,
  },
})
export default class SignupModal extends Vue {
  isModalOpen: boolean = false;
  timer: number = 0;
  signUpModalListItems: string[] = [
    'Fully customisable boxes with unlimited swaps',
    'Borrow up to £240 worth of toys in each swap',
    'Delivered to your door in plastic-free, recyclable and reusable packaging',
  ];

  SignupModalABTestGAPushLink(eventAction: string, eventLabel: string, link: string) {
    this.$gtm.analytics.event('A/B - Time sign up modal', eventAction, eventLabel);
    this.$router.push(link);
  }

  toggleModal(isOpen: boolean): void {
    this.isModalOpen = isOpen;
  }

  isSignupModalActiveOnPage(name: string | undefined | null): boolean {
    if (!name) return false;
    return SIGNUP_MODAL_ACTIVE_PAGES.includes(name);
  }

  pageWasRefreshed(): boolean {
    return this.$nuxt.context.from?.name === this.$route.name;
  }

  @Watch('$route', { immediate: true })
  handleSignUpModal(): void {
    if (
      !process.browser ||
      (this.isSignupModalActiveOnPage(this.$nuxt.context.from?.name) &&
        this.isSignupModalActiveOnPage(this.$route.name) &&
        !this.pageWasRefreshed)
    ) {
      return;
    }
    if (!this.isSignupModalActiveOnPage(this.$route.name)) {
      this.toggleModal(false);
      clearTimeout(this.timer);
      return;
    }

    this.timer = window.setTimeout(() => {
      if (!window.sessionStorage.getItem(SIGNUP_MODAL_SESSION_KEY)) {
        this.toggleModal(true);
        window.sessionStorage.setItem(SIGNUP_MODAL_SESSION_KEY, 'true');
      }
    }, SIGNUP_MODAL_TIMER);
  }
}
