var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseSideDrawer",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.close,
          expression: "close"
        }
      ],
      attrs: { id: "side-drawer", "is-open": _vm.isOpen }
    },
    [_c("template", { slot: "content" }, [_vm._t("default")], 2)],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }