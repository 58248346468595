var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$auth.loggedIn
    ? _c(
        "PromoBanner",
        {
          attrs: {
            url: "/frequently-asked-questions#what-is-delivery-booster",
            "has-icon": false,
            "ga-label": "Referral",
            type: "referral"
          }
        },
        [
          _c("HtmlText", {
            attrs: { tag: "span", color: "white" },
            domProps: { innerHTML: _vm._s(_vm.referralText) }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }