







































import { Prop, Component, Watch, mixins, namespace } from 'nuxt-property-decorator';
import accordionsMixin from '../accordionsMixin.vue';
import BaseIcon from '~/components/partials/icons/BaseIcon/BaseIcon.vue';
import { ResizeState as RS } from '~/store/resize';

interface ResizeState extends RS {} // Without this, typescript throws a warning that it cannot find the export

const ResizeStore = namespace('resize');

@Component({ components: { BaseIcon } })
export default class BaseAccordion extends mixins(accordionsMixin) {
  @ResizeStore.State debounced!: ResizeState;

  @Prop({ default: '0' }) readonly translateIcon!: string;
  @Prop({ default: 'secondary' }) readonly arrowTheme!: string;

  accordionBody: HTMLElement = {} as HTMLElement;
  accordionHeight: string = '0px';

  updateAccordionHeight(): void {
    this.accordionHeight = `${this.accordionBody?.offsetHeight}px`;
  }

  get breakpoint(): string {
    return this.debounced.breakpoint as string;
  }

  get open(): boolean {
    return this.isFixedOpen ? true : this.isOpen;
  }

  get disabled(): boolean {
    return this.isFixedOpen ? true : this.isDisabled;
  }

  get buttonElementType(): string {
    return this.isFixedOpen ? 'div' : 'button';
  }

  get classes() {
    return [{ 'v-accordion--open': this.open }, { 'v-accordion--disabled': this.disabled }];
  }

  mounted() {
    this.accordionBody = this.$refs.accordionBody as HTMLElement;
    this.updateAccordionHeight();
  }

  updated() {
    this.updateAccordionHeight();
  }

  @Watch('breakpoint')
  watchBreakpoint() {
    this.updateAccordionHeight();
  }
}
