














import { mixins, Component } from 'nuxt-property-decorator';
import PromoBanner from '~/components/prefabs/banners/PromoBanner/PromoBanner.vue';
import CampaignsMixin from '~/components/campaigns/CampaignsMixin.vue';
import HtmlText from '~/components/partials/HtmlText/HtmlText.vue';

@Component({
  components: {
    PromoBanner,
    HtmlText,
  },
})
export default class ReferralsBanner extends mixins(CampaignsMixin) {
  get referralText(): string {
    return this.isDoubleUpReferralCampaignActive
      ? 'Delivery Booster is back! Click here to find out More >>'
      : `Refer a friend and get ${this.doubleUpCampaignBonuses.referer.monetary} credit. Share the joy. >>`;
  }
}
