import { Refer } from '~/types/referral';

export const ReferralExample = {
  PRE_OFFER: '£8.99',
  POST_OFFER: '£7.65',
};

export const RefereeTypeId: Record<string, number> = {
  NORMAL: 1,
  AMBASSADOR: 2,
  PARTNER: 3,
};

export const ReferralReferer: Record<number, Refer> = {
  [RefereeTypeId.NORMAL]: {
    MONETARY: '£20',
  },
  [RefereeTypeId.AMBASSADOR]: {
    MONETARY: '£10',
  },
  [RefereeTypeId.PARTNER]: {
    MONETARY: '£10',
  },
};

export const ReferralReferee: Record<number, Refer> = {
  [RefereeTypeId.NORMAL]: {
    MONETARY: '£5',
    PERCENTAGE: '15%',
  },
  [RefereeTypeId.AMBASSADOR]: {
    MONETARY: '£10',
    PERCENTAGE: '10%',
  },
  [RefereeTypeId.PARTNER]: {
    MONETARY: '£10',
    PERCENTAGE: '10%',
  },
};
