import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '~/store/index';
import {
  types,
  state as BState,
  getters as BGetters,
  actions as BActions,
  mutations as BMutations,
  ModuleState as BModuleState,
} from '~/helpers/store/discountCode';
import { DiscountCode } from '~/types/discount-code';

export interface ModuleState extends BModuleState {}

export const state = (): ModuleState => BState();

const giftDiscountActions: ActionTree<ModuleState, RootState> = {
  async putDiscountCode({ commit, dispatch }, discountCode: ModuleState | null) {
    this.$logger.store(`action`, `[registration/discountCode/putDiscountCode]`, { discountCode });
    commit(types.PUT_DISCOUNT_CODE, discountCode);
    await dispatch('registration/subscription/updateTotalCost', null, { root: true });
  },
};

const subscriptionDiscountActions: ActionTree<ModuleState, RootState> = {
  async fetchDiscountCode({ commit, dispatch }, params) {
    commit(types.CLEAR_ERROR, null);
    commit(types.SET_LOADING);

    try {
      const response = await this.$whirli.codes.get(params.codeName, { params: params.options });
      const discountCode: DiscountCode = {
        type: response.data.type.name,
        name: response.data.code,
        value: response.data.value,
        valueTypeId: response.data.valueTypeId,
      };
      commit(types.PUT_DISCOUNT_CODE, discountCode);
    } catch (error) {
      commit(types.RESET_CODE);
      commit(types.SET_ERROR, error);
      throw error;
    } finally {
      await dispatch('registration/subscription/updateTotalCost', null, { root: true });
      commit(types.SET_LOADING);
    }
  },
};

export const getters: GetterTree<ModuleState, RootState> = BGetters;

export const actions: ActionTree<ModuleState, RootState> = Object.assign(
  {},
  BActions,
  giftDiscountActions,
  subscriptionDiscountActions
);

export const mutations: MutationTree<ModuleState> = BMutations;
