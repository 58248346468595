
import { Vue, Prop, Component } from 'nuxt-property-decorator';

const availableThemes: string[] = ['primary-light', 'tertiary-light', 'white', 'light-grey-two'];
const availableTypes: string[] = ['email', 'password', 'tel', 'text', 'checkbox', 'number'];

@Component
export default class InputMixin extends Vue {
  listeners?: Record<string, Function | Function[]>;

  @Prop({ default: '' }) readonly ariaLabel!: string;
  @Prop({ default: '' }) readonly ariaLabelledby!: string;
  @Prop({ default: () => [] }) readonly errors!: [];
  @Prop({ default: '' }) readonly id!: string;
  @Prop({ default: '' }) readonly placeholder!: string;
  @Prop({ default: false }) readonly required!: boolean;
  @Prop({ default: 0 }) readonly tabindex!: number;
  @Prop({ default: 'primary-light', validator: (value) => availableThemes.includes(value) })
  readonly theme!: string;

  @Prop({ default: 'text', validator: (value) => availableTypes.includes(value) }) readonly type!: string;

  focus: boolean = false;

  get inputClasses(): object {
    return {
      [`v-input__input--${this.theme}`]: this.theme,
      'v-input--error': this.errors.length,
      'v-input--focus': this.focus,
    };
  }

  get inputListeners() {
    const vm = this;
    return Object.assign(
      {},
      this.$listeners,
      {
        input(event: { target: { value: any } }) {
          vm.$emit('input', event.target.value);
        },
        focus(event: { target: { value: any } }) {
          vm.onFocus();
          vm.$emit('focus', event.target.value);
        },
        blur(event: { target: { value: any } }) {
          vm.onBlur();
          vm.$emit('blur', event.target.value);
        },
      },
      this.listeners || {}
    );
  }

  onFocus() {
    this.focus = true;
  }

  onBlur() {
    this.focus = false;
  }
}
