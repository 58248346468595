var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseCheckbox",
    _vm._b(
      {
        staticClass: "v-bucket-checkbox",
        attrs: {
          id: _vm.bucket.id,
          checked: _vm.isSelected(_vm.bucket.slug || _vm.bucket.name),
          name: _vm.bucket.name
        },
        on: {
          change: function($event) {
            return _vm.$emit("bucket:toggle", _vm.bucket)
          }
        }
      },
      "BaseCheckbox",
      _vm.$attrs,
      false
    ),
    [
      _vm._v("\n  " + _vm._s(_vm.bucket.name) + "\n  "),
      !_vm.isRegistrationPage && _vm.bucket.productsCount !== undefined
        ? _c(
            "BaseText",
            { attrs: { tag: "span", size: "body", color: "charcoal" } },
            [_vm._v("\n    (" + _vm._s(_vm.bucket.productsCount) + ")\n  ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }