import { ActionTree, MutationTree, GetterTree } from 'vuex';
// @ts-ignore
import Blog from 'wpapi';
import { transformArticle, transformCategory } from '~/helpers/blog';
import { Article, Category } from '~/types/blog/blog';
import { WpArticle, WpCategory } from '~/types/blog/wp-blog';
import { RootState } from '~/store/index';
import {
  ARTICLES_CACHE_KEY,
  ARTICLE_CACHE_KEY,
  BLOG_WP_ENDPOINT,
  CACHE_TIME,
  CATEGORIES_CACHE_KEY,
} from '~/constants/blog';

const types = {
  PUT_ARTICLE: 'PUT_ARTICLE',
  PUT_ARTICLES: 'PUT_ARTICLES',
  PUT_CATEGORIES: 'PUT_CATEGORIES',
};

interface ModuleState {
  article: Article | null;
  articles: Array<Article>;
  categories: Array<Category>;
}

export const state = (): ModuleState => ({
  article: null,
  articles: [],
  categories: [],
});

export const actions: ActionTree<ModuleState, RootState> = {
  async putArticle({ commit, dispatch, state }, slug: string): Promise<void> {
    this.$logger.store(`action`, `[blog/putArticle]`, { slug });

    await dispatch('putCategories');

    let article: Article = this.$memCache.get(`${ARTICLE_CACHE_KEY}-${slug}`);

    if (!article) {
      this.$logger.store(`action`, `[blog/putArticle] fetching from API`);

      const blog = new Blog({ endpoint: BLOG_WP_ENDPOINT });

      const wpArticles: Array<WpArticle> = await blog
        .posts()
        .param('slug', slug)
        .embed()
        .get();

      if (!wpArticles || !wpArticles.length) {
        throw new Error('Not found');
      }
      article = transformArticle(wpArticles[0], state.categories);

      this.$memCache.put(`${ARTICLE_CACHE_KEY}-${slug}`, article, CACHE_TIME);
    }
    commit(types.PUT_ARTICLE, article);

    await dispatch('pageConfig/updatePageTheme', article.theme, { root: true });
  },
  async putArticles({ commit, dispatch, state }, excludeTags: Array<number>): Promise<void> {
    this.$logger.store(`action`, `[blog/putArticles]`);

    await dispatch('putCategories');

    let articles: Array<Article> = this.$memCache.get(ARTICLES_CACHE_KEY);

    if (!articles) {
      this.$logger.store(`action`, `[blog/putArticles] fetching from API`);

      const blog = new Blog({ endpoint: BLOG_WP_ENDPOINT });

      const wpArticles: Array<WpArticle> = await blog
        .posts()
        .perPage(100)
        .page(1)
        .embed()
        .param('tags_exclude', excludeTags)
        .get();

      if (!wpArticles || !wpArticles.length) {
        throw new Error('Not found');
      }
      articles = wpArticles.map((article: WpArticle) => transformArticle(article, state.categories));

      this.$memCache.put(ARTICLES_CACHE_KEY, articles, CACHE_TIME);
    }
    commit(types.PUT_ARTICLES, articles);
  },
  async putCategories({ commit }): Promise<void> {
    this.$logger.store(`action`, `[blog/putCategories]`);

    let categories: Array<Category> = this.$memCache.get(CATEGORIES_CACHE_KEY);

    if (!categories) {
      this.$logger.store(`action`, `[blog/putCategories] fetching from API`);

      const blog = new Blog({ endpoint: BLOG_WP_ENDPOINT });

      const wpCategories: Array<WpCategory> = await blog.categories().get();

      if (!wpCategories || !wpCategories.length) {
        throw new Error('Not found');
      }
      categories = wpCategories.map((category: WpCategory) => transformCategory(category));

      this.$memCache.put(CATEGORIES_CACHE_KEY, categories, CACHE_TIME);
    }
    commit(types.PUT_CATEGORIES, categories);
  },
};

export const getters: GetterTree<ModuleState, RootState> = {
  getFeaturedArticle(state): Article | null {
    const latestModified = state.articles
      .map((article: Article) => article.modified)
      .sort()
      .slice(-1);

    const article = state.articles.find(
      (article: Article) => article.featured && article.modified === latestModified[0]
    );
    return article || null;
  },
};

export const mutations: MutationTree<ModuleState> = {
  [types.PUT_ARTICLE](state, article: Article) {
    state.article = article;
  },
  [types.PUT_ARTICLES](state, articles: Array<Article>) {
    state.articles = articles;
  },
  [types.PUT_CATEGORIES](state, categories: Array<Category>) {
    state.categories = categories;
  },
};
