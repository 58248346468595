var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SideDrawer",
    { attrs: { theme: "primary", name: "basket" } },
    [
      _vm.$gifts.giftMode.isOn() && _vm.isSideDrawerOpen
        ? _c("TheGiftBasket", { attrs: { id: "gift" } })
        : !_vm.$gifts.giftMode.isOn() && _vm.isSideDrawerOpen
        ? _c("BaseBasket", {
            attrs: { id: "basket" },
            on: { "basket:close": _vm.refreshCheckout },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function(ref) {
                  var tabs = ref.tabs
                  return [
                    _c(
                      "BaseCluster",
                      {
                        staticClass: "u-full-width",
                        attrs: { spacing: "none" }
                      },
                      [
                        _c(
                          "BaseTab",
                          {
                            attrs: {
                              id: "basket-tab",
                              "aria-controls": "basket-panel",
                              "is-active": tabs.activeTab === _vm.BASKET_TAB,
                              theme: "secondary"
                            },
                            on: {
                              click: function($event) {
                                return tabs.change(_vm.BASKET_TAB)
                              }
                            }
                          },
                          [_vm._v("\n          Bag\n        ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "BaseTab",
                          {
                            attrs: {
                              id: "return-tab",
                              "aria-controls": "return-panel",
                              "is-active": tabs.activeTab === _vm.RETURNS_TAB,
                              theme: "secondary"
                            },
                            on: {
                              click: function($event) {
                                return tabs.change(_vm.RETURNS_TAB)
                              }
                            }
                          },
                          [_vm._v("\n          Return\n        ")]
                        )
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "body",
                fn: function(ref) {
                  var tabs = ref.tabs
                  return [
                    _c("TheBasketTab", {
                      attrs: {
                        "is-active": tabs.activeTab === _vm.BASKET_TAB,
                        loading: !_vm.hasBasketBeenFetched && _vm.isBasketBusy
                      }
                    }),
                    _vm._v(" "),
                    _c("TheReturnsTab", {
                      attrs: {
                        "is-active": tabs.activeTab === _vm.RETURNS_TAB,
                        loading: !_vm.hasToyboxBeenFetched && _vm.isToyboxBusy
                      }
                    })
                  ]
                }
              },
              {
                key: "footer",
                fn: function() {
                  return [
                    _c(
                      "transition",
                      { attrs: { name: "u-fade-in" } },
                      [
                        _vm.$auth.loggedIn
                          ? _c(
                              "BaseBox",
                              {
                                staticClass: "u-background-color-primary",
                                attrs: { padding: "medium" }
                              },
                              [
                                _c("TokenSectionBar", {
                                  attrs: { "max-tokens": _vm.maxTokens }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "BaseButton",
                      {
                        attrs: {
                          theme: "tertiary",
                          type: "block",
                          block: true,
                          wide: true,
                          "no-hover": true,
                          tag: "nuxt-link",
                          to: _vm.checkoutButtonConfig.href,
                          disabled: _vm.checkoutButtonConfig.disabled
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.onClickHandler($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.checkoutButtonConfig.text) +
                            "\n      "
                        )
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }