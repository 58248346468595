// This is a temp helper function for the featureFlagToggle
// There are some issues with it not working correctly in the query string
// This will check and return the bool value in the toggle

export function featureFlagParam(route: string | (string | null)[]): boolean | null {
  if (!route) return null;
  const featureFlagQueryParam = route;
  return typeof featureFlagQueryParam === 'string' ? stringToBoolean(featureFlagQueryParam) : null;
}

function stringToBoolean(string: string): boolean {
  return string === 'true';
}
