


















































































































































































































import _ from 'lodash';
import 'reflect-metadata';
import { Watch, Component, mixins, namespace } from 'nuxt-property-decorator';
import NavMixin from '../navMixin.vue';
import NavHamburgerMenu from '../NavHamburgerMenu/NavHamburgerMenu.vue';
import NavDropdownMenu from '../NavDropdownMenu/NavDropdownMenu.vue';
import NavIconLink from '../NavIconLink/NavIconLink.vue';
import { NavTheme, LogoTheme } from '../interfaces';
import WhirliLogo from '~/components/partials/logo/WhirliLogo/WhirliLogo.vue';
import { themeMap } from '~/helpers/colors';
import { hasActiveSubscription } from '~/helpers/models/user';
import TokensUsedMixin from '~/components/features/basket/TokensUsedMixin/TokensUsedMixin.vue';
import DiscIcon from '~/components/partials/icons/DiscIcon/DiscIcon.vue';
import { MakeQueryPayload } from '~/store/products';
import zendesk from '~/helpers/zendesk';

const NavStore = namespace('navigation');
const BasketStore = namespace('basket');
const GiftBasketStore = namespace('gift/basket');
const RegProgressStore = namespace('registration/progress');
const ProductsStore = namespace('products');
const SearchStore = namespace('products/search');

@Component({
  components: {
    NavIconLink,
    NavHamburgerMenu,
    NavDropdownMenu,
    WhirliLogo,
    DiscIcon,
  },
  props: {
    dataBand: {
      type: Array,
      default: () => [],
    },
    username: {
      type: String,
    },
    products: {
      type: Array,
      default: () => [],
    },
    searchInitiated: {
      type: Boolean,
      default: () => [],
    },
  },
})
export default class NavMobileBar extends mixins(NavMixin, TokensUsedMixin) {
  @NavStore.State isHamburgerMenuOpen!: boolean;
  @NavStore.State isDropdownMenuOpen!: boolean;
  @NavStore.Action('toggleHamburgerMenu') toggleNavHamburgerMenu!: (isOpen: boolean) => void;
  @NavStore.Action('toggleDropdownMenu') toggleNavDropDownMenu!: (isOpen: boolean) => void;
  @BasketStore.Getter('hasOutOfStockLines') basketHasOutOfStockLines!: boolean;
  @GiftBasketStore.Getter('hasOutOfStockLines') giftHasOutOfStockLines!: boolean;
  @RegProgressStore.State currentStep!: number;
  @ProductsStore.Action makeQuery!: (payload: MakeQueryPayload) => void;
  @SearchStore.State term!: string;
  @SearchStore.Action setTerm!: (payload: { term: string }) => void;

  zendesk = zendesk;

  disabledNavigation: boolean = false;
  searchInitiated: boolean = false;

  created() {
    this.toggleNavigation();
  }

  @Watch('$route.path')
  toggleNavigation(): void {
    this.disabledNavigation = this.$navigation.disabled();
  }

  get textColor() {
    return this.theme === 'tertiary' || this.theme === 'white' ? 'secondary' : 'white';
  }

  get linkClasses() {
    return {
      'u-text--button': true,
      [`u-color-${this.textColor}`]: this.textColor,
    };
  }

  showSuggestionList: boolean = false;

  showSuggestions() {
    this.showSuggestionList = true;
  }

  closeSuggestions() {
    this.showSuggestionList = false;
    this.searchQuery = '';
    this.showSearchInput = false;
    this.searchInitiated = false;
    this.products = [];
  }

  handleOutsideClick(event) {
    if (!this.$el.contains(event.target)) {
      this.closeSuggestions();
      this.products = [];
      this.searchQuery = '';
      this.showSearchInput = false;
      this.searchInitiated = false;
    }
  }

  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  }

  showSearchInput: Boolean = false;

  searchQuery: any = '';

  filteredSuggestions: Array<{
    name: string;
    tokens: number;
    discountedValue: number | null;
    url: string;
    slug: string;
  }> = [];

  handleClick() {
    // Toggle the search input visibility
    this.showSearchInput = !this.showSearchInput;
    // If input is shown, focus on it
    if (this.showSearchInput) {
      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });
    }
  }

  clearSuggestions() {
    this.filteredSuggestions = [];
    this.searchQuery = '';
  }

  debouncedInput = _.debounce(this.callDebouncedFilterSuggestions, 300);

  callDebouncedFilterSuggestions() {
    // this.debouncedFilterSuggestions();
    this.$emit('search-input', this.searchQuery);
    this.searchInitiated = false;
  }

  highlightMatch(text) {
    if (!this.searchQuery) {
      return text;
    }
    const regex = new RegExp(`(${this.searchQuery})`, 'gi');
    return text.replace(regex, '<strong>$1</strong>');
  }

  handleScroll(event: Event) {
    const target = event.target as HTMLElement;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      this.$emit('load-more');
    }
  }

  handleEnterKey() {
    try {
      const searchData = this.searchQuery.trim();
      this.filteredSuggestions = [];
      this.products = [];
      this.searchQuery = '';
      this.showSearchInput = false;
      this.searchInitiated = true;
      this.$router.push({ path: '/toys/all', query: { search: searchData, page: 1 } });
      this.setTerm({ term: searchData });
      this.$emit('product-search-bar:input');
    } catch (error) {
      console.error('Error handling search:', error);
    }
  }

  clearSearch() {
    this.searchQuery = '';
    this.showSearchInput = false;
    this.showSuggestionList = false;
    this.filteredSuggestions = [];
    this.products = [];
    this.searchInitiated = false;
  }

  @Watch('scrolled')
  updateNavFg(): string | void {
    if (this.isHamburgerMenuOpen) return;
    if (this.isDropdownMenuOpen) return;
    if (this.scrolled) this.navBarTheme.fg = 'secondary';
  }

  get logoColor(): LogoTheme {
    if (this.isHamburgerMenuOpen) return { fg: 'tertiary', bg: 'primary' };
    if ((this.scrolled && !this.isHamburgerMenuOpen) || this.isDropdownMenuOpen) {
      return { fg: 'secondary', bg: 'primary' };
    }

    return this.logoTheme;
  }

  get navBarTheme(): NavTheme {
    const navTheme = this.defaultTheme;
    if (this.isHamburgerMenuOpen) {
      return {
        bg: themeMap.secondary.background,
        fg: themeMap.secondary.accent || themeMap.secondary.foreground,
      };
    }
    return navTheme;
  }

  get showLogo(): boolean {
    const isHomepage = this.$route.name === 'index';
    if (!isHomepage) return true;
    return this.$auth.loggedIn;
  }

  get isPickToysPage(): boolean {
    return this.$route.name === 'registration-pick-toys';
  }

  get classes(): object {
    return {
      'v-nav-mobile-bar--scrolled': (this.scrolled && !this.isHamburgerMenuOpen) || this.isDropdownMenuOpen,
      [`v-nav-mobile-bar--${this.navBarTheme.bg}`]: this.navBarTheme.bg,
    };
  }

  get userHasActiveSubscription(): boolean {
    return hasActiveSubscription(this.$auth.user);
  }

  get bagButtonText(): string {
    if (this.$gifts.giftMode.isOn()) return 'Gift Box';
    if (!this.$auth.loggedIn || !this.$auth.user) return 'Bag';

    return this.userHasActiveSubscription ? this.userTokensUsedText : 'Bag';
  }

  get userTokensUsedText(): string {
    return `${this.totalTokensUsed}/${this.$auth.user.subscriptionTokens}`;
  }

  get hasOutOfStockLines(): boolean {
    return this.$gifts.giftMode.isOn() ? this.giftHasOutOfStockLines : this.basketHasOutOfStockLines;
  }

  registerButtonClick() {
    this.toggleHamburgerMenu(false);
  }

  toggleHamburgerMenu(isOpen: boolean) {
    this.toggleNavHamburgerMenu(isOpen);
    this.setScrollDisabled(isOpen);
    zendesk.toggleLauncher(!isOpen);
  }

  toggleDropdownMenu(isOpen: boolean) {
    this.toggleNavDropDownMenu(isOpen);
    this.setScrollDisabled(isOpen);
  }
}
