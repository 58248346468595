import { ActionTree, MutationTree } from 'vuex';
import { ModuleState as AddressesModuleState } from './addresses';
import { RootState } from '~/store/index';
import { CancelSubscriptionStatus, ChangeSubscriptionStatus, User } from '~/types/user';

interface ClaimGuestResources {
  basketId?: string;
  guestToken?: string;
}

interface LogoutOptions {
  redirect: boolean;
  redirectUrl: string;
  isCancelling?: boolean;
}

type NumericalBoolean = 1 | 0;

export interface UserRequestParams {
  changeSubscription?: NumericalBoolean;
  cancelSubscription?: NumericalBoolean;
  balance?: NumericalBoolean;
}

interface LocalModuleState {
  isChangingPlan: boolean;
  isCancellingPlan: boolean;
  balance: number | null;
  changeSubscriptionStatus: ChangeSubscriptionStatus | null;
  cancelSubscriptionStatus: CancelSubscriptionStatus | null;
}

const types = {
  TOGGLE_CHANGE_SUBSCRIPTION: 'TOGGLE_CHANGE_SUBSCRIPTION',
  TOGGLE_CANCEL_SUBSCRIPTION: 'TOGGLE_CANCEL_SUBSCRIPTION',
  PUT_CANCEL_SUBSCRIPTION_STATUS: 'PUT_CANCEL_SUBSCRIPTION_STATUS',
  PUT_CHANGE_SUBSCRIPTION_STATUS: 'PUT_CHANGE_SUBSCRIPTION_STATUS',
  PUT_BALANCE: 'PUT_BALANCE',
};

export interface ModuleState extends LocalModuleState {
  addresses: AddressesModuleState;
}

export interface ModifySubscriptionStatus {
  status: boolean;
  delay?: number;
}

export const state = (): LocalModuleState => ({
  isChangingPlan: false,
  isCancellingPlan: false,
  balance: null,
  changeSubscriptionStatus: null,
  cancelSubscriptionStatus: null,
});

export const actions: ActionTree<ModuleState, RootState> = {
  async user({ dispatch }, params: UserRequestParams) {
    this.$logger.store(`action`, `[user/user]`, { params });

    try {
      const response = await this.$whirli.users.user({ params });

      if (response.data && response.data.user) {
        dispatch('childProfiles/setChildProfiles', response.data.user.childProfiles, { root: true });

        const user: User = response.data.user;

        this.$auth.setUser(user);

        dispatch('productLists/getUserLists', null, { root: true });
        dispatch('waitSpots/getUserWaitSpots', null, { root: true });

        if (user.balance) dispatch('putBalance', user.balance);
        if (user.changeSubscriptionStatus)
          dispatch('putChangeSubscriptionStatusData', user.changeSubscriptionStatus);
        if (user.cancelSubscriptionStatus)
          dispatch('putCancelSubscriptionStatusData', user.cancelSubscriptionStatus);
      }
    } catch (error) {
      if (error.response && error.response.status >= 500) {
        throw error;
      }
    }
  },
  async logout({ dispatch }, options: Partial<LogoutOptions>) {
    this.$logger.store(`action`, `[user/logout]`, { options });

    const logoutOptions: LogoutOptions = Object.assign(
      {
        redirect: true,
        redirectUrl: '/',
      },
      options
    );

    if (this.$auth.loggedIn) {
      await this.$auth.logout();
      this.$storage.removeLocalStorage('user');
      this.$storage.removeLocalStorage('token');
    } else {
      await this.$auth.reset();
    }

    await Promise.all([
      dispatch('basket/resetBasket', null, { root: true }),
      dispatch('registration/progress/reset', null, { root: true }),
      dispatch('registration/userDetails/reset', null, { root: true }),
      dispatch('registration/subscription/reset', null, { root: true }),
      dispatch('registration/discountCode/reset', null, { root: true }),
      dispatch('refer/resetReferralData', null, { root: true }),
      this.$gifts.guest.clearCachedGift(),
    ]);

    if (logoutOptions.redirect) {
      window.location.assign(logoutOptions.redirectUrl);
    } else {
      window.location.reload();
    }
    if (logoutOptions.isCancelling) {
      this.$storage.setUniversal('planCancelled', true);
    }
  },
  async claimGuestResources({ dispatch }) {
    this.$logger.store(`action`, `[user/claimGuestResources]`);

    const claimResources: ClaimGuestResources = {};

    const guestBasketId = await dispatch('basket/getBasketId', null, { root: true });
    const cachedGift = this.$gifts.guest.getCachedGift();

    if (!guestBasketId && !cachedGift) return;

    if (guestBasketId) claimResources.basketId = guestBasketId;
    if (cachedGift) claimResources.guestToken = cachedGift.accessToken;

    try {
      const response = await this.$whirli.users.claimGuestResources(claimResources);
      if (response.data) {
        const data = response.data;
        const jobs: Array<Promise<any>> = [];

        await dispatch('basket/clearCachedBasket', null, { root: true });
        await this.$gifts.guest.clearCachedGift();

        if (data.gift) jobs.push(dispatch('gift/saveGift', data.gift, { root: true }));
        if (data.basket) jobs.push(dispatch('basket/saveBasket', data.basket, { root: true }));

        await Promise.all(jobs);
      }
    } catch (error) {
      if (error.response && error.response.status >= 500) {
        throw error;
      }
      const basketClear = dispatch('basket/clearCachedBasket', null, { root: true }).then(() => {
        return dispatch('basket/getUserBasketData', null, { root: true });
      });

      const giftClear = this.$gifts.guest.clearCachedGift().then(() => {
        return dispatch('gift/getCurrent', null, { root: true });
      });

      await Promise.all([basketClear, giftClear]);
    }
  },
  setChangeSubscriptionStatus({ commit }, { status, delay = 0 }: ModifySubscriptionStatus) {
    this.$logger.store(`action`, `[user/setChangeSubscriptionStatus]`, { status, delay });
    /**
     * Timer is required because in order to successful open the change plan flow from the cancel modal without any
     * glitches, there has to be a delay between transitioning back to the account page,
     * then to trigger the change plan flow.
     */
    setTimeout(() => {
      commit(types.TOGGLE_CHANGE_SUBSCRIPTION, status);
    }, delay);
  },
  setCancelSubscriptionStatus({ commit }, { status, delay = 0 }: ModifySubscriptionStatus) {
    this.$logger.store(`action`, `[user/setCancelSubscriptionStatus]`, { status, delay });
    /**
     * Timer is required because in order to successful open the cancel plan modal without any glitches, there has
     * to be a delay between transitioning back to the account page, then to trigger the cancel plan modal.
     */
    setTimeout(() => {
      commit(types.TOGGLE_CANCEL_SUBSCRIPTION, status);
    }, delay);
  },
  putChangeSubscriptionStatusData({ commit }, changeStatus: ChangeSubscriptionStatus) {
    this.$logger.store(`action`, `[user/putChangeSubscriptionStatusData]`, { changeStatus });

    commit(types.PUT_CHANGE_SUBSCRIPTION_STATUS, changeStatus);
  },
  putCancelSubscriptionStatusData({ commit }, cancelStatus: CancelSubscriptionStatus) {
    this.$logger.store(`action`, `[user/putCancelSubscriptionStatusData]`, { cancelStatus });

    commit(types.PUT_CANCEL_SUBSCRIPTION_STATUS, cancelStatus);
  },
  putBalance({ commit }, balance: number) {
    this.$logger.store(`action`, `[user/putBalance]`, { balance });

    commit(types.PUT_BALANCE, balance);
  },
};

export const mutations: MutationTree<ModuleState> = {
  [types.TOGGLE_CHANGE_SUBSCRIPTION](state, status: boolean): void {
    state.isChangingPlan = status;
  },
  [types.TOGGLE_CANCEL_SUBSCRIPTION](state, status: boolean): void {
    state.isCancellingPlan = status;
  },
  [types.PUT_BALANCE](state, balance: number): void {
    state.balance = balance;
  },
  [types.PUT_CHANGE_SUBSCRIPTION_STATUS](state, status: ChangeSubscriptionStatus): void {
    state.changeSubscriptionStatus = status;
  },
  [types.PUT_CANCEL_SUBSCRIPTION_STATUS](state, status: CancelSubscriptionStatus): void {
    state.cancelSubscriptionStatus = status;
  },
};
