import { ActionTree, MutationTree } from 'vuex';
import { Range } from '~/types/products/range';
import { RootState } from '~/store';

const types = {
  SET_RANGE: 'SET_RANGE',
  RESET_RANGE: 'RESET_RANGE',
};

export interface ModuleState extends Range {}

export const defaults: ModuleState = {
  from: null,
  to: null,
  type: null,
};

export const state = (): ModuleState => ({
  from: defaults.from,
  to: defaults.to,
  type: defaults.type,
});

export interface RangePayload {
  from: number;
  to: number | null;
  type: string;
}

export const actions: ActionTree<ModuleState, RootState> = {
  setRange({ commit }, { from, to = null, type }: RangePayload) {
    this.$logger.store(`action`, `[products/range/setRange]`, { from, to, type });

    if (from === 0 && (to === null || to === 100)) {
      commit(types.RESET_RANGE);
    } else {
      commit(types.SET_RANGE, { from, to, type });
    }
  },

  resetRange({ commit }) {
    this.$logger.store(`action`, `[products/range/resetRange]`);

    commit(types.RESET_RANGE);
  },
};

export const mutations: MutationTree<ModuleState> = {
  [types.SET_RANGE](state, range: RangePayload) {
    state.from = range.from;
    state.to = range.to;
    state.type = range.type;
  },

  [types.RESET_RANGE](state) {
    state.from = defaults.from;
    state.to = defaults.to;
    state.type = defaults.type;
  },
};
