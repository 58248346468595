export interface BannerTheme {
  icon: string;
  iconTheme: string;
  backgroundColor: string;
  textColor: string;
}

export type BannerThemeMap = Record<string, BannerTheme>;

export const bannerThemes: BannerThemeMap = {
  warning: {
    icon: 'Info',
    iconTheme: 'secondary',
    backgroundColor: 'tertiary-dark',
    textColor: 'secondary',
  },
  info: {
    icon: 'Info',
    iconTheme: 'white',
    backgroundColor: 'secondary',
    textColor: 'white',
  },
  // @todo once design is done for success
  success: {
    icon: 'Info',
    iconTheme: 'white',
    backgroundColor: 'secondary',
    textColor: 'white',
  },
  error: {
    icon: 'Error',
    iconTheme: 'white',
    backgroundColor: 'quinary-darker',
    textColor: 'white',
  },
  referral: {
    icon: 'Info',
    iconTheme: 'white',
    backgroundColor: 'quinary',
    textColor: 'white',
  },
  offer: {
    icon: 'Info',
    iconTheme: 'white',
    backgroundColor: 'primary-dark',
    textColor: 'white',
  },
};
