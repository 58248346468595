
















import 'reflect-metadata';
import { Vue, Component } from 'nuxt-property-decorator';

interface PaymentMethod {
  alt: string;
  image: string;
}

@Component
export default class PaymentMethods extends Vue {
  visa: string = require('~/assets/img/card-brand-logos/visa.svg');
  masterCard: string = require('~/assets/img/card-brand-logos/mastercard.svg');
  americanExpress: string = require('~/assets/img/card-brand-logos/american-express.svg');
  gPay: string = require('~/assets/img/card-brand-logos/g-pay.svg');
  applePay: string = require('~/assets/img/card-brand-logos/apple-pay.svg');

  get paymentMethods(): Array<PaymentMethod> {
    return [
      {
        alt: 'We accept Visa payments',
        image: require('~/assets/img/card-brand-logos/visa.svg'),
      },
      {
        alt: 'We accept Mastercard payments',
        image: require('~/assets/img/card-brand-logos/mastercard.svg'),
      },
      {
        alt: 'We accept American Express payments',
        image: require('~/assets/img/card-brand-logos/american-express.svg'),
      },
      {
        alt: 'We accept Google Pay payments',
        image: require('~/assets/img/card-brand-logos/g-pay.svg'),
      },
      {
        alt: 'We accept Apple Pay payments',
        image: require('~/assets/img/card-brand-logos/apple-pay.svg'),
      },
    ];
  }
}
