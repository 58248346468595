var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "v-media-item" }, [
    _c("div", { staticClass: "v-media-item__media" }, [_vm._t("media")], 2),
    _vm._v(" "),
    _c("div", { staticClass: "v-media-item__body" }, [
      _c(
        "div",
        { staticClass: "v-media-item__body-inner" },
        [
          _c(
            _vm.bodyTag,
            _vm._b(
              { tag: "component", staticClass: "v-media-item__body-content" },
              "component",
              _vm.attributes,
              false
            ),
            [_vm._t("content"), _vm._v(" "), _vm._t("sub-content")],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "v-media-item__body-footer" },
            [_vm._t("footer")],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "v-media-item__body-controls" },
        [_vm._t("controls")],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }