import { throttle, debounce } from 'throttle-debounce';

function getDimensions() {
  return {
    height: window.innerHeight,
    width: window.innerWidth,
  };
}

function getPosition() {
  return window.scrollY;
}

const actions = (store) => ({
  scroll: [
    throttle(75, true, () => {
      store.dispatch('scroll/update', { type: 'THROTTLED', position: getPosition() });
    }),
    throttle(300, true, () => {
      store.dispatch('scroll/update', { type: 'LAZY_THROTTLED', position: getPosition() });
    }),
    debounce(75, () => {
      store.dispatch('scroll/update', { type: 'DEBOUNCED', position: getPosition() });
    }),
    debounce(300, () => {
      store.dispatch('scroll/update', { type: 'LAZY_DEBOUNCED', position: getPosition() });
    }),
  ],
  resize: [
    debounce(100, () => {
      store.dispatch('resize/update', { type: 'DEBOUNCED', dimensions: getDimensions() });
    }),
    debounce(300, () => {
      store.dispatch('resize/update', { type: 'LAZY_DEBOUNCED', dimensions: getDimensions() });
    }),
  ],
});

export default actions;
