import { hasActiveSubscription } from '~/helpers/models/user';
// import { redirectWithoutCaching } from '~/helpers/redirect';

export default function(context) {
  const { app, route, redirect } = context;

  // Redirect to homepage if user has active sub
  if (isReferRoute(route) && app.$auth.user && hasActiveSubscription(app.$auth.user)) {
    redirect('/');
  }

  // if (route.name.startsWith('refer') && hasCodeQueryParams(route) && !hasActiveSubscription(app.$auth.user)) {
  //   return redirectWithoutCaching(context, '/');
  // }
}

// function hasCodeQueryParams(route) {
//   return route.query.code && route.query.by;
// }

function isReferRoute(route) {
  return route.name.startsWith('offers-refer') || route.name.startsWith('refer');
}
