






import 'reflect-metadata';
import { Vue, Prop, Component } from 'nuxt-property-decorator';
import { Route } from 'vue-router';

@Component
export default class BaseLink extends Vue {
  @Prop({ required: true, type: [String, Object] }) readonly to!: string | Route;
  @Prop({ default: false }) readonly external!: boolean;
  @Prop({ default: false }) readonly underline!: boolean;
  @Prop({ default: false }) readonly bold!: boolean;

  get path(): string | undefined {
    if (typeof this.to === 'string') return this.to;
    return this.to.path;
  }

  get isExternal(): boolean {
    if (!this.path) return false;
    if (this.path.includes('mailto')) return true;

    return /^(http|\/\/)/.test(this.path) || this.external;
  }

  get tag(): string {
    return this.isExternal ? 'a' : 'nuxt-link';
  }

  get attributes() {
    if (this.isExternal) {
      return { href: this.path, target: '_blank', rel: 'noopener' };
    } else {
      return { to: this.to };
    }
  }
}
