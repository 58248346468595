
















import { Vue, Component, Prop } from 'nuxt-property-decorator';
import ButtonIcon from '~/components/partials/icons/ButtonIcon/ButtonIcon.vue';

@Component({
  components: {
    ButtonIcon,
  },
})
export default class BaseModal extends Vue {
  @Prop({ default: false }) readonly isOpen!: boolean;

  toggleModal() {
    this.$emit('closeModal');
  }
}
