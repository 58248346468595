import { redirectWithoutCaching } from '~/helpers/redirect';
import { DOWNGRADE } from '~/constants/can-change-reasons';

export default function(context) {
  const { app, store } = context;

  if (
    !app.$auth.user ||
    !app.$auth.user.changeSubscriptionStatus ||
    (!app.$auth.user.changeSubscriptionStatus.canChange &&
      app.$auth.user.changeSubscriptionStatus.canChangeReason === DOWNGRADE)
  ) {
    return store
      .dispatch('user/setChangeSubscriptionStatus', false)
      .then(() => redirectWithoutCaching(context, '/account'));
  }
}
