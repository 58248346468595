














import 'reflect-metadata';
import { Prop, Component, mixins } from 'nuxt-property-decorator';
import ImageMixin from '~/components/partials/images/ImageMixin/ImageMixin.vue';

const availableObjectFits = ['fill', 'contain', 'cover', 'none', 'scale-down', 'unset', 'initial', 'inherit'];

@Component
export default class BaseImage extends mixins(ImageMixin) {
  @Prop({ default: 'unset', validator: (value) => availableObjectFits.includes(value) })
  readonly objectFit!: string;

  mounted(): void {
    this.imageElement = this.$el as HTMLImageElement;
  }
}
