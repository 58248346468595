var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    { attrs: { "is-open": _vm.isOpen }, on: { closeModal: _vm.toggleModal } },
    [_c("template", { slot: "content" }, [_vm._t("default")], 2)],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }