var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.disabledNavigation
      ? _c(
          "div",
          _vm._b(
            {
              staticClass: "v-nav-desktop-bar",
              class: _vm.classes,
              attrs: { id: "desktop-navigation" }
            },
            "div",
            _vm.$attrs,
            false
          ),
          [
            _c(
              "BaseList",
              {
                staticClass: "v-nav-desktop-bar__list-items",
                attrs: { bare: true }
              },
              [
                _c(
                  "BaseListItem",
                  { staticClass: "v-nav-desktop-bar__list-item" },
                  [
                    _c(
                      "BaseLink",
                      {
                        staticClass: "v-nav-desktop-bar__link",
                        class: _vm.linkClasses,
                        attrs: { to: "/", "aria-label": "Go to home page" }
                      },
                      [
                        _c("WhirliLogo", {
                          staticClass: "v-nav-desktop-bar__logo",
                          attrs: {
                            background: _vm.logoTheme.bg,
                            foreground: _vm.logoTheme.fg
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "BaseListItem",
                  { staticClass: "v-nav-desktop-bar__list-item" },
                  [
                    _c(
                      "BaseLink",
                      {
                        staticClass: "v-nav-desktop-bar__link",
                        class: _vm.linkClasses,
                        attrs: { to: "/toys" }
                      },
                      [_vm._v("\n          TOYS\n        ")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "BaseListItem",
                  { staticClass: "v-nav-desktop-bar__list-item" },
                  [
                    _c(
                      "BaseLink",
                      {
                        staticClass: "v-nav-desktop-bar__link",
                        class: _vm.linkClasses,
                        attrs: {
                          to:
                            "/toys/all?page=1&category=prams%3Bhigh-chairs%3Bkids-luggage%3Bchild-carriers%3Bbouncers&orderBy=most_popular_score&sortedBy=desc"
                        }
                      },
                      [_vm._v("\n          BABY EQUIPMENT\n        ")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "BaseListItem",
                  { staticClass: "v-nav-desktop-bar__list-item" },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "v-nav-desktop-bar__link v-nav-desktop-bar__list-item-dropdown",
                        class: _vm.linkClasses,
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.toggleDropdown(
                              "aboutDropdownOpen",
                              !_vm.aboutDropdownOpen
                            )
                          }
                        }
                      },
                      [
                        _vm._v("\n          ABOUT WHIRLI\n          "),
                        _c("BaseIcon", {
                          staticClass: "v-nav-desktop-bar__dropdown-arrow",
                          attrs: {
                            icon: "ArrowCenter",
                            size: "small",
                            direction: _vm.aboutDropdownOpen ? "up" : "down",
                            theme: _vm.defaultTheme.fg
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("NavDropdown", {
                      attrs: {
                        "link-classes": _vm.linkClasses,
                        theme: _vm.defaultTheme.fg,
                        visible: _vm.aboutDropdownOpen,
                        links: _vm.navAboutLinks
                      },
                      on: {
                        "close-about-dropdown": function($event) {
                          return _vm.toggleDropdown("aboutDropdownOpen", false)
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "BaseListItem",
                  { staticClass: "v-nav-desktop-bar__list-item" },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "v-nav-desktop-bar__link v-nav-desktop-bar__list-item-dropdown",
                        class: _vm.linkClasses,
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.toggleDropdown(
                              "giftDropdownOpen",
                              !_vm.giftDropdownOpen
                            )
                          }
                        }
                      },
                      [
                        _vm._v("\n          GIFT\n          "),
                        _c("BaseIcon", {
                          staticClass: "v-nav-desktop-bar__dropdown-arrow",
                          attrs: {
                            icon: "ArrowCenter",
                            size: "small",
                            direction: _vm.giftDropdownOpen ? "up" : "down",
                            theme: _vm.defaultTheme.fg
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("NavDropdown", {
                      attrs: {
                        "link-classes": _vm.linkClasses,
                        theme: _vm.defaultTheme.fg,
                        visible: _vm.giftDropdownOpen,
                        links: _vm.navGiftLinks
                      },
                      on: {
                        "close-about-dropdown": function($event) {
                          return _vm.toggleDropdown("giftDropdownOpen", false)
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.christmasLink
                  ? _c(
                      "BaseListItem",
                      { staticClass: "v-nav-desktop-bar__list-item" },
                      [
                        _c(
                          "BaseLink",
                          {
                            staticClass: "v-nav-desktop-bar__link",
                            class: _vm.linkClasses,
                            attrs: { to: _vm.christmasLink }
                          },
                          [_vm._v("\n          Christmas\n        ")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "BaseListItem",
                  { staticClass: "v-nav-desktop-bar__list-item" },
                  [
                    _c(
                      "BaseLink",
                      {
                        staticClass: "v-nav-desktop-bar__link",
                        class: _vm.linkClasses,
                        attrs: { to: "/blog" }
                      },
                      [_vm._v("\n          BLOG\n        ")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "BaseListItem",
                  { staticClass: "v-nav-desktop-bar__list-item" },
                  [
                    _c("div", { staticClass: "search-container" }, [
                      _c(
                        "div",
                        { staticClass: "input-wrapper" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchQuery,
                                expression: "searchQuery"
                              }
                            ],
                            staticClass: "search-input",
                            attrs: { type: "text", placeholder: "Search..." },
                            domProps: { value: _vm.searchQuery },
                            on: {
                              input: [
                                function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.searchQuery = $event.target.value
                                },
                                _vm.debouncedInput
                              ],
                              focus: _vm.showSuggestions,
                              keydown: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleEnterKey($event)
                              }
                            }
                          }),
                          _vm._v(" "),
                          !_vm.searchQuery && !_vm.searchQuery.length
                            ? _c("BaseIcon", {
                                staticClass: "search-icon",
                                attrs: {
                                  icon: "Search",
                                  size: "base",
                                  theme: "white",
                                  type: "button",
                                  tag: "button"
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.searchQuery !== ""
                            ? _c("BaseIcon", {
                                staticClass: "search-icon",
                                attrs: {
                                  icon: "CrossTwo",
                                  size: "base",
                                  theme: "white",
                                  type: "button",
                                  tag: "button"
                                },
                                on: { click: _vm.clearSearch }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.showSuggestionList
                        ? _c(
                            "ul",
                            {
                              staticClass: "suggestions-list1",
                              class: { scrollable: _vm.products.length > 6 },
                              on: {
                                click: _vm.closeSuggestions,
                                scroll: _vm.handleScroll
                              }
                            },
                            [
                              _vm.products.length
                                ? [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "suggestion-item head-line-text suggestion-text"
                                      },
                                      [
                                        _vm._v(
                                          "\n                PRODUCTS\n              "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(_vm.products, function(
                                      product,
                                      index
                                    ) {
                                      return _c(
                                        "li",
                                        {
                                          key: "product-" + index,
                                          staticClass: "suggestion-item"
                                        },
                                        [
                                          _c(
                                            "BaseLink",
                                            {
                                              staticClass:
                                                "v-nav-desktop-bar__link no-uppercase",
                                              attrs: {
                                                to: "/toy/" + product.slug
                                              },
                                              on: {
                                                click: _vm.closeSuggestions
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "product-info" },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "product-image",
                                                    attrs: {
                                                      src: product.url,
                                                      alt: "Product Image"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "product-details"
                                                    },
                                                    [
                                                      _c("p", {
                                                        staticClass:
                                                          "highlight-text",
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            (function() {
                                                              return _vm.highlightMatch(
                                                                product.name
                                                              )
                                                            })()
                                                          )
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "product-price"
                                                        },
                                                        [
                                                          _c("DiscIcon", {
                                                            staticClass:
                                                              "price-image",
                                                            attrs: {
                                                              icon: "Token",
                                                              theme:
                                                                "secondary",
                                                              size: "teeny"
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          product.discountedValue
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "product-discounted-value"
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "strikethrough"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          product.tokens
                                                                        )
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "text-dicscount-value"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          product.discountedValue
                                                                        ) +
                                                                          " Tokens"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "product-discounted-value"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      product.tokens
                                                                    ) +
                                                                      " Tokens"
                                                                  )
                                                                ]
                                                              )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    })
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.searchInitiated && _vm.products.length === 0
                                ? [
                                    _c("div", [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "suggestion-item head-line-text suggestion-text product-not-found"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  No products found for term '" +
                                              _vm._s(_vm.searchQuery) +
                                              "'\n                "
                                          )
                                        ]
                                      )
                                    ])
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e()
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "BaseList",
              {
                staticClass: "v-nav-desktop-bar__list-items",
                attrs: { bare: true }
              },
              [
                !_vm.userLoggedIn
                  ? [
                      _c(
                        "BaseListItem",
                        { staticClass: "v-nav-desktop-bar__list-item" },
                        [
                          _c(
                            "BaseLink",
                            {
                              staticClass: "v-nav-desktop-bar__link",
                              class: _vm.linkClasses,
                              attrs: { to: "/login" }
                            },
                            [_vm._v("\n            Log in\n          ")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "BaseListItem",
                        { staticClass: "v-nav-desktop-bar__list-item" },
                        [
                          _c(
                            "BaseButton",
                            {
                              staticClass: "v-nav-desktop-bar__button",
                              attrs: { tag: "nuxt-link", to: "/register" }
                            },
                            [_vm._v("\n            Subscribe\n          ")]
                          )
                        ],
                        1
                      )
                    ]
                  : _c(
                      "BaseListItem",
                      { staticClass: "v-nav-desktop-bar__list-item" },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "v-nav-desktop-bar__link v-nav-desktop-bar__list-item-dropdown",
                            class: _vm.linkClasses,
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.toggleDropdown(
                                  "accountDropdownOpen",
                                  !_vm.accountDropdownOpen
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s("" + _vm.$auth.user.firstname) +
                                "\n          "
                            ),
                            _c("BaseIcon", {
                              staticClass: "v-nav-desktop-bar__dropdown-arrow",
                              attrs: {
                                icon: "ArrowCenter",
                                size: "small",
                                direction: _vm.accountDropdownOpen
                                  ? "up"
                                  : "down",
                                theme: _vm.defaultTheme.fg
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("NavAccountDropdown", {
                          attrs: {
                            "link-classes": _vm.linkClasses,
                            theme: _vm.defaultTheme.fg,
                            visible: _vm.accountDropdownOpen
                          },
                          on: {
                            "close-account-dropdown": function($event) {
                              return _vm.toggleDropdown(
                                "accountDropdownOpen",
                                false
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                _vm._v(" "),
                _c(
                  "BaseListItem",
                  { staticClass: "v-nav-desktop-bar__list-item" },
                  [
                    _c("ButtonIcon", {
                      staticClass: "v-nav-desktop-bar__link",
                      class: _vm.linkClasses,
                      attrs: {
                        disc: true,
                        size: "intermediate",
                        theme: _vm.defaultTheme.fg,
                        tag: "nuxt-link",
                        to: "/lists",
                        icon: "HeartFilled",
                        "aria-label": "Your lists"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "BaseListItem",
                  {
                    staticClass:
                      "v-nav-desktop-bar__list-item u-margin-left-tiny"
                  },
                  [
                    _vm.hasOutOfStockLines
                      ? _c("DiscIcon", {
                          staticClass: "v-nav-desktop-bar__error-icon",
                          attrs: {
                            icon: "Error",
                            theme: "quinary",
                            size: "base"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.userLoggedIn
                      ? _c("NavBagPill", {
                          attrs: {
                            theme: _vm.defaultTheme,
                            "show-meta": !_vm.$gifts.giftMode.isOn(),
                            number: _vm.$gifts.giftMode.isOn()
                              ? null
                              : _vm.numberOfItemsInBasket
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.toggleBasket($event)
                            }
                          }
                        })
                      : _c("ButtonIcon", {
                          staticClass: "v-nav-desktop-bar__link",
                          class: _vm.linkClasses,
                          attrs: {
                            disc: true,
                            theme: _vm.defaultTheme.fg,
                            "aria-label": "Open bag",
                            size: "intermediate",
                            type: "button",
                            icon: "Bag",
                            number: _vm.$gifts.giftMode.isOn()
                              ? null
                              : _vm.numberOfItemsInBasket
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.toggleBasket($event)
                            }
                          }
                        })
                  ],
                  1
                )
              ],
              2
            )
          ],
          1
        )
      : _c(
          "div",
          _vm._b(
            {
              staticClass: "v-nav-desktop-bar",
              class: _vm.classes,
              attrs: { id: "desktop-navigation" }
            },
            "div",
            _vm.$attrs,
            false
          ),
          [
            _c(
              "BaseCluster",
              { staticClass: "u-full-width" },
              [
                _c(
                  "BaseLink",
                  {
                    staticClass: "v-nav-desktop-bar__link",
                    class: _vm.linkClasses,
                    attrs: {
                      to: "/?fromRegistration=1",
                      "aria-label": "Go to home page"
                    }
                  },
                  [
                    _c("WhirliLogo", {
                      staticClass: "v-nav-desktop-bar__logo",
                      attrs: {
                        background: _vm.logoTheme.bg,
                        foreground: _vm.logoTheme.fg
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm.isPickToysPage
              ? _c(
                  "div",
                  {
                    staticClass:
                      "v-nav-desktop-bar__list-item u-margin-left-tiny"
                  },
                  [
                    _vm.hasOutOfStockLines
                      ? _c("DiscIcon", {
                          staticClass: "v-nav-desktop-bar__error-icon",
                          attrs: {
                            icon: "Error",
                            theme: "quinary",
                            size: "base"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.userLoggedIn
                      ? _c("NavBagPill", {
                          attrs: {
                            theme: _vm.defaultTheme,
                            "show-meta": !_vm.$gifts.giftMode.isOn(),
                            number: _vm.$gifts.giftMode.isOn()
                              ? null
                              : _vm.numberOfItemsInBasket
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.toggleBasket($event)
                            }
                          }
                        })
                      : _c("ButtonIcon", {
                          staticClass: "v-nav-desktop-bar__link",
                          class: _vm.linkClasses,
                          attrs: {
                            disc: true,
                            theme: _vm.defaultTheme.fg,
                            "aria-label": "Open bag",
                            size: "intermediate",
                            type: "button",
                            icon: "Bag",
                            number: _vm.$gifts.giftMode.isOn()
                              ? null
                              : _vm.numberOfItemsInBasket
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.toggleBasket($event)
                            }
                          }
                        })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }