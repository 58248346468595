import { SHARING_IMAGE_URL, WEBSITE_URL } from '~/constants/seo';

interface MetaItem {
  hid: string;
  name: string;
  content: string;
}

export default function social(
  title: string,
  description: string,
  route: string,
  type?: string,
  image?: string
): Array<MetaItem> {
  return [
    { hid: 'og:title', name: 'og:title', content: title },
    { hid: 'og:description', name: 'og:description', content: description },
    { hid: 'og:type', name: 'og:type', content: type ?? 'website' },
    { hid: 'og:url', name: 'og:url', content: `${WEBSITE_URL}${route}` },
    {
      hid: 'og:image',
      name: 'og:image',
      content: image ?? SHARING_IMAGE_URL,
    },
    { hid: 'twitter:title', name: 'twitter:title', content: title },
    { hid: 'twitter:description', name: 'twitter:description', content: description },
  ];
}
