import { Auth } from '~/index.d.ts';
export type UserType = 'users' | 'guests';

export const USERS = 'users';
export const GUESTS = 'guests';

/**
 * We need a "guests" and "users" distinction because we have to hit different endpoints depending on if the user is logged in or not.
 *
 * The client provides two parent resources: "guests" and "users".
 *
 * This method extracts this verbose logic:
 * ```javascript
 * const basketFunction = this.$auth.loggedIn ? this.$whirli.users.baskets.removeLine : this.$whirli.guests.baskets.removeLine;
 * ```
 *
 * So that we can do this:
 * ```javascript
 * this.$whirli[getUserResourceType(this.$auth)].baskets.removeLine();
 * ```
 *
 * @returns {string} "guests" or "users".
 */
export function getUserResourceType(auth: Auth): UserType {
  return auth.loggedIn ? USERS : GUESTS;
}
