var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "v-notifications",
      on: {
        click: function($event) {
          $event.stopPropagation()
        }
      }
    },
    [
      _c("notifications", {
        staticClass: "v-notifications__inner",
        attrs: {
          group: _vm.group,
          "animation-name":
            _vm.animationName !== null ? _vm.animationName : "vn-fade",
          reverse: _vm.reverseOrder
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function(props) {
              return [
                _c(
                  "BaseBox",
                  {
                    class:
                      "v-notification-item v-notification-item--" +
                      props.item.type +
                      " u-margin-bottom-mini",
                    attrs: { padding: ["tiny", "small", "tiny", "small"] }
                  },
                  [
                    _c(
                      "BaseLayout",
                      [
                        _c(
                          "BaseLayoutItem",
                          [
                            _c("DiscIcon", {
                              attrs: {
                                icon: _vm.icon(props.item.type),
                                theme: _vm.theme(props.item.type),
                                size: "base"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "v-notification-item__content" },
                              [
                                !!props.item.text
                                  ? _c(
                                      "BaseText",
                                      {
                                        attrs: {
                                          color: _vm.color(props.item.type)
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(props.item.text) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !!props.item.data && props.item.data.arrows
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "v-notification-item__arrows"
                                      },
                                      _vm._l(7, function(icon, index) {
                                        return _c("BaseIcon", {
                                          key: index,
                                          attrs: {
                                            icon: "ArrowCenter",
                                            direction: "right",
                                            size: "teeny",
                                            theme: _vm.color(props.item.type)
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !!props.item.data &&
                                (!!props.item.data.function ||
                                  !!props.item.data.to)
                                  ? _c(
                                      props.item.data.to
                                        ? "BaseLink"
                                        : "button",
                                      {
                                        tag: "component",
                                        staticClass: "v-notification-item__btn",
                                        attrs: { to: props.item.data.to },
                                        on: {
                                          click: function($event) {
                                            return _vm.onNotificationClicked(
                                              props.item.data.function,
                                              props.item.data.parameters
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "BaseText",
                                          {
                                            attrs: {
                                              color: _vm.color(props.item.type),
                                              underline: true
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(props.item.data.cta) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("ButtonIcon", {
                              staticClass: "v-notification-item__close",
                              attrs: {
                                "aria-label": "Close notification",
                                icon: "Cross",
                                size: "teeny",
                                theme: _vm.close(props.item.type)
                              },
                              on: { click: props.close }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }