import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '~/store/index';

const types = {
  PUT_CURRENT_STEP: 'PUT_CURRENT_STEP',
};

export interface ModuleState {
  currentStep: number;
}

export const state = (): ModuleState => ({
  currentStep: 1,
});

export const actions: ActionTree<ModuleState, RootState> = {
  putRegistrationStep({ commit }, step: number) {
    this.$logger.store(`action`, `[registration/progress/putRegistrationStep]`, { step });

    commit(types.PUT_CURRENT_STEP, step);
  },
  reset({ commit }) {
    this.$logger.store(`action`, `[registration/progress/reset]`);

    commit(types.PUT_CURRENT_STEP, 1);
  },
};

export const mutations: MutationTree<ModuleState> = {
  [types.PUT_CURRENT_STEP](state, step: number) {
    state.currentStep = step;
  },
};
