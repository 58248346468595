
































import 'reflect-metadata';
import { Vue, Prop, Component } from 'nuxt-property-decorator';

const availableSizes = ['small', 'base'];

@Component
export default class BaseProductImage extends Vue {
  @Prop({ default: null }) readonly src!: string;
  @Prop({ required: true }) readonly alt!: string;
  @Prop({ required: false }) readonly to!: string;
  @Prop({ default: 'base', validator: (size) => availableSizes.includes(size) }) readonly size!: string;
  @Prop({ default: 'BaseLink', validator: (value) => ['div', 'BaseLink'].includes(value) })
  readonly tag!: string;

  shouldLoadImage: boolean = false;
  hasError: boolean = false;
  hasLoaded: boolean = false;

  get image(): string {
    if (!this.shouldLoadImage) return '';

    const noImageBackup: string = require('~/assets/img/products/no-image.jpg');
    if (this.hasError) return noImageBackup;
    return this.src || noImageBackup;
  }

  onIntersect(isIntersecting: boolean) {
    if (this.shouldLoadImage) return;

    this.shouldLoadImage = isIntersecting;
  }
}
