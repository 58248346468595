var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "u-slide-left" } }, [
    _vm.isOpen
      ? _c(
          "div",
          _vm._b(
            { staticClass: "v-side-drawer", class: _vm.classes },
            "div",
            _vm.$attrs,
            false
          ),
          [_vm._t("content")],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }