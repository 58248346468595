var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass: "v-navigation",
      class: [
        _vm.isRegistrationFlow
          ? "v-navigation_position-relative"
          : "v-navigation_position-sticky"
      ]
    },
    [
      _c("NavDesktopBar", {
        attrs: {
          products: _vm.filteredSuggestions,
          searchInitiated: _vm.searchInitiated
        },
        on: {
          "search-input": _vm.handleSearchInput,
          "load-more": _vm.loadMoreResults
        }
      }),
      _vm._v(" "),
      _c("NavMobileBar", {
        attrs: {
          products: _vm.filteredSuggestions,
          searchInitiated: _vm.searchInitiated
        },
        on: {
          "search-input": _vm.handleSearchInput,
          "load-more": _vm.loadMoreResults
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }