

























import { Vue, Prop, Component } from 'nuxt-property-decorator';
import { validateTheme } from '~/helpers/validators';

const availableSizes: string[] = [
  'micro',
  'mini',
  'teeny',
  'tiny',
  'small',
  'base',
  'medium',
  'intermediate',
  'big',
  'large',
  'huge',
  'massive',
  'immense',
];

@Component
export default class LoadingSpinner extends Vue {
  @Prop({ default: 'primary', validator: (value) => validateTheme(value) })
  readonly color!: string;

  @Prop({ default: 'small', validator: (value) => availableSizes.includes(value) }) readonly size!: string;

  spinnerPoints: any[] = [
    { rotate: '0 50 50', begin: '-0.9166666666666666s' },
    { rotate: '30 50 50', begin: '-0.8333333333333334s' },
    { rotate: '60 50 50', begin: '-0.75s' },
    { rotate: '90 50 50', begin: '-0.6666666666666666s' },
    { rotate: '120 50 50', begin: '-0.5833333333333334s' },
    { rotate: '150 50 50', begin: '-0.5s' },
    { rotate: '180 50 50', begin: '-0.4166666666666667s' },
    { rotate: '210 50 50', begin: '-0.3333333333333333s' },
    { rotate: '240 50 50', begin: '-0.25s' },
    { rotate: '270 50 50', begin: '-0.16666666666666666s' },
    { rotate: '300 50 50', begin: '-0.08333333333333333s' },
    { rotate: '330 50 50', begin: '0s' },
  ];

  get loadingSpinnerClasses() {
    return {
      [`v-loading-spinner--${this.size}`]: this.size,
    };
  }
}
