var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "u-slide-right" } }, [
    _vm.isOpen
      ? _c(
          "div",
          { staticClass: "v-hamburger-menu u-color-white u-text--button" },
          [
            _c(
              "BaseBox",
              { attrs: { padding: ["base", "medium"] } },
              _vm._l(_vm.links, function(linkGroup, parentIndex) {
                return _c(
                  "BaseList",
                  { key: parentIndex, staticClass: "v-hamburger-menu__list" },
                  _vm._l(linkGroup, function(link, childIndex) {
                    return _c(
                      "BaseListItem",
                      {
                        key: childIndex,
                        staticClass: "v-hamburger-menu__list-item"
                      },
                      [
                        _c(
                          "BaseLink",
                          {
                            staticClass: "v-hamburger-menu__link",
                            attrs: { to: link.to },
                            nativeOn: {
                              click: function($event) {
                                return _vm.toggleMenu($event)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(link.text) +
                                "\n            "
                            ),
                            _c(
                              "div",
                              { staticClass: "v-hamburger-menu__gifting-tag" },
                              [
                                link.to === "/gift" && _vm.hasActiveSubscription
                                  ? _c(
                                      "BaseTag",
                                      {
                                        attrs: {
                                          theme: "tertiary",
                                          tilt: "right",
                                          tag: "span"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                10% off\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  }),
                  1
                )
              }),
              1
            ),
            _vm._v(" "),
            _vm.showCtaButton
              ? _c(
                  "div",
                  { staticClass: "v-hamburger-menu__cta" },
                  [
                    _c(
                      "BaseButton",
                      {
                        attrs: {
                          theme: "tertiary",
                          to: "/register",
                          tag: "nuxt-link"
                        }
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.ctaButtonText) + "\n      "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }