var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "u-fade-in" } }, [
    _vm.isOpen
      ? _c(
          "div",
          { staticClass: "v-modal" },
          [
            _c("ButtonIcon", {
              staticClass: "v-modal__close-button",
              attrs: {
                icon: "Cross",
                theme: "secondary",
                size: "base",
                "native-type": "button"
              },
              nativeOn: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.toggleModal($event)
                }
              }
            }),
            _vm._v(" "),
            _vm._t("content")
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }