var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "u-fade-in" } },
    [
      _c(
        "BaseFixedCTA",
        {
          staticClass: "v-fixed-btn-cta",
          attrs: { delay: _vm.delay, right: _vm.right }
        },
        [
          _c(
            "BaseButton",
            _vm._b(
              {
                attrs: {
                  active: _vm.active,
                  disabled: _vm.disabled,
                  loading: _vm.loading,
                  "mobile-min-width": false,
                  "native-type": _vm.nativeType,
                  "no-hover": _vm.noHover,
                  tag: _vm.tag,
                  theme: _vm.theme
                }
              },
              "BaseButton",
              _vm.$attrs,
              false
            ),
            [_vm._t("default")],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }