
import 'reflect-metadata';
import { Vue, Component, namespace } from 'nuxt-property-decorator';
import { RightPayload } from '~/store/products';
import {
  ToggleSelectedPayload,
  SelectedAssociations as SA,
  SelectedBucket,
} from '~/store/products/associations';
import { registrationPageCheck } from '~/helpers/registration-step-new';
import { ProductAssociationMetas } from '~/types/products/associations';

interface SelectedAssociations extends SA {} // Without this, typescript throws a warning that it cannot find the export
const ProductsStore = namespace('products');
const ProductAssociationsStore = namespace('products/associations');
const RangeStore = namespace('products/range');
const SearchStore = namespace('products/search');
const AssociationsStore = namespace('products/associations');

@Component
export default class FiltersMixin extends Vue {
  @ProductsStore.Action rightFilter!: (payload: RightPayload) => void;
  @ProductsStore.State busy!: boolean;
  @ProductAssociationsStore.Action toggleSelected!: (payload: ToggleSelectedPayload) => void;
  @ProductAssociationsStore.Action('removeAll') resetAssociations!: () => void;
  @RangeStore.Action resetRange!: () => void;
  @SearchStore.Action('reset') resetSearch!: () => void;
  @AssociationsStore.State current!: ProductAssociationMetas;
  @AssociationsStore.State selected!: SelectedAssociations;
  get isRegistrationPage(): boolean {
    return registrationPageCheck(this.$route.name);
  }

  async toggleBucketAssociation(selectedBucket: SelectedBucket) {
    try {
      await this.toggleSelected({ selectedBucket });
      await this.refreshProducts();
    } catch (error) {
      return null;
    }
  }

  async clearAll() {
    try {
      await Promise.all([this.resetAssociations(), this.resetRange(), this.resetSearch()]);
      await this.refreshProducts();
    } catch (error) {
      return null;
    }
  }

  async clearRange() {
    try {
      await this.resetRange();
      await this.refreshProducts();
    } catch (error) {
      return null;
    }
  }

  async clearSearch() {
    try {
      await this.resetSearch();
      await this.refreshProducts();
    } catch (error) {
      return null;
    }
  }

  async refreshProducts() {
    const transformed: { [key: string]: string } = {};

    for (const key in this.selected) {
      if (Object.hasOwnProperty.call(this.selected, key)) {
        transformed[key] = this.selected[key].map((item: any) => item.slug).join(';');
      }
    }
    const params: any = {
      perPage: 24,
      page: 1,
      ...transformed,
    };
    try {
      if (this.isRegistrationPage) {
        await this.rightFilter({ resetCurrentPage: true, shouldUpdateUrl: false, params });
      } else {
        await this.rightFilter({ resetCurrentPage: true, params });
      }
    } catch (error) {
      return null;
    }
  }
}
