import { Plugin } from '@nuxt/types';
import { Route } from 'vue-router';
import Navigation from './Navigation';

const plugin: Plugin = function({ app, route, store }, inject): void {
  const navigation = new Navigation(app, route, store);

  inject('navigation', navigation);

  if (app.router) {
    app.router.afterEach((to: Route) => {
      app.$navigation.setRoute(to);
    });
  }
};

export default plugin;
