var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "u-slide-down" } }, [
    this.$store.state.navigation.isDropdownMenuOpen
      ? _c(
          "div",
          { staticClass: "v-nav-dropdown" },
          [
            _c(
              "BaseBox",
              { attrs: { padding: ["base", "medium"] } },
              [
                _c(
                  "BaseList",
                  {
                    staticClass: "v-nav-dropdown__list",
                    attrs: { bare: true }
                  },
                  [
                    _vm._l(_vm.links, function(link, index) {
                      return [
                        !link.featureFlag ||
                        _vm.featureFlagParam(_vm.$route.query[link.featureFlag])
                          ? _c(
                              "BaseListItem",
                              {
                                key: index,
                                staticClass: "v-nav-dropdown__list-item"
                              },
                              [
                                _c(
                                  "BaseLink",
                                  {
                                    staticClass: "v-nav-dropdown__link",
                                    attrs: { to: link.to }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "u-text--button u-color-secondary u-margin-bottom-mini"
                                      },
                                      [_vm._v(_vm._s(link.text))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "u-text--small-body u-color-charcoal"
                                      },
                                      [_vm._v(_vm._s(link.subText))]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    }),
                    _vm._v(" "),
                    _c(
                      "BaseListItem",
                      { staticClass: "v-nav-dropdown__list-item" },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "v-nav-dropdown__link--logout u-text--button u-color-quinary u-padding-none",
                            attrs: { type: "button" },
                            on: {
                              click: function() {
                                _vm.toggleMenu()
                                _vm.logout()
                              }
                            }
                          },
                          [_vm._v("\n            Log out\n          ")]
                        )
                      ]
                    )
                  ],
                  2
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }