import { Asset } from '~/types/products/products';
import { url as assetTransformUrl } from '~/helpers/models/AssetTransform';

export function image(asset: Asset): string | null {
  if (!asset || !asset.transforms || !asset.transforms.length) return null;

  return assetTransformUrl(asset.transforms[0]);
}

export function largeImage(asset: Asset): string | null {
  if (!asset || !asset.transforms || !asset.transforms.length) return null;

  const largeImage = asset.transforms.find((transform) => transform.filename.includes('large'));
  return largeImage ? assetTransformUrl(largeImage) : null;
}
