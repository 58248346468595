var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "11px",
        height: "12px",
        viewBox: "0 0 11 12"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "final",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "xmas-page-customer-link",
                transform: "translate(-856.000000, -108.000000)",
                fill: "#FFFFFF",
                "fill-rule": "nonzero"
              }
            },
            [
              _c("polygon", {
                attrs: {
                  id: "❄",
                  points:
                    "861.804688 119.363636 861.789062 117.473011 863.21875 118.433949 863.40625 117.941761 861.773438 116.887074 861.742188 114.246449 864.015625 115.590199 864.109375 117.519886 864.640625 117.621449 864.53125 115.894886 866.140625 116.848011 866.460938 116.324574 864.8125 115.394886 866.367188 114.629261 866.03125 114.230824 864.289062 115.105824 862 113.808949 864.289062 112.512074 866.03125 113.394886 866.367188 112.996449 864.8125 112.230824 866.460938 111.301136 866.140625 110.769886 864.53125 111.730824 864.640625 110.004261 864.109375 110.098011 864.015625 112.035511 861.742188 113.379261 861.773438 110.738636 863.40625 109.683949 863.21875 109.183949 861.789062 110.144886 861.804688 108.262074 861.1875 108.262074 861.203125 110.144886 859.773438 109.183949 859.585938 109.683949 861.21875 110.738636 861.25 113.379261 858.976562 112.035511 858.882812 110.098011 858.351562 110.004261 858.460938 111.730824 856.851562 110.769886 856.53125 111.301136 858.179688 112.230824 856.625 112.996449 856.960938 113.394886 858.703125 112.512074 860.992188 113.808949 858.703125 115.105824 856.960938 114.230824 856.625 114.629261 858.179688 115.394886 856.53125 116.324574 856.851562 116.848011 858.460938 115.894886 858.351562 117.621449 858.882812 117.519886 858.976562 115.590199 861.25 114.246449 861.21875 116.887074 859.585938 117.941761 859.773438 118.433949 861.203125 117.473011 861.1875 119.363636"
                }
              })
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }