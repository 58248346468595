










import 'reflect-metadata';
import { Vue, Component, Prop } from 'nuxt-property-decorator';

@Component
export default class BaseFixedCTA extends Vue {
  @Prop({ default: 10 }) readonly bottom!: number;
  @Prop({ default: 0 }) readonly delay!: number;
  @Prop({ default: 20 }) left!: number;
  @Prop({ default: null }) readonly right!: number;

  visible: boolean = false;

  mounted() {
    setTimeout(() => (this.visible = true), this.delay);
  }

  created() {
    if (this.right) {
      return (this.left = null);
    }
  }
}
