// @ts-ignore
import Form from 'vform';
// @ts-ignore
import { token } from 'gen-uid';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { AddressDetails } from '~/types/user/addresses';
import { DeliveryOption } from '~/types/delivery-option';
import { Review } from '~/types/Reviews';
import { RootState } from '~/store/index';
import { OrderLine } from '~/types/order';
import { DELIVERY_ADDON, RETURN_ADDON, REUSABLE_PACKAGING } from '~/constants/shipping-addon-types';
import { BOOK_COLLECTION } from '~/constants/shipping-addon-names';

export interface ModuleState {
  deliveryAddress: AddressDetails | null;
  nominatedDeliveryDate: Date | null;
  reusablePackagingOptIn: boolean;
  showContactNumberWarning: boolean;
  deliveryAddon: DeliveryOption | null;
  returnAddon: DeliveryOption | null;
  orderLines: Array<OrderLine>;
  shippingAddons: Array<DeliveryOption>;
  undeliverableDates: Array<Date>;
  collectionDate: Date | null;
  collectionAddress: AddressDetails | null;
  collectionTimeSlotId: number | null;
  collectionTimeSlotTime: string | '';
  boxesToCollect: number | null;
  checkoutComplete: boolean;
  errors: Record<string, Array<string>>;
  checkoutStatus: boolean;
  checkoutMessage: string;
  idempotencyKey: string;
  reviews: Array<Review>;
  contactNumberWarningText: string;
}

const defaultState: ModuleState = {
  deliveryAddress: null,
  nominatedDeliveryDate: null,
  reusablePackagingOptIn: false,
  showContactNumberWarning: false,
  deliveryAddon: null,
  returnAddon: null,
  orderLines: [],
  shippingAddons: [],
  undeliverableDates: [],
  collectionDate: null,
  collectionAddress: null,
  collectionTimeSlotId: null,
  collectionTimeSlotTime: '',
  boxesToCollect: null,
  checkoutComplete: false,
  errors: {},
  checkoutStatus: false,
  checkoutMessage: '',
  idempotencyKey: '',
  reviews: [],
  contactNumberWarningText: `Please add a contact number by editing your delivery information below.
    This ensures the couriers can contact you about your delivery.`,
};

export const state = (): ModuleState => Object.assign({}, defaultState);

export const getters: GetterTree<ModuleState, RootState> = {
  reusablePackagingAddon(state): DeliveryOption | undefined {
    return state.shippingAddons.find((addon) => addon.typeId === REUSABLE_PACKAGING);
  },
  deliveryAddons(state): Array<DeliveryOption> | undefined {
    return state.shippingAddons.filter((addon) => addon.typeId === DELIVERY_ADDON);
  },
  returnAddons(state): Array<DeliveryOption> | undefined {
    return state.shippingAddons.filter((addon) => addon.typeId === RETURN_ADDON);
  },
  bookCollectionAddon(state): DeliveryOption | undefined {
    return state.shippingAddons.find((addon) => addon.name === BOOK_COLLECTION);
  },
};

export const actions: ActionTree<ModuleState, RootState> = {
  setDeliveryAddress({ commit }, address: AddressDetails) {
    this.$logger.store(`action`, `[checkout/setDeliveryAddress]`, { address });

    const hasPhoneNumber = address.id ? !!address.phoneNumber : true;
    commit(types.PUT_CONTACT_NUMBER_WARNING, !hasPhoneNumber);
    commit(types.PUT_DELIVERY_ADDRESS, address);
  },

  hydrateDeliveryAddress({ state, commit }, addressId: string): void {
    this.$logger.store(`action`, `[checkout/hydrateDeliveryAddress]`, { addressId });

    if (state.deliveryAddress?.id === addressId) {
      commit(types.PUT_DELIVERY_ADDRESS, null);
    }
  },

  setCollectionAddress({ commit }, address: AddressDetails) {
    this.$logger.store(`action`, `[checkout/setCollectionAddress]`, { address });
    localStorage.setItem('isCollectionAddress', 'true');
    const hasPhoneNumber = address.id ? !!address.phoneNumber : true;
    commit(types.PUT_CONTACT_NUMBER_WARNING, !hasPhoneNumber);
    commit(types.PUT_COLLECTION_ADDRESS, address);
  },

  setProductReview({ commit, state }, review: Review): void {
    let existingReviewIndex: number = -1;
    state.reviews.find((el: Review, i: number) => {
      if (el.productVariantId === review.productVariantId) {
        existingReviewIndex = i;
      }
    });
    commit(types.PUT_REVIEW, { review, existingReviewIndex });
  },

  removeProductReview({ commit }, productVariantId: string): void {
    commit(types.REMOVE_REVIEW, productVariantId);
  },

  resetReviews({ commit }): void {
    commit(types.CLEAR_REVIEWS);
  },

  async initialiseOrder({ commit }) {
    this.$logger.store(`action`, `[checkout/initialiseOrder]`);

    let response;
    try {
      response = await this.$whirli.users.orders.create();
    } catch (error) {
      return new Error(error);
    }

    if (response.data && response.data.lines) {
      commit(types.PUT_ORDER_LINES, response.data.lines);
    }
  },
  async initialiseShippingAddons({ commit }) {
    this.$logger.store(`action`, `[checkout/initialiseShippingAddons]`);

    let response;
    try {
      response = await this.$whirli.guests.shippingAddons.all();
    } catch (error) {
      return new Error(error);
    }

    if (response.data) {
      commit(types.PUT_SHIPPING_ADDONS, response.data);
    }
  },
  async initialiseUndeliverableDates({ commit }) {
    this.$logger.store(`action`, `[checkout/initialiseUndeliverableDates]`);

    let response;
    try {
      response = await this.$whirli.undeliverableDates.all();
    } catch (error) {
      return new Error(error);
    }

    if (response.data) {
      const dates: Array<Date> = response.data.map(
        (dateObject: { date: string }) => new Date(dateObject.date)
      );
      commit(types.PUT_UNDELIVERABLE_DATES, dates);
    }
  },

  async validateCanCheckout() {
    this.$logger.store(`action`, `[checkout/validateCanCheckout]`);

    await this.$auth.fetchUser();
  },

  async deleteOrderLine({ commit, dispatch, state }, { orderLineId }: { orderLineId: string }) {
    this.$logger.store(`action`, `[checkout/deleteOrderLine]`, { orderLineId });

    if (!orderLineId) return new Error('[checkout/deleteOrderLine] orderLine is undefined.');

    try {
      const response = await this.$whirli.users.orders.deleteLine(orderLineId);
      if (response.httpCode < 300) {
        const index = state.orderLines.findIndex((line) => line.id === orderLineId);
        commit(types.REMOVE_ORDER_LINE, index);

        if (!state.orderLines.length) await dispatch('validateCanCheckout');
      }
    } catch (error) {
      throw error;
    }
  },
  async processCheckout({ dispatch, commit }, { payload }: { payload: object }) {
    this.$logger.store(`action`, `[checkout/processCheckout]`, { payload });

    try {
      let response = await this.$whirli.users.checkout.process(payload);
      if (response.success) {
        if (response.data && response.data.requiresAction) {
          const result = await this.$stripe.confirmCardPayment(response.data.paymentIntentClientSecret);
          if (!result.paymentIntent || result.paymentIntent.status !== 'succeeded') {
            throw new Error('2Factor authentication failed. Please try again or contact Whirli for support.');
          }
          response = await this.$whirli.guests.paymentIntents.succeeded(result.paymentIntent.id);
        }
      }

      if (response.httpCode < 300) {
        commit(types.PUT_CHECKOUT_COMPLETE, true);
        commit('toybox/SET_TOYBOX_FETCHED', false, { root: true });

        await this.$auth.fetchUser();
        await Promise.all([
          dispatch('toybox/initialState', null, { root: true }),
          dispatch('basket/resetBasket', null, { root: true }),
        ]);
      }
    } catch (error) {
      commit(types.PUT_IDEMPOTENCY_KEY, token());
      let errors: any = {};
      if (error.response) {
        errors = new Form().extractErrors(error.response);
      }
      if (!error.response || (error && error.response && error.response.status >= 500)) {
        if (error.message) {
          errors.generalMessage = error.message;
        } else {
          errors.generalMessage = [
            'Please try refreshing the page and trying again, if the problem persists please contact us.',
          ];
        }
      }
      commit(types.SET_ERRORS, errors);
    }
  },
  async updateOrderLineQuantity(
    { commit },
    { orderLineId, quantity }: { orderLineId: string; quantity: number }
  ) {
    this.$logger.store(`action`, `[checkout/updateOrderLineQuantity]`, { orderLineId, quantity });

    try {
      const response = await this.$whirli.users.orders.updateLine(orderLineId, { quantity });
      if (response.httpCode < 300) {
        const order = response.data;
        commit(types.PUT_ORDER_LINES, order.lines);
      }
    } catch (error) {
      throw error;
    }
  },
  closeContactNumberWarning({ commit }): void {
    commit(types.PUT_CONTACT_NUMBER_WARNING, false);
  },
};

export const types = {
  PUT_DELIVERY_ADDRESS: 'PUT_DELIVERY_ADDRESS',
  PUT_CONTACT_NUMBER_WARNING: 'PUT_CONTACT_NUMBER_WARNING',
  PUT_REUSABLE_PACKAGING_OPT_IN: 'PUT_REUSABLE_PACKAGING_OPT_IN',
  PUT_DELIVERY_ADDON: 'PUT_DELIVERY_ADDON',
  PUT_RETURN_ADDON: 'PUT_RETURN_ADDON',
  PUT_ORDER_LINES: 'PUT_ORDER_LINES',
  REMOVE_ORDER_LINE: 'REMOVE_ORDER_LINE',
  UPDATE_ORDER_LINE_QUANTITY: 'UPDATE_ORDER_LINE_QUANTITY',
  PUT_SHIPPING_ADDONS: 'PUT_SHIPPING_ADDONS',
  PUT_COLLECTION_DATE: 'PUT_COLLECTION_DATE',
  PUT_COLLECTION_ADDRESS: 'PUT_COLLECTION_ADDRESS',
  PUT_COLLECTION_TIME_SLOT_ID: 'PUT_COLLECTION_TIME_SLOT_ID',
  PUT_COLLECTION_TIME_SLOT_TIME: 'PUT_COLLECTION_TIME_SLOT_TIME',
  PUT_BOXES_TO_COLLECT: 'PUT_BOXES_TO_COLLECT',
  PUT_CHECKOUT_COMPLETE: 'PUT_CHECKOUT_COMPLETE',
  SET_ERRORS: 'SET_ERRORS',
  PUT_NOMINATED_DELIVERY_DATE: 'PUT_NOMINATED_DELIVERY_DATE',
  PUT_UNDELIVERABLE_DATES: 'PUT_UNDELIVERABLE_DATES',
  PUT_STATUS_AND_MESSAGE: 'PUT_STATUS_AND_MESSAGE',
  PUT_IDEMPOTENCY_KEY: 'PUT_IDEMPOTENCY_KEY',
  RESET: 'RESET',
  PUT_REVIEW: 'PUT_REVIEWS',
  REMOVE_REVIEW: 'REMOVE_REVIEW',
  CLEAR_REVIEWS: 'CLEAR_REVIEWS',
};

export const mutations: MutationTree<ModuleState> = {
  [types.PUT_DELIVERY_ADDRESS](state, details: AddressDetails) {
    state.deliveryAddress = details;
  },
  [types.PUT_CONTACT_NUMBER_WARNING](state, value: boolean) {
    state.showContactNumberWarning = value;
  },
  [types.PUT_REUSABLE_PACKAGING_OPT_IN](state, value: boolean) {
    state.reusablePackagingOptIn = value;
  },
  [types.PUT_DELIVERY_ADDON](state, value: DeliveryOption) {
    state.deliveryAddon = value;
  },
  [types.PUT_RETURN_ADDON](state, value: DeliveryOption) {
    state.returnAddon = value;
  },
  [types.PUT_ORDER_LINES](state, value: Array<OrderLine>) {
    state.orderLines = value;
  },
  [types.REMOVE_ORDER_LINE](state, index: number) {
    state.orderLines.splice(index, 1);
  },
  [types.UPDATE_ORDER_LINE_QUANTITY](state, { index, quantity }: { index: number; quantity: number }) {
    state.orderLines[index].quantity = quantity;
  },
  [types.PUT_SHIPPING_ADDONS](state, value: Array<DeliveryOption>) {
    state.shippingAddons = value;
  },
  [types.PUT_COLLECTION_DATE](state, value: Date | null) {
    state.collectionDate = value;
  },
  [types.PUT_COLLECTION_TIME_SLOT_ID](state, value: number | null) {
    state.collectionTimeSlotId = value;
  },
  [types.PUT_COLLECTION_TIME_SLOT_TIME](state, value: string | '') {
    state.collectionTimeSlotTime = value;
  },
  [types.PUT_BOXES_TO_COLLECT](state, value: number) {
    state.boxesToCollect = value;
  },
  [types.PUT_CHECKOUT_COMPLETE](state, value: boolean) {
    state.checkoutComplete = value;
  },
  [types.SET_ERRORS](state, errors: any) {
    state.errors = errors;
  },
  [types.PUT_NOMINATED_DELIVERY_DATE](state, value: Date) {
    state.nominatedDeliveryDate = value;
  },
  [types.PUT_UNDELIVERABLE_DATES](state, dates: Array<Date>) {
    state.undeliverableDates = dates;
  },
  [types.PUT_STATUS_AND_MESSAGE](state, { status, message }: { status: boolean; message: string }) {
    state.checkoutStatus = status;
    state.checkoutMessage = message;
  },
  [types.PUT_COLLECTION_ADDRESS](state, details: AddressDetails) {
    state.collectionAddress = details;
  },
  [types.PUT_IDEMPOTENCY_KEY](state, key: string) {
    state.idempotencyKey = key;
  },
  [types.RESET](state) {
    Object.assign(state, defaultState);
  },
  [types.PUT_REVIEW](
    state,
    { review, existingReviewIndex }: { review: Review; existingReviewIndex: number }
  ) {
    if (existingReviewIndex === -1) {
      state.reviews.push(review);
    } else {
      state.reviews[existingReviewIndex].rating = review.rating;
    }
  },
  [types.REMOVE_REVIEW](state, productVariantId: string) {
    state.reviews = state.reviews.filter((review: Review) => {
      return review.productVariantId !== productVariantId;
    });
  },
  [types.CLEAR_REVIEWS](state) {
    state.reviews = [];
  },
};
