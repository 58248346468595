<template>
  <div :class="stackClasses">
    <slot />
  </div>
</template>

<script>
import { sizesMap } from '~/helpers/sizes';

/**
 * Flow elements require space to physically and conceptually separate them from the elements that come before and
 * after them. This flow space should exist solely between children elements in a container, and not between an element
 * and the container itself.
 * See https://absolutely.every-layout.dev/layouts/stack/
 *
 * Stacks can be used within stacks as well. All nested stacks can designate their own spacing value.
 * This could be useful for laying out a form, where elements are grouped into a stack, and labels + inputs exist inside
 * of nested stacks with different spacing.
 *
 * Some elements added to a stack may have a margin bottom added by default. This will alter the spacing between the
 * elements and is usually not desirable. This margin bottom on all child elements is stripped by default. This
 * behaviour can be turned off by setting the strip prop to false.
 */

const availableSizes = Object.keys(sizesMap);

export default {
  name: 'BaseStack',
  props: {
    center: { type: Boolean, default: false },
    spacing: { type: String, default: 'base', validator: (value) => availableSizes.includes(value) },
    strip: { type: Boolean, default: true },
  },
  computed: {
    stackClasses() {
      return {
        'o-stack': true,
        [`o-stack--${this.spacing}`]: this.spacing,
        'o-stack--strip': this.strip,
        'o-stack--center': this.center,
      };
    },
  },
};
</script>

<style lang="scss">
$_stack-spacing-map: $spacing-map;

.o-stack {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.o-stack--strip > * {
  margin-bottom: 0;
}

.o-stack--center {
  align-items: center;
}

/**
 * The stack spaces all elements equally by default.
 * This can be customised by specifying specific classes or tags here, such as:
 *
 * .o-stack > .v-label + .v-input { margin-top: rem-value(10) }
 *
 * which will space all inputs 10px away from labels when put in a stack, automatically.
 * Add to the styles here as necessary, for the application.
 */

@each $_size-name, $_size-value in $spacing-map {
  .o-stack--#{$_size-name} > * + * {
    margin-top: $_size-value;
  }
}
</style>
