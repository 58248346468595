import { OrderLine } from '~/types/order';
import { Product } from '~/types/products/products';
import { productVariantSku } from '~/helpers/models/product';

/**
 * @param {Product} product
 */
export function productTokensToPrice(product: Product): string {
  return product.tokens.toFixed(2);
}

/**
 * @param {Array<Product>} products
 */
export function productsTokensToPrice(products: Array<Product>): string {
  return products
    .reduce((accumulator: number, product: Product) => accumulator + product.tokens, 0)
    .toFixed(2);
}

/**
 * @param {Array<OrderLine>} orderLines
 */
export function orderLinesProductTokensToPrice(orderLines: Array<OrderLine>): string {
  return orderLines
    .reduce(
      (accumulator: number, orderLine: OrderLine) => accumulator + orderLine!.productVariant!.product!.tokens,
      0
    )
    .toFixed(2);
}

/**
 * @param {Array<Product>} products
 */
export function pinterestProducts(products: Array<Product>): any {
  return products.map((product: Product) => {
    return {
      product_name: product.name,
      product_id: productVariantSku(product),
      product_price: productTokensToPrice(product),
    };
  });
}

/**
 * @param {Array<OrderLine>} orderLines
 */
export function pinterestOrderLineProducts(orderLines: Array<OrderLine>): any {
  return orderLines.map((orderLine: OrderLine) => {
    return {
      product_name: orderLine!.productVariant!.product!.name,
      product_id: orderLine!.productVariant!.sku,
      product_price: productTokensToPrice(orderLine!.productVariant!.product!),
    };
  });
}
