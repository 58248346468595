import { Route } from 'vue-router';
import { getDateFromStringAsNumber } from '~/helpers/date-time';

export function isFlagSetForCampaign(route: Route, campaign: string): boolean {
  return route.query.campaign === campaign;
}

export function isFlagSetForQATestDate(route: Route, campaign: string): boolean {
  if (!isFlagSetForCampaign(route, campaign)) return false;
  return typeof route.query.QADate === 'string';
}

export function getQATestDate(route: Route): number {
  return getDateFromStringAsNumber(route.query.QADate as string);
}
