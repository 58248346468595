import { BasketLine } from '~/types/basket';
import { ProductVariant } from '~/types/products/products';
import {
  url as productVariantUrl,
  image as productVariantImage,
  name as productVariantName,
  tokens as productVariantTokens,
  outOfStock as productVariantOutOfStock,
  discountedValue as productDiscountedValue,
} from '~/helpers/models/productVariant';

export function productVariant(basketLine: BasketLine): ProductVariant | null {
  return basketLine.productVariant || null;
}

export function url(basketLine: BasketLine): string {
  return basketLine.productVariant ? productVariantUrl(basketLine.productVariant) : '';
}

export function image(basketLine: BasketLine): string | null {
  return basketLine.productVariant ? productVariantImage(basketLine.productVariant) : null;
}

export function name(basketLine: BasketLine): string | null {
  return basketLine.productVariant ? productVariantName(basketLine.productVariant) : null;
}

export function tokens(basketLine: BasketLine): number | null {
  return basketLine.productVariant ? productVariantTokens(basketLine.productVariant) : null;
}

export function outOfStock(basketLine: BasketLine): boolean {
  return basketLine.productVariant ? productVariantOutOfStock(basketLine.productVariant) : false;
}

export function discountedValue(basketLine: BasketLine): number | null {
  return basketLine.productVariant ? productDiscountedValue(basketLine.productVariant) : null;
}
