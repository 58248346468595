























































import { Component, Prop, Vue, namespace } from 'nuxt-property-decorator';
import { ScrollPosition as SP } from '~/store/scroll';
import BaseBanner from '~/components/partials/BaseBanner/BaseBanner.vue';
import mq from '~/helpers/screen';
import { TABLET } from '~/constants/screen-breakpoints';
import { ResizeState } from '~/store/resize';
import PaginatedCarousel from '~/components/partials/carousels/PaginatedCarousel/PaginatedCarousel.vue';
import SimpleCarousel from '~/components/partials/carousels/SimpleCarousel/SimpleCarousel.vue';
import BaseCarousel from '~/components/partials/carousels/BaseCarousel/BaseCarousel.vue';
import CarouselSlide from '~/components/partials/carousels/CarouselSlide/CarouselSlide.vue';

interface ScrollPosition extends SP {} // Without this, typescript throws a warning that it cannot find the export

const ScrollStore = namespace('scroll');
const ResizeStore = namespace('resize');

@Component({
  components: {
    BaseCarousel,
    SimpleCarousel,
    PaginatedCarousel,
    BaseBanner,
    CarouselSlide,
  },
})
export default class InfoBanner extends Vue {
  @ScrollStore.State('debounced') scrollDebounced!: ScrollPosition;
  @ResizeStore.State debounced!: ResizeState;

  @Prop({ default: '' }) readonly url!: string;
  @Prop({ default: 'success' }) readonly type!: string;
  @Prop({ default: 'Default' }) readonly gaLabel!: string;

  get scrolled(): boolean {
    return this.scrollDebounced.position > 0;
  }

  get isMobile(): boolean {
    if (!this.debounced.breakpoint) return false;

    return mq({ until: TABLET }, this.debounced.breakpoint);
  }
}
