var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 21 24",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c("title", [_vm._v("PDF available")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Page-1",
            stroke: "none",
            "stroke-width": "1",
            fill: "inherit",
            "fill-rule": "evenodd"
          }
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "noun_PDF_631167",
                transform: "translate(0.180000, 0.000000)",
                fill: "inherit",
                "fill-rule": "nonzero"
              }
            },
            [
              _c("path", {
                attrs: {
                  id: "Shape",
                  d:
                    "M0.451602112,12.7467683 C0.788511625,12.6895415 1.2616186,12.6464123 1.92814986,12.6464123 C2.60196889,12.6464123 3.08224415,12.7754414 3.40469762,13.0333803 C3.71293398,13.2771021 3.92081432,13.6784067 3.92081432,14.1513942 C3.92081432,14.6246207 3.76311199,15.0259253 3.47638049,15.2984397 C3.10362954,15.6496858 2.55167141,15.8075076 1.90652553,15.8075076 C1.76315978,15.8075076 1.63413061,15.8002198 1.53377458,15.7858833 L1.53377458,17.5133211 L0.451602112,17.5133211 L0.451602112,12.7467683 Z M1.53401352,14.9401448 C1.62720126,14.9617691 1.74189386,14.9686985 1.89959619,14.9686985 C2.48022747,14.9686985 2.83864185,14.6749182 2.83864185,14.1801869 C2.83864185,13.7358725 2.53040549,13.4707653 1.98561564,13.4707653 C1.76339872,13.4707653 1.61286469,13.4923897 1.53401352,13.5135361 L1.53401352,14.9401448 Z"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  id: "Shape",
                  d:
                    "M4.61637715,12.7467683 C5.01780125,12.6821342 5.54108623,12.6464123 6.0929249,12.6464123 C7.0104657,12.6464123 7.60543356,12.8111634 8.07149172,13.1624095 C8.57327185,13.5349215 8.88855702,14.1302478 8.88855702,14.9830351 C8.88855702,15.9075052 8.55164751,16.5454828 8.08570882,16.9398581 C7.57676041,17.3626676 6.80270483,17.5634991 5.85649088,17.5634991 C5.29019617,17.5634991 4.88877207,17.5276576 4.61649662,17.4916967 L4.61649662,12.7467683 L4.61637715,12.7467683 Z M5.71312513,16.6890875 C5.80631287,16.7105923 5.95684691,16.7105923 6.09304437,16.7105923 C7.08226805,16.7179996 7.72741392,16.1729708 7.72741392,15.0191154 C7.73458221,14.0157941 7.14678263,13.4851019 6.20773697,13.4851019 C5.9640152,13.4851019 5.80631287,13.5068457 5.71312513,13.5279922 L5.71312513,16.6890875 Z"
                }
              }),
              _vm._v(" "),
              _c("polygon", {
                attrs: {
                  id: "Path",
                  points:
                    "9.60550524 12.6821342 12.5586008 12.6821342 12.5586008 13.5781702 10.7021338 13.5781702 10.7021338 14.6820864 12.4367399 14.6820864 12.4367399 15.5708346 10.7021338 15.5708346 10.7021338 17.5133211 9.60538577 17.5133211"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  id: "Path",
                  d:
                    "M13.2828367,5.31074526 L16.764474,5.31074526 C15.7198155,3.84411364 13.6756589,1.52003536 12.5192946,0.50381114 L12.5192946,4.43454159 C12.5191752,4.91768417 12.8616998,5.31074526 13.2828367,5.31074526 Z"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  id: "icon-pdf",
                  d:
                    "M17.4189386,9.54971207 L17.4189386,6.55635469 L13.2828367,6.55635469 C12.2635062,6.55635469 11.4336575,5.60452558 11.4336575,4.43454159 L11.4336575,0 L1.3064204,0 C0.584812788,0 0,0.671071181 0,1.49924733 L0,6.11371293 L0.00274784354,6.11371293 L0.00274784354,10.5053643 L19.0438699,10.5053643 L19.0438699,19.4998925 L0.002867315,19.4998925 L0.002867315,21.7563499 C0.031540465,22.5551361 0.603211393,23.1929942 1.30653987,23.1929942 L16.1128766,23.1929942 C16.8343648,23.1929942 17.4191776,22.5216841 17.4191776,21.6937469 L17.4191776,20.4556641 L20,20.4556641 L20,9.54971207 L17.4189386,9.54971207 Z"
                }
              })
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }