var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "u-relative", class: "u-background-color-" + _vm.pageTheme },
    [
      _c("TheNotifications"),
      _vm._v(" "),
      _c("TheOverlay"),
      _vm._v(" "),
      _c("FiltersSideDrawer"),
      _vm._v(" "),
      _c("TheToyBasketContainer"),
      _vm._v(" "),
      !_vm.inMaintenanceMode ? _c("TheNavBar") : _vm._e(),
      _vm._v(" "),
      _c("nuxt", {
        attrs: { "keep-alive": "", "keep-alive-props": _vm.keepAliveProps }
      }),
      _vm._v(" "),
      !_vm.inMaintenanceMode ? _c("TheFooter") : _vm._e(),
      _vm._v(" "),
      _vm.displayFixedCTA
        ? _c(
            "TheFixedButtonCTA",
            { attrs: { tag: "nuxt-link", theme: "tertiary", to: "/register" } },
            [_vm._v("\n    Try Whirli\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("ZenDeskWrapper")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }