














import 'reflect-metadata';
import { mixins, Prop, Component } from 'nuxt-property-decorator';
import DiscIcon from '~/components/partials/icons/DiscIcon/DiscIcon.vue';
import TokensUsedMixin from '~/components/features/basket/TokensUsedMixin/TokensUsedMixin.vue';
import { NavTheme as NT } from '~/components/prefabs/navigation/interfaces';

interface NavTheme extends NT {} // Without this, typescript throws a warning that it cannot find the export

@Component({
  components: {
    DiscIcon,
  },
})
export default class NavBagPill extends mixins(TokensUsedMixin) {
  @Prop({ required: true }) readonly theme!: NavTheme;
  @Prop({ default: 0 }) readonly number!: number;
  @Prop({ default: false }) readonly active!: boolean;
  @Prop({ default: true }) readonly showMeta!: boolean;

  get pillClasses() {
    return {
      'v-nav-bag-pill': true,
      [`v-nav-bag-pill--${this.theme.bg}`]: this.theme.bg,
      [`v-nav-bag-pill--no-meta`]: !this.showMeta,
    };
  }
}
