
import { Vue, Prop, Component, Model, Watch } from 'nuxt-property-decorator';
import lottie, { AnimationItem, AnimationConfig } from 'lottie-web';

const defaultConfig: Partial<AnimationConfig> = {
  autoplay: false,
  loop: false,
  renderer: 'svg',
  rendererSettings: {
    progressiveLoad: true,
  },
};

@Component
export default class MixinRadioAndChecks extends Vue {
  loadAnimationData?: Function;

  @Prop({ default: '' }) readonly name!: string;

  @Model('change') checked!: boolean;

  @Watch('checked')
  checkInput() {
    this.check();
  }

  anim: AnimationItem | null = null;
  loaded = false;

  mounted() {
    this.$nextTick(() => this.loadAnimation());
  }

  destroyed() {
    if (!this.anim) return;
    this.anim.stop();
    this.anim.destroy();
    this.anim = null;
  }

  async loadAnimation() {
    if (!this.$refs.renderElement) {
      this.$logger.warn('No render element defined for a radio or checkbox');
      return;
    }
    if (!this.loadAnimationData) {
      this.$logger.warn('No animation data defined for a radio or checkbox');
      return;
    }

    const config: AnimationConfig = Object.assign({}, defaultConfig, {
      container: this.$refs.renderElement as Element,
      animationData: JSON.parse(JSON.stringify(await this.loadAnimationData())),
    });

    this.anim = lottie.loadAnimation(config);
    this.check(true);
    this.loaded = true;
    this.$emit('animation-loaded', this.anim);
  }

  check(initial: boolean = false) {
    if (!this.anim) return;
    if (initial) {
      if (this.checked) this.anim.goToAndStop(100); // For some reason using the duration doesn't work - 100 shows last frame, so maybe this is bugged and in %?
      return;
    }
    this.anim.play();
    this.anim.setDirection(this.checked ? 1 : -1);
  }
}
