import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '~/store';
import {
  ComparativeSubscription,
  ComparativeSubscriptions,
  SubscriptionFormRequestBody,
  PreviewChangePlanResource,
} from '~/types/subscription';

const types = {
  PUT_USER_CURRENT_SUBSCRIPTION: 'PUT_USER_CURRENT_SUBSCRIPTION',
  PUT_USER_NEW_SUBSCRIPTION: 'PUT_USER_NEW_SUBSCRIPTION',
  PUT_CHARGE_NOW_AMOUNT: 'PUT_CHARGE_NOW_AMOUNT',
  PUT_NEXT_BILLING_DATE: 'PUT_NEXT_BILLING_DATE',
  PUT_HAS_CHANGED_TERM: 'PUT_HAS_CHANGED_TERM',
  PUT_PREVIEW_CHANGE_ARGS: 'PUT_PREVIEW_CHANGE_ARGS',
  PUT_PREVIEW_CHANGE_RESOURCE: 'PUT_PREVIEW_CHANGE_RESOURCE',
};

export interface ModuleState {
  currentSubscription: ComparativeSubscription;
  newSubscription: ComparativeSubscription;
  chargeNowAmount: string | null;
  nextBillingDate: string | null;
  hasChangedTerm: boolean | null;
  previewChangeArgs: SubscriptionFormRequestBody | null;
  previewChangeResource: PreviewChangePlanResource | null;
}

const defaults: ModuleState = {
  currentSubscription: {} as ComparativeSubscription,
  newSubscription: {} as ComparativeSubscription,
  chargeNowAmount: null,
  nextBillingDate: null,
  hasChangedTerm: true,
  previewChangeArgs: null,
  previewChangeResource: null,
};

export const state = (): ModuleState => ({
  currentSubscription: defaults.currentSubscription,
  newSubscription: defaults.newSubscription,
  chargeNowAmount: defaults.chargeNowAmount,
  nextBillingDate: defaults.nextBillingDate,
  hasChangedTerm: defaults.hasChangedTerm,
  previewChangeArgs: defaults.previewChangeArgs,
  previewChangeResource: defaults.previewChangeResource,
});

export const actions: ActionTree<ModuleState, RootState> = {
  putUserComparativeSubscriptions({ dispatch }, subscriptions: ComparativeSubscriptions) {
    dispatch('putUserCurrentSubscription', subscriptions.currentSubscription);
    dispatch('putUserNewSubscription', subscriptions.newSubscription);
    dispatch('putChargeNowAmount', subscriptions.chargeNowAmount);
    dispatch('putNextBillingDate', subscriptions.nextBillingDate);
    dispatch('putHasChangedTerm', subscriptions.hasChangedTerm);
  },
  resetUserComparativeSubscriptions({ dispatch }) {
    dispatch('putUserComparativeSubscriptions', defaults);
  },
  putUserCurrentSubscription({ commit }, currentSubscription: ComparativeSubscription) {
    commit(types.PUT_USER_CURRENT_SUBSCRIPTION, currentSubscription);
  },
  putUserNewSubscription({ commit }, newSubscription: ComparativeSubscription) {
    commit(types.PUT_USER_NEW_SUBSCRIPTION, newSubscription);
  },
  putChargeNowAmount({ commit }, chargeNowAmount: string) {
    commit(types.PUT_CHARGE_NOW_AMOUNT, chargeNowAmount);
  },
  putNextBillingDate({ commit }, nextBillingDate: string) {
    commit(types.PUT_NEXT_BILLING_DATE, nextBillingDate);
  },
  putHasChangedTerm({ commit }, hasChangedTerm: boolean) {
    commit(types.PUT_HAS_CHANGED_TERM, hasChangedTerm);
  },
};

export const mutations: MutationTree<ModuleState> = {
  [types.PUT_USER_CURRENT_SUBSCRIPTION](state: ModuleState, curentSubscription: ComparativeSubscription) {
    state.currentSubscription = curentSubscription;
  },
  [types.PUT_USER_NEW_SUBSCRIPTION](state: ModuleState, newSubscription: ComparativeSubscription) {
    state.newSubscription = newSubscription;
  },
  [types.PUT_CHARGE_NOW_AMOUNT](state: ModuleState, chargeNowAmount: string) {
    state.chargeNowAmount = chargeNowAmount;
  },
  [types.PUT_NEXT_BILLING_DATE](state: ModuleState, nextBillingDate: string) {
    state.nextBillingDate = nextBillingDate;
  },
  [types.PUT_HAS_CHANGED_TERM](state: ModuleState, hasChangedTerm: boolean) {
    state.hasChangedTerm = hasChangedTerm;
  },
  [types.PUT_PREVIEW_CHANGE_ARGS](state, args: SubscriptionFormRequestBody | null) {
    state.previewChangeArgs = args;
  },
  [types.PUT_PREVIEW_CHANGE_RESOURCE](state, args: PreviewChangePlanResource | null) {
    state.previewChangeResource = args;
  },
};
