










































import 'reflect-metadata';
import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { themeMap, Theme, ThemeParts } from '~/helpers/colors';
import { hasOwnProp } from '~/helpers/objects';

@Component
export default class ThePageHeader extends Vue {
  @Prop({ default: 'primary' }) readonly theme!: string;

  fallbackTheme: Theme = themeMap.primary;

  themeColor(part: ThemeParts) {
    const theme = themeMap[this.theme] || this.fallbackTheme;

    if (part === 'tinyForeground' && !hasOwnProp(theme, 'tinyForeground')) {
      part = 'foreground';
    }

    return theme[part] || this.theme;
  }
}
