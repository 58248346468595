var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseAccordion",
    _vm._b(
      {
        staticClass: "v-flat-accordion",
        class: [_vm.classes, _vm.$attrs.class],
        attrs: { "arrow-theme": _vm.arrowTheme },
        on: { "toggle-accordion": _vm.toggleAccordion }
      },
      "BaseAccordion",
      [_vm.$attrs, _vm.$props],
      false
    ),
    [
      _c(
        "div",
        {
          staticClass: "v-flat-accordion__header",
          attrs: { slot: "base-accordion-header" },
          slot: "base-accordion-header"
        },
        [_vm._t("header")],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "v-flat-accordion__body",
          attrs: { slot: "base-accordion-body" },
          slot: "base-accordion-body"
        },
        [_vm._t("body")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }