import { productsTokensToPrice } from '../transformers/product';
import Events from './Events';
import { Product, ProductVariant } from '~/types/products/products';
import { productVariantSku } from '~/helpers/models/product';

export default class AdwordsEvents extends Events {
  /**
   * @param {Product} product
   * @param {ProductVariant} variant
   */
  addToCart(product: Product, variant: ProductVariant) {
    this.data.push({
      remarketing_items: null,
    });
    this.data.pushEvent('add_to_cart', {
      remarketing_value: product.tokens.toFixed(2),
      remarketing_items: [
        {
          id: variant.sku,
          google_business_vertical: 'retail',
        },
      ],
    });
  }

  /**
   * @param {Product} product
   * @param {ProductVariant} variant
   */
  viewItem(product: Product, variant: ProductVariant) {
    this.data.push({
      remarketing_items: null,
    });
    this.data.pushEvent('view_item', {
      remarketing_value: product.tokens.toFixed(2),
      remarketing_items: [
        {
          id: variant.sku,
          google_business_vertical: 'retail',
        },
      ],
    });
  }

  /**
   * @param {Array} products
   */
  viewItems(products: Array<Product>) {
    this.data.push({
      remarketing_items: null,
    });
    this.data.pushEvent('view_item_list', {
      remarketing_value: productsTokensToPrice(products),
      remarketing_items: products.map((product: Product) => {
        return {
          id: productVariantSku(product),
          google_business_vertical: 'retail',
        };
      }),
    });
  }
}
