import { ActionTree, MutationTree, GetterTree } from 'vuex';
import { RootState } from '~/store/index';
import { SelectedAssociations, SelectedBucket } from '~/store/products/associations';
import { SortOption } from '~/types/products/sort';
import { Range } from '~/types/products/range';

const types = {
  SET_FILTERS: 'SET_FILTERS',
};

export interface FiltersTypes {
  associations?: SelectedAssociations;
  sort?: SortOption;
  search?: string;
  perPage?: number;
  page?: number;
  hidePrevious?: boolean;
  hideNotInStock?: boolean;
  range?: Range;
}

interface SearchTerm {
  search: string;
}
export interface SelectedFilter extends Partial<SelectedBucket>, Partial<Range>, Partial<SearchTerm> {}

export interface ModuleState {
  current: FiltersTypes;
}

export const state = (): ModuleState => ({
  current: {},
});

export const getters: GetterTree<ModuleState, RootState> = {
  /**
   * The total number of selected associations, range and search terms.
   */
  totalSelected: (_state, getters): number => {
    return getters.selectedFilters ? getters.selectedFilters.length : 0;
  },

  /**
   * Used for displaying the selected filters.
   * Uses the associations, range and search terms.
   */
  selectedFilters: (_state, _getters, rootState): Array<SelectedFilter> => {
    const selected: Array<SelectedFilter> = [];
    if (Object.keys(rootState.products.associations.selected).length) {
      Object.values(rootState.products.associations.selected)
        .flat()
        .forEach((bucket: SelectedBucket) => {
          selected.push(bucket);
        });
    }
    if (rootState.products.range && rootState.products.range.type) {
      selected.push(rootState.products.range);
    }
    if (rootState.products.search && rootState.products.search.term) {
      selected.push({ search: rootState.products.search.term });
    }
    return selected;
  },
};

export const actions: ActionTree<ModuleState, RootState> = {
  async refreshFilters({ dispatch, rootState }, resetCurrentPage: boolean = false) {
    this.$logger.store(`action`, `[products/filters/refreshFilters]`, { resetCurrentPage });

    const filterState = {
      associations: { ...rootState.products.associations.selected },
      sort: rootState.products.sort.selected,
      search: rootState.products.search.term,
      perPage: rootState.products.pagination.perPage,
      page: resetCurrentPage ? 1 : rootState.products.pagination.currentPage,
      hidePrevious: rootState.products.hidePrevious,
      hideNotInStock: rootState.products.hideNotInStock,
    };
    if (rootState.products.range.type && rootState.products.range.type) {
      Object.assign(filterState, { range: rootState.products.range });
    }
    await dispatch('setCurrentFilter', { filter: filterState });
    return filterState;
  },

  setCurrentFilter({ commit }, { filter }: { filter: any }) {
    this.$logger.store(`action`, `[products/filters/setCurrentFilter]`, { filter });

    commit(types.SET_FILTERS, filter);
  },

  clearFilters({ commit }) {
    this.$logger.store(`action`, `[products/filters/clearFilters]`);

    commit(types.SET_FILTERS, {});
  },
};

export const mutations: MutationTree<ModuleState> = {
  [types.SET_FILTERS](state, filter: any) {
    state.current = filter;
  },
};
