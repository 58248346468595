












































import { Vue, Prop, Component, Watch } from 'nuxt-property-decorator';
import BaseCarousel from '../BaseCarousel/BaseCarousel.vue';
import { SlidesPerView } from '~/types/carousels.ts';
import ButtonIcon from '~/components/partials/icons/ButtonIcon/ButtonIcon.vue';
import mq from '~/helpers/screen';
import { TABLET, SMALL_DESK } from '~/constants/screen-breakpoints';

@Component({
  components: {
    BaseCarousel,
    ButtonIcon,
  },
})
export default class SimpleCarousel extends Vue {
  @Prop({ default: true }) readonly loop!: boolean;
  @Prop({ default: null }) readonly loopedSlides!: number;
  @Prop({ required: true }) readonly name!: string;
  @Prop({ default: '' }) readonly navigationText!: string;
  @Prop({ default: false }) readonly overflowVisible!: boolean;
  @Prop({ default: () => ({ mobile: 1, tablet: 2, desk: 4 }) }) readonly slidesPerView!: SlidesPerView;
  @Prop({ default: 0 }) readonly initialSlide!: number;
  @Prop({ default: false }) readonly centeredSlides!: boolean;

  componentKey: number = 0;

  // Forced re-render is required for carousel to display correctly at different screen sizes https://michaelnthiessen.com/force-re-render/
  @Watch('currentScreenSize')
  forceRerender() {
    this.componentKey += 1;
  }

  get options(): object {
    // @TODO - Figure out why the number of loopedSlides is required for the carousel to loop properly
    if (this.loopedSlides) {
      return {
        loopedSlides: this.loopedSlides,
      };
    } else {
      return {};
    }
  }

  get currentScreenSize(): string {
    return this.$store.state.resize.debounced.breakpoint;
  }

  get isMobile(): boolean {
    return mq({ until: TABLET }, this.currentScreenSize);
  }

  get isDesk(): boolean {
    return mq({ from: SMALL_DESK }, this.currentScreenSize);
  }

  get navBtnSize(): string {
    return this.isMobile ? 'tiny' : 'base';
  }
}
