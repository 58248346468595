import BaseExperiment, { ExperimentVariant } from '../BaseExperiment';
import { hasActiveSubscription } from '~/helpers/models/user';

export default class ChildProfileGetStartedExperiment extends BaseExperiment {
  protected experimentId: string = process.env.childProfileGetStartedExperimentId
    ? process.env.childProfileGetStartedExperimentId
    : '';

  protected variants: ExperimentVariant[] = [
    {
      id: 0,
      percentage: 50,
    },
    {
      id: 1,
      percentage: 50,
    },
  ];

  public setup(): void {}

  public beforeRouteEnter(): void {
    if (this.isToysPage() && hasActiveSubscription(this.app.$auth.user)) {
      this.generateAndPushExperiment();
      super.saveAndShip();
    }
  }

  protected isToysPage(): string | boolean | null | undefined {
    return this.route.name && this.route.name.startsWith('toys-all');
  }
}
