
































import 'reflect-metadata';
import { Prop, Component, mixins } from 'nuxt-property-decorator';
import iconMixin from '../iconMixin.vue';
import icons, { IconName as IN } from '../icons';
import buttonMixin from '~/components/partials/buttons/buttonMixin.vue';
import TheBagIcon from '~/components/partials/icons/TheBagIcon/TheBagIcon.vue';
import DiscIcon from '~/components/partials/icons/DiscIcon/DiscIcon.vue';

type IconName = IN; // Without this, typescript throws a warning that it cannot find the export

export const availableSizes: Array<string> = [
  'teeny',
  'tiny',
  'small',
  'base',
  'medium',
  'intermediate',
  'big',
  'large-alt',
];

@Component({
  inheritAttrs: false,
  components: {
    TheBagIcon,
    DiscIcon,
  },
})
export default class ButtonIcon extends mixins(iconMixin, buttonMixin) {
  @Prop({ default: false })
  readonly square!: boolean;

  @Prop({ type: String, required: true, validator: (value) => Object.keys(icons).includes(value) })
  readonly icon!: IconName;

  @Prop({ default: 'small', validator: (value) => availableSizes.includes(value) })
  readonly size!: string;

  get graphic() {
    return this.loading && this.disc ? 'LoadingSpinner' : this.icon;
  }

  get outerClasses() {
    return {
      [`v-btn-icon--${this.size}`]: this.size,
      [`v-btn-icon--${this.theme}`]: this.theme,
      'v-btn-icon--focus': this.focus,
      'v-btn-icon--disabled': this.disabled,
      'v-btn-icon--disc': this.disc,
      'v-btn-icon--busy': this.loading,
      'v-btn-icon--square': this.square,
    };
  }
}
