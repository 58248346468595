import { Route } from 'vue-router';
import { GlobalInjects } from '~/index.d';
import { EXPERIMENTS_COOKIE_STR } from '~/plugins/experiment/BaseExperiment';
import ChildProfileGetStartedExperiment from '~/plugins/experiment/experiments/ChildProfileGetStartedExperiment';
import FirstRegistrationStepExperiment from '~/plugins/experiment/experiments/FirstRegistrationStepExperiment';

export interface ExperimentsInterface {
  setDataString(app: GlobalInjects): void;
  getDataString(): string;
  updateDataString(data: string): string;
  pushDataString(data: string): string;
  childProfileGetStartedExperiment: ChildProfileGetStartedExperiment;
  firstRegistrationStepExperiment: FirstRegistrationStepExperiment;
}
export default class Experiments implements ExperimentsInterface {
  protected experimentsData: string = '';
  protected experiments: Array<string> = [
    'childProfileGetStartedExperiment',
    'firstRegistrationStepExperiment',
  ];

  public childProfileGetStartedExperiment: ChildProfileGetStartedExperiment;
  public firstRegistrationStepExperiment: FirstRegistrationStepExperiment;

  constructor(app: GlobalInjects, route: any, store: any) {
    this.setDataString(app);
    this.childProfileGetStartedExperiment = new ChildProfileGetStartedExperiment(app, route, store);
    this.firstRegistrationStepExperiment = new FirstRegistrationStepExperiment(app, route, store);
  }

  public setup(): void {
    this.experiments.map((experiment: string) => (this as any)[experiment].setup());
  }

  public setRoute(to: Route): void {
    this.experiments.map((experiment: string) => (this as any)[experiment].setRoute(to));
  }

  public beforeRouteEnter(): void {
    this.experiments.map((experiment: string) => (this as any)[experiment].beforeRouteEnter());
  }

  public afterRouteEnter(): void {
    this.experiments.map((experiment: string) => (this as any)[experiment].afterRouteEnter());
  }

  public setDataString(app: GlobalInjects): void {
    const experimentDataCookie = app.$cookies.get(EXPERIMENTS_COOKIE_STR);
    this.experimentsData = typeof experimentDataCookie === 'undefined' ? '' : experimentDataCookie;
  }

  public getDataString(): string {
    return this.experimentsData;
  }

  public updateDataString(data: string): string {
    return (this.experimentsData = data);
  }

  public pushDataString(data: string): string {
    if (this.experimentsData !== '') {
      this.experimentsData += '!';
    }
    this.experimentsData += data;
    return this.getDataString();
  }
}
