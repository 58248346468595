import Events from './Events';

export default class AwinEvents extends Events {
  /**
   * @param {String} id
   * @param {String} value
   * @param {String} discountCode
   */
  purchase(id: string = '', value: string = '', discountCode: string = ''): void {
    this.data.pushEvent('awin.dl.ready', {
      transactionID: id,
      transactionTotal: value,
      transactionCurrency: 'GBP',
      transactionPromoCode: discountCode || '',
    });
  }
}
