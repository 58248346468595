























import { Prop, Component, mixins } from 'nuxt-property-decorator';
import buttonMixin from '../buttonMixin.vue';
import { themeMap } from '~/helpers/colors';

import { availableTypes } from '~/helpers/buttons';

/**
 * ### **Features:**
 * - Themes - primary, secondary, tertiary, quaternary, quinary, white, charcoal, light-grey-one
 * - Types  - block, outline, line, action, fake-link, fake-link-plain
 * - States - disabled, loading, active
 * - Tag    - button, a, nuxt-link
 *
 * ### **Important attributes:**
 * `aria-label` - Add an aria-label when button content doesn't describe it, such as when icon-only. Read as `"click {label}"`
 * `aria-role` - When not using `<button>` tag, add `aria-role="button"` if the element will do something in response to user input (such as when using an a tag to display a modal, rather than to navigate).
 *
 * ### **Other notes:**
 * Block and outline styles - the width is set to 100% so that the parent can decide the width of the button.
 */
@Component
export default class BaseButton extends mixins(buttonMixin) {
  @Prop({ default: 'block', validator: (value) => availableTypes.includes(value) }) readonly type!: string;
  @Prop({ default: false }) readonly wide!: boolean;
  @Prop({ default: false }) readonly block!: boolean;

  themeMap = themeMap;

  get contentClasses() {
    return {
      'u-margin-left-micro': !!this.$slots['left-content'],
      'u-text--small-body': this.type === 'action' || this.type === 'fake-link',
      'u-text--button':
        this.type !== 'action' && this.type !== 'fake-link' && this.type !== 'fake-link-plain',
    };
  }

  get additionalButtonClasses() {
    return {
      [`v-btn--${this.type}`]: this.type,
      'v-btn--has-left-content': !!this.$slots['left-content'],
      'v-btn--wide': this.wide,
      'v-btn--display-block': this.block,
    };
  }

  get loadingSpinnerColor(): string | null {
    if (this.type === 'outline' || this.type === 'line') return this.theme;
    if (this.type === 'action') return 'secondary';

    return themeMap[this.theme] ? themeMap[this.theme].foreground : null;
  }

  get loadingSpinnerSize(): string {
    switch (this.type) {
      case 'block':
        return 'small';
      case 'action':
        return 'teeny';
      default:
        return 'base';
    }
  }
}
