import { hasActiveSubscription } from '~/helpers/models/user';
import { redirectWithoutCaching } from '~/helpers/redirect';

export default function(context) {
  const { app, route } = context;

  if (app.$auth.user && !hasActiveSubscription(app.$auth.user)) {
    return redirectWithoutCaching(context, '/register');
  } else if (!app.$auth.user) {
    return redirectWithoutCaching(context, `/login?redirect=${route.name}`);
  }
}
