import { Product, Asset } from '~/types/products/products';
import { AssetKind } from '~/constants/assets';

export function ProductAssetsByKind(product: Product, kind: keyof typeof AssetKind): Asset[] {
  return product.assets.filter((asset: Asset) => asset.kind === kind) || [];
}

export function assetsByKind(assets: Asset[], kind: keyof typeof AssetKind): Asset[] {
  return assets.filter((asset: Asset) => asset.kind === kind) || [];
}

export function ProductAssetsKindByCategory(
  product: Product,
  kind: keyof typeof AssetKind,
  category: number
): Asset[] {
  const assets: Asset[] = ProductAssetsByKind(product, kind);
  return assets.filter((asset: Asset) => asset?.category === category) || [];
}
