import { Plugin } from '@nuxt/types';
import { Route } from 'vue-router';
import Experiments from '~/plugins/experiment/Experiments';

const plugin: Plugin = function({ app, route, store }, inject): void {
  const experiments: Experiments = new Experiments(app, route, store);

  inject('experiments', experiments);

  experiments.setup();

  if (app.router) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    app.router.beforeEach((to: Route, from: Route, next) => {
      experiments.setRoute(to);
      experiments.beforeRouteEnter();
      next();
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    app.router.afterEach((to: Route) => {
      experiments.afterRouteEnter();
    });
  }
};

export default plugin;
