








import 'reflect-metadata';
import { Component, Prop, mixins } from 'nuxt-property-decorator';
import DrawersMixin from '../drawersMixin.vue';

@Component
export default class BaseSideDrawer extends mixins(DrawersMixin) {
  @Prop({ default: false }) readonly isOpen!: boolean;
}
