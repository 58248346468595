// https://github.com/whirli/whirli-api/blob/master/app/Constants/UserSubscriptionStatus.php
const NOT_ACTIVE = 0;
const ACTIVE = 1;
const PENDING_CANCEL = 2;
const CANCELLED = 3;
const SUSPENDED = 4;
const OVERDUE = 5;

const userSubscriptionStatus = {
  0: NOT_ACTIVE,
  1: ACTIVE,
  2: PENDING_CANCEL,
  3: CANCELLED,
  4: SUSPENDED,
  5: OVERDUE,
};

export { NOT_ACTIVE, ACTIVE, PENDING_CANCEL, CANCELLED, SUSPENDED, OVERDUE, userSubscriptionStatus };
