var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "div",
    _vm._b(
      {
        staticClass: "v-wrapper",
        class: [
          _vm.data.staticClass,
          _vm.data.class,
          {
            "v-wrapper--overflow-hidden": _vm.props.overflowHidden
          }
        ]
      },
      "div",
      _vm.data.attrs,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }