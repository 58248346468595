















import 'reflect-metadata';
import { Vue, Prop, Component } from 'nuxt-property-decorator';
import mq from '~/helpers/screen';
import { TABLET } from '~/constants/screen-breakpoints';

const availableThemes: Array<string> = ['secondary', 'tertiary', 'white'];

@Component
export default class TheHamburger extends Vue {
  @Prop({ default: 'secondary', validator: (value) => availableThemes.includes(value) })
  readonly theme!: string;

  @Prop({ default: false }) readonly active!: boolean;

  get classes() {
    return {
      'v-hamburger--active': this.active,
      [`v-hamburger--${this.theme}`]: this.theme,
    };
  }

  get iconSize() {
    const currentScreenSize: string = this.$store.state.resize.debounced.breakpoint;
    return mq({ from: TABLET }, currentScreenSize) ? 'medium' : 'base';
  }
}
