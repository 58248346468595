import { Plugin } from '@nuxt/types';
import { SentryLogger } from './loggers/sentry';
import AppLogger from './logger';

const plugin: Plugin = function({ app }, inject): void {
  const debugging = process.env.debugLevel === 'debug';
  const sentry = process.env.NODE_ENV !== 'development' ? new SentryLogger(app.$sentry) : null;

  inject('logger', new AppLogger(debugging, sentry));
};

export default plugin;
