var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "u-fade-in" } }, [
    _vm.visible
      ? _c(
          "div",
          {
            directives: [
              {
                name: "on-clickaway",
                rawName: "v-on-clickaway",
                value: _vm.clickedAway,
                expression: "clickedAway"
              }
            ],
            staticClass: "v-drop-down",
            class: _vm.classes
          },
          [
            _c("div", { staticClass: "v-drop-down__caret" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "v-drop-down__content" },
              [_vm._t("default")],
              2
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }