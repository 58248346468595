
import { Vue, Component, Prop } from 'nuxt-property-decorator';

@Component
export default class CanShowWhirliLogo extends Vue {
  @Prop({ default: '#FFF24F' }) readonly background!: string;
  @Prop({ default: '#3A0E7B' }) readonly foreground!: string;

  get foregroundStyles() {
    return {
      fill: this.foreground,
    };
  }

  get backgroundStyles() {
    return {
      fill: this.background,
    };
  }
}
