var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseList",
    { staticClass: "v-payment-methods", attrs: { bare: true } },
    _vm._l(_vm.paymentMethods, function(method, index) {
      return _c(
        "BaseListItem",
        { key: index, staticClass: "v-payment-methods__method" },
        [
          _c(
            "BaseImage",
            _vm._b(
              {
                staticClass: "v-payment-methods__logo",
                attrs: {
                  image: method.image,
                  alt: method.alt,
                  title: "Accepted payment methods",
                  "object-fit": "cover",
                  height: "28",
                  width: "44"
                }
              },
              "BaseImage",
              _vm.$attrs,
              false
            )
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }