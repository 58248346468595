/**
 * Full list of Zendesk Widget options here:
 * https://developer.zendesk.com/embeddables/docs/widget/chat
 */

let zendeskState = 'hidden';

function isZenDeskDisabled() {
  return !window || !window.$nuxt?.$zendesk?.zE?.t;
}

export default {
  toggleLauncher: (show: boolean) => {
    if (isZenDeskDisabled()) return;
    const toggle = show ? 'show' : 'hide';
    window.zE('messenger', toggle);
    zendeskState = show ? 'open' : 'hidden';
  },
  open: () => {
    if (isZenDeskDisabled() || zendeskState === 'open') return;
    window.zE('messenger', 'open', {
      mode: 'modal',
    });
    zendeskState = 'open';
  },
  close: () => {
    if (isZenDeskDisabled() || zendeskState === 'hidden') return;
    window.zE('messenger', 'close');
    zendeskState = 'hidden';
  },
  authUser: () => {},
  logout: () => {
    if (isZenDeskDisabled()) return;
    window.zE('messenger', 'logout');
  },
  isOpen: () => {
    if (isZenDeskDisabled()) return;
    return zendeskState === 'open';
  },
  isHidden: () => {
    if (isZenDeskDisabled()) return;
    return zendeskState === 'hidden';
  },
};
