











import 'reflect-metadata';
import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { colorFromTheme } from '~/helpers/colors';

const availableColors = ['primary', 'secondary', 'white', 'charcoal', 'quinary-darker'];

@Component
export default class FetchRetry extends Vue {
  @Prop({ default: 'secondary', validator: (value) => availableColors.includes(value) })
  readonly color!: string;

  @Prop({ default: null }) readonly error!: Error | null;
  @Prop({ default: false }) readonly hideRetry!: boolean;

  get textColor() {
    return { color: colorFromTheme(this.color) };
  }
}
