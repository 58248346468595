var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "div",
    _vm._b(
      {
        staticClass: "v-layout",
        class: [
          _vm.data.staticClass,
          _vm.data.class,
          {
            "v-layout--auto": _vm.props.autoWidth,
            "v-layout--full-width": _vm.props.fullWidth,
            "v-layout--reverse": _vm.props.reverse,
            "v-layout--reverse@smallDesk": _vm.props.reverseFromSmallDesk,
            "v-layout--between@smallDesk": _vm.props.spaceBetweenFromSmallDesk
          }
        ],
        attrs: {
          "data-align": _vm.props.align,
          "data-direction": _vm.props.direction,
          "data-fill-direction": _vm.props.fillDirection,
          "data-gutter": _vm.props.gutter
        }
      },
      "div",
      _vm.data.attrs,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }