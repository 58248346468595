import { colorFromTheme } from '~/helpers/colors';

export const baseStyle = {
  base: {
    color: colorFromTheme('secondary'),
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    fontFamily: 'Maax, Roboto, Helvetica, Arial, sans-serif',
    iconColor: colorFromTheme('white'),
    letterSpacing: '2px',
    '::placeholder': {
      color: colorFromTheme('secondary'),
      fontStyle: 'italic',
    },
  },
  invalid: {
    color: colorFromTheme('quinary-darker'),
    iconColor: colorFromTheme('quinary-darker'),
  },
};
