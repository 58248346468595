export const AssetKind: Record<string, string> = {
  IMAGE: 'image',
  DOCUMENT: 'document',
};

export const AssetDocumentCategory: Record<string, number> = {
  INSTRUCTIONS: 1,
  SAFETY_WARNING: 2,
  SAFETY_CERTIFICATE: 3,
  OTHER: 4,
};
