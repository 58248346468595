var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseSection",
    {
      staticClass: "v-page-header",
      attrs: {
        tag: "section",
        size: "intermediate",
        theme: _vm.themeColor("background")
      }
    },
    [
      _c("BaseWrapper", [
        _c(
          "div",
          { staticClass: "v-page-header__content" },
          [
            _vm.$slots.breadcrumb
              ? _c(
                  "div",
                  { staticClass: "v-page-header__breadcrumb" },
                  [_vm._t("breadcrumb")],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "BaseText",
              {
                staticClass: "v-page-header__title",
                attrs: {
                  tag: "h1",
                  size: "large-header",
                  color: _vm.themeColor("foreground"),
                  center: true
                }
              },
              [_vm._t("title")],
              2
            ),
            _vm._v(" "),
            _vm._t("alt-title"),
            _vm._v(" "),
            _vm.$slots.subtitle
              ? _c(
                  "BaseText",
                  {
                    staticClass: "v-page-header__subtitle",
                    attrs: {
                      color: _vm.themeColor("tinyForeground"),
                      center: true
                    }
                  },
                  [_vm._t("subtitle")],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._t("alt-subtitle"),
            _vm._v(" "),
            _vm.$slots.subfooter
              ? _c(
                  "BaseText",
                  {
                    staticClass: "v-page-header__subfooter",
                    attrs: {
                      color: _vm.themeColor("tinyForeground"),
                      center: true
                    }
                  },
                  [_vm._t("subfooter")],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._t("default")
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }