import dayjs, { Dayjs } from 'dayjs';
import { ACTIVE, NOT_ACTIVE } from '~/constants/user-subscription-statuses';
import { CancelSubscriptionStatus, User, UserSubscription } from '~/types/user';
import { CALENDAR_MONTHS } from '~/constants/calendar';
import { getSubscriptionPeriod, getDifferenceInDays } from '~/helpers/subscriptions';
import { DATE_FORMAT_DEFAULT, DATE_FORMAT_TEXT } from '~/constants/date-time';
import { DateFormat } from '~/types/date-time';
import {
  FORMAT_SHORT,
  HALF_YEARLY,
  HALF_YEARLY_LONG,
  HALF_YEARLY_NUMBER,
  MONTHLY,
  MONTHLY_LONG,
  YEARLY,
  YEARLY_LONG,
  YEARLY_NUMBER,
  MONTHLY_NUMBER,
  THREE_MONTHS_NUMBER,
  THREE_MONTHS,
  THREE_MONTHS_LONG,
} from '~/constants/subscription-intervals';
import {
  Addon,
  MergedAddonAndPricingPlan,
  Subscription,
  SubscriptionPricingPlan,
  SubscriptionPricingPlanWithSubscription,
  TypeSubscriptionIntervalFormat,
  TypeSubscriptionIntervalLength,
  TypeSubscriptionIntervalLengthDescriptive,
  TypeSubscriptionIntervalLengthShorthand,
} from '~/types/subscription';

// Formula: tokensInBasket + tokensInToybox - tokensDesignatedForRtrn
export function tokensUsed({
  tokensInBasket = 0,
  tokensInToybox = 0,
  tokensDesignatedForRtrn = 0,
}: { tokensInBasket?: number; tokensInToybox?: number; tokensDesignatedForRtrn?: number } = {}): number {
  return tokensInBasket + tokensInToybox - tokensDesignatedForRtrn;
}

/**
 * availableTokens from the API is calculated with subscriptionTokens - (all tokens in toybox - tokens designated for return).
 * This returns the number of tokens used in toybox minus tokens designated for return.
 */
export function tokensInToybox(user: Partial<User>): number {
  if (!user || !user.subscriptionTokens || user.availableTokens === undefined) return 0;
  return user.subscriptionTokens - user.availableTokens;
}

export function hasActiveSubscription(user: Partial<User>): boolean {
  if (!user || !user.activeUserSubscriptions || !user.activeUserSubscriptions.length) return false;
  return user.activeUserSubscriptions[0].statusId === ACTIVE;
}

export function hasSubscriptionWithNotActiveStatus(user: Partial<User>): boolean {
  if (!user || !user.userSubscriptions || !user.userSubscriptions.length) return false;
  return user.userSubscriptions[0].statusId === NOT_ACTIVE;
}

export function userSubscription(user: Partial<User>): UserSubscription | null {
  if (!user?.activeUserSubscriptions?.length) return null;
  return user.activeUserSubscriptions[user.activeUserSubscriptions.length - 1];
}

export function notStartedSubscription(user: Partial<User>): UserSubscription | null {
  if (!user || !user.userSubscriptions || !user.userSubscriptions.length) return null;

  return (
    user?.userSubscriptions?.find(
      (userSubscription: UserSubscription) => userSubscription.statusId === NOT_ACTIVE
    ) || null
  );
}

export function subscriptionTokens(user: Partial<User>): number {
  return user && user.subscriptionTokens ? user.subscriptionTokens : 0;
}

export function availableTokens(user: Partial<User>): number {
  return user && user.availableTokens ? user.availableTokens : 0;
}

export function remainingTokens(user: Partial<User>, totalTokensUsed: number): number {
  if (!user || !user.subscriptionTokens) return 0;
  return user.subscriptionTokens - totalTokensUsed;
}

export function tokensOver(remainingTokens: number): number {
  return remainingTokens < 0 ? Math.abs(remainingTokens) : 0;
}

/**
 * @param firstname {string}
 * @param lastname {string}
 * @return string
 */
export function concatName(firstname: string, lastname: string): string {
  return `${firstname} ${lastname}`;
}

/**
 * @param subscriptionPricingPlan {SubscriptionPricingPlan}
 * @param addons {Array<SubscriptionAddonPricingPlan>}
 * @return {string}
 */
export function userSubscriptionPaymentPlan(
  subscriptionPricingPlan: SubscriptionPricingPlan,
  addons: Array<MergedAddonAndPricingPlan>
): string {
  const cost: number = calculateSubscriptionCost(subscriptionPricingPlan, addons);
  const length: TypeSubscriptionIntervalLength = subscriptionPricingPlan.intervalLength;
  const unit = getSubscriptionPeriod(length);
  return `£${cost.toFixed(2)} / ${unit}`;
}

/**
 * Calculates the total payment costs from the subscription pricing and subscription addons provided
 *
 * @param subscriptionPricingPlan
 * @param addons
 */
export function calculateSubscriptionCost(
  subscriptionPricingPlan: SubscriptionPricingPlan | SubscriptionPricingPlanWithSubscription,
  addons: Array<MergedAddonAndPricingPlan>
): number {
  const pricingPlanCost: number = parseFloat(subscriptionPricingPlan.cost) / 100;
  let addonCost: number = 0;

  if (addons) {
    addons.forEach(
      (addon: MergedAddonAndPricingPlan) =>
        (addonCost += (parseFloat(addon.subscriptionAddonPricingPlan?.cost) * addon.quantity) / 100)
    );
  }

  return pricingPlanCost + addonCost;
}

/**
 * @param userSubscription {UserSubscription}
 * @param subscriptionPricingPlan {SubscriptionPricingPlan}
 * @param dateFormat {DateFormat}
 */
export function nextPaymentDate(
  userSubscription: UserSubscription,
  subscriptionPricingPlan: SubscriptionPricingPlan,
  dateFormat: DateFormat = DATE_FORMAT_DEFAULT
): string {
  const nextPaymentDate = (): Dayjs => {
    let counter = 1;
    while (
      !isBeforeRenewalDate(userSubscription.startedAt, subscriptionPricingPlan.intervalLength, counter)
    ) {
      counter++;
    }

    return dayjs(userSubscription.startedAt).add(subscriptionPricingPlan.intervalLength * counter, 'month');
  };

  if (dateFormat === DATE_FORMAT_DEFAULT) {
    return nextPaymentDate().format('DD/MM/YYYY');
  } else {
    return nextPaymentDate().format('DD MMM YYYY');
  }
}

export function getDowngradeDate(
  userSubscription: UserSubscription,
  subscriptionPricingPlan: SubscriptionPricingPlan,
  dateFormat: DateFormat = DATE_FORMAT_DEFAULT
): string {
  const renewalDate: Array<string> = nextPaymentDate(userSubscription, subscriptionPricingPlan).split('/');
  const machineDate: Date = new Date(`${renewalDate[1]}/${renewalDate[0]}/${renewalDate[2]}`);
  const downgradeDate: Date = new Date(machineDate.setDate(machineDate.getDate() - 14));

  // If both the days and months are below the number 10, append the 0 manually
  const formattedDay: string | number =
    downgradeDate.getDate() < 10 ? `0${downgradeDate.getDate()}` : downgradeDate.getDate();
  const formattedMonth: string | number =
    downgradeDate.getMonth() + 1 < 10 ? `0${downgradeDate.getMonth() + 1}` : downgradeDate.getMonth() + 1;

  if (dateFormat === DATE_FORMAT_TEXT)
    return `${formattedDay} ${CALENDAR_MONTHS[downgradeDate.getMonth()]} ${downgradeDate.getFullYear()}`;
  else return `${downgradeDate.getDate()}/${formattedMonth}/${downgradeDate.getFullYear()}`;
}

/**
 * Returns correct string format for interval length
 *
 * @param {TypeSubscriptionIntervalLength} intervalLength
 * @return {string}
 */
export function optionsInterval(intervalLength: TypeSubscriptionIntervalLength): string {
  switch (intervalLength) {
    case 3:
      return `Pay ${THREE_MONTHS_LONG.toLowerCase()}`;
    case 6:
      return `Pay ${HALF_YEARLY_LONG.toLowerCase()}`;
    case 12:
      return `Pay ${YEARLY_LONG.toLowerCase()}`;
    default:
      return `Pay ${MONTHLY_LONG.toLowerCase()}`;
  }
}

/**
 * Returns correct string format for interval length
 *
 * @param {TypeSubscriptionIntervalLength} intervalLength
 * @return {string}
 */
export function intervalText(intervalLength: TypeSubscriptionIntervalLength): string {
  switch (intervalLength) {
    case 3:
      return `${THREE_MONTHS_LONG.toLowerCase()}`;
    case 6:
      return `${HALF_YEARLY_LONG.toLowerCase()}`;
    case 12:
      return `${YEARLY_LONG.toLowerCase()}`;
    default:
      return `${MONTHLY_LONG.toLowerCase()}`;
  }
}

/**
 * This method finds the base plan using the new user-selected subscription plan
 *
 * @param {string | null} planId
 * @param {Array<Subscription>} plans
 * @return {Subscription | null}
 */
export function getPlanById(planId: string | null, plans: Array<Subscription>): Subscription | null {
  if (!plans.length || !planId) return null;
  return plans.find((plan: Subscription) => plan.id === planId) || null;
}

/**
 * @param {string | null} addonId
 * @param {Array<Addon>} addons
 * @return {Addon | null}
 */
export function getAddonPlanById(addonId: string | null, addons: Array<Addon>): Addon | null {
  if (!addons.length || !addonId) return null;
  return addons.find((addon: Addon) => addon.id === addonId) || null;
}

export function activeSubscription(user: Partial<User>): UserSubscription | null {
  if (!user || !user.activeUserSubscriptions || !user.activeUserSubscriptions.length) return null;
  return user.activeUserSubscriptions[0];
}

export function subscriptionCost(user: Partial<User>): number | null {
  if (!user || !user.activeUserSubscriptions || !user.activeUserSubscriptions.length) return null;
  return user.activeUserSubscriptions[0].totalSubscriptionCost;
}

export function customerBalance(user: Partial<User>): number {
  if (!user || !user.balance) return 0;
  return Math.abs(user.balance);
}

export function customerBalancePossiblyNegative(user: Partial<User>): number {
  if (!user || !user.balance) return 0;
  return user.balance;
}

export function nextPaymentCost(user: Partial<User>): number | null {
  const usersSubscriptionCost: number | null = subscriptionCost(user);
  if (!usersSubscriptionCost) return null;
  return usersSubscriptionCost - customerBalance(user);
}

export function currentSubscriptionAddons(user: Partial<User>): Array<MergedAddonAndPricingPlan> | null {
  const usersActiveSubscription: UserSubscription | null = activeSubscription(user) || null;
  if (!usersActiveSubscription || !usersActiveSubscription.addons) return null;
  else return usersActiveSubscription.addons;
}

/**
 * Checks if the currently selected subscription addons are different to the active UserSubscription addons
 */
export function hasDifferentSelectedAddons(
  user: Partial<User>,
  selectedAddons: Array<MergedAddonAndPricingPlan>
): boolean {
  const currentAddons = currentSubscriptionAddons(user) || [];

  if (!user || !currentAddons) {
    // console.error('Cannot find user or users current subscription addons.'); // eslint-disable-line
    return false;
  }

  const currentAddonsIds: Array<string> = currentAddons.map((addon: MergedAddonAndPricingPlan) => {
    return addon.subscriptionAddonPricingPlan.subscriptionAddonId;
  });
  const selectedAddonsIds: Array<string> = selectedAddons.map((addon) => {
    return addon.subscriptionAddonPricingPlan.subscriptionAddonId;
  });

  return JSON.stringify(currentAddonsIds) !== JSON.stringify(selectedAddonsIds);
}

export function getSubscriptionFrequency(
  userSubscription: UserSubscription,
  format: TypeSubscriptionIntervalFormat = FORMAT_SHORT
): TypeSubscriptionIntervalLengthShorthand | TypeSubscriptionIntervalLengthDescriptive | null {
  if (!userSubscription) return null;

  const formatIntervalValue = (
    intervalLength: TypeSubscriptionIntervalLength
  ): TypeSubscriptionIntervalLengthDescriptive | TypeSubscriptionIntervalLengthShorthand => {
    switch (intervalLength) {
      case YEARLY_NUMBER:
        return format === FORMAT_SHORT ? YEARLY : YEARLY_LONG;
      case HALF_YEARLY_NUMBER:
        return format === FORMAT_SHORT ? HALF_YEARLY : HALF_YEARLY_LONG;
      case THREE_MONTHS_NUMBER:
        return format === FORMAT_SHORT ? THREE_MONTHS : THREE_MONTHS_LONG;
      default:
        return format === FORMAT_SHORT ? MONTHLY : MONTHLY_LONG;
    }
  };

  return formatIntervalValue(userSubscription.subscriptionPricingPlan.intervalLength);
}

export function cancelSubscriptionStatusMessage(
  user: Partial<User>,
  status: CancelSubscriptionStatus
): string {
  const defaultMessage: string = 'Unable to cancel your plan, please contact support.';
  if (!status || !status.canCancelMessage) return defaultMessage;

  // If any meta data exists, currently, it'll only be because the user isn't eligible to "downgrade/cancel".
  // May be changed in to future*
  if (status.meta && status.meta.daysUntilCancel) {
    const activeUserSubscription: UserSubscription | null = activeSubscription(user);
    if (!activeUserSubscription) return defaultMessage;

    const activeSubscriptionPricingPlan: SubscriptionPricingPlan | null =
      activeUserSubscription.subscriptionPricingPlan;
    if (!activeSubscriptionPricingPlan) return defaultMessage;

    const downgradeDate: string = getDowngradeDate(activeUserSubscription, activeSubscriptionPricingPlan);

    return `You are currently midway through a ${activeSubscriptionPricingPlan.intervalLength} month plan
      and can cancel from ${downgradeDate}.`;
  }

  if (status.canCancelMessage) return status.canCancelMessage;
  else return defaultMessage;
}

/**
 * Private Methods
 */

function isBeforeRenewalDate(
  startDate: string,
  intervalLength: TypeSubscriptionIntervalLength,
  multiplier: number
): boolean {
  return dayjs().isBefore(dayjs(startDate).add(intervalLength * multiplier, 'month'), 'day');
}

export function getIsMonthlySubscriber(userSubscription: UserSubscription | null): boolean {
  if (!userSubscription) return false;
  return userSubscription.subscriptionPricingPlan.intervalLength === MONTHLY_NUMBER;
}

export function getIsInMidTerm(
  userSubscription: UserSubscription | null,
  subscriptionPricingPlan: SubscriptionPricingPlan
): boolean {
  if (!userSubscription) return false;
  const canDowngradeFrom: string | undefined =
    userSubscription.user?.canDowngradeFrom ||
    getDowngradeDate(userSubscription, subscriptionPricingPlan, DATE_FORMAT_TEXT);
  if (!canDowngradeFrom) return false;
  const daysUntilCancel: number = getDifferenceInDays(new Date(canDowngradeFrom), new Date());
  return daysUntilCancel > 14;
}
