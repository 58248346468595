var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" } },
    [
      _c("path", {
        attrs: {
          d:
            "M12 16.5a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5zm0-7a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5zm0-7a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5z",
          fill: "currentColor",
          "fill-rule": "evenodd"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }