import {
  MONTHLY,
  MONTHLY_LONG,
  MONTHLY_NUMBER,
  HALF_YEARLY,
  HALF_YEARLY_LONG,
  HALF_YEARLY_NUMBER,
  YEARLY,
  YEARLY_LONG,
  YEARLY_NUMBER,
  FORMAT_LONG,
  FORMAT_SHORT,
  THREE_MONTHS_NUMBER,
  THREE_MONTHS_LONG,
  THREE_MONTHS,
} from '~/constants/subscription-intervals';
import {
  Subscription,
  SubscriptionPricingPlan,
  Addon,
  SubscriptionAddonPricingPlan,
  SubscriptionWithPricingPlans,
  TypeSubscriptionIntervalLength,
  TypeSubscriptionIntervalFormat,
  PlansWithPricingPlans,
  AddonsWithAddonPricingPlans,
  SubscriptionPlansWorthOfToys,
} from '~/types/subscription';
import { capitalizeFirstLetter } from '~/helpers/strings';
import { subscriptionPricingPlans, subscriptionPricingPlansWorth } from '~/data/subscription';

export interface PlansAndPricingPlans {
  subscriptions: Array<Subscription>;
  subscriptionPricingPlans: Array<SubscriptionPricingPlan>;
}

export interface AddonsAndAddonPricingPlans {
  addons: Array<Addon>;
  addonPricingPlans: Array<SubscriptionAddonPricingPlan>;
}

/**
 * The API is providing us with an array of Subscriptions with their respective SubscriptionPricingPlans
 * under the key: subscriptionPricingPlans.
 * The Subscriptions and SubscriptionPricingPlans are stored separately in store/subscriptions.ts module.
 * This method separates the SubscriptionPricingPlan from their Subscriptions to be able to store them in the correct way.
 */
export function separatePricingPlansFromPlans(
  plans: Array<SubscriptionWithPricingPlans>
): PlansWithPricingPlans | null {
  if (!plans) return null;

  const subscriptionPricingPlans: Array<SubscriptionPricingPlan> = [];
  const subscriptions: Array<SubscriptionWithPricingPlans> = plans.map(
    (subscriptionPlan: SubscriptionWithPricingPlans) => {
      if (subscriptionPlan.subscriptionPricingPlans) {
        subscriptionPricingPlans.push(...subscriptionPlan.subscriptionPricingPlans);
        delete subscriptionPlan.subscriptionPricingPlans;
      }
      return subscriptionPlan;
    }
  );

  return { subscriptions, subscriptionPricingPlans };
}

/**
 * The API is providing us with an array of Addons with their respective SubscriptionAddonPricingPlans
 * under the key: subscriptionAddonPricingPlans.
 * The Addons and SubscriptionAddonPricingPlans are stored separately in store/subscriptions.ts module.
 * This method separates the SubscriptionAddonPricingPlans from their Addons to be able to store them in the correct way.
 */
export function separateAddonPricingPlansFromAddons(
  subscriptionAddons: any
): AddonsWithAddonPricingPlans | null {
  if (!subscriptionAddons) return null;

  const addonPricingPlans: Array<SubscriptionAddonPricingPlan> = [];

  const addons: Array<Addon> = subscriptionAddons.map((addon: any) => {
    if (addon.subscriptionAddonPricingPlans) {
      addonPricingPlans.push(...addon.subscriptionAddonPricingPlans);
      delete addon.subscriptionAddonPricingPlans;
    }
    return addon;
  });

  return { addons, addonPricingPlans };
}

/**
 * @param intervalLength {TypeSubscriptionIntervalLength}
 * @param format {TypeSubscriptionIntervalFormat}
 * @return {string}
 */
export function getSubscriptionPeriod(
  intervalLength: TypeSubscriptionIntervalLength,
  format: TypeSubscriptionIntervalFormat = FORMAT_SHORT
): string {
  switch (intervalLength) {
    case MONTHLY_NUMBER:
      return format === FORMAT_LONG ? capitalizeFirstLetter(MONTHLY_LONG) : capitalizeFirstLetter(MONTHLY);
    case YEARLY_NUMBER:
      return format === FORMAT_LONG ? capitalizeFirstLetter(YEARLY_LONG) : capitalizeFirstLetter(YEARLY);
    case THREE_MONTHS_NUMBER:
      return format === FORMAT_LONG
        ? capitalizeFirstLetter(THREE_MONTHS_LONG)
        : capitalizeFirstLetter(THREE_MONTHS);
    case HALF_YEARLY_NUMBER:
      return format === FORMAT_LONG
        ? capitalizeFirstLetter(HALF_YEARLY_LONG)
        : capitalizeFirstLetter(HALF_YEARLY);
    default:
      throw new Error('Unable to find correct period');
  }
}

export function getWorthOfToys(chosenPlan: SubscriptionPricingPlan): number | undefined {
  if (!chosenPlan) return;

  const chosenPlanCost: string = (+chosenPlan!.cost).toFixed(0);
  let worth: SubscriptionPlansWorthOfToys | undefined;
  Object.values(subscriptionPricingPlans as Record<string, object>).forEach(
    (plan: Partial<SubscriptionPricingPlan>): number | void => {
      if (plan.cost && (+plan.cost).toFixed(0) === chosenPlanCost) {
        worth = Object.values(
          subscriptionPricingPlansWorth as Record<string, SubscriptionPlansWorthOfToys>
        ).find((planWorth: SubscriptionPlansWorthOfToys): boolean => planWorth.id === plan.id);
      }
    }
  );
  if (worth) return worth.worth;
}

export function getRemainingSubscriptionTime(subscriptionEndDate: string): string {
  const endDate: Date = new Date(subscriptionEndDate);
  const currentDate: Date = new Date();
  let newCurrentDate: Date = new Date();

  if (currentDate.getTime() >= endDate.getTime()) return '0 days';

  let remainingDays: number | string = getDifferenceInDays(endDate, currentDate);
  let months: number | string = 0;

  calculateMonthsAndDays();

  function calculateMonthsAndDays(): void {
    const nextMonth: Date = new Date(currentDate.setMonth(currentDate.getUTCMonth() + 1));
    const daysInNextMonth: number = getDifferenceInDays(nextMonth, newCurrentDate);

    if (daysInNextMonth > remainingDays) return; // return if remaining days are less then a full month
    remainingDays = (remainingDays as number) - daysInNextMonth;
    (months as number)++;
    newCurrentDate = new Date(newCurrentDate.setMonth(newCurrentDate.getUTCMonth() + 1));
    return calculateMonthsAndDays();
  }

  months = months > 0 ? months + ' mo' : '';
  remainingDays = remainingDays === 1 ? remainingDays + ' day' : remainingDays + ' days';
  return months + ' ' + remainingDays;
}

export function getDifferenceInDays(endDate: Date, currentDate: Date): number {
  return Math.ceil(Math.abs(endDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24));
}

export function getErrorMessageByType(error: any): string {
  const errors: any = error.response.data.errors;
  if (errors.message) {
    return errors.message[0];
  }
  if (errors.subscription_pricing_plan_id) {
    return errors.subscription_pricing_plan_id[0];
  }

  if (errors.addons) {
    return errors.addons[0];
  }
  return 'Oops, looks like something went wrong. Try again later or contact our support team for help';
}
