












































import 'reflect-metadata';
import { mixins, Component, Prop, namespace } from 'nuxt-property-decorator';
import FiltersMixin from '../FiltersMixin/FiltersMixin.vue';
import SelectedFiltersCount from './SelectedFiltersCount/SelectedFiltersCount.vue';
import SelectedFiltersTags from './SelectedFiltersTags/SelectedFiltersTags.vue';
import FlatAccordion from '~/components/partials/accordions/FlatAccordion/FlatAccordion.vue';
import { ResizeState as RS } from '~/store/resize';
import { SelectedFilter as SF } from '~/store/products/filters';
import { SelectedBucket } from '~/store/products/associations';

interface ResizeState extends RS {} // Without this, typescript throws a warning that it cannot find the export
interface SelectedFilter extends SF {} // Without this, typescript throws a warning that it cannot find the export

const ResizeDebouncedStore = namespace('resize');
const AssociationsStore = namespace('products/associations');
const FiltersStore = namespace('products/filters');

@Component({
  components: {
    FlatAccordion,
    SelectedFiltersTags,
    SelectedFiltersCount,
  },
})
export default class SelectedFilters extends mixins(FiltersMixin) {
  @Prop({ default: false }) readonly isDisabled!: boolean;
  @Prop({ default: false }) readonly isOpen!: boolean;
  @Prop({ default: false }) readonly isFixedOpen!: boolean;

  @ResizeDebouncedStore.State('debounced') screenSize!: ResizeState;
  @AssociationsStore.Getter selectedBuckets!: Array<string>;
  @FiltersStore.Getter totalSelected!: number;
  @FiltersStore.Getter selectedFilters!: Array<SelectedFilter>;

  accordionIsOpen: boolean = false;

  handleRemove(selectedFilter: SelectedFilter) {
    if (selectedFilter.type) {
      this.clearRange();
    } else if (selectedFilter.search) {
      this.clearSearch();
    } else {
      this.toggleBucketAssociation(selectedFilter as SelectedBucket);
    }
  }
}
