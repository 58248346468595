

























import { Component, mixins } from 'nuxt-property-decorator';
import BaseTextMixin from '~/components/partials/BaseText/BaseTextMixin.vue';
@Component
export default class BaseText extends mixins(BaseTextMixin) {}
