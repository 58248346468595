var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    _vm._b(
      {
        tag: "component",
        class: "v-nav-icon-link v-nav-icon-link--" + _vm.theme
      },
      "component",
      _vm.$attrs,
      false
    ),
    [
      _vm.showWarningBadge
        ? _c("DiscIcon", {
            staticClass: "v-nav-icon-link__error-icon",
            attrs: { icon: "Error", theme: "quinary", size: "small" }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.icon === "Bag"
        ? _c("TheBagIcon", {
            staticClass: "u-margin-bottom-micro",
            attrs: { number: _vm.number, theme: _vm.theme, size: _vm.iconSize }
          })
        : _vm.icon === "Hamburger"
        ? _c("TheHamburger", {
            staticClass: "u-margin-bottom-micro",
            attrs: { theme: _vm.theme, active: _vm.active }
          })
        : _c("BaseIcon", {
            staticClass: "u-margin-bottom-micro",
            attrs: { icon: _vm.icon, theme: _vm.theme, size: _vm.iconSize }
          }),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "v-nav-icon-link__text", class: "u-color-" + _vm.theme },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _vm.active
        ? _c("span", {
            staticClass: "v-nav-icon-link__active-bar",
            class: "u-background-color-" + _vm.theme
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }