







import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { colorFromTheme } from '~/helpers/colors';
import WhirliLogoPlaceholder from '~/components/partials/logo/WhirliLogoFrames/WhirliLogoPlaceholder.vue';

const availableColors = ['primary', 'secondary', 'tertiary', 'quaternary', 'quinary'];

@Component({
  components: {
    WhirliLogoPlaceholder,
    WhirliLogoFrames: () =>
      import(
        /* webpackChunkName: "WhirliLogoFrames" */ '~/components/partials/logo/WhirliLogoFrames/WhirliLogoFrames.vue'
      ),
  },
})
export default class WhirliLogo extends Vue {
  @Prop({ default: true }) readonly autoplay!: boolean;
  @Prop({ default: 'tertiary', validator: (value) => availableColors.includes(value) })
  readonly background!: string;

  @Prop({ default: 'secondary', validator: (value) => availableColors.includes(value) })
  readonly foreground!: string;

  active: boolean = false;
  framesLoaded: boolean = false;
  animationFrameID: number = 0;
  currentFrame: number = 1;
  fps: number = 30;
  totalFrames: number = 60;
  now: number = 0;
  then: number = 0;

  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  }

  get colorAttrs() {
    return {
      background: colorFromTheme(this.background),
      foreground: colorFromTheme(this.foreground),
    };
  }

  init() {
    if (this.autoplay && process.env.pauseAnimations !== 'true' && process.env.disableAnimations !== 'true') {
      this.play();
    }
  }

  nextFrame(skip = 1) {
    this.currentFrame += skip;
    if (this.currentFrame > this.totalFrames) {
      skip = skip - (this.currentFrame - this.totalFrames);
      this.currentFrame = 1 + skip;
    }
  }

  loop() {
    if (!this.active) return;

    this.now = Date.now();
    const delta = this.now - this.then;
    if (delta > 1000 / this.fps) {
      this.then = this.now - (delta % (1000 / this.fps));
      this.nextFrame();
    }

    this.animationFrameID = window.requestAnimationFrame(this.loop);
  }

  stop() {
    this.active = false;
    window.cancelAnimationFrame(this.animationFrameID);
  }

  play() {
    this.active = true;
    this.loop();
  }

  logoLoaded() {
    this.framesLoaded = true;
  }
}
