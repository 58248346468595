import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.ts'), 'store/index.ts')

  // If store is an exported method = classic mode (deprecated)

  if (typeof store === 'function') {
    return console.warn('Classic mode for store/ is deprecated and will be removed in Nuxt 3.')
  }

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/basket.ts'), 'basket.ts')
  resolveStoreModules(require('../store/zendesk.ts'), 'zendesk.ts')
  resolveStoreModules(require('../store/campaigns.ts'), 'campaigns.ts')
  resolveStoreModules(require('../store/checkout.ts'), 'checkout.ts')
  resolveStoreModules(require('../store/childProfiles.ts'), 'childProfiles.ts')
  resolveStoreModules(require('../store/drawers.ts'), 'drawers.ts')
  resolveStoreModules(require('../store/waitSpots.ts'), 'waitSpots.ts')
  resolveStoreModules(require('../store/toybox.ts'), 'toybox.ts')
  resolveStoreModules(require('../store/subscriptions.ts'), 'subscriptions.ts')
  resolveStoreModules(require('../store/scroll.ts'), 'scroll.ts')
  resolveStoreModules(require('../store/modal.ts'), 'modal.ts')
  resolveStoreModules(require('../store/navigation.ts'), 'navigation.ts')
  resolveStoreModules(require('../store/overlay.ts'), 'overlay.ts')
  resolveStoreModules(require('../store/pageConfig.ts'), 'pageConfig.ts')
  resolveStoreModules(require('../store/productLists.ts'), 'productLists.ts')
  resolveStoreModules(require('../store/resize.ts'), 'resize.ts')
  resolveStoreModules(require('../store/refer.ts'), 'refer.ts')
  resolveStoreModules(require('../store/blog.ts'), 'blog.ts')
  resolveStoreModules(require('../store/registration/index.ts'), 'registration/index.ts')
  resolveStoreModules(require('../store/products/index.ts'), 'products/index.ts')
  resolveStoreModules(require('../store/gift/index.ts'), 'gift/index.ts')
  resolveStoreModules(require('../store/user/index.ts'), 'user/index.ts')
  resolveStoreModules(require('../store/registration/userDetails.ts'), 'registration/userDetails.ts')
  resolveStoreModules(require('../store/registration/discountCode.ts'), 'registration/discountCode.ts')
  resolveStoreModules(require('../store/registration/discountCodeFromOffer.ts'), 'registration/discountCodeFromOffer.ts')
  resolveStoreModules(require('../store/products/pagination.ts'), 'products/pagination.ts')
  resolveStoreModules(require('../store/registration/progress.ts'), 'registration/progress.ts')
  resolveStoreModules(require('../store/registration/subscription.ts'), 'registration/subscription.ts')
  resolveStoreModules(require('../store/products/search.ts'), 'products/search.ts')
  resolveStoreModules(require('../store/products/associations.ts'), 'products/associations.ts')
  resolveStoreModules(require('../store/products/sort.ts'), 'products/sort.ts')
  resolveStoreModules(require('../store/gift/discountCode.ts'), 'gift/discountCode.ts')
  resolveStoreModules(require('../store/gift/checkout.ts'), 'gift/checkout.ts')
  resolveStoreModules(require('../store/user/addresses.ts'), 'user/addresses.ts')
  resolveStoreModules(require('../store/user/card.ts'), 'user/card.ts')
  resolveStoreModules(require('../store/products/filters.ts'), 'products/filters.ts')
  resolveStoreModules(require('../store/user/subscriptions.ts'), 'user/subscriptions.ts')
  resolveStoreModules(require('../store/gift/basket.ts'), 'gift/basket.ts')
  resolveStoreModules(require('../store/products/range.ts'), 'products/range.ts')

  // If the environment supports hot reloading...

  if (process.client && module.hot) {
    // Whenever any Vuex module is updated...
    module.hot.accept([
      '../store/basket.ts',
      '../store/zendesk.ts',
      '../store/campaigns.ts',
      '../store/checkout.ts',
      '../store/childProfiles.ts',
      '../store/drawers.ts',
      '../store/waitSpots.ts',
      '../store/toybox.ts',
      '../store/subscriptions.ts',
      '../store/scroll.ts',
      '../store/index.ts',
      '../store/modal.ts',
      '../store/navigation.ts',
      '../store/overlay.ts',
      '../store/pageConfig.ts',
      '../store/productLists.ts',
      '../store/resize.ts',
      '../store/refer.ts',
      '../store/blog.ts',
      '../store/registration/index.ts',
      '../store/products/index.ts',
      '../store/gift/index.ts',
      '../store/user/index.ts',
      '../store/registration/userDetails.ts',
      '../store/registration/discountCode.ts',
      '../store/registration/discountCodeFromOffer.ts',
      '../store/products/pagination.ts',
      '../store/registration/progress.ts',
      '../store/registration/subscription.ts',
      '../store/products/search.ts',
      '../store/products/associations.ts',
      '../store/products/sort.ts',
      '../store/gift/discountCode.ts',
      '../store/gift/checkout.ts',
      '../store/user/addresses.ts',
      '../store/user/card.ts',
      '../store/products/filters.ts',
      '../store/user/subscriptions.ts',
      '../store/gift/basket.ts',
      '../store/products/range.ts',
    ], () => {
      // Update `root.modules` with the latest definitions.
      updateModules()
      // Trigger a hot update in the store.
      window.$nuxt.$store.hotUpdate(store)
    })
  }
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const storeModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(storeModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
