import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '~/store/index';
import zendesk from '~/helpers/zendesk';

const types = {
  UPDATE_ZENDESK_ACTIVE_STATE: 'UPDATE_ZENDESK_ACTIVE_STATE',
  UPDATE_ZENDESK_LOADED_STATE: 'UPDATE_ZENDESK_LOADED_STATE',
};

interface ModuleState {
  isZenDeskActive: boolean;
  isZenDeskLoaded: boolean;
}

export const state = (): ModuleState => ({
  isZenDeskActive: false,
  isZenDeskLoaded: false,
});

export const actions: ActionTree<ModuleState, RootState> = {
  updateZenDeskActiveState({ commit, state }, isActive: boolean): void {
    if (state.isZenDeskLoaded) zendesk.open();
    commit(types.UPDATE_ZENDESK_ACTIVE_STATE, isActive);
  },
};

export const mutations: MutationTree<ModuleState> = {
  [types.UPDATE_ZENDESK_ACTIVE_STATE](state, isActive: boolean): void {
    state.isZenDeskActive = isActive;
  },
  [types.UPDATE_ZENDESK_LOADED_STATE](state, isLoaded: boolean): void {
    state.isZenDeskLoaded = isLoaded;
  },
};
