import { Route } from 'vue-router';
import { GlobalInjects } from '~/index.d';

export interface ExperimentDataObject {
  experimentId: string;
  variantId: number;
}

export interface ExperimentVariant {
  id: number;
  percentage: number;
}

export const EXPERIMENTS_COOKIE_STR: string = 'experiments_data';

export default abstract class BaseExperiment {
  protected app: GlobalInjects;
  protected route: Route;
  protected store: any;
  protected experimentId: string = '';
  protected variants: ExperimentVariant[] = [];

  constructor(app: GlobalInjects, route: Route, store: any) {
    this.app = app;
    this.route = route;
    this.store = store;
  }

  // setup doesn't have access to app.$auth
  public abstract setup(): void;

  public beforeRouteEnter(): void {}

  public afterRouteEnter(): void {}

  public saveAndShip(): void {
    this.setCookie();
    this.setEvent();
  }

  public isOriginal(): boolean {
    const experimentVariant = this.getExperimentVariant();
    return experimentVariant === null || experimentVariant < 1;
  }

  public setCookie(): void {
    this.app.$cookies.set(EXPERIMENTS_COOKIE_STR, this.app.$experiments.getDataString(), {
      maxAge: this.maxAge(),
      path: '/',
    });
  }

  public setEvent(): void {
    this.app.$gtm.analytics.pushEvent('optimize.callback', {
      experiment_data: this.app.$experiments.getDataString(),
    });
  }

  public setRoute(route: Route): void {
    this.route = route;
  }

  public getExperimentsData(): ExperimentDataObject[] {
    const experiments: string[] = this.app.$experiments.getDataString().split('!');
    const experimentObjects: ExperimentDataObject[] = [];
    experiments.forEach((experiment: string) => {
      const singleExperimentArray = experiment.split('.');
      experimentObjects.push({
        experimentId: singleExperimentArray[0],
        variantId: parseInt(singleExperimentArray[1]),
      });
    });
    return experimentObjects;
  }

  public generateAndPushExperiment(variantId: number | null = null): void {
    if (variantId === null) {
      variantId = this.generateVariantId();
    }

    let experimentGenerated = false;
    this.getExperimentsData().forEach((experiment: ExperimentDataObject) => {
      if (experiment.experimentId === this.experimentId) {
        experimentGenerated = true;
      }
    });

    if (!experimentGenerated) {
      this.pushExperiment({
        experimentId: this.experimentId,
        variantId,
      });
    }
  }

  public getExperimentVariant(experimentId: string = this.experimentId): number | null {
    let variantId = null;
    this.getExperimentsData().forEach((experiment: ExperimentDataObject) => {
      if (experiment.experimentId === experimentId) {
        variantId = experiment.variantId;
      }
    });

    return variantId;
  }

  protected changeVariantId(variantId: number): void {
    const experimentData = this.getExperimentsData();
    this.app.$experiments.updateDataString('');
    experimentData.forEach((experiment: ExperimentDataObject) => {
      if (experiment.experimentId === this.experimentId) {
        experiment.variantId = variantId;
      }
      this.pushExperiment(experiment);
    });

    this.saveAndShip();
  }

  protected pushExperiment(experiment: ExperimentDataObject): void {
    this.app.$experiments.pushDataString(experiment.experimentId + '.' + experiment.variantId);
  }

  protected generateVariantId(): number {
    const randomInt = Math.floor(Math.random() * Math.floor(100)) + 1; // Generates random int from 1 to 100

    let variantId: number = 0;
    let percentageSum: number = 0;
    this.variants.forEach((variant: ExperimentVariant) => {
      const newPercentageSum: number = percentageSum + variant.percentage;
      if (randomInt > percentageSum && randomInt <= newPercentageSum) {
        variantId = variant.id;
      }
      percentageSum = newPercentageSum;
    });

    return variantId;
  }

  protected maxAge(): number {
    return 60 * 60 * 24 * 7; // default 7 days;
  }
}
