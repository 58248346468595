var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 13 12",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c("title", [_vm._v("Product saftey download available")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Page-1",
            stroke: "none",
            "stroke-width": "1",
            fill: "inherit",
            "fill-rule": "evenodd"
          }
        },
        [
          _c("path", {
            attrs: {
              id: "icon-warning",
              d:
                "M5.04166117,0.822437475 L5.04328932,0.819737259 C5.35209109,0.310804173 5.90429745,0 6.49971802,0 C7.09513858,0 7.64734493,0.310804173 7.95777486,0.822437475 L12.7717249,8.85669918 C13.0743881,9.38068687 13.0761952,10.0258778 12.7764719,10.5515516 C12.4767486,11.0772255 11.920513,11.4044268 11.3090557,11.4111111 L1.68413986,11.4111111 C1.07892305,11.4044268 0.522687466,11.0772255 0.222964162,10.5515516 C-0.0767591418,10.0258778 -0.0749520682,9.38068687 0.232323535,8.84885969 L5.04166117,0.822437475 Z M1.21114467,9.42440723 C1.11025691,9.59906978 1.10965456,9.81413342 1.20956232,9.98935804 C1.3094701,10.1645827 1.49488196,10.2736498 1.69038038,10.2758664 L11.3028151,10.2758664 C11.5045541,10.2736498 11.6899659,10.1645827 11.7898737,9.98935804 C11.8897815,9.81413342 11.8891791,9.59906978 11.7929037,9.43224671 L6.98519425,1.40852471 C6.88226032,1.23888034 6.69819153,1.13527895 6.49971802,1.13527895 C6.30167966,1.13527895 6.11798294,1.23842654 6.01492002,1.40740978 L1.21114467,9.42440723 Z M5.92222222,4.04444444 C5.92222222,3.72534659 6.18090214,3.46666667 6.5,3.46666667 C6.81909786,3.46666667 7.07777778,3.72534659 7.07777778,4.04444444 L7.07777778,6.35555556 C7.07777778,6.67465341 6.81909786,6.93333333 6.5,6.93333333 C6.18090214,6.93333333 5.92222222,6.67465341 5.92222222,6.35555556 L5.92222222,4.04444444 Z M6.09144942,8.9307728 C5.86581316,8.70513656 5.86581316,8.33930789 6.09144942,8.11367164 C6.31708567,7.88803538 6.68291433,7.88803538 6.90855058,8.11367164 C7.13418684,8.33930789 7.13418684,8.70513656 6.90855058,8.9307728 C6.68291433,9.15640907 6.31708567,9.15640907 6.09144942,8.9307728 Z",
              "fill-rule": "nonzero"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }