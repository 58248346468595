













import 'reflect-metadata';
import { Vue, Prop, Component } from 'nuxt-property-decorator';
import PageNotFoundLayout from './404.vue';
import MaintenanceLayout from './503.vue';

@Component({
  components: {
    PageNotFoundLayout,
    MaintenanceLayout,
  },
})
export default class ErrorLayout extends Vue {
  @Prop({ default: {} }) readonly error!: object;
}
