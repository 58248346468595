













import 'reflect-metadata';
import { Prop, Component, mixins } from 'nuxt-property-decorator';
import iconMixin from '../iconMixin.vue';
import icons, { IconName as IN } from '../icons';
import TheBagIcon from '../TheBagIcon/TheBagIcon.vue';
import { themeMap } from '~/helpers/colors';

type IconName = IN; // Without this, typescript throws a warning that it cannot find the export

export const availableSizes: Array<string> = [
  'teeny',
  'tiny',
  'small',
  'base',
  'medium',
  'intermediate',
  'big',
  'large-alt',
];

@Component({
  components: {
    TheBagIcon,
  },
})
export default class DiscIcon extends mixins(iconMixin) {
  @Prop({ type: String, required: true, validator: (value) => Object.keys(icons).includes(value) })
  readonly icon!: IconName;

  @Prop({ default: 'intermediate', validator: (value) => availableSizes.includes(value) })
  readonly size!: string;

  get iconSize() {
    switch (this.size) {
      case 'teeny':
        return 'mini';
      case 'tiny':
        return 'tiny';
      case 'small':
        return 'tiny';
      case 'base':
        return 'tiny';
      case 'intermediate':
        return 'small';
      case 'big':
        return 'base';
      case 'large-alt':
        return 'medium';
      case 'medium':
        return 'tiny';
      default:
        return 'teeny';
    }
  }

  get iconColor() {
    return themeMap[this.theme].foreground;
  }

  get discClasses() {
    return {
      [`v-disc-icon--${this.size}`]: this.size,
      [`v-disc-icon--${this.direction}`]: this.direction && this.icon === 'ArrowCenter',
      [`u-background-color-${this.theme}`]: this.theme,
    };
  }
}
