import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '~/store';
import { AddressDetails } from '~/types/user/addresses';

interface WithId {
  addressId: string;
}

interface WithData {
  addressData: AddressDetails;
}

interface UpdateAddressPayload extends WithId, WithData {}

type AddressDetailsObject = AddressDetails | object;

const types = {
  PUT_USER_ALT_ADDRESSES: 'PUT_USER_ALT_ADDRESSES',
  PUT_TOTAL_USER_ADDRESSES: 'PUT_TOTAL_USER_ADDRESSES',
  PUT_DEFAULT_USER_ADDRESS: 'PUT_DEFAULT_USER_ADDRESS',
  PUT_IS_EDITING_ADDRESS: 'PUT_IS_EDITING_ADDRESS',
  PUT_IS_EDITING_ADDRESS_ERROR: 'PUT_IS_EDITING_ADDRESS_ERROR',
};

export interface ModuleState {
  addresses: Array<AddressDetails>;
  defaultAddress: AddressDetails | null;
  totalAddresses: number | null;
  isEditingAddress: boolean;
  isEditingAddressError: string | null;
}

const defaults: ModuleState = {
  addresses: [],
  defaultAddress: null,
  totalAddresses: null,
  isEditingAddress: false,
  isEditingAddressError: null,
};

export const state = (): ModuleState => ({
  addresses: defaults.addresses,
  defaultAddress: defaults.defaultAddress,
  totalAddresses: defaults.totalAddresses,
  isEditingAddress: defaults.isEditingAddress,
  isEditingAddressError: defaults.isEditingAddressError,
});

export const actions: ActionTree<ModuleState, RootState> = {
  /**
   * This method will first look in the auth store to find any addresses that
   * have already been requested in the initial app load.
   *
   * (We can also specify id we'd like to update the store with a new request by using the 'updateStore' param)
   *
   * @param commit
   * @param rootState
   * @param self
   * @param {boolean} updateStore
   */
  async hydrateUserAddresses({ commit, rootState }, updateStore: boolean = true) {
    this.$logger.store(`action`, `[user/addresses/hydrateUserAddresses]`, { updateStore });

    const user = Object.assign({}, rootState.auth.user);
    const hasAddresses: boolean = 'addresses' in user && rootState.auth.user.addresses.length > 0;
    let response: any;
    try {
      if (hasAddresses && !updateStore) {
        response = user.addresses;
      } else {
        await this.$auth.fetchUser();
      }
    } catch (error) {
      throw error;
    } finally {
      /**
       * Here we are separating the default address from the alternative addresses
       * Once we have separated them we're going to commit both the defaultAddress
       * then commit the altAddresses into the store
       */
      const addresses = hasAddresses && !updateStore ? response : rootState.auth.user.addresses;
      const totalUserAddresses = addresses ? addresses.length : 0;
      const altAddresses: AddressDetails[] = [];
      let defaultAddress: AddressDetailsObject = {};

      if (addresses && addresses.length > 0) {
        addresses.forEach((addr: AddressDetails) => {
          if (addr.default) return (defaultAddress = Object.assign({}, addr));
        });
        addresses.forEach((addr: AddressDetails) => {
          if (!addr.default) return altAddresses.push(Object.assign({}, addr));
        });
      }

      commit(types.PUT_DEFAULT_USER_ADDRESS, defaultAddress);
      commit(types.PUT_USER_ALT_ADDRESSES, altAddresses);
      commit(types.PUT_TOTAL_USER_ADDRESSES, totalUserAddresses);
    }
  },

  async setDefaultAddress({ dispatch }, addressId: string) {
    this.$logger.store(`action`, `[user/addresses/setDefaultAddress]`, { addressId });
    try {
      await this.$whirli.users.addresses.default(addressId, { default: true });
    } catch (error) {
      throw error;
    } finally {
      await dispatch('hydrateUserAddresses');
    }
  },

  // eslint-disable-next-line
  async deleteUserAddress({ dispatch }, addressId: string) {
    this.$logger.store(`action`, `[user/addresses/deleteUserAddress]`, { addressId });
    try {
      await this.$whirli.users.addresses.delete(addressId);
    } catch (error) {
      throw error;
    }
  },

  async createUserAddress({ dispatch }, addressData: AddressDetails) {
    this.$logger.store(`action`, `[user/addresses/createUserAddress]`, { addressData });
    try {
      await this.$whirli.users.addresses.create(addressData);
    } catch (error) {
      throw error;
    } finally {
      await dispatch('hydrateUserAddresses');
    }
  },

  async updateUserAddress({ dispatch }, { addressId, addressData }: UpdateAddressPayload) {
    this.$logger.store(`action`, `[user/addresses/updateUserAddress]`, { addressId, addressData });
    try {
      await this.$whirli.users.addresses.update(addressId, addressData);
    } catch (error) {
      throw error;
    } finally {
      await dispatch('hydrateUserAddresses');
    }
  },

  updateIsEditingAddress({ commit }, isEditing: boolean): void {
    this.$logger.store(`action`, `[user/addresses/updateIsEditingAddress]`, { isEditing });
    commit(types.PUT_IS_EDITING_ADDRESS, isEditing);
  },

  updateIsEditingAddressError({ commit }, error: string): void {
    this.$logger.store(`action`, `[user/addresses/updateIsEditingAddressError]`, { error });
    commit(types.PUT_IS_EDITING_ADDRESS_ERROR, error);
  },
};

export const mutations: MutationTree<ModuleState> = {
  [types.PUT_USER_ALT_ADDRESSES](state: ModuleState, addresses: AddressDetails[]) {
    state.addresses = addresses;
  },
  [types.PUT_DEFAULT_USER_ADDRESS](state: ModuleState, defaultAddress: AddressDetails) {
    state.defaultAddress = defaultAddress;
  },
  [types.PUT_TOTAL_USER_ADDRESSES](state: ModuleState, totalAddresses: number) {
    state.totalAddresses = totalAddresses;
  },
  [types.PUT_IS_EDITING_ADDRESS](state: ModuleState, isEditing: boolean) {
    state.isEditingAddress = isEditing;
  },
  [types.PUT_IS_EDITING_ADDRESS_ERROR](state: ModuleState, error: string) {
    state.isEditingAddressError = error;
  },
};
