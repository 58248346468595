import { Context } from '@nuxt/types';
import { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
// @ts-ignore
import humps from 'humps';

export default function({ $axios, app, store, redirect }: Context) {
  $axios.onRequest((config: AxiosRequestConfig) => {
    config.data = humps.decamelizeKeys(config.data);

    app.$logger.onRequest(config);
  });
  $axios.onResponse((response: AxiosResponse) => {
    response.data = humps.camelizeKeys(response.data);

    app.$logger.onResponse(response);
  });
  $axios.onError((error: AxiosError) => {
    app.$logger.onResponseError(error);

    const { response } = error;

    if (response) {
      const { status, config } = response;

      if (status === 401) {
        if (config && config.url) {
          if (config.url.startsWith('/auth/user')) {
            store.dispatch('user/logout', { redirect: false });
          }
          if (!config.url.includes('login')) {
            redirect('/login');
          }
        }
      }
    }
  });
  /*
  $axios.onRequestError((error: AxiosError) => {
    app.$logger.onResponseError('onRequestError', error);
  });
  $axios.onResponseError((error: AxiosError) => {
    app.$logger.onResponseError('onResponseError', error);
  });
  */
}
