


























import axios from 'axios';
import 'reflect-metadata';
import { mixins, Component, Prop, namespace } from 'nuxt-property-decorator';
import BaseRangeSlider from '~/components/partials/inputs/BaseRangeSlider/BaseRangeSlider.vue';
import FiltersMixin from '~/components/features/browse-toys/filters/FiltersMixin/FiltersMixin.vue';
import BlockAccordion from '~/components/partials/accordions/BlockAccordion/BlockAccordion.vue';
import { Range } from '~/types/products/range';

const RangeStore = namespace('products/range');

@Component({
  components: {
    BaseRangeSlider,
    BlockAccordion,
  },
})
export default class TokenSliderAccordion extends mixins(FiltersMixin) {
  @Prop({ default: false }) readonly isOpen!: boolean;

  @RangeStore.State('from') tokensFrom!: number | null;
  @RangeStore.State('to') tokensTo!: number | null;
  @RangeStore.Action setRange!: (range: Range) => void;

  rangeStart: number = 0;
  rangeEnd: number = 100;

  apiUseHttps = process.env.protocol?.toLocaleLowerCase() === 'true';
  protocol = this.apiUseHttps ? 'https' : 'http';
  async mounted() {
    try {
      const response = await axios.get(
        `${this.protocol}://${process.env.baseHost}:${process.env.basePort}/api/guest/filter/productFilterList/tokan_range`
      );
      this.rangeEnd = response.data.data.end;
      this.rangeStart = response.data.data.start;
    } catch (error) {
      console.error('Failed to fetch token range:', error);
    }
  }

  get rangeSliderValues(): [number, number] {
    return [
      this.tokensFrom !== null ? this.tokensFrom : this.rangeStart,
      this.tokensTo !== null ? this.tokensTo : this.rangeEnd,
    ];
  }

  async setTokensRange(values: [number, number]) {
    const clampedValues: [number, number] = [
      Math.max(this.rangeStart, Math.min(values[0], this.rangeEnd)),
      Math.max(this.rangeStart, Math.min(values[1], this.rangeEnd)),
    ];

    const rangeValues: Range = { from: clampedValues[0], to: clampedValues[1], type: 'current_value' };

    try {
      await this.setRange(rangeValues);

      this.$gtm.analytics.event('Browse Toys', 'Token Range', `${clampedValues[0]} - ${clampedValues[1]}`);
    } catch (error) {
      return null;
    }
    try {
      await this.refreshProducts();
    } catch (error) {
      throw error;
    }
  }
}
