var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 32 32" } },
    [
      _c("path", {
        attrs: {
          d:
            "M21.9 8.8c-.7 0-1.3.6-1.3 1.3s.6 1.3 1.3 1.3c.7 0 1.3-.6 1.3-1.3s-.6-1.3-1.3-1.3M16 19.7c-2 0-3.7-1.6-3.7-3.7 0-2 1.6-3.7 3.7-3.7s3.7 1.6 3.7 3.7c0 2-1.7 3.7-3.7 3.7m0-9.3c-3.1 0-5.6 2.5-5.6 5.6 0 3.1 2.5 5.6 5.6 5.6s5.6-2.5 5.6-5.6c0-3.1-2.5-5.6-5.6-5.6M16 5c-3 0-3.4 0-4.5.1-1.2.1-2 .2-2.7.5-.7.3-1.3.6-2 1.2-.6.6-1 1.2-1.3 1.9-.3.7-.5 1.5-.5 2.7V16c0 3 0 3.4.1 4.5.1 1.2.2 2 .5 2.7.3.7.7 1.3 1.3 1.9.6.6 1.2 1 1.9 1.3.7.3 1.5.5 2.7.5 1.1.1 1.5.1 4.5.1s3.4 0 4.5-.1c1.2-.1 2-.2 2.7-.5.7-.3 1.3-.7 1.9-1.3.6-.6 1-1.2 1.3-1.9.3-.7.5-1.5.5-2.7.1-1.1.1-1.5.1-4.5s0-3.4-.1-4.5c-.1-1.2-.2-2-.5-2.7-.3-.7-.7-1.3-1.3-1.9-.6-.6-1.2-1-1.9-1.3-.7-.3-1.5-.5-2.7-.5C19.4 5 19 5 16 5m0 2c2.9 0 3.3 0 4.4.1 1.1 0 1.7.2 2 .4.5.2.9.4 1.3.8s.6.8.8 1.3c.2.4.3 1 .4 2 .1 1.1.1 1.5.1 4.4s0 3.3-.1 4.4c0 1.1-.2 1.7-.4 2-.2.5-.4.9-.8 1.3s-.8.6-1.3.8c-.4.2-1 .3-2 .4-1.1.1-1.5.1-4.4.1s-3.3 0-4.4-.1c-1.1 0-1.7-.2-2-.4-.5-.2-.9-.4-1.3-.8s-.6-.8-.8-1.3c-.2-.4-.3-1-.4-2C7 19.3 7 18.9 7 16s0-3.3.1-4.4c0-1.1.2-1.7.4-2 .1-.6.4-1 .7-1.4.4-.3.8-.6 1.3-.8.4-.2 1-.3 2-.4H16"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }