






import 'reflect-metadata';
import { Vue, Prop, Component } from 'nuxt-property-decorator';

@Component
export default class BaseListItem extends Vue {
  @Prop({ default: 'li', validator: (value) => ['li', 'dt', 'dd'].includes(value) }) readonly tag!: string;
}
