var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "intersect",
          rawName: "v-intersect",
          value: _vm.onIntersect,
          expression: "onIntersect"
        }
      ],
      staticClass: "v-lottie-anim"
    },
    [
      _c("div", {
        ref: "renderContainer",
        style: _vm.style,
        on: {
          mouseover: function($event) {
            return _vm.$emit("mouseover")
          },
          mouseleave: function($event) {
            return _vm.$emit("mouseleave")
          }
        }
      }),
      _vm._v(" "),
      !_vm.loaded ? _vm._t("placeholder") : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }