

































import { Prop, Component, mixins } from 'nuxt-property-decorator';
import BaseInput from '../BaseInput/BaseInput.vue';
// @TODO - Change import to './inputMixin' once UWA-56 is resolved
import InputMixin from '../inputMixin.vue';
import DiscIcon from '~/components/partials/icons/DiscIcon/DiscIcon.vue';
import { autoComplete as availableAutoCompletion } from '~/helpers/autocomplete';

@Component({
  components: {
    BaseInput,
    DiscIcon,
  },
  inheritAttrs: false,
})
export default class BaseTextInput extends mixins(InputMixin) {
  @Prop({ required: true }) readonly name!: string;
  @Prop({ default: 'on', validator: (value) => availableAutoCompletion.includes(value) })
  readonly autocomplete!: string;

  @Prop({ default: '100' }) readonly maxLength!: string;
}
