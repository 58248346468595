
import 'reflect-metadata';
import { Vue, namespace, Component } from 'nuxt-property-decorator';
import { remainingTokens, tokensOver } from '~/helpers/models/user';

const BasketStore = namespace('basket');
const ToyboxStore = namespace('toybox');

@Component
export default class TokensUsedMixin extends Vue {
  @BasketStore.State('tokensUsed') basketTokensUsed!: number;
  @ToyboxStore.State('tokensUsed') toyboxTokensUsed!: number;

  get totalTokensUsed(): number {
    return this.toyboxTokensUsed + this.basketTokensUsed;
  }

  get remainingTokens(): number {
    return remainingTokens(this.$auth.user, this.totalTokensUsed);
  }

  get tokensOver(): number {
    return tokensOver(this.remainingTokens);
  }
}
