import { redirectWithoutCaching } from '~/helpers/redirect';

export default function(context) {
  const { store, app } = context;

  if (
    !app.$storage.getUniversal('userComparativeSubscriptions') &&
    (!store.state.user.subscriptions.previewChangeArgs ||
      !store.state.user.subscriptions.previewChangeResource)
  ) {
    return redirectWithoutCaching(context, '/account/change');
  }
}
