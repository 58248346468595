import { SHARING_IMAGE_URL, WEBSITE_URL } from '~/constants/seo';
import social from '~/helpers/social-meta';
import { Product } from '~/types/products/products';
import { AssetKind } from '~/constants/assets';
import { ProductAssetsByKind } from '~/helpers/assets';

export function canonical(path: string = '') {
  return {
    rel: 'canonical',
    href: `${WEBSITE_URL}${path === '/' ? '' : path}`,
  };
}

export function robots(index: boolean) {
  return {
    hid: 'robots',
    name: 'robots',
    content: index ? `index,follow` : `noindex,nofollow`,
  };
}

export function metaDescription(content: string) {
  return {
    hid: 'metaDescription',
    name: 'metaDescription',
    content,
  };
}

export function metaOpenGraph(property: string, content: string) {
  const key = `og:${property}`;
  return {
    hid: key,
    property: key,
    content,
  };
}

export function metaTwitter(name: string, content: string) {
  const key = `twitter:${name}`;
  return {
    hid: key,
    name: key,
    content,
  };
}

export function headMeta(title: string, description: string, path: string, index: boolean = true): object {
  return {
    title,
    description,
    meta: [robots(index), metaDescription(description), ...social(title, description, path)],
    link: [canonical(path)],
  };
}

export function headPaginatedMeta(
  title: string,
  description: string,

  path: string,
  query: object,
  index: boolean = true
): object {
  const robots = paginatedRobots(index, query);
  path = paginatedPath(path, query);

  return {
    title,

    description,
    meta: [robots, metaDescription(description), ...social(title, description, path)],
    link: [canonical(path)],
  };
}

interface QueryString {
  page?: string | number;
}

export function paginatedRobots(index: boolean = true, query: QueryString): object {
  if (!index) {
    return robots(false);
  }
  if (Object.keys(query).length === 0) {
    return robots(true);
  }
  if (Object.keys(query).length > 1) {
    return robots(false);
  }
  if (!query.page) {
    return robots(false);
  }
  const page = String(query.page);
  if (!/^\d+$/.test(page)) {
    return robots(false);
  }
  if (page === '0' || page === '1') {
    return robots(false);
  }
  return robots(true);
}

export function paginatedPath(path: string, query: QueryString): string {
  if (Object.keys(query).length === 0) {
    return path;
  }
  if (query.page === 0 || query.page === '0') {
    return path;
  }
  if (/^\d+$/.test(String(query.page))) {
    return `${path}?page=${query.page}`;
  }
  return path;
}

export function headProductMeta(
  title: string,
  description: string,
  path: string,
  product: Product,
  index: boolean = true
): object {
  return {
    title,
    description,
    meta: [
      robots(index),
      metaDescription(description),
      ...social(
        title,
        description,
        path,
        'product',
        product && ProductAssetsByKind(product, AssetKind.IMAGE).length
          ? ProductAssetsByKind(product, AssetKind.IMAGE)[0].url
          : SHARING_IMAGE_URL //
      ),
    ],
    link: [canonical(path)],
  };
}

export function productMetaTitle(product: Product): string {
  if (product.name === null) {
    return `Kid's Toy Swap Subscription | Whirli`;
  } else {
    return `${product.name} | Kid's Toy Swap Subscription | Whirli`;
  }
}

export function productMetaDescription(product: Product): string {
  return `${product.metaDescription}`;
}
