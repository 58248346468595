import { orderLinesProductTokensToPrice } from '../transformers/product';
import Events from './Events';
import { OrderLine } from '~/types/order';
import { Product } from '~/types/products/products';
import { productVariantSku } from '~/helpers/models/product';

export default class TradeDoublerEvents extends Events {
  /**
   * @param {String} id
   * @param {Array<OrderLine>)} lines
   */
  postPurchase(id: string = '', lines: Array<OrderLine>): void {
    this.data.push({
      td_products: null,
    });
    this.data.pushEvent('TradeDoublerPostPurchase', {
      td_order_id: id,
      td_order_value: orderLinesProductTokensToPrice(lines),
      td_currency: 'GBP',
      td_voucher_code: '',
      td_valid_on: '',
      td_products: lines.map((line: OrderLine) => {
        const product: Product = line.productVariant.product as Product;
        return {
          id: productVariantSku(product),
          price: product.tokens.toFixed(2),
          currency: 'GBP',
          name: product.name,
          grpId: '',
          qty: line.quantity,
        };
      }),
    });
  }

  /**
   * @param {String} id
   * @param {String} value
   * @param {String} discountCode
   * @param {String} plan
   */
  purchase(id: string = '', value: string = '', discountCode: string = '', plan: string = ''): void {
    this.data.push({
      td_products: null,
    });
    this.data.pushEvent('TradeDoublerPurchase', {
      td_order_id: id,
      td_order_value: value,
      td_currency: 'GBP',
      td_voucher_code: discountCode || '',
      td_subscription_plan: plan,
    });
  }
}
