var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseDropdown",
    {
      class: "v-account-dropdown--" + _vm.theme,
      attrs: {
        theme: _vm.theme,
        visible: _vm.visible,
        "caret-position": "left"
      },
      on: {
        "clicked-away": function($event) {
          return _vm.$emit("close-account-dropdown")
        }
      }
    },
    [
      _c(
        "BaseBox",
        { attrs: { padding: ["small", "tiny"] } },
        _vm._l(_vm.links, function(linkGroup, parentIndex) {
          return _c(
            "BaseList",
            { key: parentIndex, staticClass: "v-account-dropdown__list" },
            [
              _vm._l(linkGroup, function(link, childIndex) {
                return [
                  !link.featureFlag ||
                  _vm.featureFlagParam(_vm.$route.query[link.featureFlag])
                    ? _c(
                        "BaseListItem",
                        { key: childIndex },
                        [
                          link.to
                            ? _c(
                                "BaseLink",
                                {
                                  staticClass: "v-account-dropdown__link",
                                  class: _vm.linkClasses,
                                  attrs: { to: link.to },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.$emit("close-account-dropdown")
                                    }
                                  }
                                },
                                [
                                  _c("BaseIcon", {
                                    staticClass:
                                      "v-account-dropdown__link-arrow",
                                    class: _vm.linkClasses,
                                    attrs: {
                                      icon: "ArrowCenter",
                                      size: "small",
                                      direction: "right"
                                    }
                                  }),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(link.text) +
                                      "\n          "
                                  )
                                ],
                                1
                              )
                            : _c(
                                "button",
                                {
                                  staticClass: "v-account-dropdown__link",
                                  class: _vm.linkClasses,
                                  on: {
                                    click: function() {
                                      _vm.$emit("close-account-dropdown")
                                      link.onClick()
                                    }
                                  }
                                },
                                [
                                  _c("BaseIcon", {
                                    staticClass:
                                      "v-account-dropdown__link-arrow",
                                    class: _vm.linkClasses,
                                    attrs: {
                                      icon: "ArrowCenter",
                                      size: "small",
                                      direction: "right"
                                    }
                                  }),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(link.text) +
                                      "\n          "
                                  )
                                ],
                                1
                              )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              })
            ],
            2
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }