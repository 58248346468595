var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._b(
      {
        staticClass: "v-hamburger",
        class: _vm.classes,
        attrs: {
          role: "button",
          "aria-label": "Menu",
          "aria-controls": "mobile-navigation"
        }
      },
      "div",
      _vm.$attrs,
      false
    ),
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "v-hamburger__box" }, [
      _c("span", { staticClass: "v-hamburger__inner" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }