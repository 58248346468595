var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "v-btn-icon", class: _vm.outerClasses },
    [
      _c(
        _vm.tag,
        _vm._g(
          _vm._b(
            {
              tag: "component",
              staticClass: "v-btn-icon__btn",
              attrs: { tabindex: "0" }
            },
            "component",
            _vm.$attrs,
            false
          ),
          _vm.buttonListeners
        ),
        [
          _vm.icon === "Bag" && !_vm.disc
            ? _c("TheBagIcon", {
                staticClass: "v-btn-icon__graphic",
                attrs: {
                  icon: _vm.graphic,
                  number: _vm.number,
                  size: _vm.size,
                  theme: _vm.theme
                }
              })
            : _vm.disc
            ? _c("DiscIcon", {
                staticClass: "v-btn-icon__graphic",
                attrs: {
                  direction: _vm.direction,
                  icon: _vm.graphic,
                  number: _vm.number,
                  size: _vm.size,
                  theme: _vm.theme
                }
              })
            : _c("BaseIcon", {
                staticClass: "v-btn-icon__graphic",
                attrs: {
                  direction: _vm.direction,
                  icon: _vm.graphic,
                  size: _vm.size,
                  theme: _vm.theme
                }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }