var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M21.2 4.2l-1.4-1.4-7.8 7.8-7.8-7.8-1.4 1.4 7.8 7.8-7.8 7.8 1.4 1.4 7.8-7.8 7.8 7.8 1.4-1.4-7.8-7.8z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }