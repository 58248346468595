// @ts-ignore
import humps from 'humps';
import { WpArticle, WpAuthor, WpCategory, WpYoastMeta } from '  ';
import { Article, Author, Category, ArticleMeta } from '~/types/blog/blog';
import { canonical, metaDescription, robots } from '~/helpers/seo';
import social from '~/helpers/social-meta';

/**
 * Transforms the Article data from Wordpress to consumable data to be used by pages and components.
 */
export function transformArticle(wpArticle: WpArticle, categories: Array<Category>): Article {
  return {
    id: wpArticle.id,
    date: wpArticle.date,
    modified: wpArticle.modified,
    slug: wpArticle.slug,
    type: wpArticle.type,
    link: wpArticle.link,
    title: stripTags(wpArticle.title.rendered),
    content: wpArticle.content.rendered,
    excerpt: stripTags(wpArticle.excerpt.rendered),
    author: transformAuthor(wpArticle._embedded.author[0]),
    categories: transformCategoryIdsToCategories(wpArticle.categories, categories),
    theme: wpArticle.acf.theme,
    featured: wpArticle.acf.featured,
    readTime: wpArticle.acf.read_time,
    showRelatedItemsBlock: wpArticle.acf.show_related_items_block,
    relatedItemsBlockHeader: wpArticle.acf.related_items_block_header,
    relatedProductsList: humps.decamelizeKeys(wpArticle.acf.related_products_list),
    articleMeta: transformMetaData(wpArticle),
    jsonSchema: wpArticle.yoast_json_ld, // @todo this uses blog.whirli.com, needs parsing
  };
}

export function transformAuthor(wpAuthor: WpAuthor): Author {
  return {
    id: wpAuthor.id,
    name: wpAuthor.name,
    url: wpAuthor.url,
    description: wpAuthor.description,
    link: wpAuthor.link,
    slug: wpAuthor.slug,
    avatarUrls: wpAuthor.avatar_urls,
    jobRole: wpAuthor.acf ? wpAuthor.acf.job_role : '',
    socialLinks: wpAuthor.acf ? wpAuthor.acf.social_links : {},
  };
}

export function transformCategory(wpCategory: WpCategory): Category {
  return {
    id: wpCategory.id,
    count: wpCategory.count,
    description: wpCategory.description,
    link: wpCategory.link,
    name: wpCategory.name,
    slug: wpCategory.slug,
    taxonomy: wpCategory.taxonomy,
  };
}

export function transformCategoryIdsToCategories(
  categoryIds: Array<number>,
  categories: Array<Category>
): Array<Category> {
  if (!categoryIds || !categoryIds.length) return [];

  return categories.filter((category: Category) => categoryIds.includes(category.id));
}

export function transformMetaData(article: WpArticle): ArticleMeta {
  const title = article.yoast_title;

  const yoastDescription: WpYoastMeta | undefined = article.yoast_meta.find(
    (meta: WpYoastMeta) => meta.property === 'og:description'
  );
  const description = yoastDescription ? yoastDescription.content : '';

  const yoastMeta = article.yoast_meta
    .filter(
      (meta: WpYoastMeta) =>
        meta.name !== 'robots' && meta.name !== 'twitter:card' && meta.property !== 'og:url'
    )
    .map((meta: WpYoastMeta) => {
      const metaData = meta;

      if (meta.property?.includes('twitter') || meta.property?.includes('og')) {
        Object.assign(metaData, { hid: meta.property });
      }
      if (meta.content && meta.content?.includes('blog.whirli.com')) {
        Object.assign(metaData, { hid: meta.property });
        meta.content = meta.content.replace('blog.whirli.com', 'whirli.com/blog');
      }
      return metaData;
    });

  return {
    title,
    meta: [
      ...yoastMeta,
      metaDescription(description),
      robots(true),
      ...social(title, description, `/blog/${article.slug}`),
    ],
    link: [canonical(`/blog/${article.slug}`)],
  };
}

export function getImage(image: { [key: string]: string }): string | null {
  const noImageBackup: string = require('~/assets/img/products/no-image.jpg');
  if (!image) return noImageBackup;

  return image['96'] || noImageBackup;
}

export function stripTags(content: string): string {
  return content.replace(/<\/?[^>]+>/gi, ' ').trim();
}
