/**
 * This file configures our feature flags.
 *
 * Please prefix flags with one of the following terms: (e.g. releaseTheThing, or expTryThis)
 *
 *   release - Flags that toggle an in-progress feature to allow us to still deploy the code before completion
 *   exp - Flags being used to toggle A/B tests
 *   ops - Flags that toggle operational aspects, such as turning off features in times of high load
 *   permission - Flags that toggle product experience for certain users (e.g. paying customers, or internal or beta users)
 *
 * Usually, release and experiment toggles have a short lifetime, and should be removed after the release goes
 * live or the experiment has ended. Code with flags is technical debt, so it's important that these flags
 * are removed through the codebase as soon as possible, and not left to go stale.
 *
 * To use a feature toggle, simply add it to the toggles object below, and give it an entry in the description
 * table to keep track of them.
 *
 * Then, in your code, you can use them in two ways:
 *
 * Show or Hide template based on the value:
 *     <feature-toggle name="releaseSomeFeature" :value="true">
 *       This content is only shown when releaseSomeFeature is true
 *     </feature-toggle>
 *
 * Change logic based on feature flag value:
 *     mounted() {
 *       if (this.$featureToggle.toggles.releaseSomeFeature) {
 *         this.featureMounted();
 *       } else {
 *         this.originalMounted();
 *       }
 *     }
 *
 * It can then be checked and tested in the url, for example:
 *      https://staging.whirli.com/toys?toggle_testToggle=true
 * Each feature toggle name MUST have ?toggle_ before it in the url and =true at the end
 *
 * IMPORTANT --> For the query param to work the below 'toggles' objects need to be set to false, and
 * the ':value=true' always set to true in the HTML
 *
 * EXCEPTION --> the query param for some reason does not work on the home page so if anything on the homepage is to be
 * wrapped in a feature flag it will have to be done the old way where you turn the toggles object below to true and
 * adjust the PRs accordingly.
 *
 */

/**
 *  Flag                    Date            Owner                      Short Desc
 *  releaseBlog             17 July 2020    yasmin@hexdigital.com      The blog feature.
 *  searchSuggestions       28 Aug 2020     jessica@whirli.com         Browse toys search suggestions feature.
 *  giftingDiscount         1 Sept 2020     john@whirli.com            Gifting Discount for subscribed users.
 *  electronicDelivery      9 Sept 2020     john@whirli.com            Adding Electronic Delivery to Gift.
 *  planRecommender         29 Sept 2020    john@whirli.com            Plan Recommender feature.
 *  electronicDeliveryDate  30 Oct 2020     john@whirli.com            Add date picker for delivery date
 *  popularScore            16 Nov 2020     john@whirli.com            Change Popular Score sort to new score
 *  productLandingPage      11 Nov 2021     yuliya@whirli.com          Product page LP v2
 *  waitSpot                25 Mar 2021     jessica@whirli.com         WaitSpot feature v1
 *  review                  31 Mar 2021     yuliya@whirli.com          Review feature v1
 *  reviewToysPage          13 Apr 2021     yuliya@whirli.com          Review feature v1
 *  myPlaytime              28 May 2021     liam@whirli.com            My Playtime
 *  referralsBanner         30 July 2021    yuliya@whirli.com          Referrals banner
 */

// See: https://github.com/stephenkr/nuxt-feature-toggle
export const featureToggleConfig = {
  queryString: true,
  toggles: {
    electronicDeliveryDate: true,
    popularScore: true,
    waitSpot: true,
    review: true,
    reviewToysPage: true,
    myPlaytime: true,
    greenFriday: true,
    childProfiles: true,
  },
};
