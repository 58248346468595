var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$route.name !== "register"
    ? _c(
        "PromoBanner",
        {
          attrs: {
            url: _vm.generateDiscountUrl(),
            justify: "center",
            "has-icon": false,
            "ga-label": "Christmas",
            type: "referral"
          }
        },
        [
          _c(
            "BaseText",
            { staticClass: "text", attrs: { tag: "span", color: "white" } },
            [_vm._v(_vm._s(_vm.bannerText))]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }