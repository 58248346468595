var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "40",
        height: "40",
        viewBox: "0 0 40 40",
        fill: "none",
        "xmlns:v": "https://vecta.io/nano"
      }
    },
    [
      _c("circle", {
        attrs: {
          cx: "20.215",
          cy: "20.215",
          r: "19.5",
          fill: "#ffb8ed",
          stroke: "#fff"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M12.886 28.468a6.79 6.79 0 0 1-.814-.704 7.15 7.15 0 0 1-.525-.599 6.94 6.94 0 0 1-1.468-4.432 3.43 3.43 0 0 1-1.395-3.693 3.43 3.43 0 0 1 3.004-2.561 3.45 3.45 0 0 1-.267-.545c-.932.169-1.821.525-2.612 1.047s-1.467 1.199-1.989 1.991-.877 1.68-1.045 2.613-.145 1.889.067 2.813.61 1.794 1.169 2.559 1.267 1.409 2.082 1.893 1.719.797 2.659.921 1.894.056 2.807-.2l-1.147-.746-.527-.357z",
          fill: "#51247a"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M36.603 13.009c.1.717-.166 1.649-.561 2.576l-.191-.071c-.342-.812-1.084-1.905-2.159-3.001.37.751.332 1.978.151 3.134a2.69 2.69 0 0 0-.194.1 12.24 12.24 0 0 0-1.815-1.76c.513.79.565 2.253.487 3.535a2.52 2.52 0 0 0-.056.322c-1.063-.068-2.149-.279-2.742-.757.779 1.216 1.627 2.119 2.3 2.596a11.79 11.79 0 0 1-.975 2.069c-.809 1.358-1.847 2.504-2.926 3.227a2.77 2.77 0 0 0-.759 3.841c.202.302.462.561.765.763s.642.342.999.412a2.77 2.77 0 0 0 2.078-.416c3.002-2.013 5.472-5.646 6.418-9.271a13.09 13.09 0 0 0 .407-1.847 9.2 9.2 0 0 0 .035-1.466c-.093-.996-.48-2.424-1.25-3.985l-.01.002zM16.448 31.046l-1.083-.211-.463-.121a11.68 11.68 0 0 1-2.127-.84 6.76 6.76 0 0 0 .111-1.406 6.8 6.8 0 0 1-.814-.704c-.263.382-.553.746-.867 1.088l-.013-.012-.262-.196c-.935-.88-1.9-1.983-2.067-2.909a12.25 12.25 0 0 0-.116 2.528l-.211.06c-.917-.724-1.79-1.586-2.036-2.386-.037 1.539.166 2.843.48 3.67a2.06 2.06 0 0 0-.091.181c-.923-.402-1.754-.9-2.174-1.494.722 2.373 2.325 4.294 2.891 4.764 2.559 2.145 6.31 3.502 9.839 3.625.166 0 .349.013.521.013a13.24 13.24 0 0 0 2.184-.166 2.76 2.76 0 0 0 .332-.078c-.86-1.968-2.225-3.796-4.034-5.399v-.005z",
          fill: "#00994c"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          d:
            "M21.479 40.177l-.106-.767c-.174-1.02-.472-2.016-.887-2.964-.86-1.968-2.225-3.796-4.034-5.399l-1.129-.927-.759-.55-1.148-.746-.526-.357c-.29-.213-.562-.448-.814-.704-.186-.19-.361-.39-.525-.599a6.94 6.94 0 0 1-1.468-4.432 3.43 3.43 0 0 1-1.395-3.693 3.43 3.43 0 0 1 3.004-2.561c-.386-.637-.554-1.382-.48-2.123a3.44 3.44 0 0 1 .891-1.986c.504-.548 1.172-.917 1.905-1.053s1.488-.029 2.155.302a3.54 3.54 0 0 1 .624.405l.482-.963a2.57 2.57 0 0 1 .345-3.516c.258-.222.557-.391.88-.497s.664-.147 1.003-.121a5.81 5.81 0 0 1 1.827-1.495c1.101-.578 2.358-.791 3.588-.609s2.371.75 3.259 1.621 1.475 2.002 1.678 3.229.012 2.487-.546 3.598l-3.701 7.392c1.771 1.557 3.356 3.314 4.724 5.234a26.98 26.98 0 0 1 4.062 8.427c-3.344 3.359-7.875 5.537-12.91 5.851z",
          fill: "#21a247"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M10.986 21.945c.406.203.862.282 1.312.225s.873-.245 1.216-.542a2.29 2.29 0 0 0 .71-1.126c.12-.437.108-.901-.035-1.331s-.411-.809-.769-1.087a2.29 2.29 0 0 0-1.243-.477c-.452-.032-.904.07-1.299.294s-.713.561-.916.966c-.272.544-.318 1.173-.126 1.75s.605 1.054 1.149 1.327z",
          fill: "#fff"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M12.403 19.115c-.095-.046-.197-.075-.302-.085.041.068.059.147.051.226s-.042.153-.096.211-.126.097-.204.111-.159.002-.229-.034c-.055-.028-.101-.069-.136-.119a.37.37 0 0 1-.063-.17.86.86 0 0 0-.189.249.87.87 0 0 0 .12.963.87.87 0 0 0 .936.256.87.87 0 0 0 .592-.769.87.87 0 0 0-.486-.84h.007z",
          fill: "#1d1d1b"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M18.628 10.81a1.48 1.48 0 0 1-.741-.857 1.48 1.48 0 0 1 .082-1.13 1.48 1.48 0 0 1 .857-.741 1.48 1.48 0 0 1 1.131.082l-1.329 2.647z",
          fill: "#003f23"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M16.252 13.083a2.29 2.29 0 0 0-1.991-.657 2.29 2.29 0 0 0-.996.414c-.294.215-.533.497-.696.823a2.29 2.29 0 0 0 .022 2.096 2.29 2.29 0 0 0 1.718 1.201l1.943-3.877z",
          fill: "#fff"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M15.015 13.91c-.094-.046-.196-.075-.3-.085.028.047.046.1.051.155s-.002.11-.021.162-.049.098-.089.137-.087.067-.14.085-.108.023-.162.016-.107-.026-.153-.056-.085-.07-.114-.117-.046-.1-.052-.155a.86.86 0 0 0-.189.249.87.87 0 0 0 .191 1.033.87.87 0 0 0 1.046.096l.407-.815a.87.87 0 0 0-.475-.704z",
          fill: "#1d1d1b"
        }
      }),
      _vm._v(" "),
      _c("g", { attrs: { fill: "#fff" } }, [
        _c("path", {
          attrs: {
            d:
              "M27.242 5.664l-1.727 3.447a1.93 1.93 0 0 0 2.574-.867 1.93 1.93 0 0 0-.847-2.581z"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M26.375 11.699a1.93 1.93 0 0 0-.86-2.587l-1.727 3.447c.226.114.473.181.725.2s.506-.014.747-.093.463-.206.654-.372.348-.368.461-.594z"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M24.646 15.148a1.93 1.93 0 0 0-.86-2.589l-1.727 3.449a1.93 1.93 0 0 0 1.472.106c.485-.161.886-.509 1.115-.966z"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M22.921 18.595a1.93 1.93 0 0 0-.86-2.587l-1.732 3.448a1.93 1.93 0 0 0 2.587-.86h.005z"
          }
        })
      ]),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M27.425 7.188l.664-1.328-.453-.214-.664 1.319a.69.69 0 0 0-.694.379.69.69 0 0 0 .115.783l-1.148 2.292c-.141-.015-.283.013-.407.081s-.225.172-.288.299-.086.27-.065.41.083.27.18.373l-1.148 2.29a.69.69 0 0 0-.407.081c-.124.068-.225.172-.288.299a.69.69 0 0 0 .115.783l-1.148 2.29a.7.7 0 0 0-.74.498c-.046.157-.035.324.03.474s.18.271.326.345a.7.7 0 0 0 .869-.201.7.7 0 0 0 .141-.453c-.007-.163-.071-.318-.181-.439l1.148-2.29c.14.014.282-.014.405-.082s.223-.172.286-.298.086-.269.066-.408-.082-.27-.178-.373l1.148-2.29c.141.015.283-.013.407-.081a.69.69 0 0 0 .353-.708.69.69 0 0 0-.18-.373l1.148-2.29c.141.015.283-.013.407-.081s.224-.172.288-.299.086-.27.065-.41-.083-.27-.18-.373l.008-.002z",
          fill: "#d0d0d0"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M16.275 23.009c-.179-.002-.35-.073-.478-.198s-.202-.295-.207-.474a.69.69 0 0 1 .645-.711c.583-.035 1.146-.223 1.634-.543s.884-.763 1.148-1.284l7.307-14.595c.039-.084.095-.16.163-.222s.149-.11.237-.141.18-.043.273-.037a.69.69 0 0 1 .482.242.69.69 0 0 1 .133.241c.028.088.038.182.029.274s-.036.182-.08.263l-7.307 14.594a4.73 4.73 0 0 1-1.622 1.823c-.691.455-1.488.722-2.314.773l-.043-.005z",
          fill: "#148c3e"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          d:
            "M34.051 34.658c-1.707 1.635-3.702 2.971-5.903 3.923-.313-1.157-.731-2.306-1.252-3.436-.197-.407-.311-.85-.335-1.302s.042-.904.195-1.331.389-.818.695-1.151.675-.603 1.086-.793.856-.295 1.309-.31.904.059 1.327.22a3.41 3.41 0 0 1 1.91 1.818l.969 2.362z",
          fill: "#a7d1a0"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M8.94 18.359c.261-.522.651-.968 1.133-1.297s1.039-.53 1.62-.584c-.274-.447-.441-.95-.49-1.472s.023-1.047.209-1.537a3.43 3.43 0 0 1 .865-1.287c.383-.357.843-.622 1.344-.774l-.143-.279c-.282-.554-.669-1.047-1.142-1.452a4.73 4.73 0 0 0-1.61-.904c-.591-.193-1.214-.267-1.834-.219s-1.224.218-1.778.499a4.73 4.73 0 0 0-2.356 2.752c-.193.591-.267 1.214-.219 1.834s.218 1.224.499 1.778l5.138 10.108a4.71 4.71 0 0 0 1.375 1.639 6.94 6.94 0 0 1-1.468-4.432c-.687-.468-1.181-1.169-1.391-1.974s-.12-1.658.252-2.402l-.005.003z",
          fill: "#6d3e91"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M16.448 31.046l-1.129-.927.046.716 1.083.211z",
          fill: "#003f23"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M19.499 6.927c.5-.618 1.122-1.127 1.827-1.495a2.57 2.57 0 0 0-2.422.064 2.57 2.57 0 0 0-1.291 2.05c.258-.223.558-.392.881-.498s.665-.148 1.005-.121z",
          fill: "#148c3e"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }