import { Route } from 'vue-router';
import { GlobalInjects } from '~/index.d';
import { registrationPageCheck, subTermsAndConditionsPageCheck } from '~/helpers/registration-step-new';
import { isDiscountOfferPage } from '~/helpers/offer';

export default class Navigation {
  protected app: GlobalInjects;
  protected route: Route;
  protected store: any;

  constructor(app: GlobalInjects, route: Route, store: any) {
    this.app = app;
    this.route = route;
    this.store = store;
  }

  public disabled(): boolean {
    return <boolean>(
      (this.disabledByLandingPages() || (this.route.name && this.route.name.startsWith('register')))
    );
  }

  public setRoute(route: Route): void {
    this.route = route;
  }

  private disabledByLandingPages(): boolean {
    return <boolean>(
      ((registrationPageCheck(this.route.name) ||
        isDiscountOfferPage(this.route.name) ||
        subTermsAndConditionsPageCheck(this.route.name)) &&
        this.route.name &&
        (this.route.name.startsWith('registration') || this.route.name.startsWith('lp')))
    );
  }
}
