import Vue from 'vue'
import Zendesk from '@dansmaculotte/vue-zendesk'

const KEY = '04559462-0026-4329-b96b-67254fe497cb'
const DISABLED = false
const HIDE_ON_LOAD = false
const SETTINGS = {"webWidget":{"color":{"theme":"#3a0e7b"}}}

export default function (context) {
  Vue.use(Zendesk, {
    key: KEY,
    disabled: DISABLED,
    hideOnLoad: HIDE_ON_LOAD,
    settings: SETTINGS
  })
}
