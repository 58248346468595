import { BrowserQueryParams } from '~/types/products/query';

/**
 * { key: value, key2: value2, key3: value3 }
 * becomes
 * "key=value&key2=value=2&key3=value3"
 *
 * @todo This currently doesn't support more complex query strings, such as arrays.
 */
export function mapObjectToString(queryParams?: Record<string | number, any> | null): string {
  if (!queryParams) return '';

  return Object.keys(queryParams)
    .map((paramKey: string | number) => `${paramKey}=${queryParams[paramKey]}`)
    .join('&');
}

export function getQueryString(queryParams?: Record<string | number, any> | null): string {
  if (!queryParams) return '';

  return `?${mapObjectToString(queryParams)}`;
}

export function createUrlWithQuery(url: string, queryParams?: Record<string | number, any> | null): string {
  if (!queryParams) return url;

  const queryDelimiterIndex = url.indexOf('?');
  const urlContainsQueryDelimiter = queryDelimiterIndex !== -1;
  const delimiterIsNotLastCharInUrl = url.length !== queryDelimiterIndex + 1;

  const queryString = mapObjectToString(queryParams);

  let finalUrl = url;

  if (queryString.length) {
    if (urlContainsQueryDelimiter) {
      if (delimiterIsNotLastCharInUrl) {
        finalUrl += '&';
      }
    } else {
      finalUrl += '?';
    }

    finalUrl += queryString;
  }

  return finalUrl;
}

export function createProductsUrlWithQuery(
  route: string,
  pagePath: string,
  userFacingParams: Partial<BrowserQueryParams>
): string {
  // if we are on an association page /toys/[association_type]/[association_name]
  // dont append the association match URL param
  if (route === 'toys-association-slug') {
    delete userFacingParams.age;
    delete userFacingParams.brand;
    delete userFacingParams.category;
  }
  return createUrlWithQuery(pagePath, userFacingParams);
}
