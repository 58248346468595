




























import 'reflect-metadata';
import { Vue, Component } from 'nuxt-property-decorator';
import WhirliLogo from '~/components/partials/logo/WhirliLogo/WhirliLogo.vue';

@Component({
  components: {
    WhirliLogo,
  },
})
export default class MaintenanceLayout extends Vue {}
