






































import { Prop, Component, mixins } from 'nuxt-property-decorator';
import InputMixin from '../inputMixin.vue';
import MixinRadioAndChecks from '../MixinRadioAndChecks.vue';
import BaseInput from '../BaseInput/BaseInput.vue';

@Component({
  inheritAttrs: false,
  components: {
    BaseInput,
  },
})
export default class BaseRadioButton extends mixins(InputMixin, MixinRadioAndChecks) {
  @Prop({ default: false }) readonly row!: boolean;
  @Prop({ default: false }) readonly rowReverse!: boolean;
  @Prop({ required: true }) readonly value!: string;

  get loadAnimationData(): Function {
    return (): Promise<any> => {
      return import('~/assets/animations/radio-button.json');
    };
  }

  get listeners() {
    const vm = this;
    return {
      change(event: { target: { value: any } }) {
        vm.$emit('change', event.target.value);
      },
    };
  }
}
