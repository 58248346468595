var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "intersect",
          rawName: "v-intersect.once",
          value: _vm.onIntersect,
          expression: "onIntersect",
          modifiers: { once: true }
        }
      ],
      staticClass: "v-product-image",
      class: {
        "v-product-image--loaded": _vm.hasLoaded,
        "v-product-image--error": _vm.hasError
      }
    },
    [
      _c(
        _vm.tag,
        _vm._b(
          {
            tag: "component",
            staticClass: "v-product-image__image",
            class:
              ((_obj = {}),
              (_obj["v-product-image__image--" + _vm.size] = _vm.size),
              (_obj["v-product-image__image--loaded"] = _vm.hasLoaded),
              (_obj["v-product-image__image--error"] = _vm.hasError),
              _obj),
            attrs: { to: _vm.to }
          },
          "component",
          _vm.$attrs,
          false
        ),
        [
          _vm.shouldLoadImage
            ? _c("BaseImage", {
                attrs: {
                  image: _vm.image,
                  alt: _vm.alt,
                  "object-fit": "contain"
                },
                on: {
                  "image:error": function($event) {
                    _vm.hasError = true
                  },
                  "image:load": function($event) {
                    _vm.hasLoaded = true
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }