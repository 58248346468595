var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "40",
        height: "40",
        viewBox: "0 0 40 40",
        fill: "none",
        "xmlns:v": "https://vecta.io/nano"
      }
    },
    [
      _c("circle", {
        attrs: {
          cx: "20.554",
          cy: "20.215",
          r: "19.5",
          fill: "#e8005f",
          stroke: "#fff"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M26.8 10.403c0 1.245-.368 2.462-1.059 3.497s-1.674 1.842-2.824 2.319-2.415.602-3.636.359-2.343-.841-3.223-1.721-1.48-2.001-1.724-3.222-.119-2.486.357-3.636 1.282-2.133 2.317-2.825 2.251-1.062 3.496-1.062c1.669 0 3.269.663 4.449 1.843s1.843 2.78 1.843 4.449l.004-.001z",
          fill: "#eb589a"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          d:
            "M8.931 36.481V20.609a11.54 11.54 0 0 1 .417-3.08l-.317-.167a2.42 2.42 0 0 1-.733-.596 2.41 2.41 0 0 1-.448-.83A2.4 2.4 0 0 1 7.755 15c.031-.315.123-.622.273-.902s.353-.528.599-.729a2.43 2.43 0 0 1 .834-.446c.305-.093.624-.125.941-.094s.625.123.906.271l.277.146c1.094-1.325 2.47-2.393 4.028-3.126a11.71 11.71 0 0 1 9.968 0c1.558.733 2.934 1.801 4.028 3.126l.277-.148c.281-.149.589-.241.906-.271s.637.002.941.095.588.245.833.447.449.45.598.73h-.005c.301.566.365 1.227.176 1.839s-.613 1.124-1.181 1.425l-.317.167a11.59 11.59 0 0 1 .415 3.08v15.842a19.91 19.91 0 0 1-11.682 3.764 19.91 19.91 0 0 1-11.64-3.734z",
          fill: "#7f1340"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M25.483 13.916c.037.018.067.053.082.096a.2.2 0 0 1-.002.135l-.394 1.048c-.008.021-.019.04-.034.056s-.031.029-.05.037-.039.013-.059.012-.04-.005-.058-.014-.035-.022-.049-.039-.025-.036-.032-.057-.011-.044-.011-.067.005-.046.012-.067l.394-1.047c.008-.021.019-.04.034-.056s.031-.029.05-.037.039-.013.059-.012.04.005.058.014zm1.267.744c.016.018.029.039.038.061a.19.19 0 0 1 .012.072.21.21 0 0 1-.015.071c-.009.022-.024.042-.041.059l-.851.837a.18.18 0 0 1-.059.046c-.023.011-.047.017-.073.017a.18.18 0 0 1-.073-.014c-.023-.01-.044-.025-.061-.044s-.03-.041-.039-.065a.19.19 0 0 1 .011-.15c.012-.023.028-.043.048-.059l.851-.838c.034-.035.08-.054.128-.054s.094.02.128.054l-.003.005zm-2.572-.931a.19.19 0 0 0-.067.012c-.021.008-.041.02-.057.036s-.029.033-.038.053-.014.041-.014.063v1.079c.004.042.024.08.056.109a.18.18 0 0 0 .119.044.18.18 0 0 0 .119-.044c.032-.028.052-.067.056-.109v-1.079a.16.16 0 0 0-.013-.064c-.009-.021-.021-.038-.038-.054s-.035-.028-.057-.036-.044-.013-.067-.013v.002z",
          fill: "#1d1d1b"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M26.975 17.747a2.8 2.8 0 0 1-1.727 2.584c-.511.212-1.074.267-1.616.159s-1.041-.374-1.432-.766a2.8 2.8 0 0 1-.766-1.432c-.108-.543-.053-1.105.159-1.616s.57-.948 1.03-1.255a2.8 2.8 0 0 1 1.554-.471c.367 0 .731.072 1.071.213s.648.347.908.606.466.568.606.908.213.703.213 1.071z",
          fill: "#fff"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M24.783 17.583a.44.44 0 0 1-.265-.09c-.076-.058-.132-.139-.158-.231a.44.44 0 0 1 .19-.495 1.05 1.05 0 0 0-1.235 1.576 1.05 1.05 0 0 0 1.913-.593 1.04 1.04 0 0 0-.069-.373.44.44 0 0 1-.373.205l-.002.001zm-9.232-3.667c-.046.019-.083.053-.102.097a.16.16 0 0 0 .002.134l.491 1.048c.01.021.024.04.042.056s.039.029.063.037.048.013.073.012.05-.005.073-.014.044-.022.061-.039.031-.036.041-.057.014-.044.014-.067-.006-.046-.016-.067l-.493-1.047c-.01-.021-.024-.04-.042-.056s-.039-.029-.062-.037a.2.2 0 0 0-.073-.012c-.025.001-.05.005-.073.014zm-1.118.745c-.029.036-.046.084-.046.134a.21.21 0 0 0 .046.134l.747.854c.031.029.07.043.109.04s.076-.024.103-.059a.21.21 0 0 0 .043-.127c.001-.047-.013-.093-.039-.129l-.748-.854c-.03-.035-.07-.055-.112-.055s-.082.02-.112.055l.009.005zm2.576-.932a.19.19 0 0 1 .067.012c.021.008.041.02.057.035s.029.033.038.053.014.041.014.063v1.07a.16.16 0 0 1-.051.116.18.18 0 0 1-.124.048.18.18 0 0 1-.124-.048c-.033-.031-.051-.072-.051-.116v-1.07c0-.022.004-.043.012-.063s.021-.038.038-.054.035-.027.057-.036.044-.013.067-.013v.002z",
          fill: "#1d1d1b"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M14.212 17.747a2.8 2.8 0 0 0 .471 1.554c.307.46.744.819 1.255 1.03s1.073.267 1.616.159a2.8 2.8 0 0 0 2.198-2.198c.108-.543.052-1.105-.159-1.616s-.57-.948-1.03-1.255a2.8 2.8 0 0 0-1.554-.471c-.742 0-1.453.295-1.978.819s-.819 1.236-.819 1.978z",
          fill: "#fff"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M17.617 17.577a.44.44 0 0 1-.263-.089c-.076-.057-.131-.137-.157-.229a.44.44 0 0 1 .188-.491 1.05 1.05 0 0 0-1.256.407 1.05 1.05 0 0 0 .137 1.313 1.05 1.05 0 0 0 1.313.139 1.05 1.05 0 0 0 .409-1.255c-.039.062-.094.114-.158.149s-.137.054-.21.054l-.003.001z",
          fill: "#1d1d1b"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M20.499 28.762a2.07 2.07 0 0 0 .805-.16 2.1 2.1 0 0 0 .683-.461 2.13 2.13 0 0 0 .456-.691c.106-.259.16-.536.16-.816v-2.561c0-.564-.221-1.106-.614-1.505s-.927-.623-1.483-.623-1.09.224-1.483.623-.614.941-.614 1.505v2.56c0 .563.22 1.104.612 1.503s.924.624 1.479.626z",
          fill: "#fff"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M20.507 24.566c-.046 0-.091.018-.124.051s-.051.077-.051.124v3.86c.004.044.024.085.056.114s.075.046.119.046.087-.017.119-.046.052-.07.056-.114v-3.86c0-.046-.018-.09-.051-.123s-.077-.051-.123-.052z",
          fill: "#c6c6c6"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M15.262 22.818a3.33 3.33 0 0 0 .968 2.349c.62.623 1.46.973 2.337.973h3.881c.876 0 1.717-.35 2.337-.973a3.33 3.33 0 0 0 0-4.698c-.62-.623-1.46-.973-2.337-.973h-3.881a3.29 3.29 0 0 0-1.264.253c-.401.167-.765.412-1.072.72s-.55.675-.716 1.078-.252.835-.252 1.271z",
          fill: "#9e3539"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M17.884 20.545a1.02 1.02 0 0 0 .325.741c.208.197.49.307.785.308h3.024c.295 0 .577-.111.785-.307a1.02 1.02 0 0 0 .325-.742 1.02 1.02 0 0 0-.325-.742c-.208-.197-.491-.307-.785-.307h-3.024c-.294 0-.577.111-.785.308a1.02 1.02 0 0 0-.325.741zm-3.147 3.322a.18.18 0 0 0 .013.067c.008.021.021.04.036.056a.17.17 0 0 0 .054.038c.02.009.042.013.063.013h1.08c.042-.004.081-.024.109-.056s.044-.075.044-.119a.18.18 0 0 0-.044-.119c-.028-.032-.067-.052-.109-.056h-1.08c-.022 0-.043.005-.063.013s-.039.022-.054.038-.027.036-.036.057a.17.17 0 0 0-.012.067zm-.173-1.251a.18.18 0 0 0 .029.13c.013.019.029.034.048.046a.16.16 0 0 0 .061.023l1.066.175c.022.004.043.003.065-.003s.041-.015.059-.029a.17.17 0 0 0 .044-.05c.011-.019.019-.041.023-.064s.002-.046-.003-.068a.18.18 0 0 0-.027-.062.17.17 0 0 0-.108-.07l-1.068-.175c-.021-.004-.043-.003-.065.002s-.041.015-.059.029a.19.19 0 0 0-.044.05c-.012.019-.019.041-.022.064zm11.886 1.251a.17.17 0 0 1-.055.123c-.035.033-.082.051-.132.051h-1.214c-.047-.004-.09-.024-.122-.056a.17.17 0 0 1-.05-.119.17.17 0 0 1 .05-.119c.032-.032.075-.052.122-.056h1.214c.025 0 .049.005.072.013s.043.022.061.038.031.036.041.057.014.044.014.067zm.173-1.251c.007.046-.005.093-.034.13a.19.19 0 0 1-.122.069l-1.199.175a.2.2 0 0 1-.073-.003c-.024-.005-.046-.015-.066-.029a.18.18 0 0 1-.049-.05c-.013-.02-.021-.041-.025-.064s-.003-.046.003-.068a.17.17 0 0 1 .031-.062.2.2 0 0 1 .054-.046c.021-.012.044-.02.068-.023l1.2-.175c.049-.007.099.004.139.031s.067.068.075.114z",
          fill: "#1d1d1b"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M30.868 37.365l-.022-.123c-.086-.568.053-1.147.386-1.61a5.01 5.01 0 0 0 .899-2.3c.049-.392.051-.788.008-1.18l.071-7.235a1.98 1.98 0 0 1 .464.264l-.001.002c1.954 1.459 3.261 3.647 3.634 6.085a9.45 9.45 0 0 1 .105 1.16 20.1 20.1 0 0 1-5.543 4.937zM4.709 32.399a20.12 20.12 0 0 0 4.702 4.416 7.61 7.61 0 0 1-.367-4.418h-.002l-.153-7.481a11.77 11.77 0 0 0-4.18 7.484z",
          "fill-rule": "evenodd",
          fill: "#6a0437"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M14.266 10.751c-.067 0-.133-.016-.192-.047s-.116-.084-.153-.145-.057-.13-.058-.201V8.981c0-.066.016-.131.048-.189s.078-.107.135-.143a12.13 12.13 0 0 1 13.097 0c.056.036.102.085.134.143a.39.39 0 0 1 .048.188v1.377a.39.39 0 0 1-.056.203.4.4 0 0 1-.154.146c-.064.034-.136.05-.208.047s-.143-.025-.204-.064c-.76-.488-4.724-.861-6.108-.861s-5.349.373-6.108.861a.41.41 0 0 1-.22.065v-.003z",
          fill: "#ed6ea7"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M9.665 16.524a12.33 12.33 0 0 1 1.226-2.559 1.26 1.26 0 0 0-.92.051 1.4 1.4 0 0 0-.677.675 1.57 1.57 0 0 0-.121.987c.068.334.241.632.491.842v.002zm21.801 0a1.46 1.46 0 0 0 .543-.843 1.45 1.45 0 0 0-.131-.99 1.47 1.47 0 0 0-.745-.676c-.321-.132-.679-.15-1.012-.048a11.82 11.82 0 0 1 1.345 2.555v.002z",
          fill: "#530b42"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }