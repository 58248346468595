import { Plugin } from '@nuxt/types';
import GoogleTagManager from './GoogleTagManager';

declare global {
  interface Window {
    dataLayer: object[];
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const plugin: Plugin = function({ app }, inject): void {
  inject('gtm', new GoogleTagManager(app));
};

export default plugin;
