var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseInput", {
    staticClass: "v-radio-btn",
    class: { "v-radio-btn--focus": _vm.focus, "v-radio-btn--row": _vm.row },
    style: { flexDirection: _vm.rowReverse ? "row-reverse" : "column" },
    attrs: {
      id: _vm.id,
      "inner-classes": "v-radio-btn__inner",
      errors: _vm.errors,
      name: _vm.name,
      required: _vm.required
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function() {
            return [_vm._t("default")]
          },
          proxy: true
        },
        {
          key: "input",
          fn: function(slotProps) {
            return [
              _c("span", { staticClass: "v-radio-btn__underlay" }, [
                _c(
                  "input",
                  _vm._g(
                    _vm._b(
                      {
                        staticClass: "v-radio-btn__input",
                        attrs: {
                          id: _vm.id,
                          "aria-describedby": slotProps.errorId,
                          "aria-label": _vm.ariaLabel,
                          "aria-labelledby": _vm.ariaLabelledby,
                          name: _vm.name,
                          required: _vm.required,
                          tabindex: _vm.tabindex,
                          type: "radio"
                        },
                        domProps: { checked: _vm.checked, value: _vm.value }
                      },
                      "input",
                      _vm.$attrs,
                      false
                    ),
                    _vm.listeners
                  )
                ),
                _vm._v(" "),
                _c("span", {
                  class: [
                    "v-radio-btn__overlay",
                    {
                      "v-radio-btn__overlay--checked":
                        _vm.checked && !_vm.loaded
                    }
                  ]
                }),
                _vm._v(" "),
                _c("div", {
                  ref: "renderElement",
                  staticClass: "v-radio-btn__radio-btn",
                  attrs: { "aria-checked": _vm.checked, role: "radio" }
                })
              ])
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }