import Events from './Events';

export default class AnalyticsEvents extends Events {
  /**
   * Tracks a Google Analytics event.
   *
   * @param {String|Number} category
   * @param {String|Number} action
   * @param {String|Number} label
   * @param {String|Number} value
   */
  event(
    category: string | number,
    action: string | number,
    label: string | number,
    value: string | number = 0
  ) {
    this.data.pushEvent('GoogleAnalyticsEvent', {
      event_category: category,
      event_action: action,
      event_label: label,
      event_value: value,
    });
  }

  /**
   * Fires a Google Tag Manager dataLayer virtual page view event to hook into.
   * Also passes the virtual URL.
   *
   * @param {String} path
   */
  pageView(path: string) {
    this.data.pushEvent('PageView', {
      page_path: path,
    });
  }

  /**
   * Tracks form errors from a Form object.
   *
   * @param {Form} form
   */
  formErrors(form: any) {
    Object.keys(form.errors.errors).forEach((key) => {
      this.data.pushEvent('FormError', {
        input_name: key,
        input_message: form.errors.get(key),
      });
    });
  }
}
