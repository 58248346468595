













import { Vue, Component } from 'nuxt-property-decorator';
import PromoBanner from '~/components/prefabs/banners/PromoBanner/PromoBanner.vue';
import { isDateBeforeTargetDate } from '~/helpers/dateHelper';

@Component({
  components: {
    PromoBanner,
  },
})
export default class OffersBanner extends Vue {
  discounts: { discount: number; dates: string }[] = [
    { discount: 35, dates: '13.11-24.11' },
    { discount: 50, dates: '24.11-28.11' },
    { discount: 30, dates: '28.11-02.01' }, // Add the Christmas discount
  ];

  get currentDiscount(): { discount: number; dates: string } | null {
    const currentDate = new Date();

    for (const discountEntry of this.discounts) {
      const [startStr, endStr] = discountEntry.dates.split('-');
      const [startDay, startMonth] = startStr.split('.').map(Number);
      const [endDay, endMonth] = endStr.split('.').map(Number);

      const start = new Date(currentDate.getFullYear(), startMonth - 1, startDay);
      const end = new Date(currentDate.getFullYear(), endMonth - 1, endDay);

      // Adjust for year rollover
      if (end < start) {
        end.setFullYear(end.getFullYear() + 1);
      }

      if (currentDate >= start && currentDate <= end) {
        return discountEntry;
      }
    }

    return null;
  }

  get currentDiscountText(): string | null {
    if (isDateBeforeTargetDate()) {
      return `Join Whirli with ${this.currentDiscount?.discount} -
      ${this.currentDiscount?.discount + 20}% OFF. Code: ${this.currentDiscountCode}`;
    } else {
      return '2 Months FREE + First Delivery FREE!';
    }
  }

  get currentDiscountCode(): string | null {
    return null;
    // if (!this.currentDiscount) return null;
    // const currentDate = new Date();
    // return currentDate < new Date('2023-11-28') ? `BF${this.currentDiscount?.discount}` : `XMAS`;
  }

  get bannerText(): string | null {
    if (this.$route.name && this.$route.name.startsWith('gift') && isDateBeforeTargetDate()) {
      return 'Unwrap 30% Off Whirli Gift Cards! Code:GIFT30';
    }
    return this.currentDiscountText;
  }

  generateDiscountUrl(): string {
    if (isDateBeforeTargetDate()) {
      return `/register?discountCode=${this.currentDiscountCode}`;
    } else {
      return `/offers/two-months-free`;
    }
  }
}
