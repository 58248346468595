import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '~/store/index';

export const types = {
  PUT_DISCOUNT_CODE: 'PUT_DISCOUNT_CODE',
  RESET_CODE: 'RESET_CODE',
};

export interface ModuleState {
  name: string | null;
}

export const state = (): ModuleState => ({
  name: null,
});

export const getters: GetterTree<ModuleState, RootState> = {
  getCode: (state): string | null => {
    return state.name;
  },
};

export const actions: ActionTree<ModuleState, RootState> = {
  putDiscountCodeName({ commit }, discountCodeName: string | null) {
    this.$logger.store(`action`, `[registration/discountCodeFromOffer/putDiscountCodeName]`, {
      discountCodeName,
    });

    commit(types.PUT_DISCOUNT_CODE, discountCodeName);
  },
  reset({ commit }) {
    this.$logger.store(`action`, `[registration/discountCodeFromOffer/reset]`);

    commit(types.RESET_CODE);
  },
};

export const mutations: MutationTree<ModuleState> = {
  [types.PUT_DISCOUNT_CODE](state, discountCodeName: string | null) {
    if (discountCodeName) {
      state.name = discountCodeName;
    } else {
      state.name = null;
    }
  },
  [types.RESET_CODE](state) {
    state.name = null;
  },
};
