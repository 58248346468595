




















import { Vue, Component, Prop } from 'nuxt-property-decorator';

@Component
export default class BaseTab extends Vue {
  @Prop({ required: true }) readonly id!: string;
  @Prop({ required: true }) readonly ariaControls!: string;
  @Prop({ default: false }) readonly isActive!: boolean;
  @Prop({ default: 'primary' }) readonly theme!: string;
}
