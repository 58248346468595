import { hasSubscriptionWithNotActiveStatus } from '~/helpers/models/user';
import { redirectWithoutCaching } from '~/helpers/redirect';

export default function(context) {
  const { app, store } = context;

  store.dispatch('registration/subscription/initialState', false);

  if (!app.$auth.user || !hasSubscriptionWithNotActiveStatus(app.$auth.user)) {
    return redirectWithoutCaching(context, '/register');
  }
}
