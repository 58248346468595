import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '~/store/index';
import { defaultPagination } from '~/config/products/pagination';

const types = {
  SET_PAGINATION: 'SET_PAGINATION',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SET_PER_PAGE: 'SET_PER_PAGE',
  SET_LAST_PAGE: 'SET_LAST_PAGE',
  SET_TOTAL: 'SET_TOTAL',
  RESET_PAGINATION: 'RESET_PAGINATION',
};

export interface ModuleState {
  perPage: number;
  currentPage: number;
  lastPage: number;
  total: number;
}

const defaults = { pagination: defaultPagination };

export const state = (): ModuleState => ({
  perPage: 24,
  currentPage: 1,
  lastPage: 1,
  total: 0,
});

export const actions: ActionTree<ModuleState, RootState> = {
  setPerPage({ commit }, perPage: number) {
    this.$logger.store(`action`, `[products/pagination/setPerPage]`, { perPage });

    commit(types.SET_PER_PAGE, perPage);
  },

  setCurrentPage({ commit }, currentPage: number) {
    this.$logger.store(`action`, `[products/pagination/setCurrentPage]`, { currentPage });

    commit(types.SET_CURRENT_PAGE, currentPage);
  },

  setLastPage({ commit }, lastPage: number) {
    this.$logger.store(`action`, `[products/pagination/setLastPage]`, { lastPage });

    commit(types.SET_LAST_PAGE, lastPage);
  },

  previous({ state, commit }) {
    this.$logger.store(`action`, `[products/pagination/previous]`);

    const previousPage = Math.max(1, state.currentPage - 1);
    commit(types.SET_CURRENT_PAGE, previousPage);
  },

  next({ state, commit }) {
    this.$logger.store(`action`, `[products/pagination/next]`);

    const nextPage = Math.min(state.lastPage, state.currentPage + 1);
    commit(types.SET_CURRENT_PAGE, nextPage);
  },

  reset({ commit }) {
    this.$logger.store(`action`, `[products/pagination/reset]`);

    commit(types.RESET_PAGINATION);
  },
};

export const mutations: MutationTree<ModuleState> = {
  [types.SET_CURRENT_PAGE](state, pageNumber: number) {
    state.currentPage = pageNumber;
  },

  [types.SET_PER_PAGE](state, perPage: number) {
    state.perPage = perPage;
  },

  [types.SET_LAST_PAGE](state, lastPage: number) {
    state.lastPage = lastPage;
  },

  [types.SET_TOTAL](state, total: number) {
    state.total = total;
  },

  [types.RESET_PAGINATION](state) {
    state.perPage = defaults.pagination.perPage;
    state.currentPage = defaults.pagination.currentPage;
    state.lastPage = defaults.pagination.lastPage;
    state.total = defaults.pagination.total;
  },
};
