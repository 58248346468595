import { render, staticRenderFns } from "./BaseToggleButton.vue?vue&type=template&id=1f4cbfbf&"
import script from "./BaseToggleButton.vue?vue&type=script&lang=ts&"
export * from "./BaseToggleButton.vue?vue&type=script&lang=ts&"
import style0 from "./BaseToggleButton.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/whirli-uwa/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1f4cbfbf')) {
      api.createRecord('1f4cbfbf', component.options)
    } else {
      api.reload('1f4cbfbf', component.options)
    }
    module.hot.accept("./BaseToggleButton.vue?vue&type=template&id=1f4cbfbf&", function () {
      api.rerender('1f4cbfbf', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/partials/buttons/BaseToggleButton/BaseToggleButton.vue"
export default component.exports