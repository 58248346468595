import { facebookbUser } from '../transformers/user';
import Events from './Events';
import { User } from '~/types/user';

export default class UserEvents extends Events {
  /**
   * Pushes the user data to the dataLayer
   *
   * Facebook needs to be re-initialized after this
   *
   * @param {User} user
   */
  auth(user: User): void {
    this.data.pushEvent('LoggedIn', {
      user_id: user.id,
      user_email: user.email,
      fb_user: facebookbUser(user),
    });
  }
}
