




























import { Component, mixins } from 'nuxt-property-decorator';
import BaseTextMixin from './BaseTextMixin.vue';
@Component
export default class BaseText extends mixins(BaseTextMixin) {}
