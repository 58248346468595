import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '~/store/index';
import {
  types,
  state as BState,
  getters as BGetters,
  actions as BActions,
  mutations as BMutations,
  ModuleState as BModuleState,
} from '~/helpers/store/discountCode';

export interface ModuleState extends BModuleState {}

export const state = (): ModuleState => BState();

const giftDiscountActions: ActionTree<ModuleState, RootState> = {
  putDiscountCode({ commit }, discountCode: ModuleState | null) {
    this.$logger.store(`action`, `[gift/discountCode/putDiscountCode]`, { discountCode });

    commit(types.PUT_DISCOUNT_CODE, discountCode);
  },
};

export const getters: GetterTree<ModuleState, RootState> = BGetters;

export const actions: ActionTree<ModuleState, RootState> = Object.assign({}, BActions, giftDiscountActions);

export const mutations: MutationTree<ModuleState> = BMutations;
