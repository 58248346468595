import * as screenBreakpoints from '~/constants/screen-breakpoints';

export const breakpoints: Array<string> = [
  screenBreakpoints.MOBILE,
  screenBreakpoints.TABLET,
  screenBreakpoints.SMALL_DESK,
  screenBreakpoints.LARGE_DESK,
  screenBreakpoints.HUGE_DESK,
];

interface MediaQuery {
  from?: string | null;
  until?: string | null;
}

export default function mq({ from = null, until = null }: MediaQuery, currentScreenSize: string): boolean {
  const currentBreakpointIndex = breakpoints.indexOf(currentScreenSize);

  if (from !== '' && from !== null) {
    const fromBreakpointIndex = breakpoints.indexOf(from || '');
    if (fromBreakpointIndex > currentBreakpointIndex) {
      return false;
    }
  }

  if (until !== '' && until !== null) {
    const untilBreakpointIndex = breakpoints.indexOf(until || '');
    if (untilBreakpointIndex <= currentBreakpointIndex) {
      return false;
    }
  }

  return true;
}
